import React, { useContext, useState } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import "assets/scss/profileCard.scss";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid2,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CustomInputField from "ui-component/custom-components/CustomInputField";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ABHA_ADDRESS } from "store/constant";
import { updateAbdmDetail } from "services/AbhaService";
import dayjs from "dayjs";
import abhaAddressSchema from "../../Common/ValidationSchema/abhaAddressValidation";

const AbhaDetails = ({ patientAbhaDetails, setPatientAbhaDetails, txnId, token, patientId }) => {
  const { handleClick } = useContext(ToastContext);
  const abhaAddressList = patientAbhaDetails?.abhaAddressList?.slice(0, 5);
  const [abhaCardPhoto, setAbhaCardPhoto] = useState(patientAbhaDetails?.abhaCard);
  const profilePhoto = patientAbhaDetails?.profile?.profilePhoto;
  const allowEditingAbhaAddress = patientAbhaDetails?.isEditAbhaAddress;

  const formatDateOfBirth = (day, month, year) => {
    if (!day || !month || !year) return "";
    return `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`;
  };

  const dayOfBirth = patientAbhaDetails?.profile?.dayOfBirth;
  const monthOfBirth = patientAbhaDetails?.profile?.monthOfBirth;
  const yearOfBirth = patientAbhaDetails?.profile?.yearOfBirth;

  const combinedDateOfBirth = formatDateOfBirth(dayOfBirth, monthOfBirth, yearOfBirth);
  const [isEditing, setIsEditing] = useState(false);
  const [preferredAbhaAddress, setPreferredAbhaAddress] = useState(
    patientAbhaDetails?.profile?.preferredAbhaAddress || ""
  );
  const [abhaAddressError, setAbhaAddressError] = useState("");
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setPreferredAbhaAddress(event.target.value);
  };

  const handleAbhaAddressValidation = (abhaAddress) => {
    try {
      abhaAddressSchema.validateSync(abhaAddress);
      return true;
    } catch (error) {
      setAbhaAddressError(error.message);
      return false;
    }
  };

  const handleSave = async (preferredAbhaAddress) => {
    if (!handleAbhaAddressValidation(preferredAbhaAddress)) {
      handleClick("error", "Please enter a valid Abha Address");
      return;
    }
    setAbhaAddressError("");
    const data = {
      txnId: txnId,
      token: token,
      scope: ABHA_ADDRESS,
      updateDetail: preferredAbhaAddress,
      includeProfileQr: true,
    };
    try {
      const res = await updateAbdmDetail(patientId, data);

      if (res.status === 200) {
        // settxnId(res.data.txnId);
        const updatedAbhaAddress = res.data.profile.preferredAbhaAddress;
        setAbhaCardPhoto(res.data.abhaCard);
        const updatedPatientAbhaDetails = {
          ...patientAbhaDetails,
          profile: {
            ...patientAbhaDetails.profile,
            preferredAbhaAddress: updatedAbhaAddress,
          },
        };
        setPatientAbhaDetails(updatedPatientAbhaDetails);
        setPreferredAbhaAddress(updatedAbhaAddress);
        setIsEditing(false);
        handleClick("success", "Details have been successfully updated.");
      }
    } catch (e) {
      handleClick("error", e.response?.data?.message);
    }
  };

  return (
    <div style={{ display: "flex", gap: "1em" }}>
      <Card sx={{ width: "40%", height: "fit-content", p: 2, boxShadow: 4 }}>
        <CardContent>
          {/* Profile Photo */}
          {profilePhoto ? (
            <Box
              sx={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                backgroundImage: `url(data:image/jpeg;base64,${profilePhoto})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                margin: "0 auto 1em",
              }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary" align="center">
              No profile photo available
            </Typography>
          )}

          {/* User Details */}
          <Typography variant="h5" gutterBottom align="center">
            {patientAbhaDetails?.profile?.name}
          </Typography>

          <Grid2 container spacing={2} sx={{ fontSize: "10%" }}>
            <Grid2 xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Gender
              </Typography>
              <Typography variant="body1">
                {patientAbhaDetails?.profile?.gender === "M"
                  ? "Male"
                  : patientAbhaDetails?.profile?.gender === "F"
                  ? "Female"
                  : "Other"}
              </Typography>
            </Grid2>

            <Grid2 xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Date of Birth
              </Typography>
              <Typography variant="body1">
                {dayjs(combinedDateOfBirth, "DD-MM-YYYY").format("DD MMMM, YYYY")}
              </Typography>
            </Grid2>

            <Grid2 xs={12}>
              <Typography variant="subtitle2" color="textSecondary">
                Phone Number
              </Typography>
              <Typography variant="body1">{patientAbhaDetails?.profile?.mobile}</Typography>
            </Grid2>

            <Grid2 xs={12}>
              <Typography variant="subtitle2" color="textSecondary">
                Address
              </Typography>
              <Typography variant="body1">{patientAbhaDetails?.profile?.address}</Typography>
            </Grid2>

            <Grid2 xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                District
              </Typography>
              <Typography variant="body1">{patientAbhaDetails?.profile?.districtName}</Typography>
            </Grid2>
            <Grid2 xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                State
              </Typography>
              <Typography variant="body1">{patientAbhaDetails?.profile?.stateName}</Typography>
            </Grid2>

            <Grid2 xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                PIN
              </Typography>
              <Typography variant="body1">{patientAbhaDetails?.profile?.pincode}</Typography>
            </Grid2>

            <Grid2 xs={6}>
              <Typography variant="subtitle2" color="textSecondary">
                ABHA Number
              </Typography>
              <Typography variant="body1">{patientAbhaDetails?.profile?.ABHANumber}</Typography>
            </Grid2>

            <Grid2 container spacing={2}>
              {isEditing ? (
                <>
                  <Grid2 item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                    <CustomInputField
                      value={preferredAbhaAddress}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">SAM.123</InputAdornment>,
                      }}
                      autoFocus
                    />

                    <IconButton
                      onClick={() => {
                        setIsEditing(false);
                        setAbhaAddressError("");
                        setPreferredAbhaAddress(
                          patientAbhaDetails?.profile?.preferredAbhaAddress || ""
                        );
                      }}
                      color="error"
                    >
                      <i className="ri-close-line" />
                    </IconButton>
                  </Grid2>

                  {abhaAddressError && (
                    <Grid2 item xs={12}>
                      <Typography variant="body2" color="error">
                        {abhaAddressError}
                      </Typography>
                    </Grid2>
                  )}

                  <Grid2 container spacing={1}>
                    <Grid2 item xs={1}>
                      <Typography variant="body2">Suggestions:</Typography>
                    </Grid2>
                    <Grid2 item xs={1}>
                      {abhaAddressList.map((address, index) => (
                        <Typography
                          className="abhaAddress"
                          key={index}
                          sx={{ cursor: "pointer", color: "primary.main" }}
                          onClick={() => setPreferredAbhaAddress(address)}
                        >
                          {address}
                        </Typography>
                      ))}
                    </Grid2>
                  </Grid2>
                </>
              ) : (
                <Grid2 item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary">
                    ABHA Address
                  </Typography>
                  <Typography variant="body1" sx={{ marginRight: "1em" }}>
                    {preferredAbhaAddress}
                    &nbsp;
                    {allowEditingAbhaAddress && (
                      <i
                        className="ri-edit-fill ri-lg icon-primary-blue hover"
                        onClick={handleEditClick}
                      />
                    )}
                  </Typography>
                </Grid2>
              )}
            </Grid2>

            {isEditing && (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <CustomButton
                  label="Save"
                  onClick={() => handleSave(preferredAbhaAddress)}
                  className="btn--primary"
                />
              </div>
            )}
          </Grid2>
        </CardContent>
      </Card>
      {abhaCardPhoto ? (
        <div
          style={{
            width: "50%",
            backgroundColor: "red",
            borderRadius: "11px",
            boxShadow: "#aaa 0px 4px 10px -1px",
            overflow: "hidden",
          }}
        >
          <img
            src={`data:image/jpeg;base64,${abhaCardPhoto}`}
            alt="ABHA Card"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "inherit",
            }}
          />
        </div>
      ) : (
        <div>No image available</div>
      )}
    </div>
  );
};

export default AbhaDetails;
