import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

export const getAllRoles = () => API.get(`/api/roles`);

export const getRole = (id) => API.get(`/api/roles/${id}`);

export const deleteRole = (id) => API.delete(`/api/roles/${id}`);

export const createRole = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/roles`, data);
};

export const updateRole = (roleId, data) => API.put(`/api/roles/${roleId}`, data);
