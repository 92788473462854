// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide-left-grid-button-for-appointment-list {
  margin: 2px -13px;
  width: 15px;
  height: 40px;
  border-radius: 10%;
  padding-right: 5%;
  padding-top: 1px;
  color: #004c70;
  background-color: #e6eef1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/DoctorHomeScreen/doctors-appointment-new-v2.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".hide-left-grid-button-for-appointment-list {\n  margin: 2px -13px;\n  width: 15px;\n  height: 40px;\n  border-radius: 10%;\n  padding-right: 5%;\n  padding-top: 1px;\n  color: #004c70;\n  background-color: #e6eef1;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
