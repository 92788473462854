import { Fragment, useEffect, useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "assets/scss/availability.scss";
import {
  convertTimeForTimeFormat,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
  roleId,
  orgId,
  currentActiveUser,
  roleName,
  DOCTOR,
  DATE_FORMAT_DMY,
  TELE_CONSULTATION,
  CLINIC,
  CLINIC_VISIT,
  TIME_FORMAT,
} from "store/constant";
import dayjs from "dayjs";
import { getBlockAvailabilities } from "services/Availability";
const EditAvailabilityCard = ({
  cardInfo,
  handleDeleteAvailability,
  handleOpen,
  getDataForEditAvailability,
  clinicVisitAvailabilities,
  teleconsultationAvailabilities,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [availabilityDelete, setAvailabilityDelete] = useState(null);
  const [blockAvailabilities, setBlockAvailabilities] = useState({
    blockClinic: {},
    blockTele: {},
  });
  const fetchBlockAvailabilities = async () => {
    const { data: response } = await getBlockAvailabilities(
      currentActiveUser().roleBasedId,
      orgId()
    );
    const blockClinic = {};
    const blockTele = {};
    const addEntry = (block, date, entry) => {
      if (!block[date]) {
        block[date] = [];
      }
      block[date].push(entry);
    };
    const checkInAvailabilities = (availabilities, item, type) => {
      availabilities.forEach((availability) => {
        const dayOfWeek = dayjs(item.startDate).day();
        if (availability.dayOfWeek.includes(dayOfWeek)) {
          const availStartTime = dayjs(availability.startTime, TIME_FORMAT);
          const availEndTime = dayjs(availability.endTime, TIME_FORMAT);
          const itemStartTime = dayjs(item.startTime, TIME_FORMAT);
          const itemEndTime = dayjs(item.endTime, TIME_FORMAT);
          if (
            itemStartTime.isBetween(availStartTime, availEndTime, null, "()") ||
            itemEndTime.isBetween(availStartTime, availEndTime, null, "()")
          ) {
            const entry = {
              startTime: convertTimeForTimeFormat(item.startTime),
              endTime: convertTimeForTimeFormat(item.endTime),
            };
            if (type === CLINIC_VISIT) {
              addEntry(blockTele, dayjs(item.startDate).format("Do MMM"), entry);
            } else if (type === TELE_CONSULTATION) {
              addEntry(blockClinic, dayjs(item.startDate).format("Do MMM"), entry);
            }
            return;
          }
        }
      });
    };
    response.forEach((item) => {
      if (dayjs(item.endDate).isBefore(dayjs().startOf("day"))) return;
      if (item.blockingType === "CUSTOM_HOURS") {
        const entry = {
          startTime: convertTimeForTimeFormat(item.startTime),
          endTime: convertTimeForTimeFormat(item.endTime),
        };
        if (item.serviceType === "NONE") {
          addEntry(blockClinic, dayjs(item.startDate).format("Do MMM"), entry);
          addEntry(blockTele, dayjs(item.startDate).format("Do MMM"), entry);
        } else if (item.serviceType === CLINIC_VISIT) {
          addEntry(blockTele, dayjs(item.startDate).format("Do MMM"), entry);
        } else if (item.serviceType === TELE_CONSULTATION) {
          addEntry(blockClinic, dayjs(item.startDate).format("Do MMM"), entry);
        }
      }
    });
    const customDateTimeRanges = response.filter(
      (record) => record.blockingType === "CUSTOM_DATE_TIME_RANGE"
    );
    if (customDateTimeRanges.length > 0) {
      // Group records by blockId
      const groupedByBlockId = customDateTimeRanges.reduce((acc, record) => {
        if (!acc[record.blockId]) {
          acc[record.blockId] = [];
        }
        acc[record.blockId].push(record);
        return acc;
      }, {});
      // Extract earliest and latest records for each blockId
      const result = Object.entries(groupedByBlockId).map(([blockId, records]) => {
        const sortedRecords = records.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        const earliestDate = sortedRecords[0].startDate;
        const latestDate = sortedRecords[sortedRecords.length - 1].endDate;
        const filteredRecords = sortedRecords.filter(
          (record) => record.startDate === earliestDate || record.endDate === latestDate
        );
        return {
          [blockId]: {
            filteredRecords,
          },
        };
      });
      result.forEach((item) => {
        Object.keys(item).forEach((blockId) => {
          item[blockId].filteredRecords.forEach((block) => {
            if (dayjs(block.endDate).isBefore(dayjs().startOf("day"))) return;
            if (block.serviceType === "NONE" || block.serviceType === CLINIC_VISIT) {
              checkInAvailabilities(clinicVisitAvailabilities, block, CLINIC_VISIT);
            }
            if (block.serviceType === "NONE" || block.serviceType === TELE_CONSULTATION) {
              checkInAvailabilities(teleconsultationAvailabilities, block, TELE_CONSULTATION);
            }
          });
        });
      });
    }
    setBlockAvailabilities({ blockClinic, blockTele });
  };

  useEffect(() => {
    if (roleName() === DOCTOR) {
      fetchBlockAvailabilities();
    }
  }, [clinicVisitAvailabilities, teleconsultationAvailabilities]);

  const handleDialogYes = async () => {
    setDialogOpen(false);
    handleDeleteAvailability(availabilityDelete);
    setAvailabilityDelete(null);
  };

  const handleDialogNo = () => {
    setDialogOpen(false);
    setAvailabilityDelete(null);
  };

  const handleEditAvailability = (availability) => {
    getDataForEditAvailability(availability);
    handleOpen();
  };

  return (
    <>
      {/* Dialog box for confirmation to delete */}
      <Dialog open={dialogOpen} onClose={handleDialogNo}>
        <DialogTitle id="alert-dialog-title" fontSize={16}>
          Delete Availability?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Availability will be deleted, and Appointments cancelled.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
            No
          </Button>
          <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Card variant="outlined">
        <CardContent>
          <div className="organization">
            <Typography className="organization-name">{cardInfo.name}</Typography>
            <Typography className="organization-address">{cardInfo.address}</Typography>
          </div>
          {roleName() === DOCTOR &&
            cardInfo.id === orgId() &&
            (!!Object.keys(blockAvailabilities.blockClinic).length ||
              !!Object.keys(blockAvailabilities.blockTele).length) && (
              <>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#004c70",
                    mb: 1,
                  }}
                >
                  Blocked Availability
                </Typography>
                <Grid container spacing={3} sx={{ mb: 1 }}>
                  <Grid size={{ xs: 6 }} className="cst-borger-right">
                    <BlockTemplate
                      blockTime={blockAvailabilities?.blockClinic}
                      text={TEXT_FOR_CLINIC_VISIT}
                    ></BlockTemplate>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <BlockTemplate
                      blockTime={blockAvailabilities?.blockTele}
                      text={TEXT_FOR_TELECONSULTATION}
                    ></BlockTemplate>
                  </Grid>
                </Grid>
                <hr />
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#004c70",
                    mt: 2,
                  }}
                >
                  Default Availability
                </Typography>
              </>
            )}
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid size={{ xs: 6 }} className="cst-borger-right">
              <CommonTemplate
                type={TEXT_FOR_CLINIC_VISIT}
                dataArr={clinicVisitAvailabilities}
                handleEditAvailability={handleEditAvailability}
                setDialogOpen={setDialogOpen}
                setAvailabilityDelete={setAvailabilityDelete}
              ></CommonTemplate>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <CommonTemplate
                type={TEXT_FOR_TELECONSULTATION}
                dataArr={teleconsultationAvailabilities}
                handleEditAvailability={handleEditAvailability}
                setDialogOpen={setDialogOpen}
                setAvailabilityDelete={setAvailabilityDelete}
              ></CommonTemplate>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const CommonTemplate = ({
  type,
  dataArr,
  handleEditAvailability,
  setDialogOpen,
  setAvailabilityDelete,
}) => {
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const getDayNumber = (day) => {
    switch (day) {
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      case "Sunday":
        return 7;
      default:
        return 1;
    }
  };

  return (
    <>
      <Typography className="cst-title">{type}</Typography>
      {dataArr &&
        dataArr.map((availability, index) => (
          <Fragment key={index}>
            <div key={index} style={{ display: "flex", marginBottom: "5px" }}>
              <div className="edit-timing">
                {`${convertTimeForTimeFormat(availability.startTime)} - ${convertTimeForTimeFormat(
                  availability.endTime
                )}`}
              </div>
              {daysOfWeek.map((day, index) => (
                <div
                  key={index}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "50%",
                    padding: "7px",
                    textAlign: "center",
                    background: availability.dayOfWeek.includes(getDayNumber(day))
                      ? "#247391"
                      : "#F2F6F8",
                  }}
                >
                  <Typography
                    className="day-icon"
                    style={{
                      color: availability.dayOfWeek.includes(getDayNumber(day))
                        ? "#fff"
                        : "#616B67",
                    }}
                  >
                    {day[0]}
                  </Typography>
                </div>
              ))}
              <CustomButton
                iconButton={<i className="ri-edit-fill icon-primary-blue" />}
                onClick={() => {
                  handleEditAvailability(availability);
                }}
              ></CustomButton>
              <CustomButton
                iconButton={<i className="ri-delete-bin-fill icon-primary-red" />}
                onClick={() => {
                  setDialogOpen(true);
                  setAvailabilityDelete(availability);
                }}
              ></CustomButton>
            </div>
          </Fragment>
        ))}
      {dataArr && dataArr.length === 0 && (
        <>
          <Typography className="cst-title no-text">There are no availabilities yet!</Typography>
        </>
      )}
    </>
  );
};

const BlockTemplate = ({ blockTime, text }) => {
  return (
    <>
      <Typography className="cst-title">{text}</Typography>
      {!!Object.keys(blockTime).length ? (
        Object.entries(blockTime).map(([date, entries]) => (
          <Chip
            key={date}
            label={
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 600, marginRight: 5 }}>{date}:</span>
                <div>
                  {entries.map((entry, index) => (
                    <div key={index} style={{ color: "red", fontWeight: 500 }}>
                      {entry.startTime} - {entry.endTime}
                    </div>
                  ))}
                </div>
              </div>
            }
            sx={{
              mr: 1,
              mb: 0.5,
              py: 1,
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            }}
          />
        ))
      ) : (
        <Typography className="cst-title no-text">There are no availabilities yet!</Typography>
      )}
    </>
  );
};

export default EditAvailabilityCard;
