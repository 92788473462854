import { Typography, IconButton, Skeleton, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateSpecialization from "./UpdateSpecialization";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { ACTIVE_STATE, SKELETON_LOADING_TIME_IN_MILLISECONDS, accessToken } from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useContext } from "react";
import {
  createSpecializations,
  deleteSpecializations,
  getSpecializations,
} from "services/EntitiesServices";
import Reveal from "views/utilities/Reveal";

const DoctorSpecializations = () => {
  const [specializations, setSpecializations] = useState([]);
  const [displaySpecialization, setDisplaySpecializations] = useState([]);
  const [newSpecialization, setNewSpecialization] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const [errors, setErrors] = useState({});

  const fetchData = async () => {
    try {
      const response = await getSpecializations();
      setSpecializations(response?.data);
    } catch (error) {
      console.error("Error fetching specialization");
    }
  };
  const handleDelete = async (specialization) => {
    if (specialization.status === ACTIVE_STATE) {
      if (window.confirm(`Are you sure you want to mark "${specialization.name}" as inactive?`)) {
        try {
          await deleteSpecializations(specialization.id);
          handleClick("success", "Specialization marked Inactive!");
          fetchData();
        } catch (e) {
          handleClick("error", "Specialization marked Inactive failed");
        }
      }
    } else {
      handleClick("info", "Specialization is already inactive!");
    }
  };

  // const debounce = (func, delay) => {
  //   let debounceTimer;
  //   return function () {
  //     const context = this;
  //     const args = arguments;
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => func.apply(context, args), delay);
  //   };
  // };

  // const handleSearchQueryChange = debounce((e) => {
  //   const value = e.target.value;
  //   // (validation logic here)
  //   setSearchQuery(value);
  // }, 300);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const addSpecialization = async () => {
    const trimmedSpecialization = newSpecialization.trim();

    // Validation: Required Field
    if (trimmedSpecialization === "") {
      const Errors = { specialization: "Specialization name is required." };
      setErrors(Errors);
      return;
    }

    // Validation: Minimum Length
    if (trimmedSpecialization.length < 3) {
      const Errors = { specialization: "Specialization name must be at least 3 characters long." };
      setErrors(Errors);
      return;
    }

    // Validation: Maximum Length
    if (trimmedSpecialization.length > 50) {
      const Errors = { specialization: "Specialization name must not exceed 50 characters." };
      setErrors(Errors);
      return;
    }

    // Validation: Valid Characters
    const validCharacters = /^[a-zA-Z\s\-]+$/;
    if (!validCharacters.test(trimmedSpecialization)) {
      const Errors = { specialization: "Specialization name contains invalid characters." };
      setErrors(Errors);
      return;
    }

    // Validation: No Duplicate Names
    if (
      specializations.some(
        (spec) => spec.name.toLowerCase() === trimmedSpecialization.toLowerCase()
      )
    ) {
      const Errors = { specialization: "Specialization already exists." };
      setErrors(Errors);
      return;
    }

    const data = {
      name: trimmedSpecialization,
    };

    if (
      specializations.some((spec) => spec.name.toLowerCase() === newSpecialization.toLowerCase())
    ) {
      const Errors = { specialization: "Specialization already exists." };
      setErrors(Errors);
      return;
    }

    try {
      const res = await createSpecializations(data);
      handleClick("success", "Specialization has been successfully added.");
      setNewSpecialization("");
      setSpecializations([...specializations, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the specialization.");
    }
  };

  useEffect(() => {
    setDisplaySpecializations(
      specializations.filter((s) =>
        s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    );
  }, [specializations, searchQuery]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenValue]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Specialization" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <SpecializationSkeleton>
      <Reveal>
        <Grid container spacing={5}>
          <Grid size={{ xs: 5 }}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any Speciality
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "90%" }}
                label={"Search Specializations"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  // handleSearchQueryChange();
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          <Grid size={{ xs: 7 }}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Add a new Speciality
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid size={{ xs: 8 }}>
                <FormInputField
                  label={"Add Specialization"}
                  value={newSpecialization}
                  onChange={(e) => {
                    setNewSpecialization(e.target.value);
                  }}
                  error={errors?.specialization}
                  errorText={errors.specialization}
                  startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid sx={{ mt: 0.5 }}>
                <CustomButton
                  className="btn--primary"
                  // disabled={newSpecialization === ""}
                  onClick={addSpecialization}
                  label="Add"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>
        <CustomizedTable columns={columns} tableData={displaySpecialization} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Specialization"
          component={
            <UpdateSpecialization
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </SpecializationSkeleton>
  );
};
const SpecializationSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: "10%" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="40%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DoctorSpecializations;
