import "assets/scss/subscription.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useEffect, useState } from "react";
import {
  createSubscriptionLicenceAssociations,
  getLicenceByOrgId,
  getPackagesListForLicence,
} from "services/Subscription";
import { Box, Grid2 as Grid, Skeleton, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Slices/userSlice";
import { ACTIVE_STATE, currentActiveUser, DATE_FORMAT, getUUID, PENDING } from "store/constant";
import dayjs from "dayjs";
import { convertIntoTwoDecimal } from "utils/price-utils";
import {
  createOrderForPaymentByAdmin,
  generateBillingInvoicesByAdmin,
  getInvoicePDFByIdByAdmin,
  paymentVerificationByAdmin,
} from "services/BillingService";
import { SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import Reveal from "views/utilities/Reveal";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { PrintOutlined } from "@mui/icons-material";

const StyledTab = styled(Tab)(({ theme, isactive }) => ({
  backgroundColor: isactive === "true" ? "#29BF91" : "#FFFFFF",
  color: isactive === "true" ? "#FFFFFF" : "#004C70",
  height: "36px",
  borderRadius: "100px !important",
  transition: "background-color 0.3s ease, color 0.3s ease",
  "&:hover": {
    backgroundColor: isactive === "true" ? "#003554" : "#f0f0f0",
  },
  "&.Mui-selected": {
    backgroundColor: isactive === "true" ? "#29BF91" : "#FFFFFF",
    color: isactive === "true" ? "#FFFFFF" : "#004C70",
  },
}));

const UserSubscription = ({ isOnboarding = false }) => {
  const [allPackages, setAllPackages] = useState([]);
  const [orgLicenceData, setOrgLicenseData] = useState(null);
  const userOrgId = useSelector(getUserOrgId);
  const activePackage = allPackages.find((item) => item.isOngoing);
  const currentUser = currentActiveUser();
  const { handleClick } = useContext(ToastContext);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [validityDate, setValidityDate] = useState(null);

  const [licencePackageAssociationIdInvoice, setLicencePackageAssociationIdInvoice] =
    useState(null);

  const [featuresList, setFeatureList] = useState([]);

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updatePackageListInfo = async () => {
    try {
      // get the organiazation license and ongoing licenese package association
      const orgPackageResponse = await getLicenceByOrgId(userOrgId);
      setOrgLicenseData(orgPackageResponse.data);
      const ongoingPackage =
        orgPackageResponse.data.subscriptionLicensePackageAssociationResponses.find(
          (item) => item.associationStatus === "ONGOING"
        );
      const packageResponse = await getPackagesListForLicence(ongoingPackage?.licenceId);

      setValidityDate(dayjs(ongoingPackage?.endDate).format("DD MMM, YY"));

      setRemainingAmount(packageResponse.data.remainingAmount);

      // filter out the base package, and sort with the rank
      const packagesList = packageResponse.data?.packageResponses
        .filter((item) => item.rank !== 0)
        .map((item) => {
          return {
            ...item,
            isOngoing: item.id === ongoingPackage?.packageResponse.id,
          };
        })
        .sort((a, b) => a.rank - b.rank);
      setAllPackages(packagesList);
      if (isOnboarding) {
        setLicencePackageAssociationIdInvoice(ongoingPackage?.id);
      }

      // extract the bundles list description from the highest package
      const lastPackageToExtractAllFeatures = packagesList[packagesList.length - 1];
      if (lastPackageToExtractAllFeatures) {
        const features = [];
        lastPackageToExtractAllFeatures.packageBundleAssociationResponses.forEach((bundle) => {
          features.push(bundle.bundleResponse.description);
        });
        setFeatureList(features);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updatePackageListInfo();
  }, []);

  const checkIsDisabled = (item) => {
    switch (validityType) {
      case "MONTHLY":
        return item.monthlyPrice < remainingAmount || item.rank < activePackage?.rank;
      case "QUARTERLY":
        return item.quarterlyPrice < remainingAmount || item.rank < activePackage?.rank;
      case "SEMI_ANNUAL":
        return item.semiAnnualPrice < remainingAmount || item.rank < activePackage?.rank;
      case "ANNUAL":
        return item.annualPrice < remainingAmount || item.rank < activePackage?.rank;
      default:
        return item.monthlyPrice < remainingAmount || item.rank < activePackage?.rank;
    }
  };

  const generatePDF = async (invoiceId, packageLicenseAssociationId) => {
    try {
      // toDo need to check once the pr is merged
      const pdfRes = await getInvoicePDFByIdByAdmin(invoiceId, packageLicenseAssociationId);

      const blob = new Blob([pdfRes.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create an iframe element to embed the PDF
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;

      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      };
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const getDiscountAmount = (discounts, price) => {
    let discountAmount = 0;
    discounts?.forEach((discount) => {
      if (!discount.discountStatus === ACTIVE_STATE) {
        return;
      } else if (discount.discountUnit === "PERCENTAGE") {
        discountAmount += (price * discount.value) / 100;
      } else if (discount.discountUnit === "FIXED_AMOUNT") {
        discountAmount += discount.value;
      }
    });
    return discountAmount;
  };

  const initiatePayment = async (event, invoiceDetails) => {
    event.preventDefault();
    try {
      const payloadForOrderCreation = {
        id: getUUID(),
        invoiceId: invoiceDetails.id,
        paymentDate: dayjs().format(DATE_FORMAT),
        amount: invoiceDetails.totalAmount,
        method: "RAZORPAY",
        paymentStatus: PENDING,
        razorpayOrderResponse: {
          notes: "NOTES",
        },
      };
      const orderResponse = await createOrderForPaymentByAdmin(payloadForOrderCreation);

      const { razorpayOrderResponse } = orderResponse.data;
      const options = {
        key: "rzp_test_5Fezr30xpUnvz4", // Enter the Key ID generated from the Dashboard
        amount: razorpayOrderResponse.amount, // in paise for INR
        currency: razorpayOrderResponse.currency,
        name: "Arog",
        description: "Test Transaction",
        image: "https://hc.argusservices.in/images/email/logo.png",
        order_id: razorpayOrderResponse.razorpayOrderId,
        handler: async function (response) {
          try {
            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const params = {
              id: razorpayOrderResponse.paymentId,
            };
            await paymentVerificationByAdmin(params, data);
            updatePackageListInfo();
            if (!isOnboarding) {
              await generatePDF(invoiceDetails?.id, null);
            }
          } catch (error) {
            console.error(error);
          }
        },
        prefill: {
          name: currentUser?.name,
          email: currentUser?.emailId,
          contact: currentUser?.mobileNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        timeout: 300, //seconds
        modal: {
          confirm_close: true,
          ondismiss: async (reason) => {
            // when modal is closed by the user
            if (reason === undefined) {
              handleClick("error", "Payment Cancelled. Try Again");
            }
            // when modal is auto closed because of time out
            else if (reason === "timeout") {
              handleClick("error", "Timedout. Try Again");
            }
            // When payment gets failed.
            else {
              handleClick("error", "Payment Failed. Try Again");
            }
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", async (response) => {
        console.error(response);
      });
      paymentObject.open();
    } catch (error) {
      console.error(error);
    }
  };

  const [validityType, setValidityType] = useState("MONTHLY");
  const getValidityType = (value) => {
    switch (value) {
      case 0:
        return "MONTHLY";
      case 1:
        return "QUARTERLY";
      case 2:
        return "SEMI_ANNUAL";
      case 3:
        return "ANNUAL";
      default:
        return "MONTHLY";
    }
  };

  useEffect(() => {
    setValidityType(getValidityType(tabValue));
  }, [tabValue]);

  const handleSubscrptionClick = async (event, selectedPackage, packagePrice) => {
    let licencePackageAssociationId = null;
    try {
      const data = {
        licenceId: orgLicenceData?.id,
        packageId: selectedPackage?.id,
        startDate: dayjs().format(DATE_FORMAT),
        associationType: getValidityType(tabValue),
        associationStatus: "INITIATED",
        status: ACTIVE_STATE,
      };

      const response = await createSubscriptionLicenceAssociations(data);
      licencePackageAssociationId = response.data.id;
    } catch (error) {
      console.error(error);
    }

    try {
      const invoiceId = getUUID();
      const invoiceItemData = {
        id: getUUID(),
        invoiceId: invoiceId,
        description: null,
        typeId: selectedPackage.id,
        invoiceItemType: "SUBSCRIPTION_PACKAGE",
        quantity: 1,
        discount: selectedPackage.discounts || 0,
        tax: selectedPackage.tax || 0,
        amount: packagePrice - remainingAmount || 0,
        status: ACTIVE_STATE,
      };

      const discountAmount = getDiscountAmount(selectedPackage.discounts, packagePrice);
      const data = {
        description: null,
        id: invoiceId,
        typeId: licencePackageAssociationId,
        invoiceType: "SUBSCRIPTION",
        organizationId: userOrgId,
        issueDate: dayjs(),
        totalAmount: convertIntoTwoDecimal(packagePrice - discountAmount - remainingAmount),
        invoiceStatus: "PENDING",
        status: "ACTIVE",
        invoiceItems: [invoiceItemData],
      };

      const invoiceResponse = await generateBillingInvoicesByAdmin(data);
      await initiatePayment(event, invoiceResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  return (
    <UserSubscriptionSkeleton>
      <Reveal className="user-subscription">
        <div
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px", marginBottom: "20px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <h6 className="page-title">Choose Your subscription</h6>
            {isOnboarding && (
              <div>
                {!activePackage && (
                  <div
                    onClick={() => {
                      handleClick("success", "Welcome to Arog!");
                      setTimeout(() => {
                        navigate("/home/dashboard");
                      }, 1000);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      color: "#004c70",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                  >
                    <p style={{ marginRight: "5px" }}>Skip for now</p>
                    <i className="ri-arrow-right-line ri-xl"></i>
                  </div>
                )}
                {activePackage && (
                  <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <CustomButton
                      iconButton={<PrintOutlined />}
                      style={{ marginLeft: "auto" }}
                      className={"btn--secondary-light"}
                      onClick={() => {
                        generatePDF(null, licencePackageAssociationIdInvoice);
                      }}
                    />
                    <div
                      onClick={() => {
                        handleClick("success", "Welcome to Arog!");
                        setTimeout(() => {
                          navigate("/home/dashboard");
                        }, 1000);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        color: "#004c70",
                        cursor: "pointer",
                        fontWeight: 500,
                      }}
                    >
                      <p style={{ marginRight: "5px" }}>Move to dashboard</p>
                      <i className="ri-arrow-right-line ri-xl"></i>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className="subscription-tabs"
          style={{ marginBottom: "40px", display: "flex", justifyContent: "center" }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            centered
            indicatorColor="none"
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "100px",
              width: "fit-content",
              height: "48px",
              minHeight: "48px",
              padding: "6px",
            }}
          >
            <StyledTab
              sx={{ minHeight: "36px", height: "36px" }}
              label="Monthly"
              isactive={(tabValue === 0).toString()}
            />
            <StyledTab
              sx={{ minHeight: "36px", height: "36px" }}
              label="Quarterly"
              isactive={(tabValue === 1).toString()}
            />
            <StyledTab
              sx={{ minHeight: "36px", height: "36px" }}
              label="Half-Yearly"
              isactive={(tabValue === 2).toString()}
            />
            <StyledTab
              sx={{ minHeight: "36px", height: "36px" }}
              label="Yearly"
              isactive={(tabValue === 3).toString()}
            />
          </Tabs>
        </div>

        <Grid container justifyContent="center" spacing={6} sx={{ padding: "0 60px" }}>
          {allPackages.map((item) => (
            <Grid
              key={item.id}
              size={{ lg: 4, md: 6, sm: 6, xs: 12 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                transition: "transform 0.4s ease-in-out",
                backgroundColor: "#FFFFFF",
                borderRadius: "24px",
                color: "#004C70",
                opacity: checkIsDisabled(item) ? "0.7" : "1",
                cursor: item.isOngoing || checkIsDisabled(item) ? "not-allowed" : "pointer",
                "&:hover": {
                  ...(item.isOngoing || checkIsDisabled(item)
                    ? {}
                    : {
                        transform: "scale(1.1)",
                        backgroundColor: "#004C70",
                        color: "#FFFFFF",
                        ".purchase-button": {
                          backgroundColor: "#29BF91 !important",
                          color: "#ffffff !important",
                          transition: "background-color 0.4s ease-in-out, color 0.4s ease-in-out",
                        },
                        ".subscription-type-indicator": {
                          backgroundColor: "#ffffff !important",
                        },
                      }),
                },
              }}
            >
              <SubscriptionItemNew
                item={item}
                handleSubscrptionClick={handleSubscrptionClick}
                activePackage={activePackage}
                validityDate={validityDate}
                featureList={featuresList}
                validityType={validityType}
                checkIsDisabled={checkIsDisabled}
                remainingAmount={remainingAmount}
              ></SubscriptionItemNew>
            </Grid>
          ))}
        </Grid>
      </Reveal>
    </UserSubscriptionSkeleton>
  );
};

const SubscriptionItemNew = ({
  item,
  handleSubscrptionClick,
  activePackage,
  featureList,
  validityType,
  checkIsDisabled,
  validityDate,
  remainingAmount,
}) => {
  const [packagePrice, setPackagePrice] = useState(item.monthlyPrice);

  const getPackagePrice = (validityType) => {
    switch (validityType) {
      case "MONTHLY":
        return item.monthlyPrice;
      case "QUARTERLY":
        return item.quarterlyPrice;
      case "SEMI_ANNUAL":
        return item.semiAnnualPrice;
      case "ANNUAL":
        return item.annualPrice;
      default:
        return item.monthlyPrice;
    }
  };

  useEffect(() => {
    setPackagePrice(getPackagePrice(validityType));
  }, [validityType]);

  // conditional based rendering of features in the package
  const renderFeatures = () => {
    return featureList.map((feature, index) => {
      const isFeatureIncluded = !!item.packageBundleAssociationResponses.find(
        (item) => item.bundleResponse.description === feature
      );

      return (
        <div
          key={index}
          className="card-feature-item"
          style={{ opacity: isFeatureIncluded ? 1 : 0.3 }}
        >
          <div className="card-feature-icon">
            {isFeatureIncluded ? (
              <i className="ri-check-fill ri-xl"></i>
            ) : (
              <i className="ri-close-fill ri-xl"></i>
            )}
          </div>
          <p className="card-feature-text">{feature}</p>
        </div>
      );
    });
  };

  return (
    <div className="subscription-card">
      <div className="subscription-card-content">
        <div className="subscription-card-detail">
          <div className="subscription-card-detail-title">
            <div className="subscription-type-container">
              <p className="subscription-type">{item.name.split(" ")?.[0]}</p>
              {!(item.isOngoing || checkIsDisabled(item)) && (
                <div className="subscription-type-indicator">Save 40%</div>
              )}
              {item.isOngoing && (
                <div className="subscription-type-indicator">Valid till {validityDate}</div>
              )}
            </div>
            <p className="subscription-description">{item.description}</p>
          </div>
          <div className="subscription-card-detail-body">
            <div className="subscription-card-features">{renderFeatures()}</div>
          </div>
        </div>
        <div className="subscription-card-price">
          {remainingAmount !== 0 && !checkIsDisabled(item) && !item.isOngoing && (
            <p className="subscription-card-price-value subscription-card-price-value-original">
              &#8377; {packagePrice}
            </p>
          )}
          <p className="subscription-card-price-value">
            <span> &#8377;</span>
            {remainingAmount !== 0 && !checkIsDisabled(item) && !item.isOngoing
              ? packagePrice - remainingAmount
              : packagePrice}
          </p>
        </div>
      </div>
      <div className="subscription-card-footer">
        <CustomButton
          label={item.isOngoing ? "Active" : activePackage ? "Upgrade Now" : "Purchase Now"}
          className={"purchase-button"}
          style={{
            width: "100%",
            height: "48px",
            borderRadius: "8px",
            cursor: "inherit",
          }}
          customBackGroundColor={item.isOngoing ? "#29BF91" : undefined}
          textAndIconColor={item.isOngoing ? "#ffffff" : undefined}
          onClick={(event) => {
            handleSubscrptionClick(event, item, packagePrice);
          }}
        ></CustomButton>
      </div>
    </div>
  );
};

const UserSubscriptionSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={450}
          />
        </Grid>
        <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={450}
          />
        </Grid>
        <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={450}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserSubscription;
