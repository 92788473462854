import { Typography, IconButton, Skeleton, Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateLanguage from "./UpdateLanguage";
import { languageSchema } from "../../Common/ValidationSchema/languageValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  SUPER_ADMIN,
  accessToken,
  entitiesInfo,
  roleName,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useContext } from "react";
import { createLanguages, deleteLanguages, getLanguages } from "services/EntitiesServices";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import InfoModal from "../InfoModal";
import InfoIcon from "@mui/icons-material/Info";

const Language = () => {
  const [languages, setLanguages] = useState([]);
  const [displayLanguages, setDisplayLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "Language");
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const [languageTouched, setLanguageTouched] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getLanguages();
      setLanguages(response?.data);
    } catch (error) {
      console.error("Error fetching languages");
    }
  }, []);

  const handleDelete = async (language) => {
    if (language.status === ACTIVE_STATE) {
      try {
        await deleteLanguages(language.id);
        handleClick("success", "Language has been marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error marking the Language as Inactive.");
      }
    } else {
      handleClick("info", "Language is already inactive!");
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const validateLanguage = async (language) => {
    try {
      await languageSchema.validate({ language });
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };

  const addLanguage = async () => {
    await validateLanguage(newLanguage);
    if (error) {
      handleClick("error", error);
      return;
    }

    const data = { name: newLanguage };

    if (languages.some((t) => t.name.toLowerCase() === newLanguage.toLowerCase())) {
      handleClick("error", "Language already exists");
      return;
    }

    try {
      const res = await createLanguages(data);
      handleClick("success", "Language has been successfully added.");
      setNewLanguage("");
      setLanguages([...languages, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the language.");
    }
  };

  useEffect(() => {
    setDisplayLanguages(
      languages.filter((s) => s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    );
  }, [languages, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Languages" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <LanguageSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>Language</h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Language" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={5}>
          <Grid size={{ xs: 5 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any language
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "90%" }}
                label={"Search language"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          {roleName() === SUPER_ADMIN && (
            <Grid size={{ xs: 7 }}>
              <Typography
                sx={{
                  fontSize: "19px",
                  fontWeight: 600,
                  color: "#004C70",
                }}
              >
                Add a new language
              </Typography>
              <Grid container sx={{ mt: 2 }}>
                <Grid size={{ xs: 8 }} sx={{ mr: 2 }}>
                  <FormInputField
                    label={"Add Language"}
                    value={newLanguage}
                    error={Boolean(error)}
                    errorText={error}
                    onBlur={(e) => {
                      setLanguageTouched(true);
                      validateLanguage(e.target.value);
                    }}
                    onChange={(e) => {
                      setNewLanguage(e.target.value);
                      if (languageTouched) {
                        validateLanguage(e.target.value);
                      }
                    }}
                    startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                    size="small"
                  ></FormInputField>
                </Grid>
                <Grid sx={{ mt: 0.5 }}>
                  <CustomButton
                    className="btn--primary"
                    disabled={newLanguage === ""}
                    onClick={addLanguage}
                    label="Add"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>

        <CustomizedTable columns={columns} tableData={displayLanguages} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="update Language"
          component={
            <UpdateLanguage
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </LanguageSkeleton>
  );
};

const LanguageSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: "10%" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="40%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Language;
