import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedAppointment: null,
  cancelledAppointment: null,
  todaysAppointments: [],
};

const appointmentSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setSelectedAppointmentOnDashboard: (state, action) => {
      state.selectedAppointment = action.payload;
    },
    setTodaysAppointments: (state, action) => {
      state.todaysAppointments = action.payload;
    },
    setCancelledAppointment: (state, action) => {
      state.cancelledAppointment = action.payload;
    },
  },
});

export const { setSelectedAppointmentOnDashboard, setTodaysAppointments, setCancelledAppointment } =
  appointmentSlice.actions;

export const getSelectedAppointmentOnDashboard = (state) =>
  state.doctorDashboard.appointments.selectedAppointment;
export const getTodaysAppointments = (state) =>
  state.doctorDashboard.appointments.todaysAppointments;
export const getCancelledAppointment = (state) =>
  state.doctorDashboard.appointments.cancelledAppointment;

export const AppointmentsReducer = appointmentSlice.reducer;
