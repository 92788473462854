import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, FormControl, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Formik } from "formik";
import {
  PATIENT,
  CREATE_ABHA_VIA_AADHAAR,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  VERIFY_AADHAAR_OTP,
  currentActiveUser,
  VERIFICATION_VIA_MOBILE,
  VERIFICATION_VIA_AADHAAR,
  VERIFICATION_VIA_ABHA_NUMBER,
  VERIFICATION_VIA_ABHA_ADDRESS,
  VERIFICATION_VIA_ABHA_USING_MOBILE,
  VERIFICATION_VIA_ABHA_USING_AADHAAR,
  VERIFICATION_ABHA_USING_MOBILE_OTP,
  VERIFICATION_ABHA_USING_AADHAAR_OTP,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
  VERIFY_SELECTED_ABHA_NUMBER,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  AbcOutlined,
  NumbersOutlined,
  FingerprintOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { useLocation } from "react-router";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  generateAbdmAadhaarOtp,
  resendAbdmAadhaarOtp,
  updateAbdmDetail,
  verifyAbdmAadhaarOtp,
  verifyGetAbhaOtpViaMobile,
  verifyGetAbhaOtpViaAbhaEntities,
  getLinkedAbha,
} from "services/AbhaService";
import { getPatientByUserId } from "services/patientService";
import Reveal from "views/utilities/Reveal";
import { createAbhaValidation } from "views/Components/Common/ValidationSchema/createAbhaValidation";
import AbhaDetails from "./AbhaDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import ProfileCard from "ui-component/cards/ProfileCard";
import dayjs from "dayjs";

const ManageAbhaNumber = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const { state } = useLocation();
  const [patientId, setpatientId] = useState(state?.patientId || null);
  const patientIdRef = useRef(state?.patientId ? state.patientId : null);

  const [checkedStates, setCheckedStates] = useState([true, true, true, true, true]);
  const [isSelectedIndex, setIsSelectedIndex] = useState(0);
  const [patientAbhaDetails, setPatientAbhaDetails] = useState({});
  const [isCreateAbha, setIsCreateAbha] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [txnId, setTxnId] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [otpSentMessage, setOtpSentMessage] = useState(null);
  const [getAbhaScope, setGetAbhaScope] = useState(VERIFICATION_VIA_MOBILE);
  const [getAbhaScope2, setGetAbhaScope2] = useState("");
  const [getAbhaEntityValue, setGetAbhaEntityValue] = useState("");
  const formikRef = useRef(null);
  const [accountOptions, setAccountOptions] = useState([]);
  const [viewLinkedAbha, setViewLinkedAbha] = useState(false);
  const linkedAbhaNumberRef = useRef(null);
  const [abhaImage, setAbhaImage] = useState(null);
  const [token, setToken] = useState(null);
  const [backBtnStack, setBackBtnStack] = useState([0]);
  const [inputLimits, setInputLimits] = useState({
    maxLength: 10,
    type: "tel",
    message: "Enter Mobile Number",
    kind: null,
  });
  const [showCreateAbhaBtn, setShowCreateAbhaBtn] = useState(false);
  const [resendOtpDataObject, setResendOtpDataObject] = useState({});

  const handleAutoFillDetails = () => {
    const activeUser = currentActiveUser();
    const phoneNumber =
      activeUser.roleName === PATIENT
        ? activeUser?.mobileNumber
        : state?.patientPhoneNumber || null;

    formikRef.current.setFieldValue("mobileNumber", phoneNumber);
    formikRef.current.setFieldValue("getAbhaEntityText", phoneNumber);
  };

  const handleCheckboxChange = (index) => (e) => {
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = e.target.checked;
      return newStates;
    });
  };

  const handleFetchData = async () => {
    const payload = state?.patientUserId || currentActiveUser().id;
    try {
      const response = await getPatientByUserId(payload);
      setpatientId(response.data.userResponse.roleBasedId);
      patientIdRef.current = response.data.userResponse.roleBasedId;
      linkedAbhaNumberRef.current = response.data.abhaNumber;
      ViewLinkedAbha();
    } catch (error) {
      handleClick(
        "error",
        error.response?.data?.message || "Unknown Error. Please try again later."
      );
    }
  };

  const handleAbhaCreationRouting = () => {
    setIsSelectedIndex(0);
    setViewLinkedAbha(false);
    setIsCreateAbha(true);
    setBackBtnStack([0]);
    setShowCreateAbhaBtn(false);
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (state?.abhaCreationPending) {
      handleAbhaCreationRouting();
    }
  }, [state?.abhaCreationPending]);

  const handleRadioBtnChange = () => {
    setIsCreateAbha(!isCreateAbha);
    setIsSelectedIndex(0);
    setBackBtnStack([0]);
    formikRef.current.resetForm();
    setGetAbhaScope(VERIFICATION_VIA_MOBILE);
    setShowCreateAbhaBtn(false);
    handleAutoFillDetails();
  };

  useEffect(() => {
    if (isSelectedIndex === 2) {
      setTimer(60);
      setResendEnabled(false);
    }
  }, [isSelectedIndex]);

  useEffect(() => {
    let interval;
    if (!resendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer, resendEnabled]);

  const handleBackButton = (value) => {
    setIsSelectedIndex(value);
    if (!isCreateAbha || value > 2) {
      setBackBtnStack([0]);
      return;
    } else if (backBtnStack.length > 0) {
      const lastIndexValue = backBtnStack[backBtnStack.length - 1];
      if (lastIndexValue === value) {
        return;
      }
      setBackBtnStack((prevStack) => [...prevStack, value]);
    }
  };

  const handleSubmitForGenerateAadharOtp = async (aadharNumber) => {
    const trimmedAadharNumber = aadharNumber.replace(/\s+/g, "");
    if (!trimmedAadharNumber) {
      handleClick("error", "Please enter a valid Aadhaar Number.");
      return;
    }
    const data = { loginId: trimmedAadharNumber, scope: CREATE_ABHA_VIA_AADHAAR, txnId };
    setResendOtpDataObject({ txnId: txnId });
    try {
      const res = await generateAbdmAadhaarOtp(data);
      if (res.status === 200 && res.data) {
        setTxnId(res.data.txnId);
        setOtpSentMessage(res?.data?.message);
        handleClick("success", "Otp has been successfully sent.");
        handleAutoFillDetails();
        handleBackButton(2);
      } else {
        handleClick("error", "There seems to be an error generating the Aadhaar-OTP.");
      }
    } catch (error) {
      // as per the abdm excel sheet this message is mandatory so adding this one
      if (error?.response?.data?.message === "Invalid LoginId\n") {
        handleClick("error", "Aadhaar Number is not valid");
      } else {
        handleClick(
          "error",
          error?.response?.data?.message || "Unknown Error. Please try again later."
        );
      }
    }
  };

  const handleSubmitForVerifyAadharOtp = async (otp, number) => {
    // Validate mobile number format
    if (!/^[6-9][0-9]{9}$/.test(number)) {
      handleClick("error", "Please enter a valid mobile number");
      return;
    }

    // Ensure OTP is provided
    if (!otp) {
      handleClick("error", "Please enter valid otp.");
      return;
    }

    const data = {
      scope: VERIFY_AADHAAR_OTP,
      aadhaar_otp: {
        txnId,
        otpValue: otp,
        mobile: number,
        includeAbhaAddressSuggestions: true,
        includeProfileQr: true,
      },
    };
    setResendOtpDataObject(data);
    try {
      // Verify Aadhaar OTP
      const response = await verifyAbdmAadhaarOtp(patientId, data);
      setTxnId(response?.data?.txnId);
      setToken(response?.data?.token);
      setPatientAbhaDetails(response?.data);
      if (
        response?.data?.linkMobileVerification === true &&
        response?.data?.otpMessage.includes("OTP sent to mobile number ending with ")
      ) {
        handleClick("info", response?.data?.otpMessage);
        handleBackButton(3);
        return;
      }
      if (!response?.data?.isNew) {
        handleClick("info", "ABHA account already exists!");
      } else {
        handleClick("success", "ABHA account created successfully!");
      }
      handleBackButton(4);
    } catch (error) {
      if (error?.response?.data === "User is already linked.") {
        await handleFetchData();
      }
      handleClick("error", error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleResendOtp = async (labelledby) => {
    if (resendCount < 3) {
      try {
        let response;
        switch (labelledby) {
          case "Creation":
            response = await resendAbdmAadhaarOtp({ txnId });
            setTxnId(response?.data?.txnId);
            handleClick("success", "Otp has been successfully resent.");
            break;
          case "AddPhoneNumber":
            response = await verifyAbdmAadhaarOtp(patientId, resendOtpDataObject);
            setTxnId(response?.data?.txnId);
            setToken(response?.data?.token);
            setPatientAbhaDetails(response?.data);
            break;
          case "Verification":
            response = await generateAbdmAadhaarOtp(resendOtpDataObject);
            setTxnId(response?.data?.txnId);
            break;
        }
        setResendEnabled(false);
        setTimer(60);
        setResendCount((prev) => prev + 1);
      } catch (e) {
        handleClick("error", e.response?.data?.message || "Unknown Error. Please try again later.");
      }
    } else {
      setResendEnabled(false);
      setTimer(-1);
      handleClick("error", "You have tried maximum times for receiving the OTP!");
    }
  };

  const handleSubmitForVerifyMobileOtp = async (mobileotp) => {
    if (!mobileotp) {
      return;
    }
    const data = {
      txnId: txnId,
      token: token,
      scope: "mobile_number",
      updateDetail: mobileotp,
      includeProfileQr: true,
    };
    try {
      const response = await updateAbdmDetail(patientId, data);

      if (response?.data?.message === "Mobile number is now successfully linked to your Account") {
        const updatedAbhaMobile = mobileNumber;
        const updatedPatientAbhaDetails = {
          ...patientAbhaDetails,
          profile: {
            ...patientAbhaDetails.profile,
            mobile: updatedAbhaMobile,
          },
        };
        setTxnId(response?.data?.txnId);
        setToken(response?.data?.token);
        setPatientAbhaDetails(updatedPatientAbhaDetails);
        handleClick("success", response?.data?.message);
        setIsSelectedIndex(4);
      }
    } catch (error) {
      handleClick("error", "There seems to be an error in Mobile OTP Verification.");
    }
  };

  const handleGetAbhaRadioBtnChange = (event, handleChange) => {
    const selectedValue = event.target.value;
    handleChange(event);
    setGetAbhaScope(selectedValue);

    const limitMap = {
      [VERIFICATION_VIA_MOBILE]: { maxLength: 10, type: "tel", message: "Enter Mobile Number" },
      [VERIFICATION_VIA_AADHAAR]: {
        maxLength: 12,
        kind: "aadhaarNubmer",
        type: "tel",
        message: "Enter Aadhaar Number",
      },
      [VERIFICATION_VIA_ABHA_NUMBER]: {
        maxLength: 14,
        kind: "abhaNumber",
        type: "tel",
        message: "Enter Abha Number",
      },
      [VERIFICATION_VIA_ABHA_ADDRESS]: { type: "text", message: "Enter Abha Address" },
    };

    setInputLimits(limitMap[selectedValue]);
  };

  const validateGetAbhaEntityText = async (getAbhaEntityText, getAbhaRadioBtn) => {
    const fieldName = "getAbhaEntityText";
    try {
      const values = { [fieldName]: getAbhaEntityText, getAbhaRadioBtn };
      await createAbhaValidation.validateAt(fieldName, values);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSearchAbha = async (value, radioBtn) => {
    const cleanedValue = value.replace(/\s/g, "");
    setGetAbhaEntityValue(cleanedValue);
    try {
      const validation = await validateGetAbhaEntityText(value, radioBtn);
      if (!validation) return;
      if ([VERIFICATION_VIA_ABHA_NUMBER, VERIFICATION_VIA_ABHA_ADDRESS].includes(radioBtn)) {
        handleBackButton(1);
        return;
      }
      const data = { scope: getAbhaScope, loginId: cleanedValue, txnId: "" };
      const response = await generateAbdmAadhaarOtp(data);
      setResendOtpDataObject({ txnId: response?.data?.txnId, ...data });
      setTxnId(response?.data?.txnId);
      handleBackButton(2);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Unknown Error. Please try again later.";
      handleClick("error", errorMessage);
      setShowCreateAbhaBtn(true);
    }
  };

  const handleAuthWithAbha = async () => {
    const data = { scope: getAbhaScope, loginId: getAbhaEntityValue };
    try {
      const response = await generateAbdmAadhaarOtp(data);
      setTxnId(response?.data?.txnId);
      setResendOtpDataObject({ txnId: response?.data?.txnId, ...data });
      handleBackButton(2);
    } catch (error) {
      handleClick(
        "error",
        error?.response?.data?.message || "Unknown Error. Please try again later."
      );
    }
  };

  const handleRadioBtnChangeForAuthMethod = (event, setFieldValue) => {
    const selectedValue = event.target.value;
    setFieldValue("authMethodBtn", selectedValue);

    const scopeMap = {
      abhaNumber: {
        mobileOtp: VERIFICATION_VIA_ABHA_USING_MOBILE,
        aadhaarOtp: VERIFICATION_VIA_ABHA_USING_AADHAAR,
      },
      default: {
        mobileOtp: VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
        aadhaarOtp: VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
      },
    };

    const selectedScope =
      scopeMap[getAbhaScope]?.[selectedValue] || scopeMap.default[selectedValue];
    setGetAbhaScope2(selectedScope);
  };

  const handleVerifyOtp = async (otp) => {
    if (!otp) return;
    try {
      const data = { otp, txnId };
      if (getAbhaScope === VERIFICATION_VIA_MOBILE) {
        setResendOtpDataObject(data);
        const response = await verifyGetAbhaOtpViaMobile(data, patientId);
        setTxnId(response?.data?.txnId);
        setToken(response?.data?.token);
        if (response?.data?.accounts.length >= 1) {
          setAccountOptions(response?.data?.accounts);
          handleBackButton(3);
          return;
        }
      } else {
        const scopeMap = {
          [VERIFICATION_VIA_ABHA_USING_AADHAAR]: VERIFICATION_ABHA_USING_AADHAAR_OTP,
          [VERIFICATION_VIA_ABHA_USING_MOBILE]: VERIFICATION_ABHA_USING_MOBILE_OTP,
          [VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR]:
            VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
          [VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE]: VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
        };

        const scope = scopeMap[getAbhaScope2] || VERIFICATION_VIA_AADHAAR;

        const abhaData = {
          verificationId: otp,
          txnId,
          scope,
          token: "",
          fingerPrintAuthPid: "",
          includeProfileQr: true,
        };

        await verifyGetAbhaOtpViaAbhaEntities(abhaData, patientId);
        handleClick("success", "Abha has been successfully linked!");
      }
      await handleFetchData();
      handleBackButton(1);
      setViewLinkedAbha(true);
    } catch (error) {
      if (error.response?.data.includes("User is already linked.")) {
        await handleFetchData();
        handleBackButton(1);
        setViewLinkedAbha(true);
        return;
      }
      handleClick(
        "error",
        error.response?.data?.message || "Unknown Error. Please try again later."
      );
      setShowCreateAbhaBtn(true);
    }
  };

  const handleSelectedAccount = async (value) => {
    try {
      const data = {
        verificationId: value,
        txnId: txnId,
        scope: VERIFY_SELECTED_ABHA_NUMBER,
        token: token,
        fingerPrintAuthPid: "",
        includeProfileQr: true,
      };
      const response = await verifyGetAbhaOtpViaAbhaEntities(data, patientId);
      if (response?.status === 200) {
        handleClick("success", "Abha has been successfully linked!");
        await handleFetchData();
        handleBackButton(1);
        setViewLinkedAbha(true);
      }
    } catch (e) {
      if (e.response?.data?.message === "User is already linked.") {
        await handleFetchData();
        handleBackButton(1);
        setViewLinkedAbha(true);
      }
      handleClick("error", e.response?.data?.message || "Unknown Error. Please try again later.");
    }
  };

  const ViewLinkedAbha = async () => {
    try {
      if (linkedAbhaNumberRef.current === null) {
        setIsSelectedIndex(0);
        setBackBtnStack([0]);
        setIsCreateAbha(true);
        setViewLinkedAbha(false);
        return;
      } else {
        const response = await getLinkedAbha(patientIdRef.current);
        if (response) {
          const blob = new Blob([response.data], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          if (response) {
            setAbhaImage(url);
          }
        }
        setViewLinkedAbha(true);
        handleBackButton(1);
      }
    } catch (error) {
      if (error.response && error.response.data instanceof ArrayBuffer) {
        const decoder = new TextDecoder("utf-8");
        const decodedString = decoder.decode(error.response.data);
        handleClick("error", decodedString);
      } else {
        handleClick(
          "error",
          error.response?.data?.message || "There seems to be an error! Please try again later."
        );
      }
    }
  };

  const DownloadAbhaImage = (dataLink) => {
    if (!dataLink) {
      handleClick("error", "There seems to be an error in Downloading! Please try again later.");
      return;
    }
    try {
      const downloadLink = document.createElement("a");
      downloadLink.href = dataLink;
      downloadLink.download = "abha_card.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.error(e);
      handleClick("error", "Downloading error! Please try again later.");
    }
  };

  const popBackBtnStack = () => {
    try {
      let poppedValue = 0;

      setBackBtnStack((prevStack) => {
        if (prevStack.length === 1) {
          setGetAbhaScope(VERIFICATION_VIA_MOBILE);
          poppedValue = 0;
          return [0];
        }
        poppedValue = prevStack[prevStack.length - 2];
        return prevStack.slice(0, -1);
      });

      return poppedValue;
    } catch (e) {
      console.error(e);
      return 0;
    }
  };

  return (
    <PatientAbhaSkeleton>
      <>
        {!viewLinkedAbha || state?.abhaCreationPending ? (
          <>
            <Reveal>
              <div className="section-heading">
                {isCreateAbha ? (
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#007390",
                      display: "inline-block",
                      margin: "10px",
                    }}
                  >
                    Abha Creation
                  </h2>
                ) : (
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#007390",
                      display: "inline-block",
                      margin: "10px",
                    }}
                  >
                    Link Abha
                  </h2>
                )}
                <div style={{ marginLeft: "auto", gap: "10px", display: "flex" }}>
                  {showCreateAbhaBtn && (
                    <CustomButton
                      sx={{ margin: "15px" }}
                      className="btn--primary"
                      onClick={() => {
                        formikRef.current.resetForm();
                        setIsCreateAbha(true);
                        setIsSelectedIndex(0);
                        setBackBtnStack([0]);
                        setCheckedStates([true, true, true, true, true]);
                        setShowCreateAbhaBtn(false);
                      }}
                    >
                      Create ABHA
                    </CustomButton>
                  )}
                  {isCreateAbha && isSelectedIndex === 4 && (
                    <CustomButton
                      sx={{ margin: "15px" }}
                      onClick={() => DownloadAbhaImage(abhaImage)}
                    >
                      <DownloadIcon />
                    </CustomButton>
                  )}
                  {!viewLinkedAbha && isSelectedIndex !== 0 && (
                    <CustomButton
                      sx={{ margin: "15px" }}
                      className="btn--primary"
                      onClick={() => {
                        const num = popBackBtnStack();
                        if (num === 0) formikRef.current.resetForm();
                        setIsSelectedIndex(num);
                      }}
                    >
                      <ArrowBackIcon />
                    </CustomButton>
                  )}
                  {!isCreateAbha && isSelectedIndex === 0 && (
                    <CustomButton
                      sx={{ margin: "15px", marginLeft: "-5px" }}
                      className="btn--primary"
                      onClick={() => {
                        setIsCreateAbha(true);
                        setCheckedStates([true, true, true, true, true]);
                      }}
                    >
                      <ArrowBackIcon />
                    </CustomButton>
                  )}
                </div>
              </div>
              <div style={{ margin: "5%" }}>
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={{
                    aadharNumber: "",
                    otp: "",
                    mobileNumber: "",
                    mobileotp: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    healthId: "",
                    healthIdForSearch: "",
                    mobileNumberForSearch: "",
                    otpForVerification: "",
                    submit: "",
                    getAbhaEntityText: "",
                    getAbhaRadioBtn: getAbhaScope,
                    getAbhaOtp: "",
                    authMethodBtn: "",
                    selectedOption: "",
                  }}
                  validationSchema={createAbhaValidation}
                  onSubmit={async (values) => {}}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                      {isCreateAbha && isSelectedIndex === 0 && (
                        <FormControl sx={{ width: "30%", mr: 3 }}>
                          <RadioGroup
                            row
                            defaultValue="createAbha"
                            aria-labelledby="abhaNumberRadioBtn"
                            name="abhaNumberRadioBtn"
                            value={values.abhaNumberRadioBtn}
                            onChange={handleRadioBtnChange}
                          >
                            <FormControlLabel
                              name="abhaNumberRadioBtn"
                              value="createAbha"
                              control={<Radio />}
                              label="Create ABHA"
                            />
                            <FormControlLabel
                              name="abhaNumberRadioBtn"
                              value="linkAbha"
                              control={<Radio />}
                              label="Verify ABHA"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                      {isCreateAbha && isSelectedIndex === 0 && (
                        <>
                          <p style={{ margin: "10px 0px 20px 10px" }}>I hereby declare that:</p>
                          <FormControlLabel
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              marginBottom: "10px",
                              alignItems: "flex-start",
                            }}
                            label="I am voluntarily sharing my Aadhaar Number / Virtual ID issued by the Unique Identification Authority of India (“UIDAI”), and my demographic information for the purpose of
creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”). I authorize NHA to use my
Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies,
Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication."
                            control={
                              <Checkbox
                                checked={checkedStates[0]}
                                onChange={handleCheckboxChange(0)}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  marginTop: "-8px",
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              marginBottom: "10px",
                              alignItems: "flex-start",
                            }}
                            label="I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) government health records and those which will be generated during this encounter."
                            control={
                              <Checkbox
                                checked={checkedStates[1]}
                                onChange={handleCheckboxChange(1)}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  marginTop: "-8px",
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              marginBottom: "10px",
                              alignItems: "flex-start",
                            }}
                            label="I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter."
                            control={
                              <Checkbox
                                checked={checkedStates[2]}
                                onChange={handleCheckboxChange(2)}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  marginTop: "-8px",
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              marginBottom: "10px",
                              alignItems: "flex-start",
                            }}
                            label="I intend to create Ayushman Bharat Health Account Number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”) using document other than
Aadhaar."
                            control={
                              <Checkbox
                                checked={checkedStates[3]}
                                onChange={handleCheckboxChange(3)}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  marginTop: "-8px",
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              marginBottom: "10px",
                              alignItems: "flex-start",
                            }}
                            label="I consent to the anonymization and subsequent use of my government health records for public health purposes."
                            control={
                              <Checkbox
                                checked={checkedStates[4]}
                                onChange={handleCheckboxChange(4)}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  marginTop: "-9px",
                                }}
                              />
                            }
                          />
                          {/* <div style={{ marginTop: "15px" }}>
                            <p style={{ marginTop: "15px" }}>
                              I understand that my ABHA (number) can be used and shared for purposes
                              as may be notified by Ayushman Bharat Digital Mission (ABDM) from time
                              to time including provision of healthcare services. Further, I am aware
                              that my personal identifiable information (Name, Address, Age, Date of
                              Birth, Gender and Photograph) may be made available to the entities
                              working in the National Digital Health Ecosystem (NDHE) which inter alia
                              may include stakeholders and entities such as healthcare professionals
                              (e.g. doctors), facilities (e.g. hospitals, laboratories) and data
                              fiduciaries (e.g. health programmes), which are registered with or
                              linked to the Ayushman Bharat Digital Mission (ABDM), and various
                              processes there under.
                            </p>
                            <p style={{ marginTop: "15px" }}>
                              I authorize NHA to use my Aadhaar number / Virtual ID for performing
                              Aadhaar based authentication with UIDAI as per the provisions of the
                              Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits
                              and Services) Act, 2016 for the aforesaid purpose. I understand that
                              UIDAI will share my e-KYC details, or response of “Yes” with NHA upon
                              successful authentication.
                            </p>
                            <p style={{ marginTop: "15px" }}>
                              I have been duly informed about the option of using other IDs apart from
                              Aadhaar; however, I consciously choose to use Aadhaar number / Virtual
                              ID for the purpose of availing benefits across the NDHE.
                            </p>
                            <p style={{ marginTop: "15px" }}>
                              I am aware that my personal identifiable information excluding Aadhaar
                              number / Virtual ID number can be used and shared for purposes as
                              mentioned above. I reserve the right to revoke the given consent at any
                              point of time as per provisions of Aadhar Act and Regulations.
                            </p>
                          </div> */}
                          <Box sx={{ mt: 4 }}>
                            <CustomButton
                              disabled={!checkedStates.every(Boolean)}
                              className="btn--primary"
                              onClick={() => {
                                handleBackButton(1);
                              }}
                              label="Next"
                            />
                          </Box>
                        </>
                      )}
                      {isCreateAbha && isSelectedIndex === 1 && (
                        <Reveal>
                          <h3>
                            An OTP will be sent to the mobile number linked to this Aadhaar number.
                          </h3>
                          <FormInputField
                            style={{ width: "45%", marginRight: "30px", marginTop: "15px" }}
                            label="Aadhaar Number"
                            type={"tel"}
                            name="aadharNumber"
                            value={values.aadharNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={<PermIdentityOutlined />}
                            size={"big"}
                          />
                          <Box sx={{ mt: 4 }}>
                            <CustomButton
                              disabled={!(values.aadharNumber.toString().length === 14)}
                              className="btn--primary"
                              onClick={() => handleSubmitForGenerateAadharOtp(values.aadharNumber)}
                              label="Generate"
                            ></CustomButton>
                          </Box>
                        </Reveal>
                      )}
                      {isCreateAbha && isSelectedIndex === 2 && (
                        <Reveal>
                          <div style={{ display: "flex" }}>
                            <div>
                              <h3>Enter Aadhaar Otp</h3>
                              <FormInputField
                                style={{ width: "80%", marginRight: "30px", marginTop: "15px" }}
                                label="Otp"
                                type={"tel"}
                                name="otp"
                                inputProps={{
                                  maxLength: 6,
                                }}
                                value={values.otp}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                startAdornment={<FingerprintOutlined />}
                                size={"big"}
                              />
                            </div>
                            <div>
                              <h3>Enter Mobile Number to Link with ABHA</h3>
                              <FormInputField
                                style={{ width: "80%", marginRight: "30px", marginTop: "15px" }}
                                label="Mobile Number"
                                type={"tel"}
                                name="mobileNumber"
                                inputProps={{
                                  maxLength: 10,
                                }}
                                value={values.mobileNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                startAdornment={<i className="ri-phone-line ri-xl" />}
                                size={"big"}
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <span>{otpSentMessage}</span>
                          </div>
                          <Box sx={{ mt: 2 }}>
                            <CustomButton
                              disabled={!resendEnabled}
                              className="btn--primary"
                              label={`Resend Otp${
                                !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                              }`}
                              onClick={() => handleResendOtp("Creation")}
                              style={{ marginRight: "20px" }}
                            ></CustomButton>
                            <CustomButton
                              disabled={values?.otp?.length !== 6}
                              className="btn--primary"
                              label="Verify"
                              onClick={() =>
                                handleSubmitForVerifyAadharOtp(values.otp, values.mobileNumber)
                              }
                            ></CustomButton>
                          </Box>
                        </Reveal>
                      )}
                      {isCreateAbha && isSelectedIndex === 3 && (
                        <Reveal>
                          <h3>Mobile Verification</h3>
                          <p>
                            Entered Mobile Number doesn't match with the number linked with Aadhaar.
                            <span style={{ fontWeight: 600 }}> Please Enter OTP to verify</span>
                          </p>

                          <FormInputField
                            style={{ width: "35%", marginRight: "30px", marginTop: "15px" }}
                            label="Otp"
                            type={"tel"}
                            name="mobileotp"
                            inputProps={{
                              maxLength: 6,
                            }}
                            value={values.mobileotp}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setMobileNumber(e.target.value);
                            }}
                            startAdornment={<FingerprintOutlined />}
                            size={"big"}
                          />
                          <Box sx={{ mt: 2 }}>
                            <CustomButton
                              disabled={!resendEnabled}
                              className="btn--primary"
                              label={`Resend Otp${
                                !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                              }`}
                              onClick={() => handleResendOtp("AddPhoneNumber")}
                              style={{ marginRight: "20px" }}
                            ></CustomButton>
                            <CustomButton
                              disabled={values?.mobileotp?.length !== 6}
                              className="btn--primary"
                              label="Verify"
                              onClick={() => handleSubmitForVerifyMobileOtp(values.mobileotp)}
                            ></CustomButton>
                          </Box>
                        </Reveal>
                      )}

                      {isCreateAbha && isSelectedIndex === 4 && (
                        <AbhaDetails
                          patientId={patientId}
                          patientAbhaDetails={patientAbhaDetails}
                          setPatientAbhaDetails={setPatientAbhaDetails}
                          txnId={txnId}
                        />
                      )}

                      {!isCreateAbha && isSelectedIndex === 0 && (
                        <Reveal>
                          <h3 style={{ margin: "1%" }}>Search Abha</h3>
                          <FormInputField
                            style={{ width: "50%", margin: "15px 30px 0px 0px" }}
                            label={inputLimits.message}
                            name="getAbhaEntityText"
                            value={values.getAbhaEntityText}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.getAbhaEntityText && errors.getAbhaEntityText)}
                            errorText={errors.getAbhaEntityText}
                            startAdornment={
                              inputLimits.type === "text" ? <AbcOutlined /> : <NumbersOutlined />
                            }
                            type={inputLimits.type}
                            size={"big"}
                            kind={inputLimits.kind}
                            customLength={inputLimits.maxLength}
                          />
                          <RadioGroup
                            row
                            defaultValue="mobileNoBtn"
                            aria-labelledby="getAbhaRadioBtn"
                            name="getAbhaRadioBtn"
                            value={values.getAbhaRadioBtn}
                            onChange={(e) => handleGetAbhaRadioBtnChange(e, handleChange)}
                            style={{ display: "flex" }}
                          >
                            <FormControlLabel
                              name="getAbhaRadioBtn"
                              value={VERIFICATION_VIA_MOBILE}
                              control={<Radio />}
                              label="Search via Mobile Number"
                              sx={{ pt: 2 }}
                            />
                            <FormControlLabel
                              name="getAbhaRadioBtn"
                              value={VERIFICATION_VIA_AADHAAR}
                              control={<Radio />}
                              label="Search via Aadhaar Number"
                              sx={{ pt: 2 }}
                            />
                            <FormControlLabel
                              name="getAbhaRadioBtn"
                              value={VERIFICATION_VIA_ABHA_NUMBER}
                              control={<Radio />}
                              label="Search via Abha Number"
                              sx={{ pt: 2 }}
                            />
                            <FormControlLabel
                              name="getAbhaRadioBtn"
                              value={VERIFICATION_VIA_ABHA_ADDRESS}
                              control={<Radio />}
                              label="Search via Abha Address"
                              sx={{ pt: 2 }}
                            />
                          </RadioGroup>
                          <Box sx={{ mt: 2 }}>
                            <CustomButton
                              disabled={!(values.getAbhaEntityText.toString().length >= 5)}
                              className="btn--primary"
                              label="Search"
                              onClick={() =>
                                handleSearchAbha(values.getAbhaEntityText, values.getAbhaRadioBtn)
                              }
                            ></CustomButton>
                          </Box>
                        </Reveal>
                      )}
                      {!isCreateAbha && isSelectedIndex === 1 && (
                        <Reveal>
                          <h3>Select Method</h3>
                          <FormControl sx={{ width: "30%", mt: 3, mr: 3 }}>
                            <RadioGroup
                              row
                              defaultValue="mobileOtp"
                              aria-labelledby="authMethodBtn"
                              name="authMethodBtn"
                              value={values.authMethodBtn}
                              onChange={(e) => {
                                handleRadioBtnChangeForAuthMethod(e, setFieldValue);
                              }}
                            >
                              <FormControlLabel
                                name="authMethodBtn"
                                value="mobileOtp"
                                control={<Radio />}
                                label="Mobile Otp"
                              />
                              <FormControlLabel
                                name="authMethodBtn"
                                value="aadharOtp"
                                control={<Radio />}
                                label="Aadhaar Otp"
                              />
                            </RadioGroup>
                          </FormControl>
                          <Box sx={{ mt: 2 }}>
                            <CustomButton
                              className="btn--primary"
                              label="Next"
                              onClick={() => handleAuthWithAbha()}
                            ></CustomButton>
                          </Box>
                        </Reveal>
                      )}
                      {!isCreateAbha && isSelectedIndex === 2 && (
                        <Reveal>
                          <h3 style={{ margin: "10px" }}>Otp details</h3>
                          <FormInputField
                            style={{ width: "50%", marginRight: "30px", marginTop: "15px" }}
                            label="OTP"
                            type={"tel"}
                            name="otpForVerification"
                            value={values.otpForVerification}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={<FingerprintOutlined />}
                            size={"big"}
                            customLength={6}
                          />
                          <Box sx={{ mt: 2 }}>
                            <CustomButton
                              disabled={!resendEnabled}
                              className="btn--primary"
                              label={`Resend Otp${
                                !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                              }`}
                              onClick={() => handleResendOtp("Verification")}
                              style={{ marginRight: "20px" }}
                            ></CustomButton>
                            <CustomButton
                              disabled={!values.otpForVerification}
                              className="btn--primary"
                              label="Verify"
                              onClick={() => handleVerifyOtp(values.otpForVerification)}
                            ></CustomButton>
                          </Box>
                        </Reveal>
                      )}
                      {!isCreateAbha && isSelectedIndex === 3 && (
                        <Reveal>
                          <h2 style={{ marginBottom: "35px" }}>Select an Account:</h2>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "35px",
                              margin: "10px",
                            }}
                          >
                            {accountOptions.map((account) => (
                              <div
                                key={account.ABHANumber}
                                onClick={() => {
                                  setFieldValue("selectedOption", account.ABHANumber);
                                }}
                                className={`profile-card ${
                                  values.selectedOption === account.ABHANumber
                                    ? "selected"
                                    : "not-selected"
                                }`}
                              >
                                <ProfileCard
                                  fullName={account.name}
                                  dateOfBirth={dayjs(account.dob).format("DD MMMM, YYYY")}
                                  gender={
                                    account.gender === "M"
                                      ? "Male"
                                      : account.gender === "F"
                                      ? "Female"
                                      : "Other"
                                  }
                                  abhaNumber={account.ABHANumber}
                                  abhaAddress={account.preferredAbhaAddress}
                                  image={account.profilePhoto}
                                ></ProfileCard>
                              </div>
                            ))}
                          </div>
                          <CustomButton
                            sx={{
                              margin: "25px 0px 0px 10px",
                            }}
                            disabled={!values.selectedOption}
                            className="btn--primary"
                            label="Select"
                            onClick={() => handleSelectedAccount(values.selectedOption)}
                          ></CustomButton>
                        </Reveal>
                      )}
                      {!isCreateAbha && isSelectedIndex === 4 && (
                        <AbhaDetails
                          patientId={patientId}
                          patientAbhaDetails={patientAbhaDetails}
                          setPatientAbhaDetails={setPatientAbhaDetails}
                          txnId={txnId}
                        />
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </Reveal>
          </>
        ) : (
          <>
            <div className="section-heading">
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#007390",
                  display: "inline-block",
                  margin: "10px",
                }}
              >
                View Abha
              </h2>
              <div style={{ marginLeft: "auto", gap: "10px", display: "flex" }}>
                <CustomButton onClick={() => DownloadAbhaImage(abhaImage)}>
                  <DownloadIcon />
                </CustomButton>
              </div>
            </div>
            <img src={abhaImage} alt="ABHA" style={{ width: "73vw", height: "80vh" }} />
          </>
        )}
      </>
    </PatientAbhaSkeleton>
  );
};

const PatientAbhaSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid size={{ xs: 12 }} display={"felx"}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageAbhaNumber;
