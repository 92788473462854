import { useState, useEffect, useRef } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  DATE_FORMAT,
  blockCalenderOptions,
  DATE_FORMAT_DMY,
  currentActiveUser,
} from "store/constant";
import dayjs from "dayjs";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { CorporateFareOutlined, FilterAltOutlined } from "@mui/icons-material";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import "assets/scss/availability.scss";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import {
  getBlockAvailabilities,
  cancelBlockAvailability,
  createBlockAvailabilities,
} from "services/Availability";
import { getAppointmentCountBetweenDates } from "services/Appointments";
import { useContext } from "react";
import { Typography, Radio, FormControlLabel, RadioGroup } from "@mui/material";
import Grid from "@mui/material/Grid2";
import eventBus from "utils/eventBus";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import { blockCalendarSchema } from "views/Components/Common/ValidationSchema/blockCalendarSchema";
import PropTypes from "prop-types";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { date } from "yup";
import CustomDateCalendar from "ui-component/custom-components/CustomDatePicker";

const DoctorBlockCalendar = ({ doctorId, doctorOrgId, calendarStyle, actionButtonStyle }) => {
  const blockButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [appointmentBlock, setAppointmentBlock] = useState({
    selectedAppointmentOption: "",
    blockFromDate: null,
    blockToDate: null,
    blockFromTime: "00:00",
    blockToTime: "23:59",
    blockingType: "SINGLE_DATE",
    timeRange: "ALL",
    organizationId: [doctorOrgId],
  });

  const { handleClick } = useContext(ToastContext);

  const [editDate, setEditDate] = useState(null);
  const [closeOnSelect, setCloseOnSelect] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [blockEditButton, setBlockEditButton] = useState(null);
  const [cancelButton, setCancelButton] = useState(true);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [blockAvailability, setBlockAvailability] = useState([]);

  const fetchBlockAvailabilities = async () => {
    try {
      const blockAvailabilityResponse = await getBlockAvailabilities(doctorId, doctorOrgId);
      setBlockAvailability(blockAvailabilityResponse.data);
    } catch (error) {
      console.error("Error fetching block dates list");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (blockButtonRef.current && !blockButtonRef.current.contains(event.target)) {
        // setIsButtonVisible(false); // Close the calendar
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [blockButtonRef]);

  useEffect(() => {
    fetchBlockAvailabilities();
  }, [doctorId, doctorOrgId]);

  const findMatchingDate = (blockAvailability, date) => {
    return blockAvailability.find((item) => {
      const startDate = dayjs(item.startDate).startOf("day");
      const endDate = dayjs(item.endDate).endOf("day");
      return dayjs(date).startOf("day").isBetween(startDate, endDate, null, "[]");
    });
  };

  const handleCancelBlockAvailability = async (editDate) => {
    try {
      await cancelBlockAvailability(doctorId, doctorOrgId, editDate, null);
      handleClick("success", "Cancelled successfully.");
      eventBus.emit("doctorAvailability", { isBlockedDateChanged: true });
      fetchBlockAvailabilities();
    } catch (error) {
      handleClick("error", "Error cancelling block availability");
    }
  };

  const renderDay = (day, selectedDate, pickersDayProps) => {
    const highlightedDates = formattedDates.map((date) => dayjs(date));
    const today = dayjs();
    const isHighlighted = highlightedDates.some((highlightedDate) =>
      dayjs(day).isSame(highlightedDate, "day")
    );
    const isPast = dayjs(day).isBefore(today, "day"); // Check if the date is before today

    return (
      <PickersDay
        {...pickersDayProps}
        day={day}
        disabled={isPast} // Disable all past dates
        sx={{
          ...(isHighlighted && {
            backgroundColor: "red",
            color: "white",
            "&:hover": {
              backgroundColor: "darkred",
            },
          }),
          ...(isPast && {
            pointerEvents: "none", // Disable interaction for past dates
            opacity: 0.5, // Style for past dates to show they are disabled
          }),
        }}
      />
    );
  };

  const handleCloseBlockModal = () => {
    setIsBlockModalOpen(false);
  };
  const formattedDates = Array.isArray(blockAvailability)
    ? blockAvailability
        .map((availability) => {
          const startDate = new Date(availability.startDate);
          const endDate = new Date(availability.endDate);
          const dates = [];

          // Loop from startDate to endDate
          while (startDate <= endDate) {
            dates.push({
              date: dayjs(startDate).format(DATE_FORMAT),
              blockingType: availability.blockingType,
            });
            startDate.setDate(startDate.getDate() + 1);
          }
          return dates;
        })
        .flat()
    : [];

  const BlockCalendarModal = ({ doctorId, closeModal, handleClick }) => {
    const [firstSubmit, setFirstSubmit] = useState(true);
    const [blockConfirmation, setBlockConfirmation] = useState(false);
    const [effectedAppointments, setEffectedAppointments] = useState(0);
    const relatedOrganizations = currentActiveUser()?.userOrganizationAssociationList;
    const fetchAppointmentCount = async (fromDate, toDate, fromTime, toTime) => {
      try {
        const payload = {
          fromDate,
          toDate,
          fromTime,
          toTime,
          doctorId: doctorId,
          orgId: doctorOrgId,
        };
        const response = await getAppointmentCountBetweenDates(payload);
        setEffectedAppointments(response.data.totalAppointments);
        setBlockConfirmation(true);
        return response.data.totalAppointments;
      } catch (error) {
        handleClick("error", "Error fetching appointment count");
      }
    };
    const handleFetchSaveData = async (values) => {
      try {
        const payload = {
          startDate: dayjs(values.fromDate).format(DATE_FORMAT),
          endDate: dayjs(values.toDate).format(DATE_FORMAT),
          startTime: values.fromTime,
          endTime: values.toTime,
          blockingType:
            values.blockingType === "SINGLE_DATE" && values.timeRange === "CUSTOM_TIME_RANGE"
              ? "CUSTOM_HOURS"
              : values.blockingType,
          doctorId,
          organizationId: values.organizationId,
          serviceType: values.appointmentOption,
        };
        if (blockConfirmation || effectedAppointments === 0) {
          await createBlockAvailabilities(payload);
          handleClick("success", "Availability has been successfully blocked.");
          if (dayjs(payload.startDate).isSame(dayjs(), "day")) {
            eventBus.emit("doctorAvailability", { isBlockedDateChanged: true });
          }
          closeModal();
          fetchBlockAvailabilities();
        }
      } catch (error) {
        handleClick("error", error?.response?.data?.message);
      }
    };

    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{
            fromDate: appointmentBlock.blockFromDate,
            toDate: appointmentBlock.blockToDate,
            fromTime: appointmentBlock.blockFromTime,
            toTime: appointmentBlock.blockToTime,
            appointmentOption: appointmentBlock.selectedAppointmentOption,
            blockingType: appointmentBlock.blockingType,
            timeRange: appointmentBlock.timeRange,
            organizationId: appointmentBlock.organizationId,
          }}
          validationSchema={blockCalendarSchema}
          onSubmit={async (values, { setSubmitting, submitForm }) => {
            if (firstSubmit) {
              setSubmitting(false);
              const appointmentCount = await fetchAppointmentCount(
                dayjs(values.fromDate).format(DATE_FORMAT),
                dayjs(values.toDate).format(DATE_FORMAT),
                values.fromTime,
                values.toTime
              );
              setFirstSubmit(false);
              if (appointmentCount === 0) {
                await submitForm();
              }
            } else {
              setSubmitting(true);
              await handleFetchSaveData(values);
              await fetchBlockAvailabilities();
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <Grid container spacing={3} mt={0.7}>
                  <Grid style={{ paddingTop: 0, marginTop: "20px" }}>
                    <Typography sx={{ mt: 0, mb: 0, fontWeight: 500, color: "#000000" }}>
                      Blocking Type
                    </Typography>
                    <Grid style={{ display: "flex", paddingTop: 0, marginBottom: -8 }}>
                      <RadioGroup
                        value={values.blockingType}
                        name="blockingType"
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value === "CUSTOM_DATE_TIME_RANGE") {
                            setFieldValue(
                              "toDate",
                              dayjs(values.fromDate).add(1, "day").startOf("day")
                            );
                          }
                          if (e.target.value === "SINGLE_DATE") {
                            setFieldValue("toDate", values.fromDate);
                          }
                          setBlockConfirmation(false);
                        }}
                        style={{ height: "50px" }}
                      >
                        <Grid size={{ xs: 12 }}>
                          <FormControlLabel
                            value="SINGLE_DATE"
                            control={<Radio />}
                            label="Single Date"
                          />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                          <FormControlLabel
                            value="CUSTOM_DATE_TIME_RANGE"
                            control={<Radio />}
                            label="Custom"
                          />
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3} mt={0.7}>
                  <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
                    <FormDatePicker
                      format={DATE_FORMAT_DMY}
                      label={values.blockingType === "SINGLE_DATE" ? "On Date" : "From Date"}
                      value={values.fromDate}
                      size={"big"}
                      name="fromDate"
                      required
                      minDate={dayjs().startOf("day")}
                      onChange={(e) => {
                        setFieldValue("fromDate", e);
                        if (values.blockingType === "SINGLE_DATE") {
                          setFieldValue("toDate", e);
                        }
                        setFirstSubmit(true);
                        setBlockConfirmation(false);
                      }}
                      style={{
                        width: "100%",
                        marginRight: "30px",
                        marginTop: "7px",
                      }}
                      error={Boolean(errors.fromDate && touched.fromDate)}
                      errorText={errors.fromDate}
                    ></FormDatePicker>
                  </Grid>

                  {values.blockingType !== "SINGLE_DATE" && (
                    <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
                      <FormDatePicker
                        format={DATE_FORMAT_DMY}
                        label={"To Date"}
                        name="toDate"
                        required
                        value={values.toDate}
                        size={"big"}
                        onChange={(e) => {
                          setFieldValue("toDate", e);
                          setBlockConfirmation(false);
                        }}
                        style={{
                          width: "100%",
                          marginRight: "30px",
                          marginTop: "7px",
                        }}
                        error={Boolean(errors.toDate && touched.toDate)}
                        errorText={errors.toDate}
                      ></FormDatePicker>
                    </Grid>
                  )}
                </Grid>

                {values.blockingType === "SINGLE_DATE" && (
                  <Grid style={{ display: "flex", paddingTop: 8, marginBottom: 0 }}>
                    <RadioGroup
                      value={values.timeRange}
                      name={"timeRange"}
                      onChange={(e) => {
                        handleChange(e);
                        setBlockConfirmation(false);
                      }}
                      style={{ height: "50px" }}
                    >
                      <Grid size={{ xs: 12 }}>
                        <FormControlLabel value="ALL" control={<Radio />} label="Whole Day" />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <FormControlLabel
                          value="CUSTOM_TIME_RANGE"
                          control={<Radio />}
                          label="Custom Time Range"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                )}
                {(values.blockingType !== "SINGLE_DATE" ||
                  values.timeRange === "CUSTOM_TIME_RANGE") && (
                  <Grid container spacing={3} mb={3} mt={0.1}>
                    <Grid size={{ xs: 6 }} style={{ paddingTop: 0, marginTop: "20px" }}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label="From Time"
                        name="fromTime"
                        type="time"
                        shrink={true}
                        value={values.fromTime}
                        onChange={(e) => {
                          setFieldValue("fromTime", e.target.value);
                          setBlockConfirmation(false);
                        }}
                        error={Boolean(errors.fromTime && touched.fromTime)}
                        errorText={errors.fromTime}
                        size={"big"}
                      />
                    </Grid>
                    <Grid size={{ xs: 6 }} style={{ paddingTop: 0, marginTop: "20px" }}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label="To Time"
                        shrink={true}
                        name="toTime"
                        type="time"
                        value={values.toTime}
                        onChange={(e) => {
                          setFieldValue("toTime", e.target.value);
                          setBlockConfirmation(false);
                        }}
                        error={Boolean(errors.toTime && touched.toTime)}
                        errorText={errors.toTime}
                        size={"big"}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  <Grid size={{ xs: 6 }} mt={0}>
                    <FormSelectField
                      style={{ width: "100%" }}
                      label="Appointment Options"
                      name="appointmentOption"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      startAdornment={<FilterAltOutlined />}
                      menuItems={blockCalenderOptions}
                      value={values.appointmentOption}
                      size={"big"}
                      shrink={true}
                      error={Boolean(errors.appointmentOption && touched.appointmentOption)}
                      errorText={errors.appointmentOption}
                    ></FormSelectField>
                  </Grid>
                  {relatedOrganizations.length > 1 && (
                    <Grid size={{ xs: 6 }} mt={0}>
                      <FormSelectField
                        multiple
                        required
                        name={"organizationId"}
                        label="Organizations"
                        style={{ width: "100%" }}
                        value={values.organizationId}
                        onChange={handleChange}
                        startAdornment={<CorporateFareOutlined />}
                        menuItems={relatedOrganizations.map((el) => {
                          return {
                            value: el.organizationId,
                            menuLabel: el.organizationName,
                          };
                        })}
                        error={Boolean(errors.organizationId && touched.organizationId)}
                        errorText={errors.organizationId}
                      />
                    </Grid>
                  )}
                </Grid>
                {blockConfirmation && effectedAppointments > 0 && (
                  <Typography style={{ marginTop: "20px", fontWeight: "500", color: "#004c70" }}>
                    {`You have ${effectedAppointments} appointments scheduled, which will be cancelled.`}{" "}
                    <br></br> {`Do you want to proceed?`}
                  </Typography>
                )}
                <div style={{ display: "flex" }}>
                  <CustomButton
                    className={"mui-btn--primary"}
                    height="36px"
                    label={"Block Calendar"}
                    type="submit"
                    style={{ marginTop: "20px", marginRight: "20px" }}
                  ></CustomButton>

                  <CustomButton
                    className={"btn--secondary-light"}
                    height="36px"
                    label={"Cancel"}
                    onClick={closeModal}
                    style={{ marginTop: "20px", marginRight: "20px" }}
                  ></CustomButton>
                </div>
              </>
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <>
      <CustomButton
        className="calendar ri-calendar-2-line ri-lg btn--secondary-light"
        style={{ marginLeft: "12px", position: "relative" }}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsBlock(false);
        }}
      />
      {isOpen && (
        <>
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              ...actionButtonStyle,
            }}
          >
            <div ref={blockButtonRef}>
              <CustomDateCalendar
                value={new Date()}
                closeOnSelect={false}
                onChange={(date) => {
                  setIsButtonVisible(true);
                  setIsBlock(true);
                  setAppointmentBlock({
                    ...appointmentBlock,
                    blockFromDate: date,
                    blockToDate: date,
                  });
                  const matchedDate = formattedDates.find((highlightedDate) =>
                    dayjs(date).isSame(dayjs(highlightedDate.date), "day")
                  );

                  if (matchedDate) {
                    setCancelButton(false);
                    if (
                      ["SINGLE_DATE", "CUSTOM_DATE_TIME_RANGE"].includes(matchedDate.blockingType)
                    ) {
                      setBlockEditButton(true);
                    } else {
                      setBlockEditButton(false);
                    }
                  } else {
                    setCancelButton(true);
                    setBlockEditButton(false);
                  }
                  const matchedObject = findMatchingDate(blockAvailability, date);
                  if (matchedObject) {
                    setAppointmentBlock({
                      ...appointmentBlock,
                      blockFromDate: matchedObject.startDate,
                      blockToDate: matchedObject.endDate,
                    });
                    setEditDate(matchedObject.startDate);
                  } else {
                  }
                }}
                onClose={(e) => {
                  // setIsButtonVisible(false);
                  setTimeout(() => {
                    setIsOpen(false); // Close with a delay
                  }, 180);
                }}
                blockedDates={[...new Set(formattedDates.map((item) => item.date))]}
                highlightedDates={[...new Set(formattedDates.map((item) => item.date))]}
                style={{
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  marginTop: "-340px",
                  marginLeft: "30px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        </>
      )}
      {isBlock && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            marginTop: "-30px",
            position: "absolute",
            top: 470,
            transform: "translateX(-50px)",
          }}
          ref={blockButtonRef}
        >
          {isButtonVisible && isOpen && (
            <CustomButton
              onClick={() => {
                setIsBlockModalOpen(true);
                setCloseOnSelect(false);
                setIsButtonVisible(false);
              }}
              label="Block date"
              disabled={blockEditButton}
              style={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adjust these values for your shadow
              }}
            />
          )}
          {isButtonVisible && isOpen && (
            <CustomButton
              className={""}
              // onClick={() => setIsBlockModalOpen(true)}
              onClick={() => {
                setIsCancelModalOpen(true);
                setCloseOnSelect(false);
                setIsButtonVisible(false);
                // setIsOpen(!isOpen)
              }}
              label="Cancel"
              disabled={cancelButton}
              style={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adjust these values for your shadow
              }}
            />
          )}
        </div>
      )}
      <ModalUI
        visible={isBlockModalOpen}
        close={() => {
          setIsBlockModalOpen(false);
          setAppointmentBlock({
            ...appointmentBlock,
            selectedAppointmentOption: "",
            blockFromDate: null,
            blockToDate: null,
            blockFromTime: "00:00",
            blockToTime: "23:59",
            blockingType: "SINGLE_DATE",
            timeRange: "ALL",
          });
          setIsOpen(false);
        }}
        title={"Block Calendar By Date"}
        style={{
          overflowY: "none",
          height: "fit-content",
          width: "610px",
        }}
        component={
          <BlockCalendarModal
            doctorId={doctorId}
            closeModal={handleCloseBlockModal}
            handleClick={handleClick}
          ></BlockCalendarModal>
        }
      />
      <ModalUI
        visible={isCancelModalOpen}
        close={() => setIsCancelModalOpen(false)} // Close modal on cancel
        title="Confirm Cancellation"
        component={
          <div>
            <p>Your blocked date(s) will be cancelled!</p>
            <p>Are you sure you want to cancel?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  padding: "10px",
                  backgroundColor: "#29BF91",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  handleCancelBlockAvailability(editDate);
                  // Handle the confirm action here
                  setIsCancelModalOpen(false);
                  fetchBlockAvailabilities();
                }}
              >
                Confirm Cancel
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

DoctorBlockCalendar.propTypes = {
  doctorId: PropTypes.string.isRequired,
  doctorOrgId: PropTypes.string.isRequired,
  calendarStyle: PropTypes.object,
  actionButtonStyle: PropTypes.object,
};

export default DoctorBlockCalendar;
