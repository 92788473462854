import { useState, useEffect } from "react";
import { Typography, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";

import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import * as React from "react";
import "assets/scss/doctorAppointments.scss";
import {
  getPatientPastProceduresByPatientId,
  getPatientVitalByPatientId,
} from "services/patientService";
import { getMedicationsByPatientId } from "services/PrescriptionsService";
import { getApprovedRecords } from "services/HiuService";
import {
  CHART_OPTIONS,
  currentActiveUser,
  DATE_FORMAT_DMY,
  hiuToken,
  LINE_CHART,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Stack } from "@mui/system";
import { getRecentAppointments } from "services/Appointments";
import {
  getChartByVitalType,
  getEhrDataPoints,
  getLatestVitals,
  vitalSignType,
} from "utils/ehr-data-utils";

const PatientHealthDetails = ({
  patientVitals,
  selectedVitalKey,
  setSelectedVitalKey,
  isTeleconsultation = false,
  patientAbhaId,
  patientId,
  handleClick,
  setIframeOpen,
  setIframeSrc,
  setIframeAppointmentId,
}) => {
  const [activeMedications, setActiveMedications] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [latestVitalsGraphData, setLatestVitalsGraphData] = useState(null);

  const [visits, setVisits] = useState([]);
  const [records, setRecords] = useState([]);

  const doctorId = currentActiveUser()?.roleBasedId;

  useEffect(() => {
    const fetchLatestVitals = async () => {
      try {
        if (patientId) {
          const allPatientVitals = await getPatientVitalByPatientId(patientId);
          const ehrDataPoints = getEhrDataPoints({
            encounters: [{ vitals: allPatientVitals.data }],
          });

          let newVitalData = ehrDataPoints.filter((d) => vitalSignType.includes(d.key));
          const latestVitalsValue = getLatestVitals(newVitalData);
          const LatestVitalsGraphData = newVitalData.map((item1) => {
            // Find the matching object from arr2 by key
            const matchingItem = latestVitalsValue.find((item2) => item2.key === item1.key);

            if (matchingItem) {
              return { ...item1, ...matchingItem };
            }
            return null;
          });
          if (LatestVitalsGraphData.length > 0 && setSelectedVitalKey) {
            setSelectedVitalKey(LatestVitalsGraphData[0].key);
          }
          setLatestVitalsGraphData(LatestVitalsGraphData);
        }
      } catch (e) {
        console.error("Error fetching patient vitals");
      }
    };
    fetchLatestVitals();
  }, [patientId]);

  useEffect(() => {
    const getRecordsForPatient = async () => {
      try {
        const abdmRecords = await getApprovedRecords(10, doctorId, patientAbhaId);

        setRecords(abdmRecords?.data);
      } catch (e) {
        console.error(e, "Can not fetch health records... Please try after some time");
      }
    };

    getRecordsForPatient();
    const intervalId = setInterval(getRecordsForPatient, 60000);

    return () => clearInterval(intervalId);
  }, [doctorId, handleClick, patientAbhaId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (patientId) {
          fetchPatientVisitDetails();

          const activeMedicationResponse = await getMedicationsByPatientId(patientId);
          setActiveMedications(activeMedicationResponse.data);

          const medicalHistoryResponse = await getPatientPastProceduresByPatientId(patientId);
          setMedicalHistory(medicalHistoryResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data");
      }
    };

    fetchData();
  }, [patientId]);

  const fetchPatientVisitDetails = async () => {
    try {
      const visitsResponse = await getRecentAppointments(
        patientId,
        null,
        null,
        null,
        null,
        null,
        5
      );
      setVisits(visitsResponse.data);
    } catch (error) {
      console.error("Error fetching patient visit details:", error);
    }
  };

  const trendData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    values: [1000, 1200, 1100, 1300, 1400, 1234, 1000, 1200, 1100, 1300, 1400, 1234], // Dummy values
  };

  const onClickGetAbhaRecords = () => {
    setIframeOpen(true);
    setIframeSrc(
      `https://abdmconnector.argusservices.in/abdm-m3-ui/consent-list?token=${hiuToken}&username=${doctorId}&searchValue=${patientAbhaId}&themeColor=004c70`
    );
  };

  return (
    <Grid container>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 0.5,
          mt: 1,
          fontSize: "15px",
          fontWeight: 500,
          color: "#004C70",
          // display: "flex",
          justifyContent: "space-between",
          width: "100%",
          borderRadius:
            latestVitalsGraphData?.length + records?.length + visits?.length === 0 ? "8px" : "",
          backgroundColor:
            latestVitalsGraphData?.length + records?.length + visits?.length === 0
              ? isTeleconsultation
                ? "rgba(0, 0, 0, 0)"
                : "#ededed"
              : "",
          minHeight:
            latestVitalsGraphData?.length + records?.length + visits?.length === 0 ? "100px" : "",
        }}
      >
        {/* <div style={{ display: "flex", width: "100%" }}>
            <CustomButton
              label={"Abha Records"}
              onClick={() => {
                setIframeOpen(true);
                setIframeSrc(
                  `https://abdmconnector.argusservices.in/abdm-m3-ui/consent-list?token=${hiuToken}&username=${doctorId}&searchValue=${patientAbhaId}&themeColor=004c70`
                );
              }}
              style={{ marginLeft: "auto" }}
              className={"btn--secondary"}
              startIcon={<AddOutlined />}
              gap="0px"
            />
          </div> */}
        {/* {(patientVitals?.length > 0 ||
            medicalHistory?.length > 0 ||
            activeMedications?.length > 0) && (
            <Grid
              item
              style={{ width: "calc(50% - 0.5px)", padding: "8px", backgroundColor: "#f6f8f8" }}
              sx={{
                borderRadius: "8px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  padding: "8px 0px",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  fontWeight: "500",
                }}
                className="btn--primary-light"
              >
                About
              </Typography>
              <Reveal>
                {patientVitals?.length > 0 && (
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                      borderRadius: "8px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginBottom: "20px !important",
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Vitals
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: "3px 0px" }}>
                      {patientVitals && patientVitals.length > 0 && (
                        <>
                          {patientVitals?.map((vital) => (
                            <Card
                              sx={{
                                fontSize: "12px",
                              }}
                            >
                              <CardContent sx={{ padding: "8px 0px" }}>
                                <div
                                  className="w-100"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ marginRight: "8px" }}>{vital?.displayName}</div>
                                  <div style={{ marginRight: "8px" }}>{vital?.value}</div>
                                  <div>{vital?.deviation}</div>
                                </div>
                              </CardContent>
                            </Card>
                          ))}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {medicalHistory?.length > 0 && (
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                      borderRadius: "8px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginBottom: "20px !important",
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Medical History
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: "3px 0px" }}>
                      {medicalHistory?.map((item) => (
                        <Card
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          <CardContent sx={{ padding: "8px 0px" }}>
                            <div
                              className="w-100"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ marginRight: "8px" }}>
                                {item?.procedureResponse.name}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}

                {activeMedications?.length > 0 && (
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                      borderRadius: "8px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      marginBottom: "20px !important",
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Active Medication
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: "3px 0px" }}>
                      {activeMedications?.map((item) => (
                        <Card
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          <CardContent sx={{ padding: "8px 0px" }}>
                            <div
                              className="w-100"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ marginRight: "8px" }}>
                                {item?.medicationResponse.name}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Reveal>
            </Grid>
          )} */}
        {latestVitalsGraphData?.length > 0 && (
          <Grid
            item
            size={{ md: 6 }}
            // md={6}
            sx={{
              // width: latestVitalsGraphData?.length > 0 ? "calc(50% - 0.5px)" : "100%",
              backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "#f6f8f8",
              borderRadius: "12px",

              // overflowY: "scroll"
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                padding: "8px 0px",
                borderRadius: "8px 8px 0px 0px",
                // marginBottom: "8px",
                fontWeight: "500",
                backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                color: "#004c70",
              }}
            >
              Vitals
            </Typography>
            <Grid
              className="common-scrollbar"
              container
              spacing={2}
              sx={{
                padding: "12px 8px",
                maxHeight: "495px !important",
                backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                overflowY: "auto",
              }}
            >
              <Stack spacing={2} sx={{ width: "100%" }}>
                {latestVitalsGraphData?.map((vital, index) => (
                  <Grid
                    size={{ xs: 12 }}
                    key={index}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (setSelectedVitalKey) {
                        setSelectedVitalKey(vital.key);
                      }
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "8px",
                        boxShadow:
                          selectedVitalKey === vital.key ? "0px 4px 4px rgba(0, 0, 0, 0.1)" : "",
                        cursor: "pointer",
                        backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                      }}
                    >
                      <CardContent
                        sx={{
                          padding: "16px 24px",
                          backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid>
                          <Grid container spacing={1} alignItems="center">
                            <Grid>
                              <Typography
                                sx={{
                                  color: isTeleconsultation ? "#ffffff" : "#0C2D48",
                                  fontWeight: 600,
                                }}
                              >
                                {vital?.latestValue?.vitalSignTypeResponse?.displayName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ paddingRight: "8px" }}
                          >
                            <div style={{ display: "flex" }}>
                              <Grid>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  style={{ color: isTeleconsultation ? "#ffffff" : "#0C2D48" }}
                                >
                                  {vital?.latestValue?.value}
                                  {vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation}
                                </Typography>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        {/* <Grid>{vital?.deviation}</Grid> */}
                        <Grid>
                          {getChartByVitalType(
                            vital?.key,
                            vital?.value,
                            vital?.value[0]?.vitalSignTypeResponse?.displayName,
                            CHART_OPTIONS,
                            true,
                            5,
                            true,
                            {
                              minHeight: "45px",
                              padding: "0px",
                              width: "70px",
                              border: "none",
                              backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                            },
                            {
                              maxHeight: "45px",
                              backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                            },
                            LINE_CHART
                          )}
                        </Grid>
                        {/* <Grid>
                            <IndicatorWithTrendGraph trendData={trendData} />
                          </Grid> */}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Stack>
            </Grid>
          </Grid>
        )}
        {records?.length > 0 || visits?.length > 0 ? (
          <Grid
            size={{ md: latestVitalsGraphData?.length > 0 ? 6 : 12 }}
            sx={{
              // minWidth: "50%",
              // maxHeight:"400px",
              backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "#f6f8f8",
              borderRadius: "12px",
              position: "relative",
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                padding: "8px 0px",
                borderRadius: "8px 8px 0px 0px",
                // marginBottom: "8px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "#e6eef1",
                color: "#004c70",
              }}
            >
              Visits
            </Typography>

            <Grid
              className="common-scrollbar"
              container
              spacing={2}
              sx={{
                padding: "12px 8px",
                positive: "relative",
                maxHeight: "495px !important",
                overflowY: "auto",
              }}
            >
              <Stack spacing={2} sx={{ width: "100%" }}>
                {visits.map((visit, index) => (
                  <Grid size={{ xs: 12 }} key={index}>
                    <Card
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                      }}
                      onClick={() => {
                        setIframeOpen(true);
                        setIframeAppointmentId(visit?.id);
                      }}
                    >
                      <CardContent
                        sx={{
                          padding: "16px 24px",
                          minHeight: "65px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                        }}
                      >
                        <Grid container spacing={1} alignItems="center">
                          <Grid>
                            <Typography
                              sx={{
                                color: isTeleconsultation ? "#ffffff" : "#0C2D48",
                                fontWeight: 600,
                              }}
                            >
                              Date: {dayjs(visit.appointmentDate).format(DATE_FORMAT_DMY)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ paddingRight: "8px" }}
                        >
                          <div style={{ display: "flex" }}>
                            <Grid>
                              <Typography
                                variant="body2"
                                component="div"
                                style={{ color: isTeleconsultation ? "#ffffff" : "#0C2D48" }}
                              >
                                Dr. {visit?.doctor?.userResponse?.name}
                              </Typography>
                            </Grid>
                          </div>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                {records?.map((record, index) => (
                  <Card
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                    }}
                    onClick={() => {
                      setIframeOpen(true);
                      setIframeSrc(record?.viewFhirUrl);
                    }}
                  >
                    <CardContent
                      sx={{
                        padding: "16px 24px",
                        backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "",
                      }}
                    >
                      <Typography
                        sx={{ color: isTeleconsultation ? "#ffffff" : "#0C2D48", fontWeight: 600 }}
                      >
                        Date: {dayjs(record?.creationDate).format(DATE_FORMAT_DMY)}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Typography
                          variant="body2"
                          component="div"
                          style={{ color: isTeleconsultation ? "#ffffff" : "#0C2D48" }}
                        >
                          {record?.fhirSummaryData?.map((item, index) => (
                            <div key={index}>{item?.practitionerName}</div>
                          ))}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <FloatingAbhaRecordButton onClickGetAbhaRecords={onClickGetAbhaRecords} />
              </Stack>
              {/* <Fab
                  // onClick={handleNewModalOpenForBookingAppointment}
                  variant="extended"
                  sx={{
                    // ml: "auto",
                    right: "7px",
                    bottom: "10px",
                    // left: "20",
                    // bottom: "20px",
                    position: "absolute",
                    backgroundColor: "#004c70 !important",
                    color: "#FFF",
                  }}
                  size="small"
                >
                  <i className="ri-add-circle-fill" style={{ fontSize: "24px", marginRight: "4px" }}></i>
                  Abha Records
                </Fab> */}
            </Grid>
          </Grid>
        ) : (
          <Grid
            size={{
              md: latestVitalsGraphData?.length + records?.length + visits?.length === 0 ? 12 : 6,
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : undefined,
            }}
          >
            <CustomButton
              label="Abha Records"
              className="btn--primary"
              startIcon={
                <i
                  className="ri-add-circle-fill"
                  style={{
                    fontSize: "24px",
                  }}
                ></i>
              }
              gap="0px"
              onClick={onClickGetAbhaRecords}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
  // );
};

const FloatingAbhaRecordButton = ({ onClickGetAbhaRecords }) => {
  // State to track if the button is hovered
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onClick={onClickGetAbhaRecords}
      style={{
        right: "7px",
        bottom: "10px",
        height: "35px",
        borderRadius: "35px",
        textOverflow: "fade",
        whiteSpace: "nowrap",
        width: isHovered ? "150px" : "35px",
        position: "absolute",
        backgroundColor: "#004c70 !important",
        color: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        overflow: "hidden",
        transition: "all 0.5s ease-in-out", // Smooth transition
      }}
      className="btn--primary"
      size="small"
      onMouseEnter={() => setIsHovered(true)} // Set to true on hover
      onMouseLeave={() => setIsHovered(false)} // Set back to false when not hovering
    >
      <i
        className="ri-add-circle-fill"
        style={{
          height: "24px",
          width: "24px",
          fontSize: "24px",
          marginRight: isHovered ? "7px" : "1px",
          transition: "all 0.5s ease-in-out",
        }}
      ></i>
      {isHovered ? "Abha Records" : null} {/* Show text only when hovered */}
    </div>
  );
};

export default PatientHealthDetails;
