import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

// availability Apis
export const getAvailabilities = (id) => API.get(`/api/availabilities/${id}`);
export const createAvailabilities = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/availabilities`, data);
};
export const updateAvailabilities = (availabilityId, data) =>
  API.put(`/api/availabilities/${availabilityId}`, data);
export const deleteAvailabilities = (availabilityId) =>
  API.delete(`/api/availabilities/${availabilityId}`);

// availabilities for block-calendar
export const getBlockAvailabilities = (docId, orgId) =>
  API.get(`/api/availabilities/custom?doctorId=${docId}&organizationId=${orgId}`);

export const getBlockAvailabilitiesByDate = (docId, date) =>
  API.get(`/api/availabilities/custom/doctor?doctorId=${docId}&date=${date}`);

export const cancelBlockAvailability = (docId, orgId, date, blockId) => {
  let url = `/api/availability/custom?doctorId=${docId}&organizationId=${orgId}`;
  if (date) {
    url += `&date=${date}`;
  }
  if (blockId) {
    url += `&blockId=${blockId}`;
  }
  return API.delete(url);
};

export const createBlockAvailabilities = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/availability/custom`, data);
};

export const editBlockAvailabilities = (data) => API.put(`/api/availability/custom`, data);

// availabilities for slots
export const getSlotsAvailabilities = (searchParams, data = null) => {
  return API.post(`/api/availabilities/slots`, data, {
    params: searchParams,
    headers: {
      "Content-Type": "application/json", // Set the correct Content-Type header
    },
  });
};

// availabilities for check-in
export const checkInAvailabilities = (data) => {
  data["id"] = getUUID();
  return API.put(`/api/availability/custom/check-in`, data);
};

export const currentStatusDoctor = (doctorId, organizationId) =>
  API.get(
    `/api/availabilities/current-status?doctorId=${doctorId}&organizationId=${organizationId}`
  );
