import "assets/scss/subscription.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useEffect, useState } from "react";
import {
  createSubscriptionLicenceAssociations,
  getLicenceByOrgId,
  getPackages,
} from "services/Subscription";
import { Box, Grid2 as Grid, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Slices/userSlice";
import { ACTIVE_STATE, currentActiveUser, DATE_FORMAT, getUUID, PENDING } from "store/constant";
import dayjs from "dayjs";
import { convertIntoTwoDecimal } from "utils/price-utils";
import {
  createOrderForPaymentByAdmin,
  generateBillingInvoicesByAdmin,
  getInvoicePDFByIdByAdmin,
  paymentVerificationByAdmin,
} from "services/BillingService";
import { SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import Reveal from "views/utilities/Reveal";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const packagesType = { "Silver Package": 1, "Gold Package": 2, "Platinum Package": 3 };

const UserSubscription = () => {
  const [allPackages, setAllPackages] = useState([]);
  const [orgLicenceData, setOrgLicenseData] = useState(null);
  const userOrgId = useSelector(getUserOrgId);
  const activePackage = allPackages.find((item) => item.isActive);
  const currentUser = currentActiveUser();
  const { handleClick } = useContext(ToastContext);

  const updatePackageListInfo = async () => {
    try {
      const packageResponse = await getPackages();

      const orgPackageResponse = await getLicenceByOrgId(userOrgId);
      setOrgLicenseData(orgPackageResponse.data);

      const licencePackageAssociation =
        orgPackageResponse.data.subscriptionLicensePackageAssociationResponses;

      // for now saving the package association id in localstorage
      const activePackageAssociationId = localStorage.getItem("licencePackageAssociationId");
      let lastActivePackage;

      if (activePackageAssociationId) {
        // get latest active package association from the association list
        lastActivePackage = licencePackageAssociation.find(
          (item) => item.id === activePackageAssociationId
        );
      } else {
        // find the last active package comparing start date, and active status
        lastActivePackage = [...licencePackageAssociation].reduce((closest, current) => {
          const today = dayjs();
          const currentDate = dayjs(current.startDate);
          const closestDate = dayjs(closest.startDate);

          return Math.abs(currentDate.diff(today)) < Math.abs(closestDate.diff(today)) &&
            current.associationStatus === ACTIVE_STATE
            ? current
            : closest;
        });
      }

      // select last active package from package list
      const aPackage = packageResponse.data
        .filter((item) => packagesType.hasOwnProperty(item.name))
        .find((item) => item.id === lastActivePackage.packageResponse.id);

      setAllPackages(
        packageResponse.data
          .filter((item) => packagesType.hasOwnProperty(item.name))
          .sort((a, b) => packagesType[a.name] - packagesType[b.name])
          .map((item) => {
            if (item.id === aPackage?.id) {
              return {
                ...item,
                isActive: true,
              };
            } else return item;
          })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updatePackageListInfo();
  }, []);

  const generatePDF = async (invoiceId) => {
    try {
      // toDo need to check once the pr is merged
      const pdfRes = await getInvoicePDFByIdByAdmin(invoiceId, null);

      const blob = new Blob([pdfRes.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create an iframe element to embed the PDF
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;

      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      };
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const getDiscountAmount = (discounts, price) => {
    let discountAmount = 0;
    discounts?.forEach((discount) => {
      if (!discount.discountStatus === ACTIVE_STATE) {
        discount += 0;
      } else if (discount.discountUnit === "PERCENTAGE") {
        discountAmount += (price * discount.value) / 100;
      } else if (discount.discountUnit === "FIXED_AMOUNT") {
        discountAmount += discount.value;
      }
    });
    return discountAmount;
  };

  const initiatePayment = async (event, invoiceDetails) => {
    event.preventDefault();
    try {
      const payloadForOrderCreation = {
        id: getUUID(),
        invoiceId: invoiceDetails.id,
        paymentDate: dayjs().format(DATE_FORMAT),
        amount: invoiceDetails.totalAmount,
        method: "RAZORPAY",
        paymentStatus: PENDING,
        razorpayOrderResponse: {
          notes: "NOTES",
        },
      };
      const orderResponse = await createOrderForPaymentByAdmin(payloadForOrderCreation);

      const { razorpayOrderResponse } = orderResponse.data;
      const options = {
        key: "rzp_test_5Fezr30xpUnvz4", // Enter the Key ID generated from the Dashboard
        amount: razorpayOrderResponse.amount, // in paise for INR
        currency: razorpayOrderResponse.currency,
        name: "Arog",
        description: "Test Transaction",
        image: "https://hc.argusservices.in/images/email/logo.png",
        order_id: razorpayOrderResponse.razorpayOrderId,
        handler: async function (response) {
          try {
            const data = {
              orderCreationId: razorpayOrderResponse.razorpayOrderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const params = {
              id: razorpayOrderResponse.paymentId,
            };
            await paymentVerificationByAdmin(params, data);
            updatePackageListInfo();
            await generatePDF(invoiceDetails?.id);
          } catch (error) {
            console.error(error);
          }
        },
        prefill: {
          name: currentUser?.name,
          email: currentUser?.emailId,
          contact: currentUser?.mobileNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        timeout: 300, //seconds
        modal: {
          confirm_close: true,
          ondismiss: async (reason) => {
            // when modal is closed by the user
            if (reason === undefined) {
              handleClick("error", "Payment Cancelled. Try Again");
            }
            // when modal is auto closed because of time out
            else if (reason === "timeout") {
              handleClick("error", "Timedout. Try Again");
            }
            // When payment gets failed.
            else {
              handleClick("error", "Payment Failed. Try Again");
            }
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", async (response) => {
        console.error(response);
      });
      paymentObject.open();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubscrptionClick = async (event, selectedPackage) => {
    let licencePackageAssociationId = null;
    try {
      const data = {
        licenceId: orgLicenceData?.id,
        packageId: selectedPackage?.id,
        startDate: dayjs().format(DATE_FORMAT),
        endDate: dayjs().add(selectedPackage?.validity, "day").format(DATE_FORMAT),
        status: ACTIVE_STATE,
      };

      const response = await createSubscriptionLicenceAssociations(data);
      licencePackageAssociationId = response.data.id;
      localStorage.setItem("licencePackageAssociationId", licencePackageAssociationId);
    } catch (error) {
      console.error(error);
    }

    try {
      const invoiceId = getUUID();
      const invoiceItemData = {
        id: getUUID(),
        invoiceId: invoiceId,
        description: null,
        typeId: selectedPackage.id,
        quantity: 1,
        discount: selectedPackage.discounts || 0,
        tax: selectedPackage.tax || 0,
        amount: selectedPackage?.price || 0,
        status: ACTIVE_STATE,
      };

      const discountAmount = getDiscountAmount(selectedPackage.discounts, selectedPackage.price);

      const data = {
        description: null,
        id: invoiceId,
        typeId: licencePackageAssociationId,
        invoiceType: "SUBSCRIPTION",
        organizationId: userOrgId,
        issueDate: dayjs(),
        totalAmount: convertIntoTwoDecimal(selectedPackage.price - discountAmount),
        invoiceStatus: "PENDING",
        status: "ACTIVE",
        invoiceItems: [invoiceItemData],
      };

      const invoiceResponse = await generateBillingInvoicesByAdmin(data);
      await initiatePayment(event, invoiceResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserSubscriptionSkeleton>
      <Reveal className="user-subscription">
        <div
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <h6 className="page-title">Subscriptions</h6>
        </div>

        <Grid container justifyContent="center">
          {allPackages.map((item) => (
            <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }} style={{ display: "flex" }}>
              <SubscriptionItem
                key={item?.id}
                item={item}
                handleSubscrptionClick={handleSubscrptionClick}
                activePackage={activePackage}
              ></SubscriptionItem>
            </Grid>
          ))}
        </Grid>
      </Reveal>
    </UserSubscriptionSkeleton>
  );
};

const SubscriptionItem = ({ item, handleSubscrptionClick, activePackage }) => {
  return (
    <div className={`subscription-card ${item.isActive ? "active-card" : ""}`}>
      {/* ${
        packagesType[item.name] < packagesType[activePackage.name] ? "disabled-card" : ""
      } */}

      <div className="card-icon">
        <img src="/images/arog-icon.svg" className="card-icon-logo" alt="arog" />
      </div>
      <div className="card-detail">
        <p className="card-title">{item.name}</p>
        <p className="card-description">{item.description}</p>
        <p className="card-price">&#8377; {item.price}</p>
      </div>
      <div className="card-purchase">
        <CustomButton
          label={item.isActive ? "Active" : activePackage ? "Upgrade Now" : "Purchase Now"}
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "40px",
            pointerEvents: item.isActive ? "none" : "auto",
            cursor: item.isActive ? "default" : "pointer",
          }}
          className={"purchase-button"}
          onClick={(event) => {
            handleSubscrptionClick(event, item);
          }}
        ></CustomButton>
      </div>
      <div className="card-features">
        {item.packageBundleAssociationResponses.map((feature) => (
          <div key={feature.id} className="card-feature-item">
            <i className="ri-check-fill ri-xl"></i>
            <p className="card-feature-text">{feature.bundleResponse.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const UserSubscriptionSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={450}
          />
        </Grid>
        <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={450}
          />
        </Grid>
        <Grid size={{ lg: 4, md: 6, sm: 6, xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={450}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserSubscription;
