import { LocationCity, School } from "@mui/icons-material";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import {
  createQualifications,
  deleteQualifications,
  updateQualifications,
} from "services/doctorService";
import { ACTIVE_STATE, MIN_AGE_FOR_QUALIFICATION, startsWithAlphabetic } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const calendarValue = (year) => {
  if (year) {
    return dayjs(`${year}-01-01`);
  }
  return null;
};

const DoctorEducation = ({
  doctorDetails,
  viewOnlyPage,
  setDoctorDetails,
  readOnly,
  setReadOnly,
  refreshData,
  ...others
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const { handleClick } = useContext(ToastContext);

  const doctorDateOfBirth = dayjs(doctorDetails?.userResponse?.dateOfBirth);
  const minQualificationDate = doctorDateOfBirth
    .add(MIN_AGE_FOR_QUALIFICATION, "year")
    .startOf("year");

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateEducationModal = (degree) => {
    setSelected(degree);
    setOpen(true);
  };

  const openAddEducationModal = () => {
    setOpen(true);
  };

  const handleMarkInactive = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deleteQualifications(row.id);
        handleClick("success", "Qualification marked Inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Qualification as Inactive.");
      }
    } else {
      handleClick("error", "Qualification is already inactive!");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateEducationModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMarkInactive,
    },
  ];

  const columns = [
    { field: "degreeName", label: "Degree Name" },
    { field: "collegeName", label: "College Name" },
    { field: "yearOfPassing", label: "Year of Passing" },
    ...(!viewOnlyPage ? [{ field: "Actions", label: "ACTIONS", actions: true }] : []),
  ];

  return (
    <Reveal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Educational Degrees
        </Typography>
        {!viewOnlyPage && (
          <CustomButton
            onClick={openAddEducationModal}
            label="Add"
            className="ri-add-fill ri-lg btn--primary"
          />
        )}
      </Box>

      <CustomizedTable
        columns={columns}
        tableData={doctorDetails?.qualifications}
        actions={actions}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Educational Degree" : "Add Educational Degree"}
        component={
          <DoctorDegreeModal
            minQualificationDate={minQualificationDate}
            degrees={doctorDetails?.qualifications}
            selected={selected}
            handleClick={handleClick}
            close={() => {
              closeModal();
            }}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />
    </Reveal>
  );
};

const DoctorDegreeModal = ({
  degrees,
  selected,
  handleClick,
  close,
  doctorId,
  refreshData,
  minQualificationDate,
}) => {
  const [college, setCollege] = useState(selected?.collegeName ? selected?.collegeName : "");
  const [degree, setDegree] = useState(selected?.degreeName ? selected?.degreeName : "");
  const [year, setYear] = useState(
    selected?.yearOfPassing ? calendarValue(selected?.yearOfPassing) : null
  );

  const [errors, setErrors] = useState({});

  const currentDate = dayjs();
  const [collgeTouched, setCollegeTouched] = useState(false);
  const [degreeTouched, setDegreeTouched] = useState(false);
  const pattern = /^[a-zA-Z0-9\s'-.()]+$/;
  // const [dobError, setDobError] = useState(selected ? "" : "Date is required.");

  const validateCollege = (errors, college) => {
    if (!college) {
      errors.college = "Please enter a college name";
    } else if (college.length < 3) {
      errors.college = "Name must be at least 3 characters long";
    } else if (college.length > 100) {
      errors.college = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(college)) {
      errors.college = "Name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(college)) {
      errors.college = "Name must start with alphabet";
    }
  };

  const validateDegree = (errors, degree) => {
    if (!degree) {
      errors.degree = "Please enter a degree name.";
    } else if (degree.length < 3) {
      errors.degree = "Degree name must be at least 3 characters long";
    } else if (degree.length > 100) {
      errors.degree = "Degree name cannot be longer than 100 characters";
    } else if (!pattern.test(degree)) {
      errors.degree = "Degree name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(degree)) {
      errors.degree = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setCollegeTouched(true);
    validateCollege(errors, college);

    setDegreeTouched(true);
    validateDegree(errors, degree);

    if (year === null) {
      errors.year = "Please enter a valid year.";
    } else if (!(year?.isValid ? year.isValid() : true)) {
      errors.year = "Please enter a valid 'Year' value.";
    } else if (year > currentDate) {
      errors.year = "Year cannot be in future.";
    } else if (year.isBefore(minQualificationDate)) {
      errors.year = `Year should be at least ${minQualificationDate.year()} for qualifications.`;
    }

    return errors;
  };

  useEffect(() => {
    if (selected && degrees) {
      degrees.find((degree) => {
        return degree.id === selected.id;
      });
    }
  }, [selected, degrees]);

  const saveDegree = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        doctorId: doctorId,
        collegeName: college,
        degreeName: degree,
        yearOfPassing: year?.$y ? year.$y : year.year(),
      };

      if (selected) {
        try {
          const postData = {
            ...data,
            id: selected?.id,
          };
          await updateQualifications(selected.id, postData);
          handleClick("success", "Qualification has been successfully updated.");
          refreshData();
          close();
        } catch (error) {
          handleClick("error", "There seems to be some error updating the qualification.");
        }
      } else {
        try {
          await createQualifications(data);
          handleClick("success", "Qualification has been successfully added.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be some error adding the qualification.");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="College Name"
            name="college"
            required
            value={college}
            onBlur={(e) => {
              setCollegeTouched(true);
              const newError = {};
              validateCollege(newError, college);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setCollege(e.target.value);
              if (collgeTouched) {
                const newError = {};
                validateCollege(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.college;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            error={Boolean(errors.college)}
            errorText={errors.college}
            startAdornment={<LocationCity />}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Degree Name"
            name="degree"
            required
            value={degree}
            onBlur={(e) => {
              setDegreeTouched(true);
              const newError = {};
              validateDegree(newError, degree);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setDegree(e.target.value);
              if (degreeTouched) {
                const newError = {};
                validateDegree(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.degree;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            error={Boolean(errors.degree)}
            errorText={errors.degree}
            startAdornment={<School />}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FormDatePicker
            label={"Passing year"}
            required
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            value={year}
            minDate={minQualificationDate}
            onChange={(year) => {
              setYear(year);
              if (year === null) {
                setErrors({ ...errors, year: "Please enter valid year." });
              } else if (!year.isValid()) {
                setErrors({ ...errors, year: "Please enter valid 'Year' value." });
              } else if (year > currentDate) {
                setErrors({ ...errors, year: "Year cannot be in a future." });
              } else if (year.isBefore(minQualificationDate)) {
                setErrors({
                  ...errors,
                  year: `Year should be at least ${minQualificationDate.year()} for qualifications.`,
                });
              } else {
                const { year, ...newError } = errors;
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.year)}
            errorText={errors.year}
          />
        </Grid>

        <Grid sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={saveDegree}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorEducation;
