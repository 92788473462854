import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

// appointments Apis
export const getAppointments = () => API.get(`/api/appointments`);
export const getAppointmentById = (id) => API.get(`/api/appointments/${id}`);
export const createAppointment = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/appointments`, data);
};
export const updateAppointment = (appointmentId, data) =>
  API.put(`/api/appointments/${appointmentId}`, data);
export const deleteAppointment = (appointmentId) =>
  API.delete(`/api/appointments/${appointmentId}`);

// appointments related to doctor or organization or both
export const getFilteredAppointments = (searchParams) =>
  API.get(`/api/appointments/filter`, { params: searchParams });

// appointments related to patient
export const getAppointmentsByPatientId = (patientId) =>
  API.get(`/api/appointments/patient/${patientId}`);

export const getRecentAppointments = (
  roleBasedId,
  fromDate,
  toDate,
  appointmentType,
  doctorId,
  isUnique,
  size
) =>
  API.get(`/api/appointments/${roleBasedId}/recent`, {
    params: {
      fromDate,
      toDate,
      appointmentType,
      doctorId,
      isUnique,
      size,
    },
  });

export const getAllAppointmentsByUserId = (userId, seacrhParams) =>
  API.get(`/api/appointments/${userId}/family`, {
    params: seacrhParams,
  });

// appointments related to organization
export const getAppointmentsByOrgIdOrDoctorId = (orgId, doctorId) => {
  return API.get(`/api/appointments/query`, {
    params: {
      orgId: orgId,
      doctorId: doctorId,
    },
  });
};

// appointments for mark-status
export const updateAppointmentStatus = (appointmentId, status) =>
  API.put(
    `/api/appointments/mark-status`,
    {},
    { params: { appointmentId, status }, showLoader: false }
  );

// recent appointments
export const getRecentAppointmentsOfDoctor = (searchParams, doctorId) =>
  API.get(`/api/appointments/${doctorId}/recent`, { params: searchParams });

export const getRecentAppointmentsOfPatient = (searchParams, patientId) =>
  API.get(`/api/appointments/${patientId}/recent`, { params: searchParams });

export const getAppointmentsByDoctorId = (doctorId) =>
  API.get(`/api/appointments/doctor/${doctorId}`);

export const getAppointmentCountBetweenDates = async (payload) => {
  return await API.get(`/api/appointments/count`, { params: payload });
};
