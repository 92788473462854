import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRoutes from "./AuthenticationRoutes";
import ErrorPage from "views/Components/Error/ErrorPage";
import {
  currentActiveUser,
  SUPER_ADMIN,
  CLINIC_ADMIN,
  DOCTOR,
  PATIENT,
  FRONT_DESK,
} from "store/constant";

// project imports

import { useSelector } from "react-redux";
import MainLayout from "layout/MainLayout";
import User from "../views/Components/Common/User/User";
import Roles from "../views/Components/Role/Roles";
import ManageRole from "../views/Components/Role/ManageRole";
import ManageUser from "../views/Components/Common/User/ManageUser";
import ManageDoctor from "../views/Components/Doctor/ManageDoctor";
import ManagePatient from "../views/Components/Patient/ManagePatient";
import UpdateProfile from "../views/Components/Common/UpdateProfile";
import DoctorProfileNew from "../views/Components/Doctor/DoctorProfileNew";
import Organization from "../views/Components/Organization/Organization";
import Notification from "../views/Components/Notification/Notification";
import ManageAbhaNumber from "../views/Components/Patient/Abha/ManageAbhaNumber";
import Doctor from "views/Components/Doctor/Doctor";
import Patient from "views/Components/Patient/Patient";
import Availability from "views/Components/Appointment/Availability";
import Protected from "./Protected";
import PatientPrescription from "views/Components/Patient/PatientPrescription";
import Appointment from "views/Components/Appointment/Appointment";
import PatientAppointments from "views/Components/Patient/PatientAppointments";
import DoctorSpecializations from "views/Components/Entities/Specializations/Specializations";
import PatientProfile from "views/Components/Patient/PatientProfile";
import CommonTreats from "views/Components/Entities/CommonTreat/CommonTreats";
import ManageFamilyMembers from "views/Components/Patient/ManageFamilyMembers";
import Procedure from "views/Components/Entities/Procedure/Procedure";
import Language from "views/Components/Entities/Language/Languages";
import Allergy from "views/Components/Entities/Allergy/Allergy";
import MedicalServiceCategory from "views/Components/Entities/MedicalServiceCategory/MedicalServiceCategory";
import TaxRates from "views/Components/Entities/TaxRates/TaxRates";
import ChronicDisease from "views/Components/Entities/ChronicDisease/ChronicDisease";
import VaccineTypes from "views/Components/Entities/VaccineType/VaccineType";
import VitalSignTypes from "views/Components/Entities/VitalSignType/VitalSignType";
import DoctorAvailability from "views/Components/Doctor/DoctorAvailability";
import PrescriptionPad from "views/Components/Doctor/Prescription Pad/prescriptionPad";
import Review from "views/Components/Common/Review/review";
import ReviewList from "views/Components/Common/Review/ReviewList";
import OtpScreen from "views/Components/Doctor/HPR/OtpScreen";
import CreateHprId from "views/Components/Doctor/HPR/CreateHprId";
import QRCodeGenerator from "views/Components/Common/QRCodeGenerator";
import DoctorAppointments from "views/Components/Doctor/DoctorAppointments";
import DoctorSearch from "views/Components/Doctor/DoctorSearch";
import PatientEmergencyContact from "views/Components/Patient/PatientProfile/PatientEmergencyContact";
import PatientMedicalInfo from "views/Components/Patient/PatientProfile/PatientMedicalInfo";
import PatientFamilyDetails from "views/Components/Patient/PatientProfile/PatientFamilyDetails";
import DoctorProfessionalDetails from "views/Components/Doctor/DoctorProfessionalDetails";
import HprLogin from "views/Components/Doctor/HFR/HprLogin";
import HfrBasicInfo from "views/Components/Doctor/HFR/HfrBasicInfo";
import HfrAdditionalInfo from "views/Components/Doctor/HFR/HfrAdditionalInfo";
import HfrSubmit from "views/Components/Doctor/HFR/HfrSubmit";
import HfrDetailedInfo from "views/Components/Doctor/HFR/HfrDetailedInfo";
import PrescriptionDesign from "views/Components/Doctor/Prescription Pad/pdf-template/prescriptionDesign";
import ManageOrganization from "views/Components/Organization/ManageOrganization";
import GenerateInvoice from "views/Components/Doctor/PaymentGateway/GenerateInvoice";
import Features from "views/Components/Entities/Feature/Features";
import PatientDashboard from "views/Components/Dashboard/PatientDashboard";
import ClinicDashboard from "views/Components/Dashboard/ClinicDashboard";
import SuperAdminDashboard from "views/Components/Dashboard/SuperAdminDashboard";
import AbhaClinicAdminPage from "views/Components/Organization/AbhaClinicAdminPage";
import HfrLink from "views/Components/Doctor/HFR/HfrLink";
import { Navigate } from "react-router-dom";
import RoomPage from "views/Components/TeleCommunication/Screens/Room";
import PatientPayment from "views/Components/Patient/PatientPayment";
import GenerateDashboard from "views/Components/Chart/generateDashboard";
import PatientRecords from "views/Components/Patient/PatientRecords";
import FeaturesAssociation from "views/Components/Entities/FeaturesAssociation/FeaturesAssociation";
import ValidateFeature from "./ValidateFeature";
import Template from "views/Components/Doctor/Prescription Pad/Template";
import Package from "views/Components/Package/package";
import Subscriptions from "views/Components/Package/subscriptions";
import Bundles from "views/Components/Package/bundles";
import BillingForAppointments from "views/Components/Frontdesk/BillingForAppointments";
import Content from "views/Components/Content/Content";
import ManageContent from "views/Components/Content/ManageContent";
import ViewContent from "views/Components/Content/ViewContent";
import EntityInfoPage from "views/Components/Entities/EntityInfoPage";
import AbhaDetails from "views/Components/Patient/Abha/AbhaDetails";
import { getUserFeatures } from "store/Slices/userSlice";
import PatientLifeStyle from "views/Components/Patient/PatientProfile/PatientLifeStyle";
import PatientDetails2 from "views/Components/EHR/PatientDetails/PatientEHR/PatientDetails2";
import RecentVisits from "views/Components/Patient/RecentVisits";
import ProductAndServices from "views/Components/Entities/ProductAndServices/ProductAndServices";
import CreateEditDashboard from "views/Components/Chart/CreateEditDashboard";
import PatientListForDashboard from "views/Components/Doctor/NewDoctorDashboard/PatientListForDashboard";
import PrescriptionPadForClinicVisit from "views/Components/Doctor/Prescription Pad/prescriptionPadForClinicVisit";
import Discounts from "views/Components/Entities/Discounts/Discounts";
import MockData from "views/Components/Package/mockData";
import UserSubscription from "views/Components/Package/user-subscription/UserSubscriptions";
import ManageOrganizations from "views/Components/Organization/ManageOrganizations";
import Preference from "views/Components/Entities/ManufacturerPrefernece/ManufacturerPreferences";
import TenantsOnboarding from "views/Components/TenantsOnboarding";
import DoctorAppointmentsNewV2 from "views/Components/Doctor/NewDoctorDashboard/DoctorAppointmentsNewV2";

// ==============================|| ROUTING RENDER ||============================== //

const featureRouteMapper = {
  ABHA: {
    abhaClinicAdmin: <ValidateFeature url="abhaClinicAdmin" Component={AbhaClinicAdminPage} />,
    manageAbhaNumber: <ValidateFeature url="manageAbhaNumber" Component={ManageAbhaNumber} />,
    abhaDetails: {
      path: "abhaDetails/:patientId",
      element: <ValidateFeature url="abhaDetails" Component={AbhaDetails} />,
    },
  },
  APPOINTMENT: {
    myAppointments: {
      path: "myAppointments",
      element: <ValidateFeature url="myAppointments" Component={DoctorAppointments} />,
    },
    patientPayment: <ValidateFeature url="patientPayment" Component={PatientPayment} />,
    recentVisits: <ValidateFeature url="recentVisits" Component={RecentVisits} />,
    patientAppointments: {
      path: "patientAppointments",
      element: <ValidateFeature url="patientAppointments" Component={PatientAppointments} />,
    },
    prescription: <ValidateFeature url="prescription" Component={PrescriptionDesign} />,
    patientPrescription: (
      <ValidateFeature url="patientPrescription" Component={PatientPrescription} />
    ),
    Appointment: <ValidateFeature url="Appointment" Component={Appointment} />,
    doctorProfile: <ValidateFeature url="doctorProfileForPatient" Component={DoctorProfileNew} />,
  },
  AVAILABILITY: {
    doctorAvailability: <ValidateFeature url="doctorAvailability" Component={DoctorAvailability} />,
    availability: <ValidateFeature url="availability" Component={Availability} />,
  },
  BILLING: {
    billing: <ValidateFeature url="billing" Component={BillingForAppointments} />,
    generateInvoice: <ValidateFeature url="generateInvoice" Component={GenerateInvoice} />,
  },
  CONTENT: {
    "health-feeds": <ValidateFeature url="health-feeds" Component={Content} />,
    manageContent: <ValidateFeature url="health-feeds" Component={ManageContent} />,
    "health-feeds/content": {
      path: "health-feeds/content/:id",
      element: <ValidateFeature url="health-feeds/content" Component={ViewContent} />,
    },
  },
  DOCTOR: {
    doctorProfile: <ValidateFeature url="doctorProfile" Component={DoctorProfileNew} />,
    doctorProfessionalDetails: (
      <ValidateFeature url="doctorProfessionalDetails" Component={DoctorProfessionalDetails} />
    ),
    Doctor: <ValidateFeature url="Doctor" Component={Doctor} />,
    DoctorSpecializations: (
      <ValidateFeature url="DoctorSpecializations" Component={DoctorSpecializations} />
    ),
    "DoctorEntities/CommonTreats": <ValidateFeature url="CommonTreats" Component={CommonTreats} />,
    "DoctorEntities/Preference": <ValidateFeature url="Preference" Component={Preference} />,
    "DoctorEntities/Procedure": <ValidateFeature url="Procedure" Component={Procedure} />,
    "DoctorEntities/Allergy": <ValidateFeature url="Allergy" Component={Allergy} />,
    "DoctorEntities/ChronicDisease": (
      <ValidateFeature url="ChronicDisease" Component={ChronicDisease} />
    ),
    "DoctorEntities/VaccineTypes": <ValidateFeature url="VaccineTypes" Component={VaccineTypes} />,
    "DoctorEntities/VitalSignTypes": (
      <ValidateFeature url="VitalSignTypes" Component={VitalSignTypes} />
    ),
    manageDoctor: <ValidateFeature url="manageDoctor" Component={ManageDoctor} />,
    Templates: <ValidateFeature url="Templates" Component={Template} />,
  },
  DOCTOR_SEARCH: { doctorSearch: <ValidateFeature url="doctorSearch" Component={DoctorSearch} /> },
  HPR: {
    OtpScreen: <ValidateFeature url="OtpScreen" Component={OtpScreen} />,
    "OtpScreen/create": {
      path: "OtpScreen/create/:id",
      element: <ValidateFeature url="OtpScreen/create" Component={CreateHprId} />,
    },
  },
  ORGANIZATION: {
    Organization: <ValidateFeature url="Organization" Component={Organization} />,
    manageOrganization: (
      <ValidateFeature url="manageOrganization" Component={ManageOrganizations} />
    ),
    "DoctorEntities/MedicalServiceCategory": (
      <ValidateFeature url="MedicalServiceCategory" Component={MedicalServiceCategory} />
    ),
    "DoctorEntities/TaxRates": <ValidateFeature url="TaxRates" Component={TaxRates} />,
    ProductAndServices: <ValidateFeature url="ProductAndServices" Component={ProductAndServices} />,
    qrcode: <ValidateFeature url="qrcode" Component={QRCodeGenerator} />,
    InfoPage: <ValidateFeature url="InfoPage" Component={EntityInfoPage} />,
    Discounts: <ValidateFeature url="Discounts" Component={Discounts} />,
    userSubscriptions: <ValidateFeature url="userSubscriptions" Component={UserSubscription} />,
  },
  PATIENT: {
    Patient: <ValidateFeature url="Patient" Component={Patient} />,
    managePatient: <ValidateFeature url="managePatient" Component={ManagePatient} />,
    patientProfile: <ValidateFeature url="patientProfile" Component={PatientProfile} />,
    patientEmergencyContact: (
      <ValidateFeature url="patientEmergencyContact" Component={PatientEmergencyContact} />
    ),
    patientMedicalInfo: <ValidateFeature url="patientMedicalInfo" Component={PatientMedicalInfo} />,
    patientLifestyle: <ValidateFeature url="patientLifestyle" Component={PatientLifeStyle} />,
    patientRecords: <ValidateFeature url="patientRecords" Component={PatientRecords} />,
    patientFamilyDetails: (
      <ValidateFeature url="patientFamilyDetails" Component={PatientFamilyDetails} />
    ),
    manageFamilyMembers: (
      <ValidateFeature url="manageFamilyMembers" Component={ManageFamilyMembers} />
    ),
  },
  PRESCRIPTION: {
    prescriptionPad: {
      path: "prescriptionPad/:appointmentId",
      element: <ValidateFeature url="prescriptionPad" Component={PrescriptionPad} />,
    },
  },
  REVIEW: {
    reviewList: <ValidateFeature url="reviewList" Component={ReviewList} />,
    review: <ValidateFeature url="review" Component={Review} />,
  },
  USER: {
    manageUser: <ValidateFeature url="user" Component={ManageUser} />,
    user: <ValidateFeature url="user" Component={User} />,
    updateProfile: <ValidateFeature url="updateProfile" Component={UpdateProfile} />,
    generateDashboard: <ValidateFeature url="generateDashboard" Component={GenerateDashboard} />,
    createEditDashboard: (
      <ValidateFeature url="createEditDashboard" Component={CreateEditDashboard} />
    ),
    "DoctorEntities/Language": <ValidateFeature url="Language" Component={Language} />,
  },
  ROLE: {
    ManageRole: <ValidateFeature url="ManageRole" Component={ManageRole} />,
    Roles: <ValidateFeature url="Roles" Component={Roles} />,
  },
  NOTIFICATION: { notification: <ValidateFeature url="notification" Component={Notification} /> },
  FEATURE: {
    features: <ValidateFeature url="features" Component={Features} />,
    featuresAssociation: (
      <ValidateFeature url="featuresAssociation" Component={FeaturesAssociation} />
    ),
  },
  HFR: {
    hfrBasicInfo: <ValidateFeature url="hfrBasicInfo" Component={HfrBasicInfo} />,
    hfrDetailedInfo: <ValidateFeature url="hfrDetailedInfo" Component={HfrDetailedInfo} />,
    linkHfr: <ValidateFeature url="linkHfr" Component={HfrLink} />,
    hfrAdditionalInfo: <ValidateFeature url="hfrAdditionalInfo" Component={HfrAdditionalInfo} />,
    hfrSubmit: <ValidateFeature url="hfrSubmit" Component={HfrSubmit} />,
    HprLogin: <ValidateFeature url="HprLogin" Component={HprLogin} />,
  },
  EHR: {
    // ehr: <ValidateFeature url="ehr" Component={PatientDetails2} />,
    ehr: {
      path: "ehr/:id",
      element: <ValidateFeature url="ehr" Component={PatientDetails2} />,
    },
  },
};

const frontDeskROutes = [
  {
    path: "dashboard",
    element: <Appointment />,
  },
  {
    path: "prescription/:id",
    element: <PrescriptionDesign />,
  },
];

const doctorRoutes = [
  {
    path: "dashboard",
    element: <DoctorAppointmentsNewV2 />,
    children: [
      {
        path: "",
        element: <PatientListForDashboard />,
      },
      // {
      //   path: "prescriptionPad/:id",
      //   element: <PrescriptionPadForClinicVisit />,
      // },
    ],
  },
  {
    path: "room/:roomId",
    element: <RoomPage isDoctor={true} />,
  },
  {
    path: "health-feeds",
    element: <Content />,
  },
  {
    path: "manageContent",
    element: <ManageContent />,
  },
  {
    path: "health-feeds/:id",
    element: <ViewContent />,
  },
  {
    path: "patientPrescription/:id",
    element: <PatientPrescription />,
  },
  {
    path: "prescription/:id",
    element: <PrescriptionDesign />,
  },
];

const patientRoutes = [
  {
    path: "dashboard",
    element: <PatientDashboard />,
  },
  {
    path: "room/:roomId",
    element: <RoomPage isDoctor={false} />,
  },
  {
    path: "patientPrescription/:id",
    element: <PatientPrescription />,
  },
  {
    path: "prescription/:id",
    element: <PrescriptionDesign />,
  },
  {
    path: "health-feeds",
    element: <Content />,
  },
  {
    path: "health-feeds/:id",
    element: <ViewContent />,
  },
];

const adminRoutes = [
  {
    path: "dashboard",
    // element: <SuperAdminDashboard dashboardid={"5389993d-9c4b-4b62-92c9-a897f29c8e73"} />,
    element: <SuperAdminDashboard dashboardid={"f9d810b1-5c9a-4cb6-b106-184d93da58fd"} />,
  },
  {
    path: "tenantsOnboarding",
    element: <TenantsOnboarding></TenantsOnboarding>,
  },
];

const clinincRoutes = [
  {
    path: "dashboard",
    element: <ClinicDashboard />,
  },
];

const routes = [
  {
    path: "",
    element: <Navigate to="dashboard" replace />,
  },
  {
    path: "updateProfile",
    element: <ValidateFeature url="updateProfile" Component={UpdateProfile} />,
  },
  {
    path: "packages",
    element: <Package />,
  },
  {
    path: "subscriptions",
    element: <Subscriptions />,
  },
  {
    path: "bundles",
    element: <Bundles />,
  },
  {
    path: "mockData",
    element: <MockData />,
  },
];

const getBaseRoutes = (roleName) => {
  switch (roleName) {
    case DOCTOR:
      return [...routes, ...doctorRoutes];
    case CLINIC_ADMIN:
      return [...routes, ...clinincRoutes];
    case PATIENT:
      return [...routes, ...patientRoutes];
    case FRONT_DESK:
      return [...routes, ...frontDeskROutes];
    case SUPER_ADMIN:
    default:
      return [...routes, ...adminRoutes];
  }
};

export default function ThemeRoutes() {
  const user = currentActiveUser();
  let features = useSelector(getUserFeatures) || JSON.parse(localStorage.getItem("userFeatures"));
  let generatedCustomRoutes = getBaseRoutes(user?.roleName);
  if (features) {
    for (const feature in features) {
      const accessibleRoutesForFeature = featureRouteMapper[feature];
      for (const route in accessibleRoutesForFeature) {
        if (
          accessibleRoutesForFeature[route].hasOwnProperty("path") &&
          accessibleRoutesForFeature[route].hasOwnProperty("element")
        ) {
          generatedCustomRoutes.push(accessibleRoutesForFeature[route]);
        } else {
          generatedCustomRoutes.push({ path: route, element: accessibleRoutesForFeature[route] });
        }
      }
    }
  }
  const MainRoutes = {
    path: "/home",
    errorElement: <ErrorPage />,
    element: <Protected Component={MainLayout} />,
    children: generatedCustomRoutes,
  };
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);
}
