import { combineReducers } from "redux";
import { UtilReducer } from "./ChildSlices/utilSlice";
import { PatientVitalsReducer } from "./ChildSlices/patientVitalSlice";
import { AppointmentsReducer } from "./ChildSlices/appointmentSlice";

export const doctorDashboardReducer = combineReducers({
  appointments: AppointmentsReducer,
  patientVitals: PatientVitalsReducer,
  utils: UtilReducer,
});
