import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {
  appEnv,
  DOCTOR,
  isDemo,
  isOTPLogin,
  mobilenumber,
  PATIENT,
  roleName as roleNameSelector,
  username,
} from "store/constant";
import { setActiveMember, setParentFamilyId, getRenderSidebar } from "store/Slices/userSlice";

// import Customization from '../Customization';
import navigation from "menu-items";
import { SET_MENU } from "store/Slices/customizationSlice";

// assets
import "../../assets/scss/style.scss";
import SecondarySidebar from "./Sidebar/SecondarySidebar";
import { SidebarUtilProvider } from "layout/SidebarUtilContext";
import { getPrimaryUserByUsername } from "services/userService";
import SyncModal from "views/Components/Sync/SyncModal";
import RIf from "ui-component/RIf";
import NewPrimarySidebar from "./Sidebar/NewPrimarySidebar";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { getIsOnline } from "store/Slices/cloudSyncSlice";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const [roleName, setRoleName] = useState(roleNameSelector());
  const [sidebarList, setSidebarList] = useState(null);
  const [, setSecondarySidebarVisible] = useState(false);
  const [settingsIconChecked, setSettingsIconChecked] = useState(
    useSelector(getRenderSidebar)?.isRender || false
  );
  const [profileChecked, setProfileChecked] = useState(false);
  const [routeBeforeSettingsSection, setRouteBeforeSettingsSection] = useState("");
  const [tabValue, setTabValue] = useState("0");
  // const [showContent, setshowContent] = useState(true);
  // const [isClinicAdmin, setisClinicAdmin] = useState(false);
  // const [orgArr, setorgArr] = useState([]);
  const mNumber = mobilenumber();
  const uId = username();
  const location = useLocation();
  const [appointmentInfo, setAppointmentInfo] = useState(null);
  const isOnline = useSelector(getIsOnline);
  const isRunningOnElectron = appEnv === "electron";
  const [sethuSirLayout, setSethuSirLayout] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isOTPLogin()) {
          return;
        }
        const response = await getPrimaryUserByUsername(uId);
        setRoleName(response.data?.roleName);
        localStorage.setItem("loggedInUser", JSON.stringify(response.data));
        dispatch(setParentFamilyId(response?.data?.id));
        dispatch(setActiveMember(response?.data));
      } catch (error) {
        console.error("Error fetching User info");
      }
    };
    fetchData();
  }, [mNumber, uId]);

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
  }, [matchDownMd]);

  const [mainClass, setMainClass] = useState(isDemo() ? "main shrink" : "main");

  const toggleSecondarySidebar = (visible) => {
    setSecondarySidebarVisible(visible);
  };
  const [secondarySidebarData, setSecondarySidebarData] = useState(null);
  const [secondarySidebarTitle, setSecondarySidebarTitle] = useState(null);

  const IconSeparator = () => <i className="ri-arrow-right-s-line"></i>;

  const loadRazorPayScript = async () => {
    if (document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
      return true;
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        reject(new Error("Failed to load Razorpay script"));
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const fetchScript = async () => {
      try {
        await loadRazorPayScript();
      } catch (error) {
        console.error("Error loading Razorpay script:", error);
      }
    };

    fetchScript();
  }, []);

  return (
    <Box className={mainClass} id="main">
      <>
        {roleName === DOCTOR ? (
          <>
            {/* {settingsIconChecked &&  */}
            <NewPrimarySidebar
              mainClass={mainClass}
              toggleMainClass={setMainClass}
              toggleSecondaryBar={toggleSecondarySidebar}
              setSecondarySidebarData={setSecondarySidebarData}
              setSecondarySideBarTitle={setSecondarySidebarTitle}
              setSettingsIconChecked={setSettingsIconChecked}
              settingsIconChecked={settingsIconChecked}
              sidebarList={
                sidebarList?.title === "Settings"
                  ? {
                      ...sidebarList,
                      children: sidebarList?.children?.map((child) => {
                        if (isRunningOnElectron && !isOnline && child.online) {
                          return { ...child, isOffline: true };
                        }
                        return { ...child, isOffline: false };
                      }),
                    }
                  : sidebarList
              }
              setSidebarList={setSidebarList}
              setRouteBeforeSettingsSection={setRouteBeforeSettingsSection}
            />
            {/* } */}
          </>
        ) : (
          <>
            <Sidebar
              mainClass={mainClass}
              toggleMainClass={setMainClass}
              toggleSecondaryBar={toggleSecondarySidebar}
              setSecondarySidebarData={setSecondarySidebarData}
              setSecondarySideBarTitle={setSecondarySidebarTitle}
              setSettingsIconChecked={setSettingsIconChecked}
              setRouteBeforeSettingsSection={setRouteBeforeSettingsSection}
            />
            {secondarySidebarData && (
              <SecondarySidebar
                toggleMainClass={setMainClass}
                data={secondarySidebarData}
                title={secondarySidebarTitle}
              />
            )}
          </>
        )}
        <Grid
          className="wrapper not-doctor"
          sx={
            roleName === DOCTOR
              ? {
                  position: "fixed",
                  right: 0,
                  top: 0,
                  height: "100%",
                  // transform: settingsIconChecked ? "translateX(270px)" : "translateX(0)", // Slide in/out
                  transition: "width 0.5s ease-in-out", // Add smooth transition
                  // width: "100vw !important",
                  width: sidebarList ? "calc(100vw - 270px)" : "100vw !important",
                }
              : undefined
          }
        >
          {roleName && (
            <div
              className={`main-content custom-scrollbar-main ${
                roleName !== DOCTOR ? "not-doctor" : ""
              }`}
            >
              {roleName && roleName !== PATIENT && location.pathname !== "/home/doctorSearch" && (
                <Header
                  sethuSirLayout={sethuSirLayout}
                  setSethuSirLayout={setSethuSirLayout}
                  toggleMainClass={setMainClass}
                  setSecondarySidebarData={setSecondarySidebarData}
                  handleLeftDrawerToggle={handleLeftDrawerToggle}
                  settingsIconChecked={settingsIconChecked}
                  setSettingsIconChecked={setSettingsIconChecked}
                  routeBeforeSettingsSection={routeBeforeSettingsSection}
                  setRouteBeforeSettingsSection={setRouteBeforeSettingsSection}
                  setSidebarList={setSidebarList}
                  profileChecked={profileChecked}
                  setProfileChecked={setProfileChecked}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  appointmentInfo={appointmentInfo}
                />
              )}

              <div
                className="mui-row align-items-center custom-tab-structure"
                style={{
                  paddingTop: roleName === PATIENT ? "30px" : undefined,
                }}
              >
                <Breadcrumbs
                  separator={IconSeparator}
                  navigation={navigation}
                  icon
                  title
                  rightAlign
                />
                <SidebarUtilProvider
                  mainClass={mainClass}
                  toggleMainClass={setMainClass}
                  setSecondarySideBarData={setSecondarySidebarData}
                  tabValue={tabValue}
                  setAppointmentInfo={setAppointmentInfo}
                  appointmentInfo={appointmentInfo}
                  setSidebarList={setSidebarList}
                  sethuSirLayout={sethuSirLayout}
                >
                  <Outlet />
                </SidebarUtilProvider>
              </div>
            </div>
          )}
        </Grid>
        <RIf show={isRunningOnElectron}>
          <SyncModal />
        </RIf>
      </>
      {/* )} */}
    </Box>
  );
};

export default MainLayout;
