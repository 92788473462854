import { Typography, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { getLanguages, getSpecializations } from "services/EntitiesServices";
import { updateDoctors } from "services/doctorService";
import { getAllOrganizations } from "services/organizationService";
import { uploadProfilePicture } from "services/patientService";
import {
  ACTIVE_STATE,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  MAX_AGE_FOR_REGUAR_DOB,
  MIN_AGE_FOR_DOCTOR,
  MIN_AGE_FOR_QUALIFICATION,
  genderList,
  getPrescriptionSearchType,
  prescriptionSearchTypesMenu,
  timeFormat,
  timeFormats,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { doctorGeneralInfoSchema } from "views/Components/Common/ValidationSchema/doctorGeneralInfoSchema";
import Reveal from "views/utilities/Reveal";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { splitName } from "utils/split-full-name";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { updateUserPreferences } from "services/userService";

const DoctorGeneralInfo = ({
  refreshData,
  profileImageFile,
  viewOnlyPage,
  doctorDetails,
  setDoctorDetails,
  readOnly,
  pendingTask,
  setReadOnly,
  cancelEdit,
  saveEditRef,
  ...others
}) => {
  const [lanArray, setLanArray] = useState([]);
  const [lanValue, setLanValue] = useState([]);
  const [clinicValue, setClinicValue] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
  const MAX_DATE_FOR_DOCTOR = dayjs().subtract(MIN_AGE_FOR_DOCTOR, "year");
  const [timeFormatForUser, setTimeFormatForUser] = useState(timeFormat().toString());
  const [prescriptionSearchType, setPrescriptionSearchType] = useState(getPrescriptionSearchType());

  const changeTimeFormat = (e) => {
    setTimeFormatForUser(e.target.value);
  };
  const changePrescriptionSearchType = (e) => {
    setPrescriptionSearchType(e.target.value);
  };

  const earliestQualification = doctorDetails?.qualifications?.length
    ? doctorDetails.qualifications.reduce((min, current) => {
        return current.yearOfPassing < min ? current.yearOfPassing : min;
      }, doctorDetails?.qualifications[0].yearOfPassing)
    : null;

  const MAX_DATE_FOR_DOB = earliestQualification
    ? dayjs().year(earliestQualification).subtract(MIN_AGE_FOR_QUALIFICATION, "year")
    : null;

  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { toggleMainClass } = useContext(SidebarUtilContext);

  const currentDate = dayjs();
  const [dobError, setDobError] = useState(
    doctorDetails?.userResponse.dateOfBirth ? "" : "Please select your date of birth."
  );
  const [specializations, setSpecializations] = useState([]);

  const fetchSpecializationsData = async () => {
    try {
      const response = await getSpecializations();
      setSpecializations(response?.data.map((el) => ({ value: el.id, menuLabel: el.name })));
    } catch (error) {
      console.error("Error fetching specialization");
    }
  };

  useEffect(() => {
    fetchSpecializationsData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lanResponse = await getLanguages();
        const sortedLanguages = lanResponse?.data.sort((a, b) => a.name.localeCompare(b.name));
        setLanArray(sortedLanguages);
        const clinicResponse = await getAllOrganizations();
        setLanValue(doctorDetails?.languagesSpoken.map((el) => el?.id));
        if (
          doctorDetails?.userResponse.userOrganizationAssociationList.length > 0 &&
          clinicResponse?.data
        ) {
          const associatedOrgIds = [];
          doctorDetails?.userResponse.userOrganizationAssociationList.forEach((obj) => {
            if (obj.status === ACTIVE_STATE) {
              associatedOrgIds.push(obj.organizationId);
            }
          });
          const filteredOrganization = clinicResponse?.data.filter((organization) =>
            associatedOrgIds.includes(organization.id)
          );
          setClinicValue(filteredOrganization?.map((el) => `${el?.name} - ${el?.code}`));
        }
      } catch (error) {
        console.error("Fetch languages failed");
      }
    };
    if (doctorDetails) {
      fetchData();
    }
  }, [doctorDetails, handleClick]);

  const handleFormEditCancel = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setDobError("");
    setReadOnly(true);
  };

  useEffect(() => {
    if (cancelEdit) {
      handleFormEditCancel();
    }
  }, [cancelEdit]);

  useEffect(() => {
    if (saveEditRef.current) {
      try {
        if (formikRef.current) {
          formikRef.current.validateForm().then((validationErrors) => {
            if (Object.keys(validationErrors).length > 0) {
              const allErrors = Object.values(validationErrors).join(", ");
              handleClick("error", allErrors);
            } else {
              formikRef.current.handleSubmit();
            }
            saveEditRef.current = false;
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [saveEditRef.current]);

  return (
    <Reveal>
      <Grid container>
        {doctorDetails && (
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              fullName: doctorDetails.userResponse.name ? doctorDetails.userResponse.name : "",
              mobileNumber: doctorDetails.userResponse.mobileNumber
                ? doctorDetails.userResponse.mobileNumber
                : "",
              email: doctorDetails.userResponse.emailId ? doctorDetails.userResponse.emailId : "",
              gender: doctorDetails.userResponse.gender ? doctorDetails.userResponse.gender : "",
              dateOfBirth: doctorDetails.userResponse.dateOfBirth
                ? dayjs(doctorDetails.userResponse.dateOfBirth)
                : null,
              specializationId: doctorDetails.specialization ? doctorDetails.specialization.id : "",
              highestEducation: doctorDetails.highestQualificationName
                ? doctorDetails.highestQualificationName
                : "",
              experience: doctorDetails.yearsOfExperience ? doctorDetails.yearsOfExperience : "",
              lanValue: lanValue ? lanValue : "",
              avgAppointmentDuration: doctorDetails.avgAppointmentDuration
                ? doctorDetails.avgAppointmentDuration
                : 0,
              clinics: clinicValue ? clinicValue : "",
              biography: doctorDetails?.biography ? doctorDetails?.biography : "",
              facebookLink: doctorDetails.facebookLink ? doctorDetails.facebookLink : "",
              twitterLink: doctorDetails.twitterLink ? doctorDetails.twitterLink : "",
              linkedinLink: doctorDetails.linkedinLink ? doctorDetails.linkedinLink : "",
              instagramLink: doctorDetails.instagramLink ? doctorDetails.instagramLink : "",
              submit: "",
            }}
            validationSchema={doctorGeneralInfoSchema}
            onSubmit={async (values, { errors }) => {
              if (!dobError) {
                try {
                  const { firstName, middleName, lastName } = splitName(values.fullName);
                  const updatedData = {
                    ...doctorDetails,
                    yearsOfExperience: values.experience,
                    languagesSpoken: values.lanValue.map((el) => {
                      return { id: el };
                    }),
                    specialization: { id: values.specializationId },
                    userResponse: {
                      ...doctorDetails?.userResponse,
                      firstName,
                      middleName,
                      lastName,
                      gender: values.gender,
                      mobileNumber: values.mobileNumber,
                      emailId: values.email,
                      dateOfBirth: values.dateOfBirth.format(DATE_FORMAT),
                    },
                    avgAppointmentDuration: values.avgAppointmentDuration,
                    facebookLink: values.facebookLink,
                    twitterLink: values.twitterLink,
                    linkedinLink: values.linkedinLink,
                    instagramLink: values.instagramLink,
                    biography: values.biography,
                  };
                  if (profileImageFile) {
                    let profilePicId;
                    try {
                      const formData = new FormData();
                      formData.append("file", profileImageFile);
                      const response = await uploadProfilePicture(
                        doctorDetails?.userResponse.id,
                        formData
                      );
                      profilePicId = response.data.profilePictureDocumentId;
                    } catch (error) {
                      handleClick("error", "There seems to be an error uploading profile picture");
                    }
                    await updateDoctors(doctorDetails?.id, {
                      ...updatedData,
                      user: {
                        ...updatedData.user,
                        profilePictureDocumentId: profilePicId,
                      },
                    });
                    const dataForPreference = {
                      ...doctorDetails?.userResponse.preferences,
                      prefers24HrFormat: timeFormatForUser === "24" ? true : false,
                      prescriptionSearchType: prescriptionSearchType,
                    };
                    await updateUserPreferences(doctorDetails?.userResponse.id, dataForPreference);
                    refreshData();
                    if (pendingTask) {
                      navigate("/home/dashboard", { state: { completedDetails: true } });
                      toggleMainClass("main");
                    }
                  } else {
                    await updateDoctors(doctorDetails?.id, updatedData);
                    const dataForPreference = {
                      ...doctorDetails?.userResponse.preferences,
                      prefers24HrFormat: timeFormatForUser === "24" ? true : false,
                      prescriptionSearchType: prescriptionSearchType,
                    };
                    await updateUserPreferences(doctorDetails?.userResponse.id, dataForPreference);
                    refreshData();
                    if (pendingTask) {
                      navigate("/home/dashboard", { state: { completedDetails: true } });
                      toggleMainClass("main");
                    }
                  }
                  localStorage.setItem("timeFormat", timeFormatForUser);
                  localStorage.setItem("prescriptionSearchType", prescriptionSearchType);
                  handleClick("success", "Doctor Information has been successfully updated.");
                  setReadOnly(true);
                } catch (error) {
                  handleClick(
                    "error",
                    "There seems to be an error updating the doctor information"
                  );
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              resetForm,
              handleSubmit,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                <Grid container>
                  {(values.fullName || !viewOnlyPage) && (
                    <Grid container size={{ md: 12 }} sx={{ mb: 0.5 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                          maxHeight: "40px",
                        }}
                      >
                        Full Name
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="fullName"
                        value={values.fullName || !readOnly ? values.fullName : "N/A"}
                        disableBorder={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        error={Boolean(errors.fullName && touched.fullName)}
                        errorText={errors.fullName}
                        size={"small"}
                      />
                    </Grid>
                  )}
                  {(values.gender || !viewOnlyPage) && (
                    <Grid container size={{ md: 12 }} sx={{ mb: 0.5 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Gender
                      </Typography>
                      <FormSelectField
                        noArrow={readOnly}
                        name={"gender"}
                        style={{ width: "50%" }}
                        value={values.gender || !readOnly ? values.gender : "N/A"}
                        size={"small"}
                        disableBorder={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        error={Boolean(errors.gender && touched.gender)}
                        errorText={errors.gender}
                        menuItems={genderList}
                      ></FormSelectField>
                    </Grid>
                  )}
                  {(values.dateOfBirth || !viewOnlyPage) && (
                    <Grid size={{ md: 12 }} sx={{ mb: 0.5 }} container>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                        // onClick={(e) => {
                        //   setisReadonlyFieldIndex(0);
                        //   handleSubmit();
                        // }}
                      >
                        Date Of Birth
                      </Typography>
                      <div style={{ width: "50%" }}>
                        <FormDatePicker
                          disableFuture
                          format={DATE_FORMAT_DMY}
                          minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                          maxDate={MAX_DATE_FOR_DOB ? MAX_DATE_FOR_DOB : MAX_DATE_FOR_DOCTOR}
                          value={values.dateOfBirth || !readOnly ? values.dateOfBirth : "N/A"}
                          size={"small"}
                          onChange={(date) => {
                            setFieldValue("dateOfBirth", date);
                            if (!date) {
                              setDobError("Please select your date of birth.");
                            } else if (!date.isValid()) {
                              setDobError("Please select a valid 'Date of birth' value.");
                            } else if (date > currentDate) {
                              setDobError("Date of birth cannot be a future date.");
                            } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                              setDobError(
                                `Date of birth can't be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}.`
                              );
                            } else if (
                              MAX_DATE_FOR_DOB
                                ? date > MAX_DATE_FOR_DOB
                                : date > MAX_DATE_FOR_DOCTOR
                            ) {
                              setDobError(
                                `Age must be at least ${
                                  MAX_DATE_FOR_DOB
                                    ? MAX_DATE_FOR_DOB.year()
                                    : MAX_DATE_FOR_DOCTOR.year()
                                } years.`
                              );
                            } else {
                              setDobError("");
                            }
                          }}
                          style={{
                            width: "100%",
                          }}
                          readOnly={readOnly}
                          background={readOnly ? "transparent" : "#e3e3e3"}
                          iconAtEnd={true}
                          disableBorder={true}
                          error={Boolean(dobError && touched.dateOfBirth)}
                          errorText={dobError}
                        ></FormDatePicker>
                      </div>
                    </Grid>
                  )}
                  {(values.mobileNumber || !viewOnlyPage) && (
                    <Grid sx={{ mb: 0.5 }} container size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Contact Number
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="mobileNumber"
                        value={values.mobileNumber || !readOnly ? values.mobileNumber : "N/A"}
                        type={"tel"}
                        inputProps={{
                          maxLength: 10,
                        }}
                        readOnly={true}
                        background={"transparent"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disableBorder={true}
                        size={"small"}
                        error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                        errorText={errors.mobileNumber}
                      />
                    </Grid>
                  )}
                  {(values.email || !viewOnlyPage) && (
                    <Grid size={{ md: 12 }} sx={{ mb: 0.5 }} container>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Email Address
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="email"
                        value={values.email || !readOnly ? values.email : "N/A"}
                        type="email"
                        readOnly={true}
                        background={"transparent"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disableBorder={true}
                        error={Boolean(errors.email && touched.email)}
                        errorText={errors.email}
                        size={"small"}
                      />
                    </Grid>
                  )}
                  {(values.specializationId || !viewOnlyPage) && (
                    <Grid container size={{ md: 12 }} sx={{ mb: 0.5 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Specialization
                      </Typography>
                      <FormSelectField
                        noArrow={readOnly}
                        name={"specializationId"}
                        style={{ width: "50%" }}
                        value={
                          values.specializationId || !readOnly ? values.specializationId : "N/A"
                        }
                        size={"small"}
                        disableBorder={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        error={Boolean(errors.specializationId && touched.specializationId)}
                        errorText={errors.specializationId}
                        menuItems={specializations}
                      ></FormSelectField>
                    </Grid>
                  )}
                  {(values.highestEducation || !viewOnlyPage) && (
                    <Grid sx={{ mb: 0.5 }} size={{ md: 12 }} container>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Highest Education
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="highestEducation"
                        value={
                          values.highestEducation || !readOnly ? values.highestEducation : "N/A"
                        }
                        // onBlur={handleBlur}
                        readOnly={true}
                        background={"transparent"}
                        onChange={handleChange}
                        disableBorder={true}
                        size={"small"}
                      />
                    </Grid>
                  )}
                  {(values.experience || !viewOnlyPage) && (
                    <Grid sx={{ mb: 0.5 }} size={{ md: 12 }} container>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Experience in Years
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="experience"
                        type={"tel"}
                        value={values.experience || !readOnly ? values.experience : "N/A"}
                        error={Boolean(errors.experience && touched.experience)}
                        errorText={errors.experience}
                        inputProps={{
                          maxLength: 3,
                        }}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disableBorder={true}
                        size={"small"}
                      />
                    </Grid>
                  )}
                  {(values.lanValue || !viewOnlyPage) && (
                    <Grid sx={{ mb: 0.5 }} size={{ md: 12 }} container>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Languages
                      </Typography>
                      <FormSelectField
                        noArrow={readOnly}
                        multiple
                        name={"languages"}
                        style={{ width: "50%" }}
                        value={values.lanValue || !readOnly ? values.lanValue : "N/A"}
                        size={"small"}
                        disableBorder={true}
                        error={Boolean(errors.lanValue && touched.lanValue)}
                        onBlur={handleBlur}
                        errorText={errors.lanValue}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          setFieldValue("lanValue", value);
                        }}
                        menuItems={lanArray.map((el) => {
                          return {
                            value: el.id,
                            menuLabel: el.name,
                          };
                        })}
                      />
                    </Grid>
                  )}
                  {(values.avgAppointmentDuration || !viewOnlyPage) && (
                    <Grid sx={{ mb: 0.5 }} size={{ md: 12 }} container>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Avg. Appointment Time (min.)
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="avgAppointmentDuration"
                        value={
                          values.avgAppointmentDuration || !readOnly
                            ? values.avgAppointmentDuration
                            : "N/A"
                        }
                        type={"tel"}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disableBorder={true}
                        size={"small"}
                        error={Boolean(
                          errors.avgAppointmentDuration && touched.avgAppointmentDuration
                        )}
                        errorText={errors.avgAppointmentDuration}
                      />
                    </Grid>
                  )}
                  {(timeFormatForUser || !viewOnlyPage) && (
                    <Grid container size={{ md: 12 }} sx={{ mb: 0.5 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Preferred Time Format
                      </Typography>
                      <FormSelectField
                        noArrow={readOnly}
                        name={"Time Format"}
                        style={{ width: "50%" }}
                        value={timeFormatForUser}
                        size={"small"}
                        disableBorder={true}
                        onChange={changeTimeFormat}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        menuItems={timeFormats}
                      ></FormSelectField>
                    </Grid>
                  )}

                  {(prescriptionSearchType || !viewOnlyPage) && (
                    <Grid container size={{ md: 12 }} sx={{ mb: 0.5 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Prescription Search Type
                      </Typography>
                      <FormSelectField
                        noArrow={readOnly}
                        name={"Prescription Search Type"}
                        style={{ width: "50%" }}
                        value={prescriptionSearchType}
                        size={"small"}
                        disableBorder={true}
                        onChange={changePrescriptionSearchType}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        menuItems={prescriptionSearchTypesMenu}
                      ></FormSelectField>
                    </Grid>
                  )}
                  {(values.clinics || !viewOnlyPage) && (
                    <Grid sx={{ mb: 0.5 }} size={{ md: 12 }} display={"flex"}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                        }}
                      >
                        Associated Clinic
                      </Typography>
                      <Typography
                        sx={{
                          p: "10px 14px",
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#000000",
                        }}
                      >
                        {values.clinics.join(", ")}
                      </Typography>
                      {/* if doctor can not edit his organization */}
                      {/* <FormInputField
                      disabled={!readOnly}
                      style={{ width: "50%" }}
                      name="clinics"
                      value={values.clinics}
                      type="text"
                      onChange={handleChange}
                      disableBorder={true}
                      size={"small"}
                    /> */}
                      {/* if doctor can edit his organization */}
                      {/* <FormSelectField
                      noArrow={true}
                      multiple
                      name={"clinics"}
                      style={{ width: "50%" }}
                      onBlur={handleBlur}
                      value={(values.clinics || !readOnly) ? values.clinics : "N/A"}
                      size={"small"}
                      disableBorder={true}
                      readOnly={readOnly}
                      background={readOnly ? "transparent" : "#e3e3e3"}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setClinicValue(value);
                      }}
                      menuItems={values.clinics.map((el) => {
                        return {
                          value: el,
                          menuLabel: el,
                        };
                      })}
                    /> */}
                    </Grid>
                  )}

                  {(values.biography || !viewOnlyPage) && (
                    <Grid container size={{ md: 12 }} sx={{ mb: 1.2 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "230px",
                          paddingTop: "8px",
                          // maxHeight: "40px",
                        }}
                      >
                        About Youself
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="biography"
                        value={values?.biography || !readOnly ? values?.biography : "N/A"}
                        disableBorder={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        error={Boolean(errors.biography && touched.biography)}
                        errorText={errors.biography}
                        size={"small"}
                      />
                    </Grid>
                  )}
                  {viewOnlyPage &&
                    (values.facebookLink ||
                      values.instagramLink ||
                      values.twitterLink ||
                      values.linkedinLink) && (
                      <Grid size={{ md: 12 }} container sx={{ mb: 0.5 }}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: 500,
                            color: "#004C70",
                            minWidth: "230px",
                            paddingTop: "8px",
                          }}
                        >
                          Social Media
                        </Typography>
                        <Grid container sx={{ width: "50%" }}>
                          <Grid style={{ marginRight: 8 }}>
                            {values.facebookLink && (
                              <IconButton
                                component="a"
                                href={values.facebookLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "#3b5998" }}
                              >
                                <FacebookIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid style={{ marginRight: 8 }}>
                            {values.instagramLink && (
                              <IconButton
                                component="a"
                                href={values.instagramLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "#E1306C" }}
                              >
                                <InstagramIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid style={{ marginRight: 8 }}>
                            {values.twitterLink && (
                              <IconButton
                                component="a"
                                href={values.twitterLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "#1DA1F2" }}
                              >
                                <TwitterIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid style={{ marginRight: 8 }}>
                            {values.linkedinLink && (
                              <IconButton
                                component="a"
                                href={values.linkedinLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "#0077b5" }}
                              >
                                <LinkedInIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                  {!viewOnlyPage && (
                    <Grid container spacing={1}>
                      <Grid size={{ md: 12 }} sx={{ mb: 0.5 }} container alignItems="center">
                        <div style={{ width: "222px", display: "flex", alignItems: "center" }}>
                          <IconButton
                            style={{ paddingTop: 13, paddingLeft: "0px", cursor: "default" }}
                            sx={{
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiTouchRipple-root": {
                                display: "none",
                              },
                            }}
                          >
                            <FacebookIcon sx={{ color: "#3b5998" }} />
                          </IconButton>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#004C70",
                              minWidth: "198px",
                              paddingTop: "8px",
                            }}
                          >
                            Facebook
                          </Typography>
                        </div>
                        {readOnly ? (
                          values.facebookLink ? (
                            <a
                              href={values.facebookLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ width: "50%" }}
                            >
                              <Typography
                                sx={{
                                  width: "100%",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  p: "10px 14px",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  color: "#000000",
                                }}
                              >
                                {values.facebookLink}
                              </Typography>
                            </a>
                          ) : (
                            <Typography
                              sx={{
                                width: "50%",
                                p: "10px 14px",
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#000000",
                              }}
                            >
                              N/A
                            </Typography>
                          )
                        ) : (
                          <FormInputField
                            style={{ width: "50%" }}
                            name="facebookLink"
                            type="text"
                            value={values.facebookLink}
                            readOnly={readOnly}
                            background={readOnly ? "transparent" : "#e3e3e3"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.facebookLink && touched.facebookLink)}
                            errorText={errors.facebookLink}
                            disableBorder={true}
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid size={{ md: 12 }} sx={{ mb: 0.5 }} container alignItems="center">
                        <div style={{ width: "222px", display: "flex", alignItems: "center" }}>
                          <IconButton
                            style={{ paddingTop: 13, paddingLeft: "0px", cursor: "default" }}
                            sx={{
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiTouchRipple-root": {
                                display: "none",
                              },
                            }}
                          >
                            <TwitterIcon sx={{ color: "#1DA1F2" }} />
                          </IconButton>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#004C70",
                              minWidth: "198px",
                              paddingTop: "8px",
                            }}
                          >
                            X(Twitter)
                          </Typography>
                        </div>
                        {readOnly ? (
                          values.twitterLink ? (
                            <a
                              href={values.twitterLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ width: "50%" }}
                            >
                              <Typography
                                sx={{
                                  width: "100%",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  p: "10px 14px",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  color: "#000000",
                                }}
                              >
                                {values.twitterLink}
                              </Typography>
                            </a>
                          ) : (
                            <Typography
                              sx={{
                                width: "50%",
                                p: "10px 14px",
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#000000",
                              }}
                            >
                              N/A
                            </Typography>
                          )
                        ) : (
                          <FormInputField
                            style={{ width: "50%" }}
                            name="twitterLink"
                            type="text"
                            value={values.twitterLink}
                            readOnly={readOnly}
                            background={readOnly ? "transparent" : "#e3e3e3"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.twitterLink && touched.twitterLink)}
                            errorText={errors.twitterLink}
                            disableBorder={true}
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid size={{ md: 12 }} sx={{ mb: 0.5 }} container alignItems="center">
                        <div style={{ width: "222px", display: "flex", alignItems: "center" }}>
                          <IconButton
                            style={{ paddingTop: 13, paddingLeft: "0px", cursor: "default" }}
                            sx={{
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiTouchRipple-root": {
                                display: "none",
                              },
                            }}
                          >
                            <LinkedInIcon sx={{ color: "#0077b5" }} />
                          </IconButton>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#004C70",
                              minWidth: "198px",
                              paddingTop: "8px",
                            }}
                          >
                            LinkedIn
                          </Typography>
                        </div>
                        {readOnly ? (
                          values.linkedinLink ? (
                            <a
                              href={values.linkedinLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ width: "50%" }}
                            >
                              <Typography
                                sx={{
                                  width: "100%",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  p: "10px 14px",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  color: "#000000",
                                }}
                              >
                                {values.linkedinLink}
                              </Typography>
                            </a>
                          ) : (
                            <Typography
                              sx={{
                                width: "50%",
                                p: "10px 14px",
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#000000",
                              }}
                            >
                              N/A
                            </Typography>
                          )
                        ) : (
                          <FormInputField
                            style={{ width: "50%" }}
                            name="linkedinLink"
                            type="text"
                            value={values.linkedinLink}
                            readOnly={readOnly}
                            background={readOnly ? "transparent" : "#e3e3e3"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.linkedinLink && touched.linkedinLink)}
                            errorText={errors.linkedinLink}
                            disableBorder={true}
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid size={{ md: 12 }} sx={{ mb: 0.5 }} container alignItems="center">
                        <div style={{ width: "222px", display: "flex", alignItems: "center" }}>
                          <IconButton
                            style={{ paddingTop: 13, paddingLeft: "0px", cursor: "default" }}
                            sx={{
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiTouchRipple-root": {
                                display: "none",
                              },
                            }}
                          >
                            <InstagramIcon sx={{ color: "#E1306C" }} />
                          </IconButton>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#004C70",
                              minWidth: "198px",
                              paddingTop: "8px",
                            }}
                          >
                            Instagram
                          </Typography>
                        </div>
                        {readOnly ? (
                          values.instagramLink ? (
                            <a
                              href={values.instagramLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ width: "50%" }}
                            >
                              <Typography
                                sx={{
                                  width: "100%",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  p: "10px 14px",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  color: "#000000",
                                }}
                              >
                                {values.instagramLink}
                              </Typography>
                            </a>
                          ) : (
                            <Typography
                              sx={{
                                width: "50%",
                                p: "10px 14px",
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#000000",
                              }}
                            >
                              N/A
                            </Typography>
                          )
                        ) : (
                          <FormInputField
                            style={{ width: "50%" }}
                            name="instagramLink"
                            type="text"
                            value={values.instagramLink}
                            readOnly={readOnly}
                            background={readOnly ? "transparent" : "#e3e3e3"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.instagramLink && touched.instagramLink)}
                            errorText={errors.instagramLink}
                            disableBorder={true}
                            size="small"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {!readOnly && (
                  <Grid sx={{ mt: 7 }} display={"flex"} justifyContent={"center"}>
                    <CustomButton
                      label={"Cancel"}
                      className={"btn--error"}
                      style={{ width: "100px" }}
                      onClick={handleFormEditCancel}
                    />

                    <CustomButton
                      type="submit"
                      label={"Save"}
                      className={"btn--secondary"}
                      style={{ marginLeft: "10px", width: "100px" }}
                    />
                  </Grid>
                )}
              </form>
            )}
          </Formik>
        )}
      </Grid>
    </Reveal>
  );
};

export default DoctorGeneralInfo;
