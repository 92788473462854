import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useContext } from "react";
import { login } from "services/AuthService";
import { CLINIC_ADMIN, DOCTOR, FRONT_DESK } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { Typography, Link } from "@mui/material";
import { useNavigate } from "react-router";
import { dummyToken, setIsDemo } from "store/Slices/demoSlice";
import { setFrontDeskUserLinking, setUserFeatures, setUserOrgId } from "store/Slices/userSlice";

const DemoAuthFooter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);

  const dummyLogin = async () => {
    dispatch(setIsDemo(true));
    const accessToken = dummyToken.access_token;
    const refreshToken = dummyToken.refresh_token;
    const decodedValue = jwtDecode(accessToken);
    localStorage.setItem("isOTPLogin", true);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("username", decodedValue?.sub);
    localStorage.setItem("loggedIn", true);

    const responseList = await login(decodedValue?.sub);
    const userResponse = responseList.data.userResponse;
    localStorage.setItem("currentActiveUser", JSON.stringify(userResponse));
    localStorage.setItem("loggedInUser", JSON.stringify(userResponse));
    let userFeatures = responseList.data.features;
    if (Object.keys(userFeatures).length === 0) {
      userFeatures = { USER: "" };
    }
    dispatch(setUserFeatures(userFeatures));

    const roleArr = [CLINIC_ADMIN, DOCTOR, FRONT_DESK];
    dispatch(setFrontDeskUserLinking(false));

    if (roleArr.includes(userResponse.roleName)) {
      dispatch(setUserOrgId(userResponse.userOrganizationAssociationList[0]?.organizationId));
    }
    dispatch({ type: "SET_ACCESS_TOKEN", payload: accessToken });
    handleClick("success", "Welcome to Arog!");
    navigate("/demo");
  };

  return (
    <Typography
      style={{ color: "#004c70", cursor: "pointer" }}
      variant="h5"
      component={Link}
      underline="hover"
      onClick={dummyLogin}
    >
      Demo Mode
    </Typography>
  );
};

export default DemoAuthFooter;
