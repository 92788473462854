import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import * as React from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  createUserFamilyProfile,
  linkExistingUserAsFamilyMember,
  updateFamilyMemberRelation,
} from "services/userService";
import {
  familyMemberSchema,
  familyMemberSchemaForExistingUser,
} from "../Common/ValidationSchema/manageFamilyValidation";
import {
  ACTIVE_STATE,
  AGE_DIFFERENCE_MAP,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  FEMALE,
  MALE,
  MAX_AGE_FOR_REGUAR_DOB,
  OTHER,
  PATIENT,
  RELATIONSHIPS_WITH_OLDER_AGE,
  RELATIONSHIPS_WIT_YOUNGER_AGE,
  VERIFY_MOBILE_NUMBER,
  currentActiveUser,
  familyRelations,
  filteredRelationsForFemale,
  filteredRelationsForMale,
  genderList,
  getRoleIdByName,
  getUUID,
} from "store/constant";
import SubCard from "ui-component/cards/SubCard";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  GroupOutlined,
  HomeOutlined,
  PersonOutlineOutlined,
  FingerprintOutlined,
} from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { getUserById, updateUserProfile } from "services/userService";
import { useContext } from "react";
import {
  getOtpForFamilyMember,
  getPatientByMobileNumber,
  getPatientFamilyDetails,
  verifyOtpForFamilyMember,
} from "services/patientService";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { calculateAge } from "utils/calculate-age";

const ManageFamilyMembers = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const { state } = useLocation();
  const userId = state ? state.userId : null;
  const relationship = state ? state.relationship : null;
  const isUserPrimary = state ? state.isPrimary : false;
  const familyMappingId = state?.familyMappingId;
  let primaryUser = currentActiveUser();
  const [userData, setuserData] = useState({});
  const [patientRoleId, setPatientRoleId] = useState(null);
  const [existingUser, setExistingUser] = useState(false);
  const [isDateValid, setIsDateValid] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const currentDate = dayjs();
  const [dobError, setDobError] = useState(userId ? "" : "Date of Birth is required.");
  const [dob, setDob] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(userData?.mobileNumber || "");
  const [isOtpSent, setisOtpSent] = useState(false);
  const [isOtpVerified, setisOtpVerified] = useState(false);
  const [isPrimaryUser, setisPrimaryUser] = useState(false);
  const formRef = React.useRef(null);

  const patientAge = calculateAge(primaryUser.dateOfBirth);

  const [lowerBoundDateForFamilyDOB, setLowerBoundDateForFamilyDOB] = useState(
    dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year")
  );
  const [upperBoundDateForFamilyDOB, setUpperBoundDateForFamilyDOB] = useState(dayjs());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await getRoleIdByName(PATIENT, handleClick);
        setPatientRoleId(id);
      } catch (error) {
        console.error("error", "Error fetching role!");
      }
    };
    fetchData();
  }, [handleClick]);

  useEffect(() => {
    if (userId) {
      const getUserData = async () => {
        try {
          const res = await getUserById(userId);

          if (res && res.data) {
            setuserData(res.data);
            setisPrimaryUser(res?.data?.isPrimary);
          }
        } catch (e) {
          console.error("API issue!");
        }
      };
      getUserData();
    }
  }, [handleClick, userId]);

  const verifyDateOfBirth = (date) => {
    let eighteenYearsAgo = currentDate.subtract(18, "year");
    let sixtyYearsAgo = currentDate.subtract(60, "year");

    if (date.isBefore(eighteenYearsAgo) && date.isAfter(sixtyYearsAgo)) {
      eighteenYearsAgo = true;
      setIsDateValid(true);
    } else {
      eighteenYearsAgo = false;
      setIsDateValid(false);
    }
  };

  const handleSave = async (data, resetForm) => {
    if (!data.mobileNumber) {
      data.mobileNumber = primaryUser?.mobileNumber || "";
    }

    if (existingUser) {
      try {
        const apiBody = {
          relationship: data.relationship,
          userId: userData?.id,
          familyId: userData?.familyResponse?.id || getUUID(),
        };
        const response = await linkExistingUserAsFamilyMember(primaryUser?.id, apiBody);
        const familyResponse = await getPatientFamilyDetails(response.data.familyResponse.id);
        primaryUser = { ...primaryUser, familyResponse: familyResponse.data };
        localStorage.setItem("currentActiveUser", JSON.stringify(primaryUser));
        handleClick("success", "Family member has been successfully added.");
        resetForm();
        setTimeout(() => {
          navigate("/home/patientFamilyDetails");
        }, 1000);
      } catch (error) {
        handleClick("error", "There seems to be an error adding the user/profile.");
      }
    } else {
      if (userId) {
        const apiBody = { ...data, id: userId };

        try {
          if (isPrimaryUser) {
            await updateFamilyMemberRelation(familyMappingId, data);
          } else {
            await updateFamilyMemberRelation(familyMappingId, data);
            await updateUserProfile(userId, apiBody);
          }
          handleClick("success", "Family member has been successfully updated.");
          resetForm();
          setTimeout(() => {
            navigate("/home/patientFamilyDetails");
          }, 1000);
        } catch (e) {
          handleClick("error", "There seems to be an error updating the user/profile.");
          navigate("/home/patientFamilyDetails");
        }
      } else {
        try {
          const { relationship, ...userData } = data;
          const userId = getUUID();
          const updatedData = {
            userResponse: { id: userId, ...userData, roleBasedId: getUUID() },
            userId: userId,
            familyId: primaryUser?.familyResponse?.id || getUUID(),
            relationship: relationship,
          };
          const response = await createUserFamilyProfile(updatedData, primaryUser?.id);
          const familyResponse = await getPatientFamilyDetails(response.data.familyResponse.id);
          primaryUser = { ...primaryUser, familyResponse: familyResponse.data };
          localStorage.setItem("currentActiveUser", JSON.stringify(primaryUser));
          handleClick("success", "Family member has been successfully added.");
          resetForm();
          setTimeout(() => {
            navigate("/home/patientFamilyDetails");
          }, 1000);
        } catch (error) {
          handleClick("error", "There seems to be an error adding the user/profile");
        }
      }
    }
  };
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    let interval = null;

    if (isOtpSent) {
      setTimer(10);
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isOtpSent]);

  useEffect(() => {
    if (mobileNumber && mobileNumber !== "" && !isOtpSent) {
      if (!existingUser) {
        if (mobileNumber === primaryUser.mobileNumber) {
          setisOtpVerified(true);
          setButtonDisable(false);
        } else {
          setButtonDisable(true);
        }
      }
    }
  }, [buttonDisable, dob, existingUser, isOtpSent, mobileNumber, primaryUser.mobileNumber]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const sendOtp = async (number) => {
    const mobileNumberPattern = /^[6-9][0-9]{9}$/;

    if (!mobileNumberPattern.test(number)) {
      handleClick("error", "Please enter a valid mobile number");
      return;
    }
    try {
      await getOtpForFamilyMember(number, VERIFY_MOBILE_NUMBER);
      setisOtpSent(true);
      setButtonDisable(false);
      handleClick("success", "OTP for login has been sent to your registered mobile number.");
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  const ResendOtp = async () => {
    setisOtpVerified(false);
    const number = formRef.current.values.mobileNumber;
    if (formRef.current.values.otp) {
      formRef.current.values.otp = "";
    }
    try {
      await getOtpForFamilyMember(number, VERIFY_MOBILE_NUMBER);
      setisOtpSent(true);
      setTimer(10);
      handleClick("success", "OTP for login has been sent to your registered mobile number.");
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  const handleSubmitForVerifyMobileOtp = async (otp) => {
    if (!otp) return;
    try {
      const response = await verifyOtpForFamilyMember(mobileNumber, VERIFY_MOBILE_NUMBER, otp);
      if (response?.data?.isVerified) {
        setisOtpVerified(true);
        handleClick("success", "Otp has been successfully verified.");
      } else {
        handleClick("error", "Otp you provided seems to be incorrect.");
      }
    } catch (error) {
      handleClick("error", "There seems to be an error verifying the mobile otp.");
    }
  };

  useEffect(() => {
    const getExistingPatientByMobileNumber = async () => {
      try {
        if (mobileNumber.length === 10) {
          const patientData = await getPatientByMobileNumber(mobileNumber);
          if (patientData?.data?.length > 0) {
            const existingUser = patientData?.data?.find(
              (e) => e?.userResponse?.isPrimary === true
            );
            setuserData(existingUser?.userResponse);
            setExistingUser(true);
            setDobError("");
            setButtonDisable(false);
          }
        }
      } catch (error) {}
    };
    if (mobileNumber) {
      getExistingPatientByMobileNumber(mobileNumber);
    }
  }, [mobileNumber]);

  return (
    <Reveal>
      <Formik
        innerRef={formRef}
        enableReinitialize={!!userData}
        initialValues={{
          firstName: userData && userData.firstName ? userData.firstName : "",
          middleName: userData && userData.middleName ? userData.middleName : "",
          lastName: userData && userData.lastName ? userData.lastName : "",
          roleId: patientRoleId,
          status: ACTIVE_STATE,
          relationship: relationship || "",
          // mobileNumber:
          //   userData && userData.mobileNumber
          //     ? userData.mobileNumber
          //     : currentActiveUser()?.mobileNumber,
          // usesExistingPhoneNumber:
          //   userData && userData.mobileNumber
          //     ? userData.mobileNumber === currentActiveUser()?.mobileNumber
          //       ? "true"
          //       : "false"
          //     : "true",
          roleName: PATIENT,
          mobileNumber:
            userData && userData?.mobileNumber ? userData?.mobileNumber : primaryUser?.mobileNumber,
          emailId: userData && userData?.emailId ? userData?.emailId : primaryUser?.emailId,
          address: userData && userData.address ? userData.address : "",
          gender: userData && userData.gender ? userData.gender : "",
          dateOfBirth: userData && userData.dateOfBirth ? userData.dateOfBirth : null,
        }}
        validationSchema={() => {
          if (existingUser) {
            return familyMemberSchemaForExistingUser;
          } else {
            return familyMemberSchema(isDateValid);
          }
        }}
        onSubmit={async (values, { resetForm }) => {
          if (!dobError) {
            const dateFormat = dayjs(values.dateOfBirth).format(DATE_FORMAT);
            handleSave({ ...values, dateOfBirth: dateFormat }, resetForm);
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, handleBlur, handleChange, handleSubmit }) => (
          <Box>
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: 600,
                  color: "#004C70",
                  mb: 1,
                }}
              >
                {userId ? "Manage Family Member" : "Add Family Member"}
              </Typography>
              <SubCard style={{ padding: "15px" }}>
                <FormInputField
                  style={{
                    width: "29%",
                    marginRight: "30px",
                    marginBottom: "25px",
                    marginTop: "10px",
                  }}
                  disabled={existingUser || isPrimaryUser}
                  label="First Name"
                  name="firstName"
                  required
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.firstName && errors.firstName)}
                  errorText={errors.firstName}
                  startAdornment={<PersonOutlineOutlined />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "29%",
                    marginRight: "30px",
                    marginBottom: "25px",
                    marginTop: "10px",
                  }}
                  disabled={existingUser || isPrimaryUser}
                  label="Middle Name"
                  name="middleName"
                  value={values.middleName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.middleName && errors.middleName)}
                  errorText={errors.middleName}
                  startAdornment={<PersonOutlineOutlined />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "29%",
                    marginRight: "30px",
                    marginBottom: "25px",
                    marginTop: "10px",
                  }}
                  disabled={existingUser || isPrimaryUser}
                  label="Last Name"
                  name="lastName"
                  required
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.lastName && errors.lastName)}
                  errorText={errors.lastName}
                  startAdornment={<PersonOutlineOutlined />}
                  size={"big"}
                />

                <FormSelectField
                  style={{
                    width: "29%",
                    marginBottom: "25px",
                    marginRight: "30px",
                  }}
                  disabled={existingUser || isPrimaryUser}
                  label="Gender"
                  name="gender"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    if (values.relationship && e.target.value !== OTHER) {
                      const isRelationshipValid =
                        e.target.value ===
                        familyRelations.find((item) => item.value === values.relationship)?.gender;
                      if (!isRelationshipValid) {
                        setFieldValue("relationship", "");
                      }
                    }
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched.gender && errors.gender)}
                  errorText={errors.gender}
                  startAdornment={<PersonOutlineOutlined />}
                  menuItems={genderList}
                  value={values.gender}
                  size={"big"}
                ></FormSelectField>

                <RelationShipForm
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isUserPrimary={isUserPrimary}
                  touched={touched}
                  errors={errors}
                  patientAge={patientAge}
                  setLowerBoundDateForFamilyDOB={setLowerBoundDateForFamilyDOB}
                  setUpperBoundDateForFamilyDOB={setUpperBoundDateForFamilyDOB}
                  setDobError={setDobError}
                  setFieldValue={setFieldValue}
                />

                <FormDatePicker
                  format={DATE_FORMAT_DMY}
                  disabled={existingUser || isPrimaryUser}
                  disableFuture
                  required
                  minDate={lowerBoundDateForFamilyDOB}
                  maxDate={upperBoundDateForFamilyDOB}
                  label={"Birth date"}
                  value={values.dateOfBirth}
                  error={Boolean(dobError && touched.dateOfBirth)}
                  errorText={dobError}
                  size={"big"}
                  onChange={(date) => {
                    setFieldValue("dateOfBirth", date);
                    setDob(date);
                    if (date !== null && date.isValid()) {
                      verifyDateOfBirth(date);
                    }
                    if (date === null) {
                      setDobError("Date of birth is required.");
                    } else if (!date.isValid()) {
                      setDobError("Please select valid 'Date of birth' value.");
                    } else if (date > currentDate) {
                      setDobError("Date of birth cannot be a future date.");
                    } else if (date < lowerBoundDateForFamilyDOB) {
                      setDobError(
                        `Should be greater than ${lowerBoundDateForFamilyDOB
                          .subtract(1, "day")
                          .format(DATE_FORMAT_DMY)}.`
                      );
                    } else if (date > upperBoundDateForFamilyDOB) {
                      setDobError(
                        `Should be less than ${upperBoundDateForFamilyDOB
                          .add(1, "day")
                          .format(DATE_FORMAT_DMY)}.`
                      );
                    } else {
                      setDobError("");
                    }
                  }}
                  style={{
                    width: "29%",
                    marginBottom: "25px",
                    marginRight: "30px",
                  }}
                ></FormDatePicker>

                <FormInputField
                  disabled={userId}
                  style={{
                    width: "29%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Mobile Number"
                  type={"tel"}
                  inputProps={{
                    maxLength: 10,
                  }}
                  name="mobileNumber"
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setMobileNumber(e.target.value);
                  }}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  errorText={errors.mobileNumber}
                  startAdornment={<i className="ri-phone-line ri-xl" />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "29%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  disabled={existingUser || userId}
                  label="Email"
                  name="emailId"
                  type={"email"}
                  value={values.emailId}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  error={Boolean(touched.emailId && errors.emailId)}
                  errorText={errors.emailId}
                  startAdornment={<i className="ri-mail-line ri-lg" />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "29%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  disabled={existingUser || isPrimaryUser}
                  label=" Address"
                  name="address"
                  value={values.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.address && errors.address)}
                  errorText={errors.address}
                  startAdornment={<HomeOutlined />}
                  size={"big"}
                />

                <>
                  {isOtpSent && (
                    <div>
                      <h3>Verify Otp</h3>
                      <FormInputField
                        style={{ width: "30%", marginRight: "30px" }}
                        label="Otp"
                        type={"tel"}
                        name="otp"
                        value={values.otp}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 6,
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        endAdorment={
                          isOtpVerified ? (
                            <i className="ri-checkbox-circle-fill ri-xl icon-primary-green" />
                          ) : null
                        }
                        startAdornment={<FingerprintOutlined />}
                        size={"big"}
                      />

                      {isOtpSent && (
                        <>
                          <Typography
                            onClick={timer === 0 ? () => ResendOtp() : undefined}
                            variant="subtitle1"
                            color={timer === 0 ? "secondary" : "textSecondary"}
                            sx={{ textDecoration: "underline", cursor: "pointer" }}
                          >
                            Resend OTP
                          </Typography>
                          {timer !== 0 && (
                            <Typography
                              variant="subtitle1"
                              color="secondary"
                              sx={{ fontWeight: 500, ml: "4px !important" }}
                            >
                              in {formatTime(timer)}
                            </Typography>
                          )}
                        </>
                      )}
                      {!isOtpVerified && (
                        <Box sx={{ mt: 2 }}>
                          <CustomButton
                            disabled={values?.otp?.length !== 6}
                            className="btn--primary"
                            label="Verify"
                            onClick={() => handleSubmitForVerifyMobileOtp(values.otp)}
                          ></CustomButton>
                        </Box>
                      )}
                    </div>
                  )}

                  <Box sx={{ mt: 2 }} style={{ display: "flex" }}>
                    {!buttonDisable ? (
                      <CustomButton
                        disabled={isOtpSent ? !isOtpVerified : isOtpVerified}
                        className="btn--primary"
                        style={{ marginRight: "20px" }}
                        type="submit"
                        label={"Save"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <CustomButton
                        disabled={values?.mobileNumber?.length !== 10}
                        className="btn--primary"
                        style={{ marginRight: "20px" }}
                        // type="submit"
                        label={"Send Otp"}
                        onClick={() => sendOtp(values?.mobileNumber)}
                      />
                    )}
                    <CustomButton
                      label="Cancel"
                      onClick={() => navigate("/home/patientFamilyDetails")}
                      className="btn--error"
                    />
                  </Box>
                </>

                {/* todo : for now new family member will be created by same mobile number as primary user */}
                {/* <Grid display="flex" sx={{ mt: 2 }}>
                    <Grid>
                      <CustomTypography>
                        Do you want to continue with same <br /> Mobile number?
                      </CustomTypography>
                      <FormControl>
                        <RadioGroup
                          row
                          defaultValue="true"
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="usesExistingPhoneNumber"
                          value={values.usesExistingPhoneNumber}
                          onChange={(e) => {
                            setFieldValue("usesExistingPhoneNumber", e.target.value);
                            setFieldValue("mobileNumber", currentActiveUser()?.mobileNumber);
                          }}
                        >
                          <FormControlLabel
                            name="usesExistingPhoneNumber"
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            name="usesExistingPhoneNumber"
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid>
                      {values.usesExistingPhoneNumber === "false" && (
                        <Grid sx={{ ml: 8 }}>
                          <CustomTypography>Enter the new mobile number:</CustomTypography>
                          <FormInputField
                            style={{
                              width: "78%",
                              marginRight: "30px",
                              marginBottom: "25px",
                              marginTop: "10px",
                            }}
                            label="Mobile Number"
                            type={"tel"}
                            inputProps={{
                              maxLength: 10,
                            }}
                            name="mobileNumber"
                            value={values.mobileNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                            errorText={errors.mobileNumber}
                            startAdornment={<LocalPhoneOutlined />}
                            size={"big"}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid> */}
              </SubCard>
            </form>
          </Box>
        )}
      </Formik>
    </Reveal>
  );
};

const RelationShipForm = ({
  handleBlur,
  handleChange,
  isUserPrimary,
  touched,
  errors,
  patientAge,
  setLowerBoundDateForFamilyDOB,
  setUpperBoundDateForFamilyDOB,
  setDobError,
}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    let newUpperBound;
    let newLowerBound;

    if (values?.relationship) {
      const ageDifference = AGE_DIFFERENCE_MAP[values.relationship] || 0;

      if (RELATIONSHIPS_WITH_OLDER_AGE.includes(values.relationship)) {
        newUpperBound = dayjs().subtract(patientAge + ageDifference, "year");
        newLowerBound = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
        setUpperBoundDateForFamilyDOB(newUpperBound);
        setLowerBoundDateForFamilyDOB(newLowerBound);
      } else if (RELATIONSHIPS_WIT_YOUNGER_AGE.includes(values.relationship)) {
        newLowerBound = dayjs().subtract(patientAge - ageDifference, "year");
        newUpperBound = dayjs();
        setLowerBoundDateForFamilyDOB(newLowerBound);
        setUpperBoundDateForFamilyDOB(newUpperBound);
      }

      if (dayjs(values?.dateOfBirth)?.isValid()) {
        if (values.dateOfBirth < newLowerBound) {
          setDobError(
            `Should be greater than ${newLowerBound.subtract(1, "day").format(DATE_FORMAT_DMY)}.`
          );
        } else if (values.dateOfBirth > newUpperBound) {
          setDobError(
            `Should be less than ${newUpperBound.add(1, "day").format(DATE_FORMAT_DMY)}.`
          );
        } else {
          setDobError("");
        }
      }
    }
  }, [values?.relationship]);

  return (
    <>
      <FormSelectField
        style={{
          width: "29%",
          marginBottom: "25px",
          marginRight: "30px",
        }}
        label={"Relationship"}
        name={"relationship"}
        onBlur={handleBlur}
        value={values.relationship}
        size={"big"}
        startAdornment={<GroupOutlined />}
        onChange={handleChange}
        disabled={isUserPrimary}
        menuItems={
          values.gender === MALE
            ? filteredRelationsForMale
            : values.gender === FEMALE
            ? filteredRelationsForFemale
            : familyRelations
        }
        error={Boolean(touched.relationship && errors.relationship)}
        errorText={errors.relationship}
      ></FormSelectField>
    </>
  );
};

export default ManageFamilyMembers;
