import React, { useState } from "react";
import "assets/scss/PatientDetails2.scss";
import HumanBody from "../../HumanBody/HumanBody";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { currentActiveUser, DATE_FORMAT_DMY, isGuidedDemoOn } from "store/constant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getColorAndBackgroundColorBySeverity } from "utils/calculate-vital-color";
import {
  BLADDER,
  BRAIN,
  getChartByVitalType,
  getEhrDataPoints,
  getLatestVitals,
  HEART,
  INTESTINES,
  KIDNEY,
  LIVER,
  LUNGS,
  MOUTH,
  organIdMapper,
  organToVitalMap,
  specializationForEhr,
  specializationToOrgansMapper,
  STOMACH,
  THROAT,
  vitalSignType,
  vitalToOrganMap,
} from "utils/ehr-data-utils";
import {
  getPatientData,
  getPatientLifestyleByPatientId,
  getProfilePicture,
} from "services/patientService";
import PatientCard from "views/Components/Doctor/PatientDTO/PatientCard";
import PatientHealthDetails from "views/Components/Doctor/PatientDTO/PatientHealthDetails";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import { Close, ExpandMore, TrendingUp, VaccinesOutlined } from "@mui/icons-material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { createRoot } from "react-dom/client";
import dayjs from "dayjs";
import Xarrow, { Xwrapper, useXarrow } from "react-xarrows";
import { calculateAge } from "utils/calculate-age";
import HumanSkeleton from "../../HumanSkeleton/HumanSkeleton";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useMainClass } from "layout/SidebarUtilContext";
import { getEhrData, getEhrSummary } from "services/PrescriptionsService";
import { detailedEhrGuideText, ehrSummaryGuideText } from "utils/guide-steps";
import { useShepherd } from "react-shepherd";
import { useLoader } from "layout/LoaderContext";
import Reveal from "views/utilities/Reveal";
import PatientPrescription from "views/Components/Patient/PatientPrescription";

const PatientDetails2 = () => {
  const { state } = useLocation();
  const [searchItem, setSearchItem] = useState("");
  const [activeTab, setActiveTab] = useState(state?.activeTab ? state.activeTab : 1);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [patientEhrData, setPatientEhrData] = useState(null);
  const [patientChronicDiseases, setPatientChronicDiseases] = useState(null);
  const [patientPastProcedures, setPatientPastProcedures] = useState(null);
  const [lifestyleHabits, setLifestyleHabits] = useState([]);
  const [encounters, setEncounters] = useState(null);
  const [vitalData, setVitalData] = useState([]);
  const [allVitalData, setAllVitalData] = useState([]);
  const [vitalLatestValues, setVitalLatestValues] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const [chartList, setChartList] = useState(null);
  const [selectedOrgan, setSelectedOrgan] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [selectedEncounter, setSelectedEncounter] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [listOfArrow, setListOfArrow] = useState([]);
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [iframeAppointmentID, setIframeAppointmentId] = useState(null);
  const { mainClass } = useMainClass();
  const updateXarrow = useXarrow();
  const [ehrSummary, setEhrSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const patientId = id;

  useEffect(() => {
    const fetchAppointment = async () => {
      const response = await getPatientData(patientId);

      setPatientData(response.data);
    };
    if (patientId) {
      fetchAppointment();
    }
  }, [patientId]);
  useEffect(() => {
    setTimeout(() => {
      updateXarrow();
    }, 300);
  }, [mainClass]);

  const [ehrSummaryApiCalled, setEhrSummaryApiCalled] = useState(false);

  useEffect(() => {
    const fetchPatientEhrSummary = async () => {
      try {
        const { data: result } = await getEhrSummary(patientId, null, true);
        if (!result) {
          setEhrSummary(null);
        }
        const indexOfFirstDiv = result.EHR_SUMMARY.indexOf("<div>");
        const indexOflastDiv = result.EHR_SUMMARY.lastIndexOf("</div>") + 5;
        setEhrSummary(result.EHR_SUMMARY.substring(indexOfFirstDiv, indexOflastDiv + 1));
      } catch (e) {
      } finally {
        setEhrSummaryApiCalled(true);
      }
    };
    fetchPatientEhrSummary();
  }, []);

  useEffect(() => {
    const fetchPatientEhrDetails = async () => {
      try {
        const response = await getEhrData(patientId);
        setPatientEhrData(response?.data);
        setPatientChronicDiseases(response?.data?.patientMedicalHistory?.patientChronicDiseases);
        setPatientPastProcedures(response?.data?.patientMedicalHistory?.pastProcedures);
        setEncounters(response?.data?.encounters);
        const ehrDataPoints = getEhrDataPoints(response.data);

        const newVitalData = ehrDataPoints.filter((d) => vitalSignType.includes(d.key));

        const latestVitalValue = getLatestVitals(newVitalData);

        setAllVitalData(ehrDataPoints);
        setVitalData(newVitalData);
        setVitalLatestValues(latestVitalValue);

        if (newVitalData[0]) {
          setSelectedGraph(newVitalData[0].key);
        }
        const userId = response?.data?.patientDetails?.userResponse?.id;
        if (userId) {
          try {
            setIsLoading(true);
            const profilePicResponse = await getProfilePicture(userId);
            const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;

            setProfileImage({
              filename: profilePicResponse.data.filename,
              content,
            });
          } catch (error) {
            console.error("Error fetching profile picture", error);
          } finally {
            setIsLoading(false);
          }
        }
      } catch (error) {}
    };

    const fetchData = async () => {
      // if (!patientEhrData?.patientId) return;

      try {
        const lifestyleResponse = await getPatientLifestyleByPatientId(patientId);

        const habitStyles = {
          Smoking: { color: "red", borderColor: "darkred", icon: "🚬" },
          "Consuming Tobacco": { color: "brown", borderColor: "purple", icon: "🚭" },
          Drinking: { color: "blue", borderColor: "darkblue", icon: "🍻" },
          "Non-vegetarian": { color: "green", borderColor: "darkgreen", icon: "🍗" },
          Vegetarian: { color: "green", borderColor: "darkgreen", icon: "🥦" },
          Eggetarian: { color: "yellow", borderColor: "goldenrod", icon: "🍳" },
        };

        const matchedHabits = lifestyleResponse.data.flatMap((responseObj) => {
          const responseArray = responseObj.response.split(",");
          return responseArray
            .map((response) => response.trim())
            .filter((response) => habitStyles.hasOwnProperty(response))
            .map((habit) => ({
              name: habit,
              color: habitStyles[habit]?.color || "black",
              borderColor: habitStyles[habit]?.borderColor || "gray",
              icon: habitStyles[habit]?.icon || "❓",
            }));
        });

        if (Array.isArray(matchedHabits)) {
          setLifestyleHabits(matchedHabits);
        } else {
          console.error("Matched habits are not an array:", matchedHabits);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchPatientEhrDetails();
  }, []);

  useEffect(() => {
    if (selectedSpecialization === "") {
    } else if (selectedSpecialization) {
      const organList = specializationToOrgansMapper[selectedSpecialization.id];
      setSelectedOrgan([...organList]);
    } else {
      setSelectedOrgan([]);
    }
  }, [selectedSpecialization]);

  const getSpecializationVitalCharts = (specialization) => {
    let vitalList;
    if (specialization) {
      const organList = specializationToOrgansMapper[specialization];
      vitalList = [...new Set(organList.flatMap((organ) => organToVitalMap[organ]))];
    } else {
      vitalList = [
        "BLOOD_OXYGEN",
        "RESPIRATORY_RATE",
        "BODY_TEMPERATURE",
        "BLOOD_PRESSURE",
        "BLOOD_GLUCOSE",
        "HEART_RATE",
      ];
    }
    const list = vitalList
      ?.map((vital) => {
        if (allVitalData.find((graph) => graph.key === vital)) {
          const matchedOrganVitalChartList = allVitalData.find((graph) => graph.key === vital);

          return {
            vitalName: vital,
            vitalChart: getChartByVitalType(
              vital,
              matchedOrganVitalChartList?.value,
              matchedOrganVitalChartList?.value[0].vitalSignTypeResponse?.displayName
            ),
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    setChartList(list);
    return list;
  };

  const getOrganVitalCharts = (organList) => {
    let vitalList;
    if (organList && organList.length > 0) {
      vitalList = [...new Set(organList.flatMap((organ) => organToVitalMap[organ]))];
    } else {
      vitalList = [
        "BLOOD_OXYGEN",
        "RESPIRATORY_RATE",
        "BODY_TEMPERATURE",
        "BLOOD_PRESSURE",
        "BLOOD_GLUCOSE",
        "HEART_RATE",
      ];
    }

    const list = vitalList
      ?.map((vital) => {
        if (allVitalData.find((graph) => graph.key === vital)) {
          const matchedOrganVitalChartList = allVitalData.find((graph) => graph.key === vital);

          return {
            vitalName: vital,
            vitalChart: getChartByVitalType(
              vital,
              matchedOrganVitalChartList?.value,
              matchedOrganVitalChartList?.value[0].vitalSignTypeResponse?.displayName
            ),
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    setChartList(list);
    return list;
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";

    for (let i = 0; i < 6; i++) {
      const randomValue = Math.floor(Math.random() * 12) + 4;
      color += letters[randomValue];
    }

    return color;
  };

  const getTimeAgo = (datePerformed) => {
    const diff = new Date() - new Date(datePerformed);
    const monthsAgo = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));

    if (monthsAgo === 0) {
      return "less than a month";
    } else {
      return `${monthsAgo} months ago`;
    }
  };

  const tabs = [
    { id: 1, label: "Summary" },
    { id: 2, label: "Organs" },
    { id: 3, label: "Skeleton" },
  ];

  const handleAppointmentViewClick = (report) => {
    if (report.encounterDetails?.id === selectedEncounter?.encounterDetails?.id) {
      setSelectedEncounter(null);
    } else {
      setSelectedEncounter(report);
    }
  };

  const organOptions = [
    { name: "BRAIN", id: BRAIN },
    { name: "MOUTH", id: MOUTH },
    { name: "THROAT", id: THROAT },
    { name: "LUNGS", id: LUNGS },
    { name: "HEART", id: HEART },
    { name: "LIVER", id: LIVER },
    { name: "STOMACH", id: STOMACH },
    { name: "KIDNEY", id: KIDNEY },
    { name: "INTESTINES", id: INTESTINES },
    { name: "BLADDER", id: BLADDER },
  ];

  const convertPatientVitalsForPatientHealthDetails = (patientLatestVitals) => {
    const vitalDetails = patientLatestVitals.map((vital) => {
      return {
        ...vital.latestValue,
        value: `${vital.latestValue.value}${
          vital.latestValue.vitalSignTypeResponse.unitAbbreviation ?? ""
        }`,
        displayName: `${vital.latestValue.vitalSignTypeResponse.displayName}`,
        deviation: (
          <Grid display={"flex"}>
            <TrendingUp
              style={{
                color: "#388E3C",
                height: "20px",
                width: "20px",
              }}
            ></TrendingUp>
            <span className="change-field-value-up">+15%</span>
          </Grid>
        ),
      };
    });
    return vitalDetails;
  };

  //temp mapper for all body
  const organMapper = {
    BLOOD_OXYGEN: "human-body-lungs",
    RESPIRATORY_RATE: "human-body-lungs",
    BODY_TEMPERATURE: "human-body-throat",
    BLOOD_PRESSURE: "human-body-brain",
    BLOOD_GLUCOSE: "human-body-bladder",
    HEART_RATE: "human-body-heart",
  };

  const rootsMap = new Map();

  function distributeItemsRandomly(chartList) {
    const grids = [
      document.querySelector("#random-graph-grid-1"),
      document.querySelector("#random-graph-grid-2"),
      document.querySelector("#random-graph-grid-3"),
      document.querySelector("#random-graph-grid-4"),
      document.querySelector("#random-graph-grid-5"),
      document.querySelector("#random-graph-grid-6"),
    ].sort(() => 0.5 - Math.random());

    grids.forEach((grid) => {
      // Create a new React root for each grid
      if (!rootsMap.has(grid)) {
        // If root doesn't exist for the grid, create it and store in map
        if (grid) {
          const root = createRoot(grid);
          rootsMap.set(grid, root);
        }
      }
      const root = rootsMap.get(grid);
      // const root = createRoot(grid);
      if (root) {
        root.render(null); // Unmount any existing content by rendering null
      }
    });

    // Shuffle the list to randomize the order
    let shuffledList = chartList.sort(() => 0.5 - Math.random());
    if (shuffledList.length > 6) {
      shuffledList = shuffledList.slice(0, 6);
    }
    const tempArrowList = [];
    let organListToFind = [];
    // Distribute the shuffled list across the grid items
    shuffledList.forEach((item, index) => {
      const randomGrid = grids[index];

      if (randomGrid) {
        const vitalName = item.vitalChart.props.title;
        const organs = vitalToOrganMap[vitalName] || [];

        if (selectedOrgan.length > 0) {
          organListToFind = [...selectedOrgan];
        } else if (selectedSpecialization) {
          organListToFind = specializationToOrgansMapper[selectedSpecialization.id];
        }
        const organ = organs.find((organ) => organListToFind.includes(organ));
        const organId = organIdMapper[organ];
        tempArrowList.push({
          end: randomGrid?.id,
          start: organId,
        });
      }
      // Create a new React root for each grid and render the component
      if (!rootsMap.has(randomGrid)) {
        // If root doesn't exist for the grid, create it and store in map
        if (randomGrid) {
          const root = createRoot(randomGrid);
          rootsMap.set(randomGrid, root);
        }
      }
      const root = rootsMap.get(randomGrid);
      // const root = createRoot(randomGrid);
      if (root) {
        root.render(item.vitalChart);
      }
    });
    if (organListToFind.length > 0) {
      setListOfArrow([...tempArrowList]);
    } else {
      setListOfArrow([]);
    }
  }

  useEffect(() => {
    if (chartList) {
      distributeItemsRandomly(chartList);
    }
  }, [selectedOrgan]);

  useEffect(() => {
    if (activeTab === 1 || activeTab === 3) {
      setSelectedOrgan([]);
    }
    if (vitalData.length > 0 && activeTab === 2) {
      const initialChartList = vitalData.map((item) => {
        return {
          vitalChart: getChartByVitalType(
            item?.key,
            item?.value,
            item?.value[0]?.vitalSignTypeResponse?.displayName
          ),
        };
      });
      distributeItemsRandomly(initialChartList);
    }
  }, [vitalData, activeTab]);

  /****************************** DEMO MODE START ********************************** */
  const { loading } = useLoader();

  const [firstTimeLoad, setFirstTimeLoad] = useState(true); // Tract first time load, to avoid shephard display before
  const [tourStarted, setTourStarted] = useState(false); // Track if the tour has already started

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    useModalOverlay: true,
  };

  const ehrSummaryGuideSteps = [
    {
      id: "guide-intro",
      text: ehrSummaryGuideText["intro"],
      attachTo: {
        element: "",
        on: "bottom",
      },
      buttons: [
        {
          text: "Start",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal",
    },
    {
      id: "c-ehr-guide-step-1",
      text: ehrSummaryGuideText["step-1"],
      attachTo: {
        element: "#patient-basic-details",
        on: "right",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-right",
    },
    {
      id: "c-ehr-guide-step-2",
      text: ehrSummaryGuideText["step-2"],
      attachTo: {
        element: "#patient-ehr-summary",
        on: "right",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-right",
    },
    {
      id: "c-ehr-guide-step-3",
      text: ehrSummaryGuideText["step-3"],
      attachTo: {
        element: "#patient-vital-big-number-chart",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "c-ehr-guide-step-4",
      text: ehrSummaryGuideText["step-4"],
      attachTo: {
        element: "#patient-vital-graphs",
        on: "top",
      },
      buttons: [
        {
          text: "Finish",
          action: (tour) => Shepherd.activeTour.complete(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-top",
    },
  ];

  const detailedEhrGuideSteps = [
    {
      id: "guide-intro",
      text: detailedEhrGuideText["intro"],
      attachTo: {
        element: "",
        on: "bottom",
      },
      buttons: [
        {
          text: "Start",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal",
    },
    {
      id: "d-ehr-guide-step-1",
      text: detailedEhrGuideText["step-1"],
      attachTo: {
        element: "#human-body-diagram",
        on: "right",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-right",
    },
    {
      id: "d-ehr-guide-step-2",
      text: detailedEhrGuideText["step-2"],
      attachTo: {
        element: "#organ-search-input",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "d-ehr-guide-step-3",
      text: detailedEhrGuideText["step-3"],
      attachTo: {
        element: "#organ-vital-graph-1",
        on: "top",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-top",
    },
    {
      id: "d-ehr-guide-step-4",
      text: detailedEhrGuideText["step-4"],
      attachTo: {
        element: "#organ-vital-graph-2",
        on: "top",
      },
      buttons: [
        {
          text: "Finish",
          action: (tour) => Shepherd.activeTour.complete(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-top",
    },
  ];

  const Shepherd = useShepherd();

  useEffect(() => {
    if (isGuidedDemoOn()) {
      setFirstTimeLoad(false);
      if (!loading && !firstTimeLoad && !tourStarted) {
        setTourStarted(true);
        const tour = new Shepherd.Tour({
          ...tourOptions,
          steps: activeTab === 1 ? ehrSummaryGuideSteps : detailedEhrGuideSteps,
        });
        tour.start();
      }
    }
  }, [loading]);
  /****************************** DEMO MODE END   ********************************** */

  const navigate = useNavigate();
  return (
    <>
      {ehrSummaryApiCalled && (
        <div>
          <Reveal
            style={{ display: "flex", alignItems: "center", margin: "1em 1em 1em 0em", gap: "8px" }}
          >
            <CustomButton
              iconButton={<ArrowBackIcon style={{ color: "#004C70" }}></ArrowBackIcon>}
              onClick={() => {
                navigate(-1);
              }}
              style={{ padding: "2px 5px", marginRight: "5px" }}
              customBackGroundColor="#29BF911A"
            ></CustomButton>
            <h2 style={{ width: "40%" }} className="page-title">
              Patient's EHR
            </h2>
            <div
              style={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="tab-list">
                {tabs.map((tab) => (
                  <div
                    key={tab.id}
                    className={`tab-list-names hover ${activeTab === tab.id ? "active-tab" : ""} ${
                      tab.disabled ? "disabled-tab" : ""
                    }`}
                    onClick={() => {
                      if (!tab.disabled) {
                        setSelectedSpecialization(null);
                        setActiveTab(tab.id);
                      }
                    }}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
              {activeTab === 2 && (
                <Reveal className="organs-options" id="organ-search-input">
                  <CustomAutoComplete
                    options={specializationForEhr.filter((specialization) => specialization.id)}
                    label="Select Specialization"
                    placeholder="Select"
                    iconStart={<VaccinesOutlined />}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, value) => {
                      setSelectedSpecialization(value);

                      if (value) {
                        getSpecializationVitalCharts(value.id);
                      } else {
                        getSpecializationVitalCharts(null);
                      }
                    }}
                    value={selectedSpecialization ? selectedSpecialization : null}
                  />
                </Reveal>
              )}
            </div>
          </Reveal>
          {activeTab === 1 && (
            <Reveal className="patient-details-2">
              <div className="patient-basic-details border" id="patient-basic-details">
                {/* <div className="patient-basic-details-summary"> */}
                <div
                  style={{
                    height: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.7em",
                    position: "sticky",
                    top: currentActiveUser().roleName === "PATIENT" ? "20px" : "80px",
                  }}
                >
                  <Reveal className="name-card border">
                    <div className="name-card-details">
                      <div className="user-image">
                        <img
                          style={{ width: "60px ", height: "60px", borderRadius: "50%" }}
                          src={profileImage ? profileImage?.content : "/images/patient-image.png"}
                          // src="/images/patient-image.png"
                          alt=""
                        />
                      </div>
                      <div className="user-details">
                        <p style={{ fontSize: "1.1em", fontWeight: 500, color: "black" }}>
                          {patientEhrData?.patientDetails?.userResponse?.name}
                        </p>
                        <p>
                          <span>
                            {calculateAge(
                              patientEhrData?.patientDetails?.userResponse?.dateOfBirth
                            )}
                          </span>{" "}
                          | <span>{patientEhrData?.patientDetails?.userResponse?.gender}</span> |{" "}
                          <span>{patientEhrData?.patientDetails?.bloodGroup}</span> |{" "}
                          <span>{patientEhrData?.patientDetails?.height} cm</span>{" "}
                        </p>
                      </div>
                    </div>
                    {/* <div className="icon">
                        <i className="ri-more-2-fill ri-xl hover" />
                      </div> */}
                  </Reveal>

                  {ehrSummary && (
                    <Reveal id="patient-ehr-summary">
                      <div style={{ paddingLeft: "0px" }} className="card-title">
                        EHR Summary
                      </div>
                      <div
                        style={{ padding: "0px 15px 15px 15px" }}
                        dangerouslySetInnerHTML={{ __html: ehrSummary }}
                      ></div>
                    </Reveal>
                  )}

                  <Reveal className="report-card border">
                    <div className="header-container">
                      <p className="header-title">Encounters</p>
                      {/* <div className="header-icons">
                          <i className="ri-more-2-fill ri-xl hover" />
                        </div> */}
                    </div>
                    <ul className="list">
                      {encounters
                        ?.filter((encounter) => encounter?.encounterDetails) // Filter out encounters with null encounterDetails
                        .map((encounter, index) => (
                          <Reveal>
                            <li
                              style={{
                                backgroundColor: `${
                                  selectedEncounter?.encounterDetails?.id ===
                                  encounter?.encounterDetails?.id
                                    ? "lightgrey"
                                    : "white"
                                }`,
                              }}
                              className="item border"
                              key={index}
                            >
                              <div className="title">
                                <p style={{ color: "#004c70", fontWeight: 600 }}>
                                  {encounter?.encounterDetails?.appointmentResponse?.organization
                                    ?.name || "Appointment"}
                                </p>
                                <p>
                                  <i className="ri-file-fill ri-lg icon-primary-blue" />
                                  <span style={{ marginLeft: "3px", fontSize: ".8rem" }}>
                                    {dayjs(encounter?.encounterDate).format(DATE_FORMAT_DMY)}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="action"
                                onClick={() => {
                                  handleAppointmentViewClick(encounter);
                                }}
                              >
                                <i
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      selectedEncounter?.encounterDetails?.id ===
                                      encounter?.encounterDetails?.id
                                        ? "#29bf91"
                                        : "#004c70",
                                  }}
                                  className={`ri-eye-${
                                    selectedEncounter?.encounterDetails?.id ===
                                    encounter?.encounterDetails?.id
                                      ? "fill"
                                      : "line"
                                  } ri-xl`}
                                />
                              </div>
                            </li>
                          </Reveal>
                        ))}
                    </ul>
                  </Reveal>

                  <Reveal className="lifestyles border">
                    <div className="header-container">
                      <p className="header-title">Lifestyle & Hx</p>
                      {/* <div className="header-icons">
                          <i className="ri-more-2-line ri-xl hover" />
                        </div> */}
                    </div>
                    <ul className="lifestyle-list">
                      {lifestyleHabits.map((lifestyle, index) => (
                        <Reveal>
                          <li
                            key={index}
                            style={{ borderLeft: `2px solid ${lifestyle.borderColor}` }}
                            className="lifestyle-item"
                          >
                            <p className="lifestyle-name">{lifestyle.name}</p>
                            <span>{lifestyle.icon}</span>
                          </li>
                        </Reveal>
                      ))}
                    </ul>
                  </Reveal>
                  <Reveal className="health-conditions border">
                    <div className="header-container">
                      <p className="header-title">Health Conditions</p>
                    </div>
                    <ul className="condition-list">
                      {patientChronicDiseases && patientChronicDiseases?.length > 0 ? (
                        patientChronicDiseases?.map((condition, index) => {
                          const colorCode = getColorAndBackgroundColorBySeverity(
                            condition?.severity
                          );

                          return (
                            <Reveal>
                              <li
                                key={index}
                                style={{
                                  borderLeft: `2px solid ${getRandomColor()}`,
                                  marginBottom: "10px",
                                }}
                                className="condition-item"
                              >
                                <p className="condition-name">
                                  {condition?.chronicDiseaseResponse?.name}
                                </p>
                                <span
                                  className="condition-badge"
                                  style={{
                                    color: colorCode?.color,
                                    backgroundColor: colorCode?.backgroundColor,
                                  }}
                                >
                                  {condition?.severity}
                                </span>
                              </li>
                            </Reveal>
                          );
                        })
                      ) : (
                        <p style={{ margin: "5px" }}>Data Not Available</p>
                      )}
                    </ul>
                  </Reveal>
                  <Reveal className="surgery">
                    <div className="header-container">
                      <p className="header-title">Past procedures</p>
                    </div>
                    <ul className="surgery-list">
                      {patientPastProcedures && patientPastProcedures?.length > 0 ? (
                        patientPastProcedures?.map((procedure) => (
                          <Reveal>
                            <li
                              key={procedure.id}
                              className="surgery-item"
                              style={{
                                borderLeft: `2px solid ${getRandomColor()}`,
                                marginBottom: "10px",
                              }}
                            >
                              <p className="surgery-name">
                                {procedure.procedureResponse.name} (
                                {getTimeAgo(procedure.datePerformed)})
                              </p>
                            </li>
                          </Reveal>
                        ))
                      ) : (
                        <p style={{ margin: "5px" }}>Data Not Available</p>
                      )}
                    </ul>
                  </Reveal>
                </div>
              </div>
              <div className="patient-advanced-details">
                {selectedEncounter && (
                  <Reveal className="patient-advanced-details-2">
                    <Accordion
                      sx={{
                        margin: "0px !important",
                        ":: before": { opacity: 0 },
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`panel-content`}
                        id={`panel-header`}
                        sx={{
                          borderRadius: "10px",
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#004c70",
                          backgroundColor: "#e6eef1",
                          minHeight: "48px !important",
                          "& .MuiAccordionSummary-content": {
                            margin: "0px !important",
                          },
                        }}
                      >
                        {"Encounter details"}
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "7px 0px 7px 0px" }}>
                        <div className="patient-advanced-details-2-content">
                          <div className="appointment-details border">
                            <div className="header-container">
                              <p className="header-title" style={{ marginLeft: "10px" }}>
                                {selectedEncounter?.encounterDate
                                  ? dayjs(selectedEncounter?.encounterDate).format("D MMMM, YYYY")
                                  : "No Encounter selcted"}
                              </p>
                            </div>
                            <div className="appointment-item">
                              <p className="item-title">Diagnosis :</p>
                              <ul
                                style={{ margin: "0px", marginTop: "4px", marginLeft: "5px" }}
                                className="appointment-item-ul"
                              >
                                {selectedEncounter?.encounterDetails
                                  ?.prescriptionDiagnosisResponses &&
                                selectedEncounter?.encounterDetails?.prescriptionDiagnosisResponses
                                  .length > 0 ? (
                                  selectedEncounter?.encounterDetails?.prescriptionDiagnosisResponses?.map(
                                    (diagnosis) => (
                                      <li key={diagnosis.id} className="appointment-item-li">
                                        <p className="appointment-item-li-name">
                                          {diagnosis.diagnosisResponse.name}
                                        </p>
                                      </li>
                                    )
                                  )
                                ) : (
                                  <p style={{ margin: "0px", marginTop: "4px", marginLeft: "5px" }}>
                                    Data Not Available
                                  </p>
                                )}
                              </ul>
                            </div>
                            <div className="appointment-item">
                              <p className="item-title">Symptoms :</p>
                              <ul
                                style={{ margin: "0px", marginTop: "4px", marginLeft: "5px" }}
                                className="appointment-item-ul"
                              >
                                {selectedEncounter?.encounterDetails
                                  ?.prescriptionSymptomResponses &&
                                selectedEncounter?.encounterDetails?.prescriptionSymptomResponses
                                  .length > 0 ? (
                                  selectedEncounter?.encounterDetails?.prescriptionSymptomResponses?.map(
                                    (symptom) => (
                                      <li key={symptom.id} className="appointment-item-li">
                                        <p className="appointment-item-li-name">
                                          {symptom.symptomResponse.name}
                                        </p>
                                      </li>
                                    )
                                  )
                                ) : (
                                  <p style={{ margin: "0px", marginTop: "4px", marginLeft: "5px" }}>
                                    Data Not Available
                                  </p>
                                )}
                              </ul>
                            </div>
                            <div className="appointment-item">
                              <p className="item-title">Lab Tests :</p>
                              <ul
                                style={{ margin: "0px", marginTop: "4px", marginLeft: "5px" }}
                                className="appointment-item-ul"
                              >
                                {selectedEncounter?.encounterDetails
                                  ?.prescriptionLabReportResponses &&
                                selectedEncounter?.encounterDetails?.prescriptionLabReportResponses
                                  .length > 0 ? (
                                  selectedEncounter?.encounterDetails?.prescriptionLabReportResponses?.map(
                                    (labReport) => (
                                      <li key={labReport.id} className="appointment-item-li">
                                        <p className="appointment-item-li-name">
                                          {labReport.labTestResponse.name}
                                        </p>
                                      </li>
                                    )
                                  )
                                ) : (
                                  <p style={{ margin: "0px", marginTop: "4px", marginLeft: "5px" }}>
                                    Data Not Available
                                  </p>
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="conditions">
                            <div className="health-conditions">
                              <div className="header-container">
                                <p className="header-title">Health Conditions</p>
                              </div>
                              <ul className="condition-list">
                                {patientChronicDiseases && patientChronicDiseases?.length > 0 ? (
                                  patientChronicDiseases?.map((condition, index) => {
                                    const colorCode = getColorAndBackgroundColorBySeverity(
                                      condition?.severity
                                    );

                                    return (
                                      <li
                                        key={index}
                                        style={{
                                          borderLeft: `2px solid ${getRandomColor()}`,
                                          marginBottom: "10px",
                                        }}
                                        className="condition-item"
                                      >
                                        <p className="condition-name">
                                          {condition?.chronicDiseaseResponse?.name}
                                        </p>
                                        <span
                                          className="condition-badge"
                                          style={{
                                            color: colorCode?.color,
                                            backgroundColor: colorCode?.backgroundColor,
                                          }}
                                        >
                                          {condition?.severity}
                                        </span>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <p style={{ margin: "5px" }}>Data Not Available</p>
                                )}
                              </ul>
                            </div>
                            <div className="surgery">
                              <div className="header-container">
                                <p className="header-title">Last Surgery</p>
                              </div>
                              <ul className="surgery-list">
                                {patientPastProcedures && patientPastProcedures?.length > 0 ? (
                                  patientPastProcedures?.map((procedure) => (
                                    <li
                                      key={procedure.id}
                                      className="surgery-item"
                                      style={{
                                        borderLeft: `2px solid ${getRandomColor()}`,
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <p className="surgery-name">
                                        {procedure.procedureResponse.name} (
                                        {getTimeAgo(procedure.datePerformed)})
                                      </p>
                                    </li>
                                  ))
                                ) : (
                                  <p style={{ margin: "5px" }}>Data Not Available</p>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Reveal>
                )}
                <div className="patient-advanced-details-1 ">
                  {vitalData.length > 0 && (
                    <>
                      <Grid container spacing={1} id="patient-vital-big-number-chart">
                        {vitalLatestValues.map((latestVitalValue) => {
                          return (
                            <Grid key={latestVitalValue?.key} size={{ xs: 4 }}>
                              <Reveal>
                                <Card variant="outlined">
                                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <CardContent>
                                      <Typography variant="h3" sx={{ color: "#004c70" }}>
                                        {latestVitalValue.latestValue.value}{" "}
                                        {
                                          latestVitalValue.latestValue?.vitalSignTypeResponse
                                            ?.unitAbbreviation
                                        }
                                      </Typography>
                                      <Typography variant="h6" component="div">
                                        {
                                          latestVitalValue.latestValue?.vitalSignTypeResponse
                                            ?.displayName
                                        }
                                      </Typography>
                                    </CardContent>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {latestVitalValue?.isIncreased === 2 ? (
                                        <Grid display={"flex"}>
                                          <TrendingUp
                                            style={{
                                              color: "#388E3C",
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        </Grid>
                                      ) : latestVitalValue?.isIncreased === 0 ? (
                                        <Grid display={"flex"}>
                                          <TrendingDownIcon
                                            style={{
                                              color: "#EF5148",
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        </Grid>
                                      ) : null}

                                      <Typography variant="h6" component="div">
                                        {latestVitalValue?.differencePercentage}
                                      </Typography>
                                    </div>
                                  </div>
                                </Card>
                              </Reveal>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid container spacing={1} id="patient-vital-graphs">
                        <Grid container spacing={1.5} size={{ xs: 12 }}>
                          {vitalData.map((vital) => {
                            return (
                              <Grid key={vital?.key} size={{ xs: 6 }}>
                                <Reveal>
                                  {getChartByVitalType(
                                    vital?.key,
                                    vital?.value,
                                    vital?.value[0]?.vitalSignTypeResponse?.displayName
                                  )}
                                </Reveal>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {vitalData.length === 0 && (
                    <Typography
                      sx={{ textAlign: "center", fontStyle: "italic", color: "rgba(0, 0, 0, 0.5)" }}
                    >
                      No patient vitals found...
                    </Typography>
                  )}
                </div>
              </div>
            </Reveal>
          )}
          {activeTab === 2 && (
            <Reveal className="organs">
              {/* <div className="left border" style={{ width: "33%", height: "100px" }}>
                  <CustomDoughnutChart title={"BMI Index"} message={"Here i am."} />
                  {chartList.map((e))}
                </div> */}
              {/* Lines will be dynamically added here */}
              <div className="organs-chart border" id="organ-vital-graph-1">
                {/* {chartList.map((chart, index) => ( */}
                <Reveal className="charts-container" id="random-graph-grid-1"></Reveal>
                <Reveal className="charts-container" id="random-graph-grid-2"></Reveal>
                <Reveal className="charts-container" id="random-graph-grid-3"></Reveal>
                {/* ))} */}
              </div>
              <Xwrapper key={`${selectedSpecialization}`}>
                {listOfArrow.map((arrow, index) => (
                  <div key={index} style={{ position: "relative", zIndex: "20" }}>
                    <Xarrow
                      start={arrow.start}
                      end={arrow.end}
                      path="grid"
                      showHead={false}
                      showTail={false}
                      lineColor="#007390"
                      strokeWidth={1}
                      endAnchor={
                        [
                          "random-graph-grid-1",
                          "random-graph-grid-2",
                          "random-graph-grid-3",
                        ].includes(arrow.end)
                          ? "right"
                          : "left"
                      }
                      monitorDOMchanges={true}
                    />
                  </div>
                ))}
              </Xwrapper>

              <Reveal className="human-organs border" id="human-body-diagram">
                <HumanBody
                  selectedOrgan={selectedOrgan}
                  setSelectedOrgan={setSelectedOrgan}
                  getOrganVitalCharts={getOrganVitalCharts}
                  setSelectedSpecialization={setSelectedSpecialization}
                  selectedSpecialization={selectedSpecialization}
                />
              </Reveal>
              <div
                className="human-organs-details custom-scrollbar border"
                id="organ-vital-graph-2"
              >
                {patientId && (
                  <Accordion
                    sx={{
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                      borderRadius: "10px",
                      width: "100% !important",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel-content`}
                      id={`panel-header`}
                      sx={{
                        borderRadius: "10px",
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#004c70",
                        backgroundColor: "#e6eef1",
                        minHeight: "48px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      {"Patient details"}
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "7px 16px 7px 16px" }}>
                      <PatientCard
                        patientId={patientId}
                        patientData={patientData}
                        viewData={{
                          basicDetails: true,
                          lifeStyle: true,
                          chronicDiseases: true,
                          lastVisited: true,
                        }}
                        handleClick={handleClick}
                      ></PatientCard>
                      <PatientHealthDetails
                        patientVitals={convertPatientVitalsForPatientHealthDetails(
                          vitalLatestValues
                        )}
                        patientId={patientId}
                        handleClick={handleClick}
                        patientAbhaId={patientData?.abhaId}
                        setIframeOpen={setIframeOpen}
                        setIframeSrc={setIframeSrc}
                        setIframeAppointmentId={setIframeAppointmentId}
                      ></PatientHealthDetails>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Reveal className="charts-container" id="random-graph-grid-4"></Reveal>
                <Reveal className="charts-container" id="random-graph-grid-5"></Reveal>
                <Reveal className="charts-container" id="random-graph-grid-6"></Reveal>
              </div>
            </Reveal>
          )}
          {activeTab === 3 && (
            <Reveal style={{ display: "flex", justifyContent: "center" }}>
              <HumanSkeleton></HumanSkeleton>
            </Reveal>
          )}
          {iframeOpen && (
            <div className="iframe-div">
              {iframeSrc ? (
                <iframe
                  title="consent form"
                  src={iframeSrc}
                  target="_blank"
                  className="iframe"
                ></iframe>
              ) : (
                <div className="iframe">
                  <PatientPrescription appId={iframeAppointmentID} />
                </div>
              )}

              <CustomButton
                iconButton={<Close />}
                onClick={() => {
                  setIframeOpen(false);
                  setIframeSrc(null);
                }}
                className="iframe-close-button"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PatientDetails2;
