import React, { useMemo, useState, useEffect } from "react";
import { Box, Input, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import {
  doctorTableColumns,
  orgId,
  accessToken,
  ACTIVE_STATE,
  currentActiveUser,
  SUPER_ADMIN,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useContext } from "react";
import { deleteDoctors, getAllDoctors, getDoctorByOrgId } from "services/doctorService";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";

const Doctors = () => {
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();
  const loggedInUserRole = currentActiveUser()?.roleName;
  const [doctors, setDoctors] = useState([]);
  const organizationId = orgId();
  const accessTokenValue = accessToken();

  const fetchData = useCallback(async () => {
    try {
      const response =
        loggedInUserRole === SUPER_ADMIN
          ? await getAllDoctors()
          : await getDoctorByOrgId(organizationId);
      const doctors =
        loggedInUserRole === SUPER_ADMIN
          ? response.data
          : response.data.filter((doctor) => {
              const targetOrg = doctor?.userResponse?.userOrganizationAssociationList.find(
                (org) => org.organizationId === organizationId && org.isDoctor
              );
              return !!targetOrg;
            });

      const updatedDoctors = doctors.map((doctor) => ({
        ...doctor,
        userResponse: {
          ...doctor.userResponse,
          name: `Dr. ${doctor.userResponse.name}`,
        },
      }));

      setDoctors(updatedDoctors);
    } catch (error) {
      console.error("Error fetching doctors");
    }
  }, [organizationId]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const navigateToManageDoctor = () => {
    navigate("/home/manageDoctor", { state: { doctorId: null } });
  };

  const navigateToManageDoctorAction = (row) => {
    navigate("/home/manageDoctor", { state: { doctorId: row?.id } });
  };

  const handleDelete = async (doctor) => {
    if (doctor.status === ACTIVE_STATE) {
      try {
        await deleteDoctors(doctor.id);
        handleClick("success", "Doctor has been successfully deleted!");
        // fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error deleting doctor!");
      }
    } else {
      handleClick("info", "Doctor is already inactive!");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const filteredRows = useMemo(() => {
    if (!searchTerm) return doctors;

    if (doctors.length > 0) {
      doctors.forEach((element) => {
        element["name"] = element["userResponse"]?.name;
        element["drspecialization"] = element["specialization"]
          ? element["specialization"]?.name
          : null;
      });
      const attributes = Object.keys(doctors[0]);
      const list = [];
      const keyArr = ["name", "biography", "specialization", "qualification"];
      for (const current of doctors) {
        for (const attribute of attributes) {
          if (attribute === "id") {
            continue;
          }
          if (keyArr.includes(attribute)) {
            const value =
              attribute !== "specialization" ? current[attribute] : current["drspecialization"];
            if (value && value.toLowerCase().includes(searchTerm.toLowerCase())) {
              const found = doctors.find((row) => row.id === current.id);
              if (found && !list.includes(found)) {
                list.push(found);
              }
            }
          }
        }
      }
      return list;
    }
    return [];
  }, [searchTerm, doctors]);

  const actions = [
    {
      label: "View and Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: navigateToManageDoctorAction,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <DoctorSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 className="page-title">Doctors</h2>
          <Input
            sx={{ ml: 5, mb: 2 }}
            size="lg"
            placeholder="Search a doctor..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <>
            <CustomButton
              className="ri-add-fill ri-lg btn--primary"
              style={{ marginLeft: "auto" }}
              label="Add Doctor"
              onClick={() => navigateToManageDoctor()}
            />
          </>
        </div>
        <CustomizedTable columns={doctorTableColumns} tableData={filteredRows} actions={actions} />
      </Reveal>
    </DoctorSkeleton>
  );
};

export const DoctorSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Doctors;
