import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  GET_API_METHOD_NAME,
  accessToken,
  baseUrl,
  refreshToken,
  citizenDomainUrl,
  citizenUrl,
  argusDomainUrl,
  argusUrl,
  clinicDomainUrl,
  clinicUrl,
  getSubDomain,
  appEnv,
  loggedInUser,
  currentActiveUser,
  DOCTOR,
  accessTokenForCloud,
} from "store/constant";
import { useLoader } from "../layout/LoaderContext";
import dayjs from "dayjs";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import ModalUI from "ui-component/ModalUI";
import AccessTokenModal from "ui-component/AccessTokenModal";

const getBaseURL = () => {
  const currDomain = window.location.origin;
  switch (currDomain) {
    case argusDomainUrl:
      return `${argusUrl}`;
    case citizenDomainUrl:
      return `${citizenUrl}`;
    case clinicDomainUrl:
      return `${clinicUrl}`;
    default:
      return `${baseUrl}`;
  }
};

const api = axios.create({ baseURL: getBaseURL() });

const AxiosInterceptor = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { showLoader, hideLoader, setLoaderMessage } = useLoader();
  const [pendingApiCalls, setPendingApiCalls] = useState(0);
  const [loaderStartTime, setLoaderStartTime] = useState(null);

  const isRunningOnElectron = appEnv === "electron";

  useEffect(() => {
    if (pendingApiCalls === 0 && loaderStartTime) {
      const elapsed = dayjs().valueOf() - loaderStartTime;
      const remainingTime = Math.max(0, 500 - elapsed);
      setTimeout(() => hideLoader(), remainingTime);
    }
  }, [pendingApiCalls, loaderStartTime]);

  const refreshAccessToken = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${refreshToken()}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${baseUrl}/hc-auth/refresh-token?clientId=hc-web`,
        {},
        {
          headers: headers,
          withCredentials: true,
        }
      );

      const newAccessToken = response.data.access_token;
      localStorage.setItem("accessToken", newAccessToken);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      return newAccessToken;
    } catch (error) {
      return null;
    }
  };

  const reqInterceptor = (config) => {
    setPendingApiCalls((pre) => pre + 1);
    if (config.loaderMessage && config.loaderMessage !== "") {
      setLoaderMessage(config.loaderMessage);
    }
    if (config.method !== GET_API_METHOD_NAME) {
      if (config.showLoader !== false) {
        if (!loaderStartTime) {
          setLoaderStartTime(dayjs().valueOf());
        }
        showLoader();
      }
    }
    const cloudToken = accessTokenForCloud();
    const simpleToken = accessToken();

    if (config.url.includes("/hc-auth")) {
      config.headers["Authorization"] = `Basic ${btoa("hc-web:healthcloud-secret")}`;
    } else if (config.baseURL.includes("hc.argusservices")) {
      if (cloudToken) {
        config.headers["Authorization"] = `Bearer ${cloudToken}`;
      } else if (simpleToken) {
        config.headers["Authorization"] = `Bearer ${simpleToken}`;
      } else {
        config.headers["Authorization"] = `Basic ${btoa("hc-web:healthcloud-secret")}`;
      }
    } else {
      config.headers["Authorization"] = simpleToken
        ? `Bearer ${simpleToken}`
        : `Basic ${btoa("hc-web:healthcloud-secret")}`;
    }

    if (getSubDomain() && !config.disabledTenant) {
      config.headers["Application-Agent"] = getSubDomain();
    }
    return config;
  };

  const reqErrInterceptor = (error) => {
    setPendingApiCalls((pre) => pre - 1);
    return Promise.reject(error.config);
  };

  const resInterceptor = (response) => {
    setPendingApiCalls((pre) => pre - 1);
    return response;
  };

  const resErrInterceptor = async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        setPendingApiCalls((pre) => pre - 1);
        return api(originalRequest);
      } else {
        localStorage.clear();
        navigate("/");
      }
    }

    // NEW: Electron-specific logic for showing the modal on 401
    if (error?.response?.status === 401 && isRunningOnElectron) {
      setShowModal(true); // Open modal if 401 and running in Electron
    }

    const contentType = error.response?.headers["content-type"];
    if (error.response?.status === 502) {
      error.response.data["message"] = "Server under maintenance. Please try again later.";
    } else if (contentType && contentType === "text/html") {
      error.response.data["message"] = "An unexpected error occurred. Please try again later.";
    }

    setPendingApiCalls((pre) => pre - 1);

    return Promise.reject(error);
  };

  useEffect(() => {
    const reqInterceptorEject = api.interceptors.request.use(reqInterceptor, reqErrInterceptor);
    const resInterceptorEject = api.interceptors.response.use(resInterceptor, resErrInterceptor);
    setIsLoaded(true);
    return () => {
      api.interceptors.request.eject(reqInterceptorEject);
      api.interceptors.response.eject(resInterceptorEject);
    };
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
  };
  return (
    <>
      {isLoaded ? children : null}
      <div>
        <ModalUI
          visible={showModal}
          close={handleModalClose}
          title="Access Required!"
          titleStyle={{
            // color: "red",
            fontSize: "25px",
            fontWeight: "bold",
          }}
          style={{
            overflowY: "hidden",
            height: "410px",
            width: "510px",
          }}
          component={<AccessTokenModal close={handleModalClose} />}
        />
      </div>
    </>
  );
};

export { AxiosInterceptor, api };
