// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.dropdown-content.hide {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropdown-content.hide {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/DoctorHomeScreen/patient-prescription-details.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,yBAAA;EACA,+CAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,cAAA;AACF;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown-content {\n  display: none;\n  position: fixed;\n  background-color: #ffffff;\n  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n  z-index: 100;\n  &.hide {\n    display: none;\n  }\n}\n\n.dropdown:hover .dropdown-content {\n  display: block;\n  &.hide {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
