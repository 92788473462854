import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "store/constant";

const initialState = {
  sethuSirLayout: {
    value: false,
    timestamp: dayjs().format(DATE_TIME_FORMAT),
  },
  refreshAppointmentsList: {
    value: false,
    timestamp: dayjs().format(DATE_TIME_FORMAT),
  },
  appointmentSelectedOnFirstLoad: {
    value: false,
    timestamp: dayjs().format(DATE_TIME_FORMAT),
  },
};

const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setSethuSirLayout: (state, action) => {
      state.sethuSirLayout = {
        value: action.payload,
        timestamp: dayjs().format(DATE_TIME_FORMAT),
      };
    },
    setRefreshAppointmntsList: (state, action) => {
      state.refreshAppointmentsList = {
        value: action.payload,
        timestamp: dayjs().format(DATE_TIME_FORMAT),
      };
    },
    setAppointmentSelectedOnFirstLoad: (state, action) => {
      state.appointmentSelectedOnFirstLoad = {
        value: action.payload,
        timestamp: dayjs().format(DATE_TIME_FORMAT),
      };
    },
  },
});

export const { setSethuSirLayout, setRefreshAppointmntsList, setAppointmentSelectedOnFirstLoad } =
  utilSlice.actions;

export const getSethuSirLayout = (state) => state.doctorDashboard.utils.sethuSirLayout;
export const getRefreshAppointmentsList = (state) =>
  state.doctorDashboard.utils.refreshAppointmentsList;
export const getAppointmentSelectedOnFirstLoad = (state) =>
  state.doctorDashboard.utils.appointmentSelectedOnFirstLoad;

export const UtilReducer = utilSlice.reducer;
