import React, { useMemo, useState, useEffect } from "react";
import { Box, Input, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import {
  ACTIVE_STATE,
  DATE_FORMAT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
  accessToken,
  orgId,
  loggedInUser,
} from "store/constant";
import {
  deletePatientData,
  getPatientData,
  getPatientsByDoctorOrOrgId,
} from "services/patientService";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PatientCard from "../Doctor/PatientDTO/PatientCard";
import ModalUI from "ui-component/ModalUI";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { getPrescriptionByIds } from "services/PrescriptionsService";
import { setPatientPastPrescriptions } from "store/Slices/prescriptionPadSlice";

const Patients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = orgId();
        const doctorId = currentActiveUser().roleBasedId;
        const response = await getPatientsByDoctorOrOrgId(organizationId, doctorId);
        setPatients(response.data.content);
      } catch (error) {
        console.error("Fetch patient failed");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  const handleUpdate = (patient) => {
    navigate("/home/managePatient", { state: { patientId: patient.id } });
  };

  const handleMarkInactive = async (patient) => {
    if (patient.status === ACTIVE_STATE) {
      try {
        await deletePatientData(patient.id);
        const response = await getPatientData(patient.id);
        handleClick("success", "Patient has been marked Inactive");
        const index = patients.findIndex((el) => el.id === patient.id);
        patients.splice(index, 1);
        setPatients((prevRows) => [...prevRows, response.data]);
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Patient as Inactive.");
      }
    } else {
      handleClick("error", "Patient marked as Inactive can not be deleted");
    }
  };

  const navigateToManagePatient = () => {
    navigate("/home/managePatient", { state: { patientId: null } });
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredRows = useMemo(() => {
    if (!searchTerm) return patients;

    if (patients.length > 0) {
      patients.forEach((element) => {
        element["name"] = element["content"] ? element["content"].patientName : "N/A";
        element["mobile"] = element["content"] ? element["content"].mobileNumber : "N/A";
      });
      const attributes = Object.keys(patients[0]);
      const list = [];
      const keyArr = ["patientName", "mobileNumber", "medicalHistory", "allergies", "medications"];
      for (const current of patients) {
        for (const attribute of attributes) {
          if (attribute === "id") {
            continue;
          }
          if (keyArr.includes(attribute)) {
            const value = current[attribute];
            if (value && value.toLowerCase().includes(searchTerm.toLowerCase())) {
              const found = patients.find((row) => row.id === current.id);
              if (found && !list.includes(found)) {
                list.push(found);
              }
            }
          }
        }
      }
      return list;
    }
    return [];
  }, [searchTerm, patients]);

  const [isModalOpen, setisModalOpen] = useState(false);

  const handleBookAppointment = (data) => {
    const appointment = {
      patientMobile: data?.userResponse?.mobileNumber || data?.mobileNumber,
      isUhiDoctor: false,
      orgId: orgId(),
      appointmentDate: dayjs().format(DATE_FORMAT),
      appointmentTime: null,
      patientId: data.id,
      doctorId: currentActiveUser().roleBasedId,
      notes: "",
      type: null,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setisModalOpen(true);
  };

  const handleCloseModal = () => {
    dispatch(setSelectedAppointmentData({}));
    setisModalOpen(false);
  };

  const [isViewDisable, setIsViewDisable] = useState(false);

  const actions = [
    {
      label: "Book Appointment",
      icon: <i className="ri-contract-line ri-xl icon-primary-blue" />,
      onClick: handleBookAppointment,
    },
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
    {
      label: "View Prescription",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: () => {
        navigate("/home/patientPrescription/" + patientPrescriptions[0]?.appointmentId, {
          state: {
            isDoctor: true,
            patientName: clickedRow?.patientName,
          },
        });
      },
      checkDisable: isViewDisable,
    },
    {
      label: "View EHR",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: () => {
        navigate(`/home/ehr/${clickedRow?.id}`);
      },
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [patientPrescriptions, setPatientPrescriptions] = useState(null);

  const fetchPatientPrescriptions = async (row) => {
    try {
      const patientPrescriptions = await getPrescriptionByIds(loggedInUser()?.roleBasedId, row?.id);
      let prescriptionsData = patientPrescriptions?.data;

      prescriptionsData = prescriptionsData.sort((a, b) => {
        const dateA = new Date(
          a.appointmentResponse.appointmentDate + " " + a.appointmentResponse.appointmentTime
        );
        const dateB = new Date(
          b.appointmentResponse.appointmentDate + " " + b.appointmentResponse.appointmentTime
        );

        return dateB - dateA;
      });
      setPatientPrescriptions(prescriptionsData);
      dispatch(setPatientPastPrescriptions(prescriptionsData));

      if (prescriptionsData.length > 0) {
        setIsViewDisable(false);
      } else {
        setIsViewDisable(true);
      }
    } catch (error) {
      console.error("Error fetching patient prescriptions");
    }
  };

  const handleMenuOpen = (event, row) => {
    fetchPatientPrescriptions(row);
    setClickedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <PatientSkeleton>
      <div>
        <Reveal style={{ display: "flex", alignItems: "center" }}>
          <h2 className="page-title">My Patients</h2>
          <Input
            sx={{ ml: 5, mb: 2 }}
            size="lg"
            placeholder="Search patient..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Reveal>
        <Grid container className="row mb-0 mx-0 column-patient-details">
          {!filteredRows.length > 0 && (
            <Reveal
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card
                className="custom-card-ui"
                style={{
                  backgroundColor: "white",
                  margin: "5px",
                  width: "300px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                  No Patient Added
                </Typography>
              </Card>
            </Reveal>
          )}
          {filteredRows.length > 0 &&
            filteredRows.map((patient, index) => {
              return (
                <>
                  <Grid size={{ md: 4 }} className="col">
                    <Reveal>
                      <Card
                        key={index}
                        className="custom-card-ui"
                        style={{ backgroundColor: "white", margin: "5px" }}
                      >
                        <PatientCard
                          patientId={patient?.id}
                          patientData={patient}
                          viewData={{
                            basicDetails: true,
                            lifeStyle: false,
                            chronicDiseases: false,
                            lastVisited: false,
                          }}
                          handleClick={handleClick}
                          isPatientData={{
                            isPatientPage: true,
                            anchorEl: anchorEl,
                            clickedRow: clickedRow,
                            handleMenuOpen: handleMenuOpen,
                            handleMenuClose: handleMenuClose,
                            actions: actions,
                          }}
                        ></PatientCard>
                      </Card>
                    </Reveal>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <ModalUI
          visible={isModalOpen}
          close={handleCloseModal}
          title="Book Appointment"
          style={{
            overflowY: "auto",
            height: "550px",
            width: "610px",
          }}
          component={<BookAppointmentModal closeModal={handleCloseModal} />}
        />
      </div>
    </PatientSkeleton>
  );
};

export const PatientSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid container size={{ xs: 12 }} spacing={1}>
          {Array.from({ length: 9 }, (_, index) => index).map((el, index) => (
            <Grid size={{ xs: 4 }} key={index}>
              <Skeleton
                sx={{ borderRadius: "4px" }}
                animation="wave"
                variant="rectangular"
                width="100%"
                height={200}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Patients;
