import { Box, Card, CardContent, CardHeader, Popover, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditSelectField from "ui-component/custom-components/prescription-pad/EditSelectField";
import { useState } from "react";

import "assets/scss/prescriptionPad.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import EditInputField from "ui-component/custom-components/prescription-pad/EditInputField";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  updateAdvices,
  setDiagnosis,
  setLabInvestigations,
  updateMedications,
  setPrescriptionPadData,
  setSymptoms,
  getPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import { useDispatch, useSelector } from "react-redux";
import Reveal from "views/utilities/Reveal";
import { SEVERITY_TYPES, DOSE_TIMINGS } from "store/constant";
import PrescriptionSearchCard from "./PrescriptionSearchCard";
import ResizableEditInputField from "ui-component/custom-components/prescription-pad/ResizableEditInputField";
import ResizableEditSelectField from "ui-component/custom-components/prescription-pad/ResizeableEditSelectField";

/* responsible for creating cards with different category, Dx, Cc, Rx, Lab, Adv */

const PrescriptionCard = ({
  cardHeaderTitle,
  sethuSirLayout,
  values,
  onDelete,
  isEditable,
  isTeleconsultation = false,
  patientView = false,
  hideActions = false,
}) => {
  const currentPrescription = useSelector(getPrescriptionPadData);

  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const handlePopoverOpen = (event) => {
    setCopied(false);
    setOpenPopup(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenPopup(false);
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  // methods to update prescription items
  const handleDiagnosisUpdate = (index, field, value) => {
    dispatch(setDiagnosis({ index, field, value }));
  };

  const handleSymptomUpdate = (index, field, value) => {
    dispatch(setSymptoms({ index, field, value }));
  };
  const handleMedicationUpdate = (index, field, value) => {
    dispatch(updateMedications({ index, field, value }));
  };
  const handleLabInvestigationUpdate = (index, field, value) => {
    dispatch(setLabInvestigations({ index, field, value }));
  };

  const handleAdvicesUpdate = (index, field, value) => {
    dispatch(updateAdvices({ index, field, value }));
  };

  const handleCopyClicked = () => {
    switch (cardHeaderTitle.toLowerCase()) {
      case "cc":
        const updatedSymptomsArr = [...currentPrescription.symptomsArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            symptomsArr: updatedSymptomsArr,
          })
        );
        break;
      case "dx":
        const updatedDiagnosisArr = [...currentPrescription.diagnosisArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            diagnosisArr: updatedDiagnosisArr,
          })
        );
        break;
      case "rx":
        const updatedMedicationsArr = [...currentPrescription.medicationsArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            medicationsArr: updatedMedicationsArr,
          })
        );
        break;
      case "lab":
        const updatedLabInvestigationsArr = [
          ...currentPrescription.labInvestigationsArr,
          ...values,
        ];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            labInvestigationsArr: updatedLabInvestigationsArr,
          })
        );
        break;
      case "adv":
        const updatedAdvicesArr = [...currentPrescription.advicesArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            advicesArr: updatedAdvicesArr,
          })
        );
        break;
      default:
        break;
    }
    setCopied(true);
    setTimeout(() => {
      handlePopoverClose();
    }, 2000);
  };

  return (
    values &&
    values.length > 0 && (
      <Reveal className="prescription-pad-card">
        <Card
          sx={{
            borderRadius: isTeleconsultation ? "6px" : "3px 3px 0 0",
            backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "white",
            width: "100%",
          }}
        >
          {cardHeaderTitle && (
            <CardHeader
              title={cardHeaderTitle}
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.8)"
                  : isEditable
                  ? sethuSirLayout
                    ? "#004c70"
                    : "#c8d5da"
                  : "#D7B975",
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
              }}
              titleTypographyProps={{
                className: `cardHeaderTitle ${sethuSirLayout ? "sethu-sir-layout" : ""}`,
                sx: { fontSize: { md: "13px", lg: "14px", xl: "15px" } },
              }}
              action={
                !isEditable &&
                !hideActions && (
                  <div>
                    <IconButton onClick={handlePopoverOpen}>
                      <i
                        className="ri-more-2-fill"
                        style={{ color: isTeleconsultation ? "white" : "" }}
                      />
                    </IconButton>
                    <Popover
                      open={openPopup}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                    >
                      <Box>
                        <MenuItem onClick={handleCopyClicked}>
                          {copied ? (
                            <DoneAllIcon style={{ color: "green", fontSize: 20 }} />
                          ) : (
                            "Copy"
                          )}
                        </MenuItem>
                      </Box>
                    </Popover>
                  </div>
                )
              }
            ></CardHeader>
          )}
          {cardHeaderTitle === "Dx" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                {values.map((value, index) => (
                  <div
                    key={`${value.diagnosisId}${value.prescriptionId}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="prescription-dx">
                      <div>
                        <PrescriptionSearchCard
                          key={value.diagnosisId}
                          value={value}
                          categoryType="DX"
                          isEditable={isEditable}
                          isTeleconsultation={isTeleconsultation}
                        ></PrescriptionSearchCard>
                      </div>
                      {!(!value.conclusions && patientView) && (
                        <div style={{}}>
                          <ResizableEditInputField
                            name="conclusions"
                            placeHolder="Conclusions"
                            showBrackets={true}
                            initialValue={value.conclusions || ""}
                            index={index}
                            field="conclusions"
                            maxLength={50}
                            isEditable={isEditable}
                            handleUpdate={handleDiagnosisUpdate}
                            isTeleconsultation={isTeleconsultation}
                          />
                        </div>
                      )}
                    </div>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon style={{ width: "18px", height: "18px" }} />}
                        style={{
                          padding: "0px 5px",
                          height: "18px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                    {index !== values.length - 1 && (
                      <span style={{ fontSize: "14px", fontWeight: "700" }}>,&nbsp;&nbsp;</span>
                    )}{" "}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Cc" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {values.map((value, index) => (
                  <div
                    key={`${value.symptomId}${value.prescriptionId}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="prescription-cc">
                      <div>
                        <PrescriptionSearchCard
                          key={value.symptomId}
                          value={value}
                          categoryType="CC"
                          isEditable={isEditable}
                          isTeleconsultation={isTeleconsultation}
                        ></PrescriptionSearchCard>
                      </div>
                      <span style={{ fontStyle: "italic", color: "#616161" }}>
                        &nbsp;&nbsp;since&nbsp;&nbsp;
                      </span>

                      <div style={{}}>
                        <ResizableEditInputField
                          name="since"
                          initialValue={value.since}
                          index={index}
                          field="since"
                          handleUpdate={handleSymptomUpdate}
                          isTeleconsultation={isTeleconsultation}
                          isEditable={isEditable}
                        ></ResizableEditInputField>
                      </div>
                      <div style={{}}>
                        <ResizableEditSelectField
                          name={"severity"}
                          initialValue={value.severity}
                          menuItems={SEVERITY_TYPES.filter((type) => type.menuLabel)}
                          isEditable={isEditable}
                          index={index}
                          showBrackets={true}
                          field="severity"
                          handleUpdate={handleSymptomUpdate}
                          isTeleconsultation={isTeleconsultation}
                        ></ResizableEditSelectField>
                      </div>
                    </div>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon style={{ width: "18px", height: "18px" }} />}
                        style={{
                          padding: "0px 5px",
                          height: "18px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                    {index !== values.length - 1 && (
                      <span style={{ fontSize: "14px", fontWeight: "700" }}>,&nbsp;&nbsp;</span>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Rx" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div style={{}}>
                {values.map((value, index) => (
                  <div
                    key={`${value.medicationId}${value.prescriptionId}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="prescription-rx">
                      <div
                        style={{
                          flex: 1.5,
                        }}
                      >
                        <PrescriptionSearchCard
                          key={value.medicationId}
                          value={value}
                          categoryType="RX"
                          isEditable={isEditable}
                          isTeleconsultation={isTeleconsultation}
                        ></PrescriptionSearchCard>
                      </div>
                      <div style={{ flex: 1, display: "flex", justifyContent: "start" }}>
                        {!(!value.frequency && patientView) && (
                          <div style={{}}>
                            <ResizableEditInputField
                              name="frequency"
                              initialValue={value.frequency || ""}
                              index={index}
                              field="frequency"
                              placeHolder="Frequency"
                              maxLength={15}
                              handleUpdate={handleMedicationUpdate}
                              isEditable={isEditable}
                              isTeleconsultation={isTeleconsultation}
                            />
                          </div>
                        )}
                        <span style={{ fontStyle: "italic", color: "#616161" }}>
                          &nbsp;&nbsp;for&nbsp;&nbsp;
                        </span>
                        {!(value.duration === "0 Day" && patientView) && (
                          <div style={{}}>
                            <ResizableEditInputField
                              name="duration"
                              maxLength={15}
                              placeHolder="Duration"
                              initialValue={value.duration || ""}
                              index={index}
                              field="duration"
                              handleUpdate={handleMedicationUpdate}
                              isEditable={isEditable}
                              isTeleconsultation={isTeleconsultation}
                            />
                          </div>
                        )}
                        <div>
                          <ResizableEditSelectField
                            name={"doseTiming"}
                            menuItems={DOSE_TIMINGS.filter((type) => type.menuLabel)}
                            isEditable={isEditable}
                            initialValue={value.doseTiming}
                            index={index}
                            field="doseTiming"
                            showBrackets={true}
                            handleUpdate={handleMedicationUpdate}
                            isTeleconsultation={isTeleconsultation}
                          />
                        </div>
                        {/* todo: for now hiding instructions as less space */}
                        {/* {!(!value.instructions && patientView) && (
                        <div
                          style={{
                            margin: isTeleconsultation ? "7px" : "",
                          }}
                        >
                          <ResizableEditInputField
                            name="instructions"
                            placeHolder="Instructions"
                            initialValue={value.instructions || ""}
                            index={index}
                            field="instructions"
                            maxLength={100}
                            handleUpdate={handleMedicationUpdate}
                            isEditable={isEditable}
                            isTeleconsultation={isTeleconsultation}
                          />
                        </div>
                      )} */}
                      </div>
                    </div>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon style={{ width: "18px", height: "18px" }} />}
                        style={{
                          padding: "0px 5px",
                          height: "18px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Lab" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div>
                {values.map((value, index) => (
                  <div
                    key={`${value.labTestId}${value.prescriptionId}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="prescription-lab">
                      <div>
                        <PrescriptionSearchCard
                          key={value.labTestId}
                          value={value}
                          categoryType="LAB"
                          isEditable={isEditable}
                          isTeleconsultation={isTeleconsultation}
                        ></PrescriptionSearchCard>
                      </div>
                      {!(!value.instructions && patientView) && (
                        <div style={{}}>
                          <ResizableEditInputField
                            name="test-instructions"
                            placeHolder="Instructions"
                            initialValue={value.instructions || ""}
                            index={index}
                            field="instructions"
                            handleUpdate={handleLabInvestigationUpdate}
                            isEditable={isEditable}
                            maxLength={50}
                            showBrackets={true}
                            isTeleconsultation={isTeleconsultation}
                          />
                        </div>
                      )}
                    </div>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon style={{ width: "18px", height: "18px" }} />}
                        style={{
                          padding: "0px 5px",
                          height: "18px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Adv" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div>
                {values
                  .filter((value) => value.displayName.trim() !== "")
                  .map((value, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ResizableEditInputField
                        name="advices"
                        maxLength={100}
                        isEditable={isEditable}
                        placeHolder="Advices"
                        initialValue={value.displayName}
                        index={index}
                        field="advices"
                        handleUpdate={handleAdvicesUpdate}
                        isTeleconsultation={isTeleconsultation}
                      />

                      {isEditable && (
                        <CustomButton
                          iconButton={<CloseIcon style={{ width: "18px", height: "18px" }} />}
                          style={{
                            padding: "0px 5px",
                            height: "18px",
                            marginLeft: "auto",
                          }}
                          textAndIconColor="red"
                          onClick={() => {
                            onDelete(index);
                          }}
                        ></CustomButton>
                      )}
                    </div>
                  ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "SOAP" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // gap: "0.3rem",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {values &&
                  values.length > 0 &&
                  values.map((value, valueIndex) =>
                    Object.keys(value).map(
                      (val, valIndex) =>
                        val !== "oldPrescription" && (
                          <div
                            key={`${valueIndex}  ${valIndex}`}
                            style={{
                              // borderTop: valIndex =  == 0 ? "none" : "0.5px solid black",
                              padding: "0.5rem",
                              width: "50%",
                              boxSizing: "border-box",
                            }}
                          >
                            <Box
                              component={"span"}
                              sx={{
                                fontSize: { md: "13px", lg: "14px", xl: "15px" },
                                textTransform: "capitalize",
                              }}
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldNameSoapHeading"
                              }
                            >
                              {val}
                            </Box>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "10px",
                                padding: 0,
                                marginBottom: "5px",
                                width: "100%",
                              }}
                            >
                              {
                                <ul
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value[val]?.map((v, vIndex) => (
                                    <Box
                                      component="li"
                                      key={`${valueIndex} ${valIndex} ${vIndex}`}
                                      sx={{
                                        padding: "0.5rem",
                                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                      }}
                                    >
                                      {v}
                                    </Box>
                                  ))}
                                </ul>
                              }
                            </div>
                          </div>
                        )
                    )
                  )}
              </div>
            </CardContent>
          )}
        </Card>
        <br></br>
      </Reveal>
    )
  );
};

export default PrescriptionCard;
