import Grid from "@mui/material/Grid2";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";
import TemplateCard from "./TemplateCard";
import {
  createTemplates,
  getTemplatesByDocId,
  UpdateTemplate,
} from "services/PrescriptionsService";
import { ACTIVE_STATE, currentActiveUser, DATE_FORMAT_DMY } from "store/constant";
import PrescriptionCard from "./prescriptionCard";
import PrescriptionSearch from "./prescriptionSearch";
import { useSelector } from "react-redux";
import {
  getPrescriptionPadData,
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import { useDispatch } from "react-redux";
import ModalUI from "ui-component/ModalUI";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import dayjs from "dayjs";
import CustomBackButton from "ui-component/custom-components/CustomBackButton";

const Template = () => {
  const [addTemplate, setAddTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({
    templateName: "",
    templateDescription: "",
  });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { handleClick } = useContext(ToastContext);

  const currentPrescription = useSelector(getPrescriptionPadData);

  const getTemplates = async () => {
    try {
      const response = await getTemplatesByDocId(currentActiveUser()?.roleBasedId);
      setTemplates(response?.data);
    } catch {}
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const [searchItem, setSearchItem] = useState("");
  const filteredRows = useMemo(() => {
    if (!searchItem) return templates;

    const searchTermLower = searchItem.toLowerCase();
    const filteredEntries = templates.filter((entry) => {
      const keysToSearch = ["templateName"];
      return keysToSearch.some((key) => entry[key]?.toLowerCase().includes(searchTermLower));
    });

    return filteredEntries;
  }, [searchItem, templates]);

  useEffect(() => {
    if (selectedTemplate && !isCopied) {
      setTemplateInfo({
        templateName: selectedTemplate.templateName || "",
        templateDescription: selectedTemplate.description || "",
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {}, [isCopied]);

  const handleDelete = useCallback((id) => {
    setTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== id));
  }, []);

  const handleQuickCreate = (data) => {
    setIsOpen(true);
    setSelectedTemplate(data);
    setIsCopied(data);
  };

  const handleEdit = useCallback((data) => {
    setIsOpen(true);
    setSelectedTemplate(data);
  }, []);

  const handleRemoveMedication = (index) => {
    const updatedMedicationsArr = [...currentPrescription.medicationsArr];
    updatedMedicationsArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        medicationsArr: updatedMedicationsArr,
      })
    );
  };

  const handleRemoveSymptom = (index) => {
    const updatedSymptomsArr = [...currentPrescription.symptomsArr];
    updatedSymptomsArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        symptomsArr: updatedSymptomsArr,
      })
    );
  };

  const handleRemoveDiagnosis = (index) => {
    const updatedDiagnosisArr = [...currentPrescription.diagnosisArr];
    updatedDiagnosisArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        diagnosisArr: updatedDiagnosisArr,
      })
    );
  };

  const handleRemoveLabInvestigations = (index) => {
    const updatedLabInvestigations = [...currentPrescription.labInvestigationsArr];
    updatedLabInvestigations.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        labInvestigationsArr: updatedLabInvestigations,
      })
    );
  };

  const handleRemoveAdvices = (index, key) => {
    const updatedAdvicesArr = [...currentPrescription.advicesArr];
    updatedAdvicesArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        advicesArr: updatedAdvicesArr,
      })
    );
  };

  const handleNewTemplate = () => {
    setAddTemplate(true);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: [],
        symptomsArr: [],
        medicationsArr: [],
        labInvestigationsArr: [],
        advicesArr: [],
        soapNotes: {},
      })
    );
    dispatch(setPrescriptionId(null));
  };

  useEffect(() => {
    const shouldShowSaveButton =
      (selectedTemplate?.templateName && selectedTemplate.description) ||
      templateInfo.templateName.trim() !== "";

    setShowSaveButton(shouldShowSaveButton);
  }, [templateInfo, selectedTemplate]);

  const handleTemplateInfoChange = (event) => {
    setTemplateInfo({
      ...templateInfo,
      [event.target.name]: event.target.value,
    });
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleSaveTemplate = async () => {
    try {
      const templateObject = {
        diagnosis: currentPrescription.diagnosisArr,
        symptoms: currentPrescription.symptomsArr,
        medications: currentPrescription.medicationsArr,
        labInvestigations: currentPrescription.labInvestigationsArr,
        advices: currentPrescription.advicesArr,
      };
      const payload = {
        templateName: templateInfo.templateName.trim(),
        templateContent: JSON.stringify(templateObject),
        description: templateInfo.templateDescription.trim(),
        status: ACTIVE_STATE,
        version: "1.0",
        doctorId: currentActiveUser()?.roleBasedId,
      };
      if (!checkIfValueIsPresent(templateObject)) {
        handleClick("error", "Template should not be empty.");
      } else if (selectedTemplate?.id && !isCopied) {
        await UpdateTemplate(selectedTemplate?.id, payload);
        handleClick("success", "Template updated successfully!");
      } else {
        if (
          templates.find(
            (template) =>
              template.templateName.trim().toLowerCase() ===
              templateInfo.templateName.trim().toLowerCase()
          )
        ) {
          handleClick("error", "Template already exists with same name.");
          return;
        } else {
          await createTemplates(payload);
          handleClick("success", "Template saved successfully!");
        }
      }
      setTemplateInfo({ templateName: "", templateDescription: "" });
      getTemplates();
      closeModal();
      // to get the list of templates after saving
      setIsOpen(false);
      setIsCopied(false);
    } catch (error) {
      handleClick("error", "There seems to be an error saving the template");
    }
    setAddTemplate(false);
  };

  const handleCancelData = () => {
    setAddTemplate(false);
    setIsOpen(false);
    setIsCopied(false);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: [],
        symptomsArr: [],
        medicationsArr: [],
        labInvestigationsArr: [],
        advicesArr: [],
        soapNotes: {},
      })
    );
    dispatch(setPrescriptionId(null));
  };

  const checkIfValueIsPresent = (obj) => {
    return Object.values(obj).some((res) => res != null && res?.length > 0);
  };

  return (
    <div>
      {!isOpen && (
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomBackButton />
              <h6 className="page-title">Templates</h6>
            </div>
          </div>
          <div className="col">
            {!addTemplate && (
              <CustomButton
                className="ri-add-fill ri-lg btn--secondary"
                style={{ marginLeft: "10px" }}
                onClick={() => handleNewTemplate()}
                label="New Template"
              />
            )}
            {addTemplate && (
              <CustomButton
                className="ri-save-line ri-lg btn--primary"
                style={{ marginLeft: "10px" }}
                onClick={() => setModalOpen(true)}
                label="Save Template"
              />
            )}
            {addTemplate && (
              <CustomButton
                className="ri-indeterminate-circle-line ri-lg btn--secondary"
                style={{ marginLeft: "10px" }}
                onClick={() => handleCancelData()}
                label="Cancel"
              />
            )}
          </div>
        </Reveal>
      )}

      {isOpen && (
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <div
              className="hover"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // border: `1px solid red`,
                backgroundColor: "lightGrey",
              }}
              onClick={() => {
                setIsOpen(false);
                setIsCopied(false);
              }}
            >
              <i
                style={{ color: "#004C70", zIndex: 1000 }}
                className="ri-arrow-drop-left-line ri-2x"
              />
            </div>
            <h6 className="page-title">{selectedTemplate.templateName}</h6>
          </div>
          <div className="col">
            {!isCopied && (
              <CustomButton
                className="ri-edit-line ri-xl btn--secondary"
                style={{ marginLeft: "10px" }}
                onClick={() => setModalOpen(true)}
                label="Save Changes"
              />
            )}
            {isCopied && (
              <CustomButton
                className="ri-file-line ri-xl btn--primary"
                style={{ marginLeft: "10px" }}
                onClick={() => setModalOpen(true)}
                label="Quick Create"
              />
            )}
          </div>
        </Reveal>
      )}

      <Grid container columnSpacing={1}>
        {!isOpen && !addTemplate && (
          <Grid size={{ md: 4, xs: 4 }}>
            <Reveal>
              {templates.length > 0 ? (
                <>
                  <div style={{ marginTop: "20px", marginRight: "20px", marginBottom: "20px" }}>
                    <FormInputField
                      style={{ width: "100%" }}
                      label={"Search"}
                      value={searchItem}
                      startAdornment={<i className="ri-search-line ri-lg" />}
                      onChange={(e) => {
                        setSearchItem(e.target.value);
                      }}
                      size="small"
                    ></FormInputField>
                  </div>
                </>
              ) : (
                <p style={{ color: "gray", marginTop: "20px" }}>No available templates</p>
              )}
              {/* this is tatci data and when we search the template it searches with single letters */}
              {/* so hiding this one */}
              {/* <p style={{ color: "lightGray", marginTop: "20px" }}>Recently Searched</p> */}
              {/* <div style={{ height: "100%", width: "90%", paddingBottom: "10px" }}>
                <p>Fever</p>
                <hr
                  style={{
                    backgroundColor: "lightgray",
                    border: "none",
                    height: "1px",
                  }}
                ></hr>
                <p>Headache</p>
                <hr
                  style={{
                    backgroundColor: "lightgray",
                    border: "none",
                    height: "1px",
                  }}
                ></hr>
                <p>Diarrhea</p>
                <hr
                  style={{
                    backgroundColor: "#888888",
                    border: "none",
                    height: "1px",
                  }}
                ></hr>
              </div> */}
            </Reveal>
          </Grid>
        )}
        {isOpen && (
          <Grid size={{ md: 4, xs: 12 }} style={{ backgroundColor: "white" }}>
            <Reveal>
              <div style={{ margin: "15px" }}>
                <p style={{ color: "lightGray", fontWeight: "500" }}>About Template</p>
                <div style={{ display: "flex" }}></div>
                <div className="cst-template-div">
                  <p className="cst-template-timing">Created On</p>
                  <p>{selectedTemplate.createdOn}</p>
                </div>
                <div className="cst-template-div">
                  <p className="cst-template-timing">Modified On</p>
                  <p>{selectedTemplate.modifiedOn}</p>
                </div>
                <div className="cst-template-div">
                  <p className="cst-template-timing">Created By</p>
                  <p>Dr. {currentActiveUser()?.name}</p>
                </div>
              </div>
            </Reveal>
          </Grid>
        )}
        {!isOpen && !addTemplate && !isCopied && (
          <Grid size={{ md: 12, xs: 12 }} style={{ display: "flex", flexDirection: "column" }}>
            {filteredRows.map((entry, index) => (
              <Reveal key={index} style={{ display: "flex", flexDirection: "column" }}>
                <TemplateCard
                  data={{
                    ...entry,
                    createdOn: dayjs(entry.createdOn).format(DATE_FORMAT_DMY),
                    modifiedOn: dayjs(entry.modifiedOn).format(DATE_FORMAT_DMY),
                  }}
                  onQuickCreate={handleQuickCreate}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </Reveal>
            ))}
          </Grid>
        )}
        {(isOpen || addTemplate || isCopied) && (
          <Grid
            size={{ md: addTemplate ? 12 : 8, xs: 12 }}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Reveal style={{ display: "flex", flexDirection: "column", gap: "1vw" }}>
              <PrescriptionSearch />
              <PrescriptionCard
                key="Cc"
                cardHeaderTitle="Cc"
                values={currentPrescription.symptomsArr}
                onDelete={handleRemoveSymptom}
                isEditable={true}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Dx"
                cardHeaderTitle="Dx"
                values={currentPrescription.diagnosisArr}
                onDelete={handleRemoveDiagnosis}
                isEditable={true}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Rx"
                cardHeaderTitle="Rx"
                cardContentField="Medicines"
                values={currentPrescription.medicationsArr}
                onDelete={handleRemoveMedication}
                isEditable={true}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Lab"
                cardHeaderTitle="Lab"
                values={currentPrescription.labInvestigationsArr}
                onDelete={handleRemoveLabInvestigations}
                isEditable={true}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Adv"
                cardHeaderTitle="Adv"
                values={currentPrescription.advicesArr}
                onDelete={handleRemoveAdvices}
                isEditable={true}
              ></PrescriptionCard>
            </Reveal>
          </Grid>
        )}
      </Grid>
      <ModalUI
        visible={modalOpen}
        close={closeModal}
        title={"Save Prescription as Template"}
        component={
          <>
            <div style={{ width: "500px" }}>
              <FormInputField
                style={{ width: "60%", marginTop: "20px" }}
                label={"Template Name *"}
                name={"templateName"}
                inputProps={{ maxLength: 30 }}
                value={templateInfo.templateName}
                onChange={handleTemplateInfoChange}
                startAdornment={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>}
              ></FormInputField>
              <FormInputField
                style={{ width: "100%", marginTop: "20px" }}
                label="Template Description"
                name="templateDescription"
                inputProps={{ maxLength: 100 }}
                value={templateInfo.templateDescription}
                onChange={handleTemplateInfoChange}
                startAdornment={<DescriptionIcon></DescriptionIcon>}
              ></FormInputField>
              <div style={{ marginTop: "20px", display: "flex" }}>
                <CustomButton
                  label="Save"
                  height="36px"
                  className="mui-btn--primary"
                  style={{ marginRight: "30px" }}
                  onClick={handleSaveTemplate}
                  disabled={!showSaveButton}
                ></CustomButton>
                <CustomButton
                  height="36px"
                  label="Cancel"
                  className="btn--secondary-light"
                  onClick={() => {
                    setTemplateInfo({
                      templateName: "",
                      templateDescription: "",
                    });
                    closeModal();
                  }}
                ></CustomButton>
              </div>
            </div>
          </>
        }
      ></ModalUI>
    </div>
  );
};

export default Template;
