import { useState } from "react";
import CustomDropZone from "ui-component/custom-components/CustomDropZone";
import {
  deletePatientDocumentByDocumentId,
  generateVitalsFromDocuments,
  getPatientDocumentByPatientId,
  uploadPatientDocuments,
} from "services/patientService";
import { Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import {
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
  fileTypes,
  loggedInUser,
  getUUID,
} from "store/constant";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useCallback } from "react";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import Reveal from "views/utilities/Reveal";
import LabReportVitalModal from "./PatientProfile/LabReportVitalModal";
import CustomTableNew from "ui-component/custom-components/custom-table/CustomTableNew";

const PatientRecords = () => {
  const { handleClick } = useContext(ToastContext);
  const patientId = currentActiveUser()?.roleBasedId;
  const [patientDocuments, setPatientDocuments] = useState([]);
  const [open, setOpen] = useState(false);
  const [extractedVitals, setExtractedVitals] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getPatientDocumentByPatientId(patientId);
      const filesWithContent = response.data?.patientDocumentResponses.map((res) => ({
        file: res.documentResponse,
        content: `data:${getFullDocType(res.documentResponse?.extension)};base64,${
          res.documentResponse?.document
        }`,
      }));
      setPatientDocuments(filesWithContent);
    } catch (error) {
      console.error("error fetching documents");
    }
  }, [patientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openModal = (data) => {
    setSelected(data);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelected([]);
  };

  const handleViewDocument = async (document) => {
    // converting base64 string file into blob to view
    const response = await axios({
      url: document.content,
      method: "get",
      responseType: "blob",
    });
    const fileURL = window.URL.createObjectURL(response.data);
    window.open(fileURL, "_blank");
  };

  const handleDownloadDocument = async (documentToDownload) => {
    try {
      const response = await axios({
        url: documentToDownload.content,
        method: "GET",
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", documentToDownload?.file.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleClick("error", "There seems to be an error downloading document");
    }
  };

  const handleDeleteDocument = async (document) => {
    try {
      await deletePatientDocumentByDocumentId(document?.file?.id);
      handleClick("success", "File deleted successfully");
    } catch (error) {
      handleClick("error", "There seems to be an error deleting document");
    }
    fetchData();
    closeModal();
  };

  const columns = [
    { field: "#", label: "#", align: "center", width: "15%", isSerial: true },
    { field: "file.filename", label: "File Name", width: "85%" },
  ];

  const actions = [
    {
      label: "View",
      icon: <VisibilityIcon style={{ color: "#004C70" }} />,
      onClick: handleViewDocument,
    },
    {
      label: "Download",
      icon: <DownloadIcon style={{ color: "#004C70" }} />,
      onClick: handleDownloadDocument,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: openModal,
    },
  ];

  const getFullDocType = (value) => {
    for (const [key, val] of Object.entries(fileTypes)) {
      if (val === value) {
        return key;
      }
    }
    return null;
  };

  const handleFileUpload = async (filesToUpload) => {
    const metadata = [];

    for (let i = 0; i < filesToUpload.length; i++) {
      metadata.push({
        id: getUUID(),
        patientId: patientId,
        type: "PRESCRIPTION",
        issueDate: "2024-06-01",
        notes: `Lab Report ${i}`,
        status: "ACTIVE",
      });
    }

    const formData = new FormData();
    filesToUpload.forEach((file, index) => {
      formData.append("files", file);
    });
    formData.append("metadata", JSON.stringify(metadata));

    const formDataForVitalExtraction = new FormData();
    filesToUpload.forEach((file) => {
      formDataForVitalExtraction.append("files", file);
    });
    try {
      const currentUserId = loggedInUser().roleBasedId;
      let extractedVitals = await generateVitalsFromDocuments(
        currentUserId,
        formDataForVitalExtraction
      );
      if (extractedVitals?.data?.length) {
        setExtractedVitals(extractedVitals?.data);
        setisModalOpen(true);
      }
      await uploadPatientDocuments(formData);
      handleClick("success", "File uploaded successfully");
      fetchData();
    } catch (error) {
      handleClick("error", "There seems to be an error uploading documents");
    }
  };

  const handleCloseVitalModal = () => {
    setisModalOpen(false);
  };

  return (
    <PatientRecordsSkeleton>
      <ModalUI
        style={{ width: "80%", overflowY: "auto", maxHeight: "80%" }}
        visible={isModalOpen}
        close={handleCloseVitalModal}
        component={
          <LabReportVitalModal
            vitals={extractedVitals}
            closeModal={handleCloseVitalModal}
            setVitals={setExtractedVitals}
          ></LabReportVitalModal>
        }
        title={"Vitals"}
      ></ModalUI>
      <Reveal>
        <div className="section-heading">
          <h2 className="page-title">Patient Records</h2>
        </div>
        <CustomDropZone
          acceptedFileTypes={["application/pdf", "image/png", "image/jpeg"]}
          maxSizeMB={10}
          handleFileUpload={handleFileUpload}
        ></CustomDropZone>
        <br></br>
        <CustomTableNew
          columns={columns}
          tableData={patientDocuments}
          actions={actions}
          paginationProps={{ rowsPerPage: 10 }}
        ></CustomTableNew>
      </Reveal>
      <ModalUI
        visible={open}
        close={closeModal}
        title="Are you sure you want to delete this file?"
        component={
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              justifyContent: "center",
            }}
          >
            <CustomButton
              className="btn--secondary"
              onClick={() => {
                handleDeleteDocument(selected);
              }}
              label="Yes"
              style={{ marginRight: "30px" }}
            />
            <CustomButton className="btn--primary" onClick={() => closeModal()} label="No" />
          </div>
        }
      />
    </PatientRecordsSkeleton>
  );
};

const PatientRecordsSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "12px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={150}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={200}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientRecords;
