import { IconButton, List, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LabReportVitalCard from "./LabReportVitalCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/system";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { useState } from "react";
import dayjs from "dayjs";
import ModalUI from "ui-component/ModalUI";
import PatientVitalSignModal from "./PatientVitalSignModal";
import { DATE_FORMAT_DMY, DATE_TIME_FORMAT, loggedInUser } from "store/constant";
import { useEffect } from "react";

const LabReportVitalContainer = ({
  reportDate,
  vitals,
  handleCheckBoxOnChange,
  delimeter,
  handleUpdate,
  vitalSignTypes,
  allReportDates,
  setVitals,
  setContainerErrors,
}) => {
  const [unMappedVitals, setUnMappedVitals] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [dateOfReport, setDateOfReport] = useState(reportDate);
  const [isAddVitalModalOpen, setIsAddVitalModalOpen] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setContainerErrors((prevErrors) => {
        return { ...prevErrors, [dateOfReport]: true };
      });
    } else {
      setContainerErrors((prevErrors) => {
        const { [dateOfReport]: currentDateOfReport, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  }, [errors, dateOfReport, setContainerErrors]);

  useEffect(() => {
    const unMappedVitals = vitalSignTypes.filter((vitalSignType) => {
      return !vitals.map((vital) => vital?.vitalSignTypeId).includes(vitalSignType?.id);
    });
    setUnMappedVitals(unMappedVitals);
  }, [vitalSignTypes, vitals]);

  const handleDoubleClick = () => {
    setReadOnly(false);
  };

  const handleAddVitalIconClick = () => {
    setIsAddVitalModalOpen(true);
  };

  const closeAddVitalModal = () => {
    setIsAddVitalModalOpen(false);
  };

  const handleChange = (event) => {
    const selectedDate = dayjs(event.$d).format(DATE_TIME_FORMAT);
    setDateOfReport(event);
    setVitals((prevVitals) => {
      const reportDateInDateTimeFormat = dayjs(dateOfReport, DATE_FORMAT_DMY).format(
        DATE_TIME_FORMAT
      );
      prevVitals.forEach((prevVital) => {
        if (
          prevVital?.dateFrom === reportDateInDateTimeFormat &&
          prevVital?.dateTo === reportDateInDateTimeFormat
        ) {
          prevVital.dateFrom = selectedDate;
          prevVital.dateTo = selectedDate;
        }
      });
      return [...prevVitals];
    });
    setReadOnly(true);
  };

  const handleAddVital = (vitalSignType, readingValue) => {
    const reportDateInDateTimeFormat = dayjs(dateOfReport, DATE_FORMAT_DMY).format(
      DATE_TIME_FORMAT
    );
    const newVital = {
      dateFrom: reportDateInDateTimeFormat,
      dateTo: reportDateInDateTimeFormat,
      patientId: loggedInUser().roleBasedId,
      sourceType: loggedInUser().roleName,
      unit: vitalSignType?.unit,
      value: readingValue,
      vitalSignTypeId: vitalSignType?.id,
      vitalSignTypeResponse: vitalSignType,
    };
    setVitals((prevVitals) => [...prevVitals, newVital]);
    setIsAddVitalModalOpen(false);
  };

  const getDisabledDate = (date) => {
    const date_in_dmy_format = dayjs(date.$d).format(DATE_FORMAT_DMY);
    return allReportDates.includes(date_in_dmy_format) && reportDate !== date_in_dmy_format;
  };

  return (
    <Box
      sx={{ p: "1rem", mb: "1rem" }}
      children={
        <Grid sx={{ width: "100%" }}>
          <Box display={"flex"} justifyContent={"space-between"} marginRight={"1rem"}>
            <Typography variant="h4" color="rgb(73, 67, 67)" display={"flex"} alignItems={"center"}>
              Report Date :
              <Box onDoubleClick={handleDoubleClick}>
                <FormDatePicker
                  disableTextField={true}
                  disabledDates={getDisabledDate}
                  disableFuture={true}
                  format={DATE_FORMAT_DMY}
                  value={dayjs(dateOfReport, DATE_FORMAT_DMY)}
                  size={"small"}
                  style={{
                    width: "50%",
                  }}
                  readOnly={readOnly}
                  iconAtEnd={true}
                  background={readOnly ? "transparent" : "#e3e3e3"}
                  disableBorder={true}
                  onChange={handleChange}
                ></FormDatePicker>
              </Box>
            </Typography>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
              {Object.keys(errors)?.length > 0 && (
                <Typography variant="h6" color="#f44336" display={"flex"} alignItems={"center"}>
                  Please enter valid number
                </Typography>
              )}
              {unMappedVitals?.length > 0 && (
                <IconButton aria-label="delete" size="small" onClick={handleAddVitalIconClick}>
                  <AddCircleOutlineIcon fontSize="medium" color="success" />
                </IconButton>
              )}
            </Box>
          </Box>
          <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Grid container spacing={2}>
              {vitals.map((vital, index) => {
                return (
                  <LabReportVitalCard
                    vital={vital}
                    handleCheckBoxOnChange={handleCheckBoxOnChange}
                    delimeter={delimeter}
                    handleUpdate={handleUpdate}
                    setContainerErrors={setErrors}
                    key={index}
                  ></LabReportVitalCard>
                );
              })}
            </Grid>
          </List>
          <ModalUI
            visible={isAddVitalModalOpen}
            close={closeAddVitalModal}
            title={"Add Vital"}
            component={
              <PatientVitalSignModal
                initialValue={{ dateFrom: dateOfReport }}
                vitalSignTypes={unMappedVitals}
                dateAdministeredReadOnly={true}
                successButtonLabel={"Add"}
                successButtonOnClick={handleAddVital}
                close={closeAddVitalModal}
              />
            }
          />
        </Grid>
      }
    />
  );
};

export default LabReportVitalContainer;
