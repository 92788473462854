import { useEffect, useState } from "react";
import LabReportVitalContainer from "./LabReportVitalContainer";
import Grid from "@mui/material/Grid2";
import CustomButton from "ui-component/custom-components/CustomButton";
import { createMultiplePatientVital } from "services/patientService";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { getLabReportVitalSignTypes } from "services/EntitiesServices";
import dayjs from "dayjs";
import { DATE_FORMAT_DMY, DATE_TIME_FORMAT } from "store/constant";

const LabReportVitalModal = ({ vitals, closeModal, setVitals }) => {
  const [vitalsAccordingToReportDate, setVitalsAccordingToReportDate] = useState({});
  const [checkedVitals, setCheckedVitals] = useState([...vitals]);
  const delimeter = "#";
  const [vitalSignTypes, setVitalSignTypes] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const [allReportDates, setAllReportDates] = useState([]);
  const [containerErrors, setContainerErrors] = useState({});

  useEffect(() => {
    const fetchVitalSignTypes = async () => {
      try {
        const allLabReportVitals = (await getLabReportVitalSignTypes()).data;
        setVitalSignTypes(allLabReportVitals);
      } catch (error) {
        handleClick("error", "Error fetching Vital sign types");
      }
    };
    fetchVitalSignTypes();
  }, []);

  useEffect(() => {
    const vitalsAccordingToReportDate = {};
    setAllReportDates([]);
    for (let vital of vitals) {
      let currentDate = dayjs(vital?.dateFrom, DATE_TIME_FORMAT).format(DATE_FORMAT_DMY);
      if (!(currentDate in vitalsAccordingToReportDate)) {
        vitalsAccordingToReportDate[currentDate] = [];
      }
      vitalsAccordingToReportDate[currentDate].push(vital);
    }
    setVitalsAccordingToReportDate(vitalsAccordingToReportDate);
    setAllReportDates(Object.keys(vitalsAccordingToReportDate));
  }, [vitals]);

  const handleUpdate = (vital, vitalValue) => {
    const changedVital = vitals.find(
      (currentVital) =>
        currentVital.dateFrom === vital.dateFrom &&
        currentVital.vitalSignTypeId === vital.vitalSignTypeId
    );
    changedVital.value = vitalValue;
  };

  const handleCheckBoxOnChange = (event) => {
    const [reportDate, currentVitalSignTypeId] = event.target.value.split(delimeter);
    if (event.target.checked) {
      const checkedVital = vitals.find(
        ({ dateFrom, vitalSignTypeId }) =>
          dateFrom === reportDate && vitalSignTypeId === currentVitalSignTypeId
      );
      setCheckedVitals([...checkedVitals, checkedVital]);
    } else {
      let filteredVitals = checkedVitals.filter(
        ({ dateFrom, vitalSignTypeId }) =>
          dateFrom !== reportDate || vitalSignTypeId !== currentVitalSignTypeId
      );
      setCheckedVitals(filteredVitals);
    }
  };

  const handleSaveVitals = async () => {
    try {
      await createMultiplePatientVital(checkedVitals);
      handleClick("success", "Patient Vital Details saved Successfully");
    } catch (error) {
      handleClick("error", "Error saving Patient Vitals");
    } finally {
      closeModal();
    }
  };

  return (
    <Grid style={{ padding: "1rem", width: "100%" }}>
      {Object.keys(vitalsAccordingToReportDate).map((reportDate, index) => {
        return (
          <LabReportVitalContainer
            reportDate={reportDate}
            vitals={vitalsAccordingToReportDate[reportDate]}
            handleCheckBoxOnChange={handleCheckBoxOnChange}
            key={index}
            delimeter={delimeter}
            handleUpdate={handleUpdate}
            vitalSignTypes={vitalSignTypes}
            setVitals={setVitals}
            allReportDates={allReportDates}
            setContainerErrors={setContainerErrors}
          ></LabReportVitalContainer>
        );
      })}
      <Grid className="utility-buttons" display={"flex"} justifyContent={"flex-end"} gap={2}>
        <CustomButton
          className={"btn--error"}
          label={"Cancel"}
          onClick={() => {
            closeModal();
          }}
        />
        <CustomButton
          className={"btn--secondary"}
          label={"Save"}
          onClick={() => {
            handleSaveVitals();
          }}
          disabled={Object.keys(containerErrors).length > 0}
        />
      </Grid>
    </Grid>
  );
};

export default LabReportVitalModal;
