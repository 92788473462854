import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Skeleton,
  Grid,
  StepContent,
  StepLabel,
  Step,
  Stepper,
  Typography,
  Tooltip,
} from "@mui/material";
import { profileDashboard } from "menu-items/dashboard";
import { ThemeProvider } from "@mui/system";
import themes from "themes";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import { Outlet, useLocation, useNavigate } from "react-router";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  DAY_GRID_CALENDAR,
  SCHEDULED,
  currentActiveUser,
  RESCHEDULED,
  TELE_CONSULTATION,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  DATE_FORMAT,
  TIME_FORMAT,
  CHECKED_IN,
  COMPLETED,
  PRESCRIPTION_DONE,
  DOCTOR,
  HOUR_MINUTE_FORMAT,
  NO_SHOW,
  DATE_FORMAT_DMY,
  PATIENT,
} from "store/constant";
import {
  doSpecializationAndExperienceExist,
  doesAvailabilityExist,
  doesProductServicesExist,
} from "services/doctorService";
import CustomButton from "ui-component/custom-components/CustomButton";
import "assets/scss/doctorAppointments.scss";
import "assets/scss/DoctorHomeScreen/doctors-appointment-new-v2.scss";
import "assets/scss/DoctorHomeScreen/patient-prescription-details.scss";
import ModalUI from "ui-component/ModalUI";
import {
  CancelOutlined,
  Close,
  Build,
  Stars,
  EventAvailable,
  CorporateFareOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { getDoctorAvailability, getDoctorByUserId } from "services/doctorService";
import {
  updateAppointmentStatus,
  getAppointmentById,
  getFilteredAppointments,
} from "services/Appointments";
import { linkUserWithOrganization } from "services/userService";
import {
  getFrontDeskUserLinking,
  getUserFeatures,
  setFrontDeskUserLinking,
  setUserOrgId,
} from "store/Slices/userSlice";
import { getBlockAvailabilities } from "services/Availability";
import { useContext } from "react";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import { useSelector } from "react-redux";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import {
  getAppointmentEvents,
  handleMonthViewMoreClick,
  updateFilteredAppointment,
} from "utils/appointment-utils";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import advancedFormat from "dayjs/plugin/advancedFormat";
import PatientPrescription from "../../Patient/PatientPrescription";
import SuperAdminDashboard from "../../Dashboard/SuperAdminDashboard";
import { motion } from "framer-motion";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import {
  setPrescriptionId,
  setPrescriptionPadData,
  setSoapNotes,
} from "store/Slices/prescriptionPadSlice";
import { getUserOrgId } from "store/Slices/userSlice";
import PrescriptionPatientDetails from "./PatientPrescriptionOverview/PrescriptionPatientDetails";
import { getPatientsByDoctorOrOrgId } from "services/patientService";
import zIndex from "@mui/material/styles/zIndex";
import PrescriptionPatientDetailsSethuSir from "./PatientPrescriptionOverview/PrescriptionPatientDetailsSethuSir";
import { getSethuSirLayout } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";

dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

const DoctorAppointmentsNewV2 = ({ ...others }) => {
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [todaysAppointments, setTodaysAppointments] = useState([]);
  const { tabValue } = useContext(SidebarUtilContext);
  const [actionedAppoinment, setActionedAppointment] = useState(null);
  const [checkInAppointment, setCheckedInAppointment] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(true);
  const [checkProfileCompletedApiCalled, setCheckProfileCompletedApiCalled] = useState(true);
  const [specialtyAndExperiencePending, setSpecialtyAndExperiencePending] = useState(true);
  const [availabilityPending, setAvailabilityPending] = useState(true);
  const [prodServciesPending, setProdServciesPending] = useState(true);
  const activeUser = currentActiveUser();
  const [pendingTaskPopupVisible, setPendingTaskPopupVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orgLink, setorgLink] = useState("");
  const [organizationDetails, setOrganizationDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [doctorAppointmentsForAllOrganization, setDoctorAppointmentsForAllOrganization] =
    useState(null);
  const { toggleMainClass, setSecondarySideBarData } = useContext(SidebarUtilContext);
  const userFeatures = useSelector(getUserFeatures);
  const completedDetails = useLocation()?.state?.completedDetails;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLinkUser = async () => {
    const currUserData = activeUser;
    const data = {
      firstName: currUserData.firstName,
      middleName: currUserData.middleName,
      lastName: currUserData.lastName,
      mobileNumber: currUserData.mobileNumber,
      hprId: currUserData.hprId,
      email: currUserData.emailId,
      orgId: orgLink,
      isDoctor: false,
      isAdmin: false,
    };
    try {
      await linkUserWithOrganization(data);
      dispatch(setFrontDeskUserLinking(false));
      dispatch(setUserOrgId(orgLink));
      handleCloseModal();
      fetchData();
      handleClick("success", "Organization has been successfully linked!");
    } catch (error) {
      handleCloseModal();
      handleClick("error", "There seems to be an error linking the organization!");
    }
  };

  const steps = [
    {
      label: "Set Your Speciality, Experience and Language in profile",
      icon: <Stars />,
      condition: specialtyAndExperiencePending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          doctorOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/doctorProfile", { state: { pendingTask: true } });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
    {
      label: "Help us set your Availability Schedule",
      icon: <EventAvailable />,
      condition: availabilityPending,
      onClick: () => {
        navigate("/home/doctorAvailability", { state: { pendingTask: true } });
      },
    },
    {
      label: "Complete Product-Service Information",
      icon: <Build />,
      condition: prodServciesPending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          doctorOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/ProductAndServices", {
          state: { organizationId: doctorOrgId, pendingTask: true },
        });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
  ];

  const fetchData = async () => {
    let doctorId = activeUser?.roleName === "DOCTOR" ? activeUser.roleBasedId : null;
    try {
      const response = await getFilteredAppointments(
        doctorId ? { doctorId } : { orgId: doctorOrgId }
      );
      let apIdArr = [];
      const appointmentData = response.data.content.map((element) => {
        const timeArr = element.appointmentTime.split(":");
        let apDate = dayjs(element.appointmentDate);
        apDate = apDate.set("hour", Number(timeArr[0])).set("minute", Number(timeArr[1]));
        element.appointmentTime = apDate.format(HOUR_MINUTE_FORMAT);
        if (element["appointmentStatus"] === SCHEDULED) {
          // starting of todays date
          const currDate = dayjs().startOf("day");
          const isDayPassed = dayjs(element.appointmentDate).isBefore(currDate);
          // appointment status should be changed to NO_SHOW
          if (isDayPassed) {
            element["appointmentStatus"] = NO_SHOW;
            apIdArr.push(element.id);
          }
        }
        return {
          ...element,
          formattedDate: apDate.format(DATE_FORMAT_DMY),
        };
      });
      if (apIdArr.length > 0) {
        apIdArr.forEach(async (id) => {
          try {
            await updateAppointmentStatus(id, NO_SHOW);
          } catch (error) {
            handleClick("error", "There seems to be some error updating appointment status.");
          }
        });
      }
      setDoctorAppointmentsForAllOrganization(appointmentData);
    } catch (error) {
      console.error("Error fetching doctor's appointments");
      setLoading(false);
    }
  };

  const sethuSirLayout = useSelector(getSethuSirLayout);

  const handleFilteredAppointmentsChange = async (appointments, info, appointmentResponse) => {
    try {
      await fetchAndFilterAppointments(doctorData?.id, doctorOrgId, appointmentResponse, info);
      const sortedAppointments = [...appointments].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      if (isCalendarView && !appointmentSelectedOnFirstLoad) {
        appointments.forEach((appointment) => {
          if (appointment.appointmentId === sortedAppointments[0].appointmentId) {
            appointment.eventSelected = true;
          } else {
            appointment.eventSelected = false;
          }
        });

        if (sortedAppointments.length > 0) {
          const appointmentDetails = await getAppointmentById(sortedAppointments[0].appointmentId);
          setSelectedAppointment(appointmentDetails.data);
          setAppointmentSelectedOnFirstLoad(true);
          // await fetchPatientLatestVitalSigns(
          //   appointmentDetails?.data?.patientId,
          //   setPatientLatestVitals,
          //   handleClick
          // );
        } else {
          setSelectedAppointment(null);
        }
      }
    } catch (error) {
      console.error("Error fetching appointment");
    }
  };

  const dispatch = useDispatch();

  const [doctorData, setDoctorData] = useState({});

  const [appointmentSelectedOnFirstLoad, setAppointmentSelectedOnFirstLoad] = useState(false);
  const [doctorAvailability, setDoctorAvailability] = useState([]);
  const [doctorAppointments, setDoctorAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const user = currentActiveUser();
  const docId = currentActiveUser()?.roleBasedId;
  // const [patientVitals, setPatientVitals] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  const [appointmentResponse, setAppointmentResponse] = useState(null);
  const [patients, setPatients] = useState(null);
  const [collapseList, setCollapseList] = useState(false);
  const [newVitalAdded, setNewVitalAdded] = useState(false);

  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const [filteredDoctorEvents, setFilteredDoctorEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const [calendarView, setCalendarView] = useState(DAY_GRID_CALENDAR);
  const [viewInfo, setViewInfo] = useState(null);

  const [appointmentList, setAppointmentList] = useState([]);
  const [showAppointmentList, setShowAppointmentList] = useState(false);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const doctorOrgId = useSelector(getUserOrgId);

  const [appointmentModalTitle, setAppointmentModalTitle] = useState(null);
  const [isReschedule, setIsRechedule] = useState(false);
  const { setAppointmentInfo, appointmentInfo } = useContext(SidebarUtilContext);

  const pageFlipVariants = {
    hidden: { rotateY: -180, opacity: 0 },
    enter: { rotateY: 0, opacity: 1, transition: { duration: 1.0 } },
    exit: { rotateY: 180, opacity: 0, transition: { duration: 1.0 } },
  };

  useEffect(() => {
    const fetchTodaysAppointment = async () => {
      if (doctorAppointments?.length > 0 && tabValue === "0") {
        // let patientResponse;
        // try {
        //   patientResponse = await getPatientsByDoctorOrOrgId(doctorOrgId, docId);
        //   setPatients(patientResponse.data.content);
        // } catch (error) {
        //   console.error("Error fetching patients");
        // }
        const currentTime = dayjs().format(TIME_FORMAT);

        let TodaysAppointments = doctorAppointments
          .filter((appointment) =>
            [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(appointment.appointmentStatus)
          )
          .filter((appointment) => dayjs(appointment.appointmentDate).isSame(dayjs(), "day"));

        if (TodaysAppointments.length > 0) {
          TodaysAppointments.sort((a, b) => {
            // First, sort by date (day)
            const dateComparison = dayjs(a.appointmentDate).diff(dayjs(b.appointmentDate), "day");
            if (dateComparison !== 0) {
              return dateComparison; // If dates are different, return the result
            }

            // If the dates are the same, sort by time
            return a.appointmentTime.localeCompare(b.appointmentTime);
          });

          TodaysAppointments.sort((a, b) => {
            if (a.patientQueueNo > 0 && b.patientQueueNo > 0) {
              return a.patientQueueNo - b.patientQueueNo; // Sort in ascending order of patientQueueNo
            }

            // Place items with patientQueueNo of zero at the end
            if (a.patientQueueNo === 0 && b.patientQueueNo > 0) {
              return 1; // Move `a` after `b` when `a` has a queue number of zero
            }

            if (a.patientQueueNo > 0 && b.patientQueueNo === 0) {
              return -1; // Move `b` after `a` when `b` has a queue number of zero
            }

            return 0;
          });

          const upcomingAppointments = TodaysAppointments.filter(
            (appointment) => appointment.appointmentTime > currentTime
          );
          if (
            !appointmentSelectedOnFirstLoad ||
            actionedAppoinment?.id === selectedAppointment?.id
          ) {
            const selectedAppointment =
              upcomingAppointments.length > 0
                ? upcomingAppointments[0]
                : TodaysAppointments[TodaysAppointments.length - 1];
            try {
              const appointment = await getAppointmentById(selectedAppointment.id);
              setSelectedAppointment(appointment.data);
              setAppointmentSelectedOnFirstLoad(true);
            } catch (error) {
              console.error("Error fetching appointment");
            }
          }
        } else {
          // TodaysAppointments = patientResponse.data.content;
          // if (TodaysAppointments?.length > 0) {
          //   if (!appointmentSelectedOnFirstLoad) {
          //     setSelectedAppointment(TodaysAppointments[0]);
          //     setAppointmentSelectedOnFirstLoad(true);
          //   }
          // }
        }

        setTodaysAppointments(TodaysAppointments);
      } else {
        setTodaysAppointments([]);
      }
    };

    fetchTodaysAppointment();
  }, [doctorAppointments, tabValue]);

  useEffect(() => {
    if (checkInAppointment) {
      parentAppointmentsRefresh(doctorData);
    }
  }, [checkInAppointment]);

  // useEffect(() => {
  //   if (!isCalendarView && todaysAppointments?.length === 0 && patients) {
  //     setSelectedAppointment(patients[0]);
  //   }
  // }, [patients, isCalendarView, todaysAppointments]);

  const checkForProfileSetupDetails = async () => {
    if (activeUser.roleName === DOCTOR) {
      try {
        const speAndExpBool = await doSpecializationAndExperienceExist(activeUser.roleBasedId);
        const availBool = await doesAvailabilityExist(activeUser.roleBasedId, doctorOrgId);

        const orgAssociationRecord = activeUser.userOrganizationAssociationList.filter(
          (el) => el.organizationId === doctorOrgId
        )[0];
        if (orgAssociationRecord.isAdmin) {
          const prodServBool = await doesProductServicesExist(doctorOrgId);
          setProdServciesPending(prodServBool.data);
        }
        setSpecialtyAndExperiencePending(speAndExpBool.data);
        setAvailabilityPending(availBool.data);
        setCheckProfileCompletedApiCalled(true);
      } catch (error) {}
    }
  };

  useEffect(() => {
    checkForProfileSetupDetails();
  }, []);

  const handleNewModalOpenForBookingAppointment = () => {
    const appointment = {
      doctorId: docId,
      orgId: doctorOrgId,
      appointmentDate: dayjs().format(DATE_FORMAT),
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
  };

  const handlePrescriptionClick = async (appointmentId) => {
    try {
      const response = await getPrescriptionByAppointmentId(appointmentId);
      const prescriptionId = response?.data?.id;

      const diagnosisArr = [
        ...response?.data?.prescriptionDiagnosisResponses?.map((diagnosis) => {
          return {
            ...diagnosis,
            displayName: diagnosis?.diagnosisResponse?.name,
            category: "DX",
          };
        }),
      ];
      const symptomsArr = [
        ...response?.data?.prescriptionSymptomResponses?.map((symptom) => {
          return {
            ...symptom,
            displayName: symptom?.symptomResponse?.name,
            category: "CC",
          };
        }),
      ];

      const medicationsArr = [
        ...response?.data?.prescriptionMedicationResponses?.map((medication) => {
          return {
            ...medication,
            displayName: medication?.medicationResponse?.name,
            category: "RX",
          };
        }),
      ];
      const labInvestigationsArr = [
        ...response?.data?.prescriptionLabReportResponses?.map((labTest) => {
          return {
            ...labTest,
            displayName: labTest?.labTestResponse?.name,
            category: "LAB",
          };
        }),
      ];

      const advice = response?.data?.additionalRecommendations;
      let advicesArr = [];
      if (advice !== "") {
        advicesArr = response?.data?.additionalRecommendations.split(",").map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
      }
      dispatch(setPrescriptionId(prescriptionId));
      dispatch(
        setPrescriptionPadData({
          diagnosisArr,
          symptomsArr,
          medicationsArr,
          labInvestigationsArr,
          advicesArr,
          soapNotes: response?.data?.soapNotes ?? {},
        })
      );
      navigate(`/home/prescriptionPad/${appointmentId}`, {
        state: {
          isEditable: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewModalOpenForReschedulingAppointment = (selectedAppointment) => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile:
        selectedAppointment?.patient?.user?.mobileNumber ||
        selectedAppointment?.patientMobileNumber,
      patientName: selectedAppointment?.patient?.user?.name || selectedAppointment?.patientName,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };

    dispatch(setSelectedAppointmentData(appointment));
    setIsRechedule(true);
    setAppointmentModalTitle("Reschedule Appointment");
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
    parentAppointmentsRefresh(doctorData);
    dispatch(setSelectedAppointmentData({}));
    // handleGetAppointmentEvents(doctorData);
  };

  const [doctorBlockCalendar, setDoctorBlockCalendar] = useState([]);
  const [doctorBlockCalendarEvents, setDoctorBlockCalendarEvents] = useState([]);

  const formatDateTime = (date, time) => {
    return `${date}T${time}`;
  };

  const blockEventsByTime = (blockedEvents, startTimeTobeChecked, endTimeTobeChecked) => {
    while (startTimeTobeChecked <= endTimeTobeChecked) {
      const endDateTime30Min = dayjs(startTimeTobeChecked).add(30, "minute");
      blockedEvents.push({
        start: dayjs(startTimeTobeChecked),
        end: dayjs(endDateTime30Min),
        appointmentType: "Blocked",
        display: "background",
      });
      startTimeTobeChecked = `${endDateTime30Min.format(DATE_FORMAT)}T${endDateTime30Min.format(
        TIME_FORMAT
      )}`;
    }
    return blockedEvents;
  };

  const createBlockCalendarEvents = (blockedDetails) => {
    let blockedEvents = [];
    blockedDetails.forEach((blocked) => {
      let startDateTime = formatDateTime(blocked.startDate, blocked.startTime);
      const endDateTime = formatDateTime(blocked.endDate, blocked.endTime);
      if (blocked.blockingType === "CUSTOM_DATE_TIME_RANGE") {
        let startDay = dayjs(blocked.startDate).date();
        let endDay = dayjs(blocked.endDate).date();
        let dateToBeBlocked = blocked.startDate; // by default start date
        for (let index = startDay; index <= endDay; index++) {
          let startTimeForADay = formatDateTime(dateToBeBlocked, blocked.startTime);
          let endDateTimeForADay = formatDateTime(dateToBeBlocked, blocked.endTime);
          blockedEvents = blockEventsByTime(blockedEvents, startTimeForADay, endDateTimeForADay);
          dateToBeBlocked = dayjs(dateToBeBlocked).add(1, "d").format(DATE_FORMAT);
        }
      } else {
        blockedEvents = blockEventsByTime(blockedEvents, startDateTime, endDateTime);
      }
    });
    return blockedEvents;
  };

  const getBlockCalendar = async () => {
    try {
      const response = await getBlockAvailabilities(user?.roleBasedId, doctorOrgId);
      const doctorBlockedDetails = response.data.filter(
        (entry) =>
          entry.blockingType !== "SINGLE_DATE" || entry.blockingType !== "CUSTOM_DATE_TIME_RANGE"
      );
      setDoctorBlockCalendar(doctorBlockedDetails);
      const events = createBlockCalendarEvents(doctorBlockedDetails);
      setDoctorBlockCalendarEvents(events);
    } catch (error) {
      if (error.response?.status === 404) {
      } else {
        console.error("Error fetching block calendar details");
      }
    }
  };

  useEffect(() => {
    getBlockCalendar();
  }, []);

  // useEffect(() => {
  //   fetchAndFilterAppointments(doctorData?.id, doctorOrgId);
  // }, [viewInfo]);

  const handleGetAppointmentEvents = async (doctorDetail) => {
    try {
      const appointmentResponse = await getFilteredAppointments({
        doctorId: docId,
        orgId: doctorOrgId,
      });
      setAppointmentResponse(appointmentResponse);
      // await fetchAndFilterAppointments(docId, doctorOrgId, appointmentResponse);
      const appointmentInfoData = await getAppointmentEvents(
        doctorDetail,
        doctorOrgId,
        appointmentResponse
      );
      setDoctorAppointments([...appointmentInfoData.doctorAppointments]);
      setAllEvents([...appointmentInfoData.allEvents]);
      // const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
      // handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
      // setFilteredDoctorEvents(filteredAppointments);
      return appointmentResponse;
    } catch (error) {
      console.error("Error fetching appointments");
    }
  };

  const parentAppointmentsRefresh = async (doctorData) => {
    if (location.pathname === "/home/dashboard") {
      try {
        const appointmentResponse = await handleGetAppointmentEvents(doctorData);

        // await fetchAndFilterAppointments(docId, doctorOrgId, appointmentResponse);
        const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
        setFilteredDoctorEvents(filteredAppointments);
        if (isCalendarView) {
          handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
        } else {
          await fetchAndFilterAppointments(
            doctorData?.id,
            doctorOrgId,
            appointmentResponse,
            viewInfo
          );
        }
      } catch (error) {
        console.error("Error fethcing refreshed appointments");
      }
    }
  };

  const fetchAndFilterAppointments = async (doctorId, doctorOrgId, appointmentResponse, info) => {
    try {
      if (!appointmentResponse) {
        appointmentResponse = await getFilteredAppointments({
          doctorId: docId,
          orgId: doctorOrgId,
        });
      }

      const allowedStatuses = [SCHEDULED, RESCHEDULED, CHECKED_IN, PRESCRIPTION_DONE, COMPLETED];
      const allAppointments = appointmentResponse?.data?.content
        ?.filter((app) => allowedStatuses.includes(app.appointmentStatus))
        .filter((app) => app.appointmentDate === dayjs().format(DATE_FORMAT));

      const offline = allAppointments?.filter(
        (app) =>
          app.type === CLINIC_VISIT &&
          [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(app.appointmentStatus)
      );
      const online = allAppointments?.filter(
        (app) =>
          app.type === TELE_CONSULTATION &&
          [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(app.appointmentStatus)
      );
      const checkedIn = allAppointments?.filter((app) => app.appointmentStatus === CHECKED_IN);
      const prescriptionDone = allAppointments?.filter(
        (app) => app.appointmentStatus === COMPLETED || app.appointmentStatus === PRESCRIPTION_DONE
      );

      setAppointmentInfo({
        totalAppointments: allAppointments?.length.toString(),
        onlineAppointments: online?.length.toString(),
        offlineAppointments: offline?.length.toString(),
        checkedIn: checkedIn?.length.toString(),
        prescriptionDone: prescriptionDone,
      });
    } catch (error) {
      console.error("Error filtering appointments", error);
      return null;
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const docResponse = await getDoctorByUserId(user.id);
        if (docResponse?.data) {
          const docId = docResponse?.data.id;
          setDoctorData(docResponse.data);

          const response = await getDoctorAvailability(docId, doctorOrgId);
          setDoctorAvailability(response);

          parentAppointmentsRefresh(docResponse.data);

          intervalId = setInterval(() => {
            parentAppointmentsRefresh(docResponse.data);
          }, 300000);

          // const appointmentData = await fetchAndFilterAppointments(docId, doctorOrgId);

          // if (appointmentData) {
          //   setAppointmentInfo(appointmentData);
          // }
        }
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line
  }, []);

  const eventClick = async (eventInfo) => {
    if (eventInfo.event.extendedProps.appointmentType === "Blocked") {
      return;
    }
    try {
      const appointmentId = doctorAppointments.find(
        (appointment) => appointment.id === eventInfo.event.extendedProps.appointmentId
      )?.id;

      const appointmentResponse = await getAppointmentById(appointmentId);
      setSelectedAppointment(appointmentResponse.data);
      // await fetchPatientLatestVitalSigns(
      //   appointmentResponse?.data?.patientId,
      //   setPatientLatestVitals,
      //   handleClick
      // );

      const updatedEvents = filteredDoctorEvents.map((item) => {
        if (item.appointmentId === eventInfo.event.extendedProps.appointmentId) {
          return { ...item, eventSelected: true };
        } else {
          return { ...item, eventSelected: false };
        }
      });

      setFilteredDoctorEvents(updatedEvents);
    } catch (error) {
      console.error("Error fetching appointment detail");
    }
  };
  const location = useLocation();

  const handleCancelAppointment = (appointment) => {
    setActionedAppointment(appointment);
    handleDialogOpen();
  };

  // dialog box
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogYes = async () => {
    setOpen(false);
    // delete appointment, and fetch current appointments as events
    // todo : currently all appointments are shown, need to filter based on active/inactive once dto changed
    try {
      await updateAppointmentStatus(actionedAppoinment.id, "CANCELLED");
      handleClick("success", "Appointment cancelled successfully!");
      setAppointmentSelectedOnFirstLoad(false);
      parentAppointmentsRefresh(doctorData);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the appointment");
    }
  };

  const handleDialogNo = () => {
    setOpen(false);
  };

  const handleMonthViewMore = async (eventInfo) => {
    const appointmentDetails = await handleMonthViewMoreClick(eventInfo, doctorAppointments);
    setAppointmentList(appointmentDetails);
    setShowAppointmentList(true);
    setSelectedAppointment("");
  };

  const handleViewInfoClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowAppointmentList(false);
  };

  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [iframeAppointmentID, setIframeAppointmentId] = useState(null);

  const handleJoinRoom = useCallback(() => {
    try {
      if (selectedAppointment) {
        const room = selectedAppointment.id?.toString();
        navigate(`/home/prescriptionPad/${room}`, {
          state: {
            roomId: room,
          },
        });
      } else {
        handleClick("error", "Please select an appointment to join");
      }
    } catch (e) {
      handleClick("error", "Not able to join the call");
    }
  }, [selectedAppointment, navigate, handleClick]);

  const [anchorEl, setAnchorEl] = useState(null);
  const actions = [
    {
      label: "Cancel",
      icon: <CancelOutlined style={{ color: "#f5574c" }} />,
      onClick: handleCancelAppointment,
    },
    // ...([SCHEDULED, RESCHEDULED, CHECKED_IN].includes(selectedAppointment?.appointmentStatus)
    //   ? [
    //       {
    //         label: "Reschedule",
    //         icon: <EventOutlined style={{ color: "#004c70" }} />,
    //         onClick: handleNewModalOpenForReschedulingAppointment,
    //       },
    //     ]
    //   : []),
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isExtraLargeScreen, setIsExtraLargeScreen] = useState(false);
  const [isExtraLargeScreenForCalendarView, setIsExtraLargeScreenForCalendarView] = useState(false);

  useEffect(() => {
    if (specialtyAndExperiencePending && availabilityPending && prodServciesPending) {
      setPendingTaskPopupVisible(false);
    } else if (checkProfileCompletedApiCalled && completedDetails) {
      handleClick("success", "You're all set to go!!");
    } else {
      setPendingTaskPopupVisible(true);
    }
  }, [
    specialtyAndExperiencePending,
    availabilityPending,
    prodServciesPending,
    checkProfileCompletedApiCalled,
  ]);

  const handlePendingPopupClose = () => {
    setPendingTaskPopupVisible(false);
  };

  useEffect(() => {
    const divElement = document.querySelector(".main-content");
    const handleResize = () => {
      try {
        if (divElement.offsetWidth <= 1200) {
          setIsMediumScreen(true);
          setIsLargeScreen(false);
          setIsExtraLargeScreen(false);
          setIsExtraLargeScreenForCalendarView(false);
        } else if (divElement.offsetWidth > 1200 && divElement.offsetWidth <= 1536) {
          setIsLargeScreen(true);
          setIsMediumScreen(false);
          setIsExtraLargeScreen(false);
          setIsExtraLargeScreenForCalendarView(false);
        } else if (divElement.offsetWidth > 1536) {
          setIsExtraLargeScreen(true);
          setIsMediumScreen(false);
          setIsLargeScreen(false);
          if (divElement.offsetWidth >= 1600) {
            setIsExtraLargeScreenForCalendarView(true);
          } else {
            setIsExtraLargeScreenForCalendarView(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(divElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const PatientsComponent = (
    <Reveal className="doctor-appointments" style={{ overflowX: "hidden" }}>
      {pendingTaskPopupVisible && (
        <ModalUI
          visible={pendingTaskPopupVisible}
          close={handlePendingPopupClose}
          title={"Complete below profile details"}
          style={{
            overflowY: "auto",
          }}
          component={
            <div>
              <PendingDetailsPopupComponent steps={steps} activeUser={activeUser} />
            </div>
          }
        />
      )}
      <Dialog
        open={open}
        onClose={handleDialogNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ padding: "16px 20px !important" }} id="alert-dialog-title" fontSize={16}>
          Are you sure?
        </DialogTitle>
        <DialogContent sx={{ padding: "0px 20px 10px 20px !important" }}>
          <DialogContentText id="alert-dialog-description">
            Appointment will be cancelled and patient will be notified.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
            No
          </Button>
          <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        spacing={2}
        // paddingRight={"38px"}
        sx={{
          height: "calc(100vh - 68px) !important",
          position: "relative",
          width: "100%",
        }}
        className="mx-0 mb-0 "
      >
        <Grid
          // md={collapseList ? 0.5 :4}
          item
          // md={viewInfo !== null && viewInfo?.view?.type !== DAY_GRID_CALENDAR ? 8 : 4}
          sx={{
            height: "100%",
            // pl: "12px !important",
            // pr: "15px !important",
            pl: "0px !important",
            // pr: "16px !important",
            // position: "absolute",
            // right: "0px",
            // marginRight: "12px",
            position: "relative",
            left: 0,
            width: collapseList ? "calc(100% *0.5 / 12)" : "calc(100% * 4 / 12)",
            transition: "width 0.5s ease-in-out",

            // width:
            //   viewInfo !== null && viewInfo?.view?.type !== DAY_GRID_CALENDAR
            //     ? "66.66%"
            //     : "33.33%",
          }}
        >
          <Reveal style={{ height: "100%" }}>
            {/* <motion.div
            key={location.pathname}
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={pageFlipVariants}
            style={{ height: "100%" }}
          > */}
            <Box
              className="left-box-new"
              sx={{ padding: collapseList ? "6px" : "12px" }}
              // style={{ paddingLeft: "5px", paddingRight: "5px" }}
            >
              <Outlet
                context={{
                  patientListSharedValue: {
                    handleNewModalOpenForBookingAppointment,
                    handleCancelAppointment,
                    setCheckedInAppointment,
                    newVitalAdded,
                    setNewVitalAdded,
                    isMediumScreen,
                    setIframeSrc,
                    setIframeOpen,
                    isExtraLargeScreenForCalendarView,
                    isLargeScreen,
                    isExtraLargeScreen,
                    appointmentInfo,
                    setCollapseList,
                    collapseList,
                    setTodaysAppointments,
                    setAppointmentSelectedOnFirstLoad,
                    appointmentSelectedOnFirstLoad,
                    isCalendarView,
                    setIsCalendarView,
                    calendarView,
                    setCalendarView,
                    viewInfo,
                    setViewInfo,
                    appointmentResponse,
                    setFilteredDoctorEvents,
                    allEvents,
                    filteredDoctorEvents,
                    eventClick,
                    doctorBlockCalendar,
                    doctorBlockCalendarEvents,
                    doctorData,
                    handleFilteredAppointmentsChange,
                    handleMonthViewMore,
                    setShowAppointmentList,
                    handlePrescriptionClick,
                    todaysAppointments,
                    patients,
                    selectedAppointment,
                    setSelectedAppointment,
                    parentAppointmentsRefresh,
                    handleNewModalOpenForReschedulingAppointment,
                    setAppointmentModalTitle,
                    setIsNewModalOpen,
                  },
                }}
              ></Outlet>
            </Box>
            {/* </motion.div> */}
          </Reveal>
        </Grid>

        <Grid
          item
          // md={collapseList ? 12 :8}
          sx={{
            // pl: "0px !important",
            height: "100%",
            position: "absolute",
            right: 0,
            // pl: collapseList ? "0px !important" : undefined,
            width: collapseList ? "calc(100% * 11.5 / 12)" : "calc(100% * 8 / 12)",
            transition: "width 0.5s ease-in-out",
          }}
        >
          {sethuSirLayout?.value ? (
            <PrescriptionPatientDetailsSethuSir
              handleNewModalOpenForReschedulingAppointment={
                handleNewModalOpenForReschedulingAppointment
              }
              viewInfo={viewInfo}
              handleJoinRoom={handleJoinRoom}
              selectedAppointment={selectedAppointment}
              handleClick={handleClick}
              isMediumScreen={isMediumScreen}
              isLargeScreen={isLargeScreen}
              isExtraLargeScreen={isExtraLargeScreen}
              docId={docId}
              newVitalAdded={newVitalAdded}
              setNewVitalAdded={setNewVitalAdded}
              setIframeOpen={setIframeOpen}
              setIframeAppointmentId={setIframeAppointmentId}
              setIframeSrc={setIframeSrc}
              parentAppointmentsRefresh={parentAppointmentsRefresh}
              doctorData={doctorData}
              setTodaysAppointments={setTodaysAppointments}
              handleCancelAppointment={handleCancelAppointment}
              noAppointmentsToday={todaysAppointments?.length === 0}
              setCheckedInAppointment={setCheckedInAppointment}
            />
          ) : (
            <PrescriptionPatientDetails
              handleNewModalOpenForReschedulingAppointment={
                handleNewModalOpenForReschedulingAppointment
              }
              viewInfo={viewInfo}
              newVitalAdded={newVitalAdded}
              setNewVitalAdded={setNewVitalAdded}
              handleJoinRoom={handleJoinRoom}
              selectedAppointment={selectedAppointment}
              handleClick={handleClick}
              isMediumScreen={isMediumScreen}
              setCheckedInAppointment={setCheckedInAppointment}
              isLargeScreen={isLargeScreen}
              isExtraLargeScreen={isExtraLargeScreen}
              docId={docId}
              setIframeOpen={setIframeOpen}
              setIframeAppointmentId={setIframeAppointmentId}
              setIframeSrc={setIframeSrc}
              parentAppointmentsRefresh={parentAppointmentsRefresh}
              doctorData={doctorData}
              setTodaysAppointments={setTodaysAppointments}
              handleCancelAppointment={handleCancelAppointment}
              noAppointmentsToday={todaysAppointments?.length === 0}
            />
          )}
        </Grid>
      </Grid>

      <ModalUI
        visible={isNewModalOpen}
        close={() => {
          dispatch(setSelectedAppointmentData({}));
          setIsNewModalOpen(false);
        }}
        title={appointmentModalTitle}
        style={{
          overflowY: "auto",
          height: "550px",
          width: "610px",
        }}
        component={
          <BookAppointmentModal
            reschedule={isReschedule}
            handleClick={handleClick}
            closeModal={handleNewModalClose}
          />
        }
      />
      {/* <Fab
          onClick={handleNewModalOpenForBookingAppointment}
          variant="extended"
          sx={{
            ml: "auto",
            right: "20px",
            bottom: "20px",
            position: "absolute",
            backgroundColor: "#004c70 !important",
            color: "#FFF",
          }}
        >
          <i className="ri-add-circle-fill" style={{ fontSize: "24px", marginRight: "4px" }}></i>
          Appointment
        </Fab> */}
      {iframeOpen && (
        <div className="iframe-div">
          {iframeSrc ? (
            <iframe
              title="consent form"
              src={iframeSrc}
              target="_blank"
              className="iframe"
            ></iframe>
          ) : (
            <div className="iframe">
              <PatientPrescription appId={iframeAppointmentID} />
            </div>
          )}

          <CustomButton
            iconButton={<Close />}
            onClick={() => {
              setIframeOpen(false);
              setIframeSrc(null);
            }}
            className="iframe-close-button"
          />
        </div>
      )}
    </Reveal>
  );

  const items = [
    {
      label: "My Appointments",
      value: 0,
      component: PatientsComponent,
    },
    {
      label: "Dashboard",
      value: 1,
      component: <SuperAdminDashboard dashboardid={"f9d810b1-5c9a-4cb6-b106-184d93da58fd"} />,
    },
  ];

  return (
    <DoctorAppointmentSkeleton>
      <Box>
        <Grid>{items[tabValue].component}</Grid>
      </Box>
    </DoctorAppointmentSkeleton>
  );
};

export const PendingDetailsPopupComponent = ({
  steps,
  isAbhaNumberExist,
  handleSkipForNow,
  activeUser,
}) => {
  const navigate = useNavigate();
  const customization = {
    colors: {
      primaryMain: "#29bf91",
    },
    ...useSelector((state) => state.customization),
  };

  const sortedSteps = steps.sort((a, b) =>
    a.condition === b.condition ? 0 : a.condition ? -1 : 1
  );
  const activeStep = sortedSteps.findIndex((el) => !el.condition);

  return (
    // <Grid display={"flex"} flexDirection="column" spacing={4} sx={{ mt: 2 }}>
    //   {steps.map(
    //     (step, index) =>
    //       !step.condition && (
    //         <Grid key={index}>
    //           <ListItem sx={{ maxHeight: "70px", p: 0 }}>
    //             <ListItemButton sx={{ maxHeight: "70px" }} onClick={step.onClick}>
    //               <Box
    //                 sx={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                 }}
    //               >
    //                 <Box
    //                   sx={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     width: 40,
    //                     height: 40,
    //                     bgcolor: step.condition ? "#29bf91" : "#29bf91", //F7CB73
    //                     borderRadius: "50%",
    //                     color: "white",
    //                     boxShadow: 3,
    //                     p: 1,
    //                     mr: 1,
    //                   }}
    //                 >
    //                   {step.icon}
    //                 </Box>
    //                 <Typography variant="h5">{step.label}</Typography>
    //               </Box>
    //             </ListItemButton>
    //           </ListItem>
    //         </Grid>
    //       )
    //   )}
    // </Grid>
    <ThemeProvider theme={themes(customization)}>
      <Grid sx={{ mt: 2 }}>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {sortedSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  "& .MuiStepIcon-root": {
                    display: sortedSteps.length === 1 ? "none" : "flex",
                  },
                  "& .MuiStepIcon-text": {
                    fill: "#ffffff",
                  },
                }}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <CustomButton
                      label={"Continue"}
                      className={"btn--secondary"}
                      onClick={step.onClick}
                    />
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}

          {activeUser?.roleName === PATIENT && !isAbhaNumberExist && (
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
              <CustomButton
                label={"Create/link"}
                className={"btn--secondary"}
                onClick={() =>
                  navigate("/home/manageAbhaNumber", {
                    state: {
                      abhaCreationPending: true,
                    },
                  })
                }
              ></CustomButton>

              <CustomButton
                className={"btn--secondary"}
                label={"Skip For Now"}
                onClick={handleSkipForNow}
              />
            </div>
          )}
        </Stepper>
      </Grid>
    </ThemeProvider>
  );
};

export default DoctorAppointmentsNewV2;

const DoctorAppointmentSkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>

          <Grid display={"flex"} item xs={6}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="10%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={8} pr={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
