import { ClearOutlined, PersonOutlineOutlined, VaccinesOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getVaccineTypes } from "services/EntitiesServices";
import {
  createPatientImmunization,
  deletePatientImmunization,
  editPatientImmunization,
  getPatientImmunizationByPatientId,
} from "services/patientService";
import { DATE_FORMAT, DATE_FORMAT_DMY, startsAndEndsWithAlphabetic } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import CustomTableNew from "ui-component/custom-components/custom-table/CustomTableNew";
import Reveal from "views/utilities/Reveal";

const columns = [
  { field: "#", label: "#", align: "center", width: "7%", isSerial: true },
  { field: "vaccineName", label: "Vaccine Name", width: "23%" },
  { field: "dateAdministered", label: "Date Administered", width: "20%" },
  { field: "administeringDoctor", label: "Administering Doctor", width: "30%" },
  { field: "nextDoseDate", label: "Next Dose Date", width: "20%" },
];

const PatientImmunization = ({ patientDOB, patientId }) => {
  const [patientImmunizations, setPatientImmunizations] = useState([]);
  const [displayPatientImmunizations, setDisplayPatientImmunizations] = useState([]);
  const [immunizations, setImmunizations] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientImmunization(row.id);
      handleClick("success", "Patient Immunization has been successfully deleted.");
      setPatientImmunizations(patientImmunizations.filter((el) => el.id !== row.id));
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient Immunization.");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pImmunization) => {
    setSelected(pImmunization);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchImmunization = async () => {
    try {
      const Procedures = await getVaccineTypes();
      setImmunizations(Procedures.data);
    } catch (error) {
      console.error("Error fetching Immunizations");
    }
  };

  const fetchPatientImmunization = async () => {
    try {
      const response = await getPatientImmunizationByPatientId(patientId);
      const PatientImmunization = response.data.map((pImmunization) => {
        return {
          ...pImmunization,
          vaccineName: pImmunization?.vaccineTypeResponse.name,
          dateAdministered: dayjs(pImmunization?.dateAdministered).format(DATE_FORMAT_DMY),
          nextDoseDate: pImmunization?.nextDoseDate
            ? dayjs(pImmunization?.nextDoseDate).format(DATE_FORMAT_DMY)
            : null,
        };
      });
      setPatientImmunizations(PatientImmunization);
    } catch (error) {
      console.error("Error fetching patient immunization");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    if (immunizations.length > 0) {
      fetchPatientImmunization();
    }
  }, [immunizations]);

  useEffect(() => {
    fetchImmunization();
  }, []);

  useEffect(() => {
    setDisplayPatientImmunizations(
      patientImmunizations
        .filter((item) => {
          const values = Object.values(item);
          const lowerCaseQuery = searchQuery.toLowerCase();
          return values.some((value, index) => {
            if (typeof value === "string" && index !== 6) {
              return value.toLowerCase().includes(lowerCaseQuery);
            }
            return false;
          });
        })
        .map((item) => {
          const { administeringDoctor, ...rest } = item;
          return {
            ...rest,
            administeringDoctor: `Dr. ${administeringDoctor}`,
          };
        })
    );
  }, [patientImmunizations, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%", margin: "5px 0" }}
          label={"Search Immunization"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomTableNew
        columns={columns}
        tableData={displayPatientImmunizations}
        actions={actions}
        paginationProps={{ rowsPerPage: 10 }}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Immunization" : "Create Patient Immunization"}
        component={
          <PatientImmunizationModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientImmunization();
              } else {
                closeModal();
              }
            }}
            patientId={patientId}
            patientDOB={patientDOB}
            immunizations={immunizations}
          />
        }
      />
    </Reveal>
  );
};

const PatientImmunizationModal = ({
  patientDOB,
  immunizations,
  patientId,
  selected,
  handleClick,
  close,
}) => {
  const [administeringDoctor, setAdministeringDoctor] = useState(
    selected?.administeringDoctor ? selected?.administeringDoctor.replace(/^Dr\. /, "") : ""
  );
  const [dateAdministered, setDateAdministered] = useState(
    selected?.dateAdministered
      ? dayjs(selected?.dateAdministered, DATE_FORMAT_DMY).format(DATE_FORMAT)
      : null
  );
  const [nextDoseDate, setNextDoseDate] = useState(
    selected?.nextDoseDate
      ? dayjs(selected?.nextDoseDate, DATE_FORMAT_DMY).format(DATE_FORMAT)
      : null
  );
  const [immunization, setImmunization] = useState(null);
  const [, setNewImmunization] = useState(null);
  const [errors, setErrors] = useState({});

  const minDateForImmunization = dayjs(patientDOB ? patientDOB : "1900-01-01");

  const currentDate = dayjs();
  const [administeringDoctorTouched, setAdministeringDoctorTouched] = useState(false);
  const pattern = /^[a-zA-Z0-9\s]+$/;

  const validateAdministeringDoctor = (errors, administeringDoctor) => {
    if (!administeringDoctor) {
      errors.administeringDoctor = "Please enter administering doctor name.";
    } else if (administeringDoctor.length < 3) {
      errors.administeringDoctor = "Name must be at least 3 characters long";
    } else if (administeringDoctor.length > 100) {
      errors.administeringDoctor = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(administeringDoctor)) {
      errors.administeringDoctor = "Name can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(administeringDoctor)) {
      errors.administeringDoctor = "Name must start and end with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setAdministeringDoctorTouched(true);
    validateAdministeringDoctor(errors, administeringDoctor);

    if (!immunization) {
      errors.immunization = "Please select an immunization.";
    }

    if (dateAdministered === null) {
      errors.dateAdministered = "Please select a valid date.";
    } else if (!(dateAdministered?.isValid ? dateAdministered.isValid() : true)) {
      errors.dateAdministered = "Please select a valid 'Date' value.";
    } else if (dateAdministered > currentDate) {
      errors.dateAdministered = "Date cannot be in future.";
    } else if (dateAdministered < minDateForImmunization) {
      errors.dateAdministered = "Date can't be less than DOB.";
    }

    if (!(nextDoseDate?.isValid ? nextDoseDate.isValid() : true)) {
      errors.nextDoseDate = "Please select a valid 'Date' value.";
    } else if (nextDoseDate && nextDoseDate <= dateAdministered) {
      errors.nextDoseDate = "Date cannot be less than administered date";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && immunizations) {
      const Immunization = immunizations.find((immunization) => {
        return immunization.id === selected.vaccineTypeId;
      });
      setImmunization(Immunization);
    }
  }, [selected, immunizations]);

  const saveImmunization = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        administeringDoctor,
        dateAdministered: dateAdministered ? dayjs(dateAdministered).format(DATE_FORMAT) : null,
        nextDoseDate: nextDoseDate ? dayjs(nextDoseDate).format(DATE_FORMAT) : null,
        vaccineTypeId: selected?.vaccineTypeResponse?.id,
      };

      if (selected) {
        try {
          await editPatientImmunization(selected.id, data);
          handleClick("success", "Patient Immunization has been successfully updated.");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error updating the patient immunization");
        }
      } else {
        try {
          const postData = { ...data, vaccineTypeId: immunization.id };
          await createPatientImmunization(postData);
          handleClick("success", "Patient Immunization has been successfully added.");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error adding the patient immunization.");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <CustomAutoComplete
            options={immunizations}
            readOnly={selected}
            label={"Vaccine"}
            required
            placeholder="Select"
            iconStart={<VaccinesOutlined />}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => {
              setImmunization(value);
              const newErrors = { ...errors };
              delete newErrors.immunization;
              setErrors(newErrors);
            }}
            onInput={(event, newInputValue) => {
              setNewImmunization(newInputValue);
            }}
            value={immunization}
            error={Boolean(errors.immunization)}
            errorText={errors.immunization}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            format={DATE_FORMAT_DMY}
            label={"Administered Date"}
            required
            disableFuture
            minDate={minDateForImmunization}
            value={dayjs(dateAdministered) || ""}
            size={"big"}
            onChange={(date) => {
              setDateAdministered(date);
              if (date === null) {
                setErrors({ ...errors, dateAdministered: "Please select a valid date." });
              } else if (!date.isValid()) {
                setErrors({
                  ...errors,
                  dateAdministered: "Please select a valid 'Date' value.",
                });
              } else if (date > currentDate) {
                setErrors({
                  ...errors,
                  dateAdministered: "Date cannot be in a future.",
                });
              } else if (date < minDateForImmunization) {
                setErrors({
                  ...errors,
                  dateAdministered: "Date can't be less than Date of birth.",
                });
              } else {
                const { dateAdministered, ...newError } = errors;
                if (nextDoseDate && dayjs(nextDoseDate).isBefore(date)) {
                  newError.nextDoseDate = "Date cannot be less than administered date";
                }
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.dateAdministered)}
            errorText={errors.dateAdministered}
          ></FormDatePicker>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            format={DATE_FORMAT_DMY}
            label={"Next Dose Date"}
            minDate={dateAdministered ? dayjs(dateAdministered) : minDateForImmunization}
            value={dayjs(nextDoseDate) || ""}
            size={"big"}
            onChange={(date) => {
              setNextDoseDate(date);
              if (!date.isValid()) {
                setErrors({ ...errors, nextDoseDate: "Please select a valid 'Date' value." });
              } else if (date <= dateAdministered) {
                setErrors({
                  ...errors,
                  nextDoseDate: "Date cannot be less than administered date",
                });
              } else {
                const { nextDoseDate, ...newError } = errors;
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.nextDoseDate)}
            errorText={errors.nextDoseDate}
          ></FormDatePicker>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            required
            label="Administering Doctor"
            name="administeringDoctor"
            value={administeringDoctor}
            onBlur={(e) => {
              setAdministeringDoctorTouched(true);
              const newError = {};
              validateAdministeringDoctor(newError, administeringDoctor);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setAdministeringDoctor(e.target.value);
              if (administeringDoctorTouched) {
                const newError = {};
                validateAdministeringDoctor(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.administeringDoctor;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            error={Boolean(errors.administeringDoctor)}
            errorText={errors.administeringDoctor}
          />
        </Grid>

        <Grid sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={saveImmunization}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientImmunization;
