import { ClearOutlined } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, IconButton, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getPatientFamilyDetails } from "services/patientService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
  SIZE_10_MB,
} from "store/constant";
import { deleteUser, unlinkFamilyMember } from "services/userService";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import PatientFamilyCard from "./PatientFamilyCard";
import { getProfilePicture, uploadProfilePicture } from "services/patientService";

const PatientFamilyDetails = () => {
  const familyId = currentActiveUser()?.familyResponse?.id;
  const fileInputRef = useRef(null);
  const { handleClick } = useContext(ToastContext);

  const [patientFamilyMembers, setPatientFamilyMembers] = useState([]);
  const [displayPatientFamilyMembers, setDisplayPatientFamilyMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      if (row?.userResponse?.isPrimary) {
        await unlinkFamilyMember(row?.userId, row?.familyId);
      } else {
        await deleteUser(row.userId);
      }
      await fetchPatientFamilyMembers();
      handleClick("success", "Family member has been deleted succesfully!!");
    } catch (e) {
      handleClick("error", "There seems to be an error deleting family member.");
    }
  };

  const updateFamilyMember = async (row) => {
    navigate("/home/manageFamilyMembers", {
      state: {
        userId: row.userId,
        relationship: row.relationship,
        isPrimary: row.isPrimary,
        familyMappingId: row.id,
      },
    });
  };

  const fetchPatientFamilyMembers = async () => {
    try {
      if (familyId) {
        const response = await getPatientFamilyDetails(familyId);
        const familyMembersData = response.data?.userFamilyMappingResponses.filter(
          (el) => el.userResponse !== null
        );
        const tempData = await Promise.all(
          familyMembersData?.map(async (el) => {
            try {
              setIsLoading(true);
              const profilePicResponse = await getProfilePicture(el.userId);
              const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
              const profilePicWithContent = {
                filename: profilePicResponse.data.filename,
                content: content,
              };

              el["profilePicWithContent"] = profilePicWithContent;
              return el;
            } catch (error) {
              console.error(error);
              const profilePicWithContent = null;
              el["profilePicWithContent"] = profilePicWithContent;
              return el;
            } finally {
              setIsLoading(false);
            }
          })
        );

        setPatientFamilyMembers(tempData);
      }
    } catch (error) {
      console.error("Error fetching patient family details");
    }
  };

  const createMember = () => {
    navigate("/home/manageFamilyMembers");
  };

  useEffect(() => {
    fetchPatientFamilyMembers();
  }, []);

  useEffect(() => {
    setDisplayPatientFamilyMembers(
      patientFamilyMembers?.filter((item) => {
        const values = Object?.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values?.some((value, index) => {
          if (index === 2) {
            const obj = Object.values(value);
            return obj?.some((el, i) => {
              if (i === 1 || i === 11 || i === 14) {
                return el.toLowerCase().includes(lowerCaseQuery);
              }
              return value;
            });
          } else if (index === 4) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return value;
        });
      })
    );
  }, [patientFamilyMembers, searchQuery, displayPatientFamilyMembers]);

  const [currData, setcurrData] = useState(null);
  const addProfilePic = (data) => {
    if (fileInputRef.current) {
      setcurrData(data.userId);
      fileInputRef.current.click();
    }
  };

  const handleFileUploads = async (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      return; // Exit early if no file is selected
    }
    if (file.size > SIZE_10_MB) {
      handleClick("error", "File size exceeds 10MB. Please choose a smaller file.");
      event.target.value = null;
      return;
    }

    let updatedSelected;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const s = new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({ file: file, content: reader.result });
      };
    });
    try {
      const updatedFiles = await s;
      updatedSelected = updatedFiles;
      try {
        const formData = new FormData();
        formData.append("file", file);
        await uploadProfilePicture(currData, formData);
        const index = displayPatientFamilyMembers.findIndex((el) => el.userId === currData);
        displayPatientFamilyMembers[index]["profilePicWithContent"] = updatedSelected;
        setDisplayPatientFamilyMembers(displayPatientFamilyMembers);
      } catch (error) {
        handleClick("error", "There seems to be an error uploading profile picture");
      }
    } catch (e) {
      handleClick("error", "Your file cannot be uploaded.");
    }
  };

  const actions = [
    {
      label: "Manage Picture",
      icon: <i className="ri-folder-image-line ri-xl icon-primary-blue" />,
      onClick: addProfilePic,
    },
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: updateFamilyMember,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  return (
    <PatientProfileTablesSkeleton>
      <Reveal>
        <div className="section-heading">
          <h2 className="page-title">Family Details</h2>
        </div>
        <Grid display={"flex"} alignItems={"center"} sx={{ pt: "10px", mb: 1.4 }}>
          <FormInputField
            style={{ width: "40.8%" }}
            label={"Search Family-member"}
            value={searchQuery}
            startAdornment={<i className="ri-search-line ri-lg" />}
            endAdornment={
              <IconButton onClick={clearSearch} edge="end">
                {<ClearOutlined fontSize="small" />}
              </IconButton>
            }
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            size="small"
          ></FormInputField>
          <CustomButton
            style={{ marginLeft: "auto" }}
            label={"Add"}
            className={"ri-add-fill ri-lg btn--primary"}
            onClick={createMember}
          />
        </Grid>
        <Grid container className="row mb-0 mx-0 column-patient-details">
          {!displayPatientFamilyMembers?.length > 0 && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "100px", width: "100%" }}
            >
              <Typography style={{ margin: "20px", fontSize: "18px", fontWeight: "500" }}>
                No Family Members Added
              </Typography>
            </Grid>
          )}

          {displayPatientFamilyMembers?.length > 0 &&
            displayPatientFamilyMembers?.map((familyMember, index) => {
              return (
                <>
                  <Grid size={{ md: 3 }} className="col">
                    <Reveal>
                      <Card
                        key={index}
                        className="custom-card-ui"
                        style={{ backgroundColor: "white", margin: "5px", padding: "12px 0px" }}
                      >
                        <PatientFamilyCard
                          isLoading={isLoading}
                          data={familyMember}
                          actions={actions}
                        />
                      </Card>
                    </Reveal>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileUploads}
        />
      </Reveal>
    </PatientProfileTablesSkeleton>
  );
};

export const PatientProfileTablesSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientFamilyDetails;
