import React, { useState, useCallback, useEffect } from "react";
import {
  APPOINTMENT_TYPE_SYSTEM,
  APPOINTMENT_TYPE_UHI,
  convertTimeForTimeFormat,
  DATE_FORMAT_DMY,
  NO_SHOW_LABEL,
  PRESCRIPTION_DONE_LABEL,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
  timeFormat,
} from "store/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Drawer,
  Skeleton,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import ModalUI from "ui-component/ModalUI";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { getActiveMember } from "store/Slices/userSlice";
import {
  CANCELLED,
  SCHEDULED,
  NO_SHOW,
  currentActiveUser,
  TELE_CONSULTATION,
  RESCHEDULED,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
  COMPLETED,
  CLINIC_VISIT,
  PRESCRIPTION_DONE,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import VideocamIcon from "@mui/icons-material/Videocam";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import { getAllAppointmentsByUserId, updateAppointmentStatus } from "services/Appointments";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { getPatientFamilyDetails } from "services/patientService";
import FilterDrawer from "ui-component/FilterDrawer";
import CustomTableNew from "ui-component/custom-components/custom-table/CustomTableNew";

const columns = [
  { field: "#", label: "#", width: "10%", align: "center", isSerial: true },
  { field: "doctorName", label: "Doctor", width: "27%" },
  { field: "appointmentTime", label: "Time", width: "15%" },
  { field: "formattedDate", label: "Date", width: "18%" },
  { field: "appointmentDisplayType", label: "Type", width: "10%" },
  { field: "appointmentDisplayStatus", label: "Status", width: "15%" },
];

const detailColumn = [
  { field: "patientName", label: "Patient's Name" },
  { field: "doctorName", label: "Doctor's Name" },
  { field: "orgName", label: "Organization" },
  { field: "notes", label: "Reason for Consultation" },
  // { field: "doctorContactNumber", label: "Doctor's Contact Number" },
];

const DetailList = ({ details }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: "450px",
        margin: "0 auto",
        padding: "20px",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      {detailColumn.map((column) => (
        <Grid
          container
          size={{ xs: 12 }}
          key={column.field}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Typography variant="subtitle2" sx={{ color: "#004C70", fontWeight: "bold", flex: 1 }}>
            {column.label}:
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "text.primary", flex: 2, textAlign: "left", maxWidth: "200px" }}
          >
            {details[0][column.field] || "N/A"}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const PatientAppointment = () => {
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const userId = currentActiveUser().id;
  const patientId = currentActiveUser()?.roleBasedId;
  const navigate = useNavigate();
  // to listen to profile switch and show appointments
  const activeMemberProfile = useSelector(getActiveMember);
  const [appointment, setAppointment] = useState([]);
  const [searchParams, setSearchParams] = useState(null);
  const [applyFilterDisable, setApplyFilterDisable] = useState(true);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [familyMembersList, setFamilyMembersList] = useState([]);
  const [names, setNames] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [customRange, setCustomRange] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isDateValid, setIsDateValid] = useState(true);
  const [clinicVisitSelected, setClinicVisitSelected] = useState(false);
  const [teleconsultationSelected, setTeleconsultationSelected] = useState(false);
  const [statusCompleted, setStatusCompleted] = useState(false);
  const [statusScheduled, setStatusScheduled] = useState(false);
  const [statusCancelled, setStatusCancelled] = useState(false);
  const [statusNoShow, setStatusNoShow] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const [sourceType, setSourceType] = useState({ label: "All Appointments", value: "ALL" });
  const sourceTypeMenu = [
    { label: "All Appointments", value: "ALL" },
    { label: "Arog Appointments", value: APPOINTMENT_TYPE_SYSTEM },
    { label: "UHI Appointments", value: APPOINTMENT_TYPE_UHI },
  ];

  const handleFilterAppointmentsBySourceType = (sourceType) => {
    setSourceType(sourceType);
  };

  const CustomizedToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow placement="bottom-start" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#004c70",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      color: "#fff",
      border: "0.5px solid #004c70",
      backgroundColor: "#004c70",
      margin: "0px",
    },
  }));

  const handleJoinRoom = useCallback(
    (data) => {
      try {
        const room = data?.appointmentId?.toString();
        navigate(`/home/room/${room}`, {
          state: {
            roomId: room,
          },
        });
      } catch (e) {
        handleClick("error", "There seems to be an error joining call.");
      }
    },
    [handleClick, navigate]
  );

  const DeleteAppointment = async (app) => {
    try {
      await updateAppointmentStatus(app.appointmentId, CANCELLED);
      handleClick("success", "Appointment has been successfully deleted.");
      fetchData();
      closeModal();
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the appointment.");
    }
  };

  const [selected, setSelected] = useState([]);

  const openModal = (data) => {
    setSelected(data);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setOpenDetails(false);
  };

  const openDetailModal = async (data) => {
    const Selected = {
      ...data,
      patientName: data.patientName,
      patientContactNo: data.patientContactNumber,
      reason: data.notes,
      docName: data.doctorName,
      docContactNo: data.doctorContactNumber,
    };
    setSelected([Selected]);
    setOpenDetails(true);
  };

  const getUserAppointmnetsData = async (obj) => {
    try {
      const response = await getAllAppointmentsByUserId(userId, obj ? obj : searchParams);
      const uniqueDoctorsMap = new Map();

      response?.data?.content?.forEach((doctor) => {
        if (!uniqueDoctorsMap.has(doctor.doctorId)) {
          uniqueDoctorsMap.set(doctor.doctorId, {
            name: doctor.doctorName,
            id: doctor.doctorId,
            isDoctor: true,
          });
        }
      });

      const doctorsList = Array.from(uniqueDoctorsMap.values());
      if (doctorsList.length > 0) {
        setDoctorsList((prevList) => {
          if (prevList.length === 0) {
            return doctorsList;
          }
          return prevList;
        });
      }
      let apIdArr = [];
      const appointmentData = response.data.content.map((element) => {
        const timeArr = element.appointmentTime.split(":");
        let apDate = dayjs(element.appointmentDate);
        apDate = apDate.set("hour", Number(timeArr[0])).set("minute", Number(timeArr[1]));
        element.appointmentTime = apDate.format(HOUR_MINUTE_FORMAT);
        if (element["appointmentStatus"] === SCHEDULED) {
          // starting of todays date
          const currDate = dayjs().startOf("day");
          const isDayPassed = dayjs(element.appointmentDate).isBefore(currDate);
          // appointment status should be changed to NO_SHOW
          if (isDayPassed) {
            element["appointmentStatus"] = NO_SHOW;
            apIdArr.push(element.appointmentId);
          }
        }
        return {
          ...element,
          doctorName: `Dr. ${element.doctorName}`,
          appointmentDisplayStatus:
            element?.appointmentStatus === NO_SHOW
              ? NO_SHOW_LABEL
              : element?.appointmentStatus === PRESCRIPTION_DONE
              ? PRESCRIPTION_DONE_LABEL
              : element?.appointmentStatus,
          appointmentDisplayType: (
            <CustomizedToolTip
              title={
                element?.appointmentType === CLINIC_VISIT
                  ? TEXT_FOR_CLINIC_VISIT
                  : TEXT_FOR_TELECONSULTATION
              }
            >
              {element?.appointmentType === CLINIC_VISIT ? (
                <i className="ri-walk-fill ri-lg" alt="offline" style={{ color: "#4caf50" }} />
              ) : element?.appointmentType === TELE_CONSULTATION ? (
                <img src="/images/hc_online_icon.svg" alt="online" />
              ) : (
                ""
              )}
            </CustomizedToolTip>
          ),

          formattedDate: apDate.format(DATE_FORMAT_DMY),
          appointmentTime: convertTimeForTimeFormat(element?.appointmentTime),
          doctorData: (
            <div>
              <CustomizedToolTip
                title={element?.providerDetails ? element?.providerDetails?.id : "Arog"}
              >
                {element?.providerDetails && (
                  <CustomButton
                    disabled
                    iconButton={
                      <img
                        src={element?.providerDetails && element?.providerDetails?.image}
                        alt={element?.providerDetails && element?.providerDetails?.id}
                        height="30px"
                        width="30px"
                      />
                    }
                  />
                )}
              </CustomizedToolTip>
              <span> {element?.doctorName}</span>
            </div>
          ),
          // provider: (
          //   <CustomizedToolTip
          //     title={element?.providerDetails ? element?.providerDetails?.id : "Arog"}
          //   >
          //     <div>
          //       <CustomButton
          //         disabled
          //         iconButton={
          //           <img
          //             src={
          //               element?.providerDetails
          //                 ? element?.providerDetails?.image
          //                 : "/images/arog-icon.svg"
          //             }
          //             alt={element?.providerDetails ? element?.providerDetails?.id : "Arog"}
          //             height="30px"
          //             width="30px"
          //           />
          //         }
          //       />
          //     </div>
          //   </CustomizedToolTip>
          // ),
        };
      });
      if (apIdArr.length > 0) {
        apIdArr.forEach(async (id) => {
          try {
            await updateAppointmentStatus(id, NO_SHOW);
          } catch (error) {
            handleClick("error", "There seems to be an error updating appointment status");
          }
        });
      }

      if (sourceType.value !== "ALL") {
        setAppointment(
          appointmentData.filter((appointment) => appointment.sourceType === sourceType.value)
        );
      } else {
        setAppointment(appointmentData);
      }
    } catch (error) {
      console.error("Error fetching patient appointment");
    }
  };

  const mergeLists = useCallback(() => {
    const combinedList = [...doctorsList, ...familyMembersList];
    const uniqueList = Array.from(new Map(combinedList.map((item) => [item.id, item])).values());
    setNames(uniqueList);
  }, [doctorsList, familyMembersList]);

  useEffect(() => {
    mergeLists();
  }, [doctorsList, familyMembersList]);

  useEffect(() => {
    if (fromDate && toDate) {
      setIsDateValid(dayjs(fromDate) <= dayjs(toDate));
    } else {
      setIsDateValid(true);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    const mergeLists = () => {
      const combinedList = [...doctorsList, ...familyMembersList];
      const uniqueList = Array.from(new Map(combinedList.map((item) => [item.id, item])).values());
      setNames(uniqueList);
    };

    mergeLists();
  }, []);

  const fetchData = useCallback(async () => {
    getUserAppointmnetsData();
  }, [appointment.appointmentDate, handleClick, patientId, sourceType]);

  useEffect(() => {
    fetchData(searchParams);
  }, [activeMemberProfile, fetchData]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const dispatch = useDispatch();

  const handleEditAppointment = (data) => {
    const appointment = {
      id: data.appointmentId,
      orgId: data.orgId,
      appointmentTime: data.appointmentTime.replaceAll(" ", ""),
      patientId: data.patientId,
      doctorId: data.doctorId,
      appointmentDate: data.appointmentDate,
      notes: data.notes,
      type: data.appointmentType,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setIsModalOpen(true);
  };

  const isDisable = (row) => {
    if (row?.appointmentStatus === SCHEDULED || row?.appointmentStatus === RESCHEDULED) {
      const now = dayjs();
      const nowString = now.format(DATE_FORMAT);
      const currentTime = now.format(HOUR_MINUTE_FORMAT);
      const appointmentTime =
        timeFormat().toString() === "12"
          ? dayjs(row?.appointmentTime, "hh:mm A").format(HOUR_MINUTE_FORMAT)
          : row?.appointmentTime;
      if (nowString === row.appointmentDate && currentTime > appointmentTime.replaceAll(" ", "")) {
        return true;
      } else {
        return undefined;
      }
    } else {
      return true;
    }
  };

  const isViewDisable = (row) => {
    if (row?.appointmentStatus === PRESCRIPTION_DONE || row?.appointmentStatus === COMPLETED) {
      return undefined;
    } else {
      return true;
    }
  };

  const isCallJoinDisable = (row) => {
    if (
      row?.appointmentType === TELE_CONSULTATION &&
      (row.appointmentStatus === SCHEDULED || row.appointmentStatus === RESCHEDULED)
    ) {
      const appointmentTime =
        timeFormat().toString() === "12"
          ? dayjs(row?.appointmentTime, "hh:mm A").format(HOUR_MINUTE_FORMAT)
          : row?.appointmentTime;
      const cleanedAppointmentTimeStr = appointmentTime.replace(/\s*:\s*/, ":").trim();
      const formattedAppointmentTime = cleanedAppointmentTimeStr + ":00";

      const currentTime = dayjs();
      const appointmentDateTime = dayjs(`${row?.appointmentDate}T${formattedAppointmentTime}`);

      return !(
        currentTime.isAfter(appointmentDateTime.subtract(10, "minute")) &&
        currentTime.isBefore(appointmentDateTime.add(31, "minute"))
      );
    } else {
      return true;
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: handleEditAppointment,
      checkDisable: isDisable,
    },
    {
      label: "Details",
      icon: <i className="ri-information-line ri-xl icon-primary-blue" />,
      onClick: openDetailModal,
    },
    {
      label: "View Prescription",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: (appointmentDetail) =>
        navigate("/home/patientPrescription/" + appointmentDetail.appointmentId),
      checkDisable: isViewDisable,
    },
    {
      label: "View PDF",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: (appointmentDetail) =>
        navigate("/home/prescription/" + appointmentDetail.appointmentId),
      checkDisable: isViewDisable,
    },
    {
      label: "Join Call",
      icon: <VideocamIcon style={{ color: "#004C70" }} />,
      onClick: handleJoinRoom,
      checkDisable: isCallJoinDisable,
    },
    {
      label: (rowData) => {
        return "Cancel";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: openModal,
      checkDisable: isDisable,
    },
  ];

  const fetchFamilyMembers = async () => {
    if (!currentActiveUser()?.familyResponse) {
      return;
    }
    try {
      const response = await getPatientFamilyDetails(currentActiveUser()?.familyResponse?.id);
      const membersList = response.data?.userFamilyMappingResponses?.map((member) => ({
        name: member.userResponse?.name,
        id: member.userId,
        isDoctor: false,
      }));
      setFamilyMembersList(membersList);
    } catch (error) {
      console.error(error, "error finding family members list");
    }
  };

  useEffect(() => {
    fetchFamilyMembers();
  }, []);

  const today = dayjs();
  const startDate = today.format(DATE_FORMAT);

  const dateOptions = [
    {
      label: "All",
      startDate: null,
      endDate: null,
    },
    {
      label: "Today",
      startDate: startDate,
      endDate: startDate,
    },
    {
      label: "Yesterday",
      startDate: today.subtract(1, "day").format(DATE_FORMAT),
      endDate: today.subtract(1, "day").format(DATE_FORMAT),
    },
    {
      label: "Last 7 Days",
      startDate: today.subtract(7, "day").format(DATE_FORMAT),
      endDate: startDate,
    },
    {
      label: "Last 30 Days",
      startDate: today.subtract(30, "day").format(DATE_FORMAT),
      endDate: startDate,
    },
    {
      label: "Last 3 Months",
      startDate: today.subtract(3, "month").format(DATE_FORMAT),
      endDate: startDate,
    },
    {
      label: "Enter date range",
      startDate: fromDate,
      endDate: toDate,
    },
  ];

  const filterDrawer = (
    <FilterDrawer
      title="Filters"
      filters={[
        {
          label: "Search Person",
          componentType: "autocomplete",
          options:
            names?.map((e) => ({
              value: e?.id,
              label: e?.isDoctor ? `Dr. ${e?.name}` : e?.name,
              name: e?.name,
              isDoctor: e?.isDoctor,
            })) || [],
          onChange: (event, newValue) => {
            setSearchParams({
              ...searchParams,
              doctorId: newValue?.isDoctor ? newValue?.value : null,
              familyMemberUserId: newValue?.isDoctor ? null : newValue?.value,
            });
            setApplyFilterDisable(false);
          },
          value:
            names
              .map((e) => ({
                value: e?.id,
                label: e?.isDoctor ? `Dr. ${e?.name}` : e?.name,
                name: e?.name,
                isDoctor: e?.isDoctor,
              }))
              .find(
                (option) =>
                  option.value === searchParams?.doctorId ||
                  option.value === searchParams?.familyMemberUserId
              ) || null,
          placeholder: "Select Member",
        },
        {
          label: "Appointment Status",
          componentType: "radio",
          name: "status",
          value: searchParams?.status || null,

          onChange: (e) => {
            const newStatus = e.target.value;

            setSearchParams({ ...searchParams, status: newStatus });

            setStatusCompleted(newStatus === COMPLETED);
            setStatusScheduled(newStatus === SCHEDULED);
            setStatusCancelled(newStatus === CANCELLED);
            setStatusNoShow(newStatus === NO_SHOW);

            setApplyFilterDisable(false);
          },
          options: [
            {
              value: COMPLETED,
              label: "Completed",

              checked: searchParams?.status === COMPLETED,
            },
            {
              value: SCHEDULED,
              label: "Scheduled",

              checked: searchParams?.status === SCHEDULED,
            },
            {
              value: CANCELLED,
              label: "Cancelled",

              checked: searchParams?.status === CANCELLED,
            },
            {
              value: NO_SHOW,
              label: "No Show",

              checked: searchParams?.status === NO_SHOW,
            },
          ],
        },
        {
          label: "Appointment Type",
          componentType: "radio",
          name: "appointmentType",
          value: searchParams?.appointmentType || null,

          onChange: (e) => {
            const selectedType = e.target.value;
            setSearchParams({
              ...searchParams,
              appointmentType: selectedType,
            });

            if (selectedType === TELE_CONSULTATION) {
              setClinicVisitSelected(false);
              setTeleconsultationSelected(true);
            } else if (selectedType === CLINIC_VISIT) {
              setTeleconsultationSelected(false);
              setClinicVisitSelected(true);
            }

            setApplyFilterDisable(false);
          },
          options: [
            {
              value: TELE_CONSULTATION,
              label: "Teleconsultation",

              checked: searchParams?.appointmentType === TELE_CONSULTATION,
            },
            {
              value: CLINIC_VISIT,
              label: "Clinic Visit",

              checked: searchParams?.appointmentType === CLINIC_VISIT,
            },
          ],
        },
        {
          label: "Select Date",
          componentType: "autocomplete",
          options: dateOptions,
          onChange: (e, newValue) => {
            if (newValue?.label === "Enter date range") {
              setCustomRange(true);
            } else {
              setCustomRange(false);
              setSearchParams({
                ...searchParams,
                startDate: newValue?.startDate,
                endDate: newValue?.endDate,
              });
            }
            setApplyFilterDisable(false);
          },
          value:
            dateOptions.find(
              (option) =>
                option.startDate === searchParams?.startDate &&
                option.endDate === searchParams?.endDate
            ) || null,
          placeholder: "Date Range",
          customComponent: customRange && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <TextField
                style={{ width: "48%" }}
                label="From Date"
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    startDate: e.target.value,
                  });
                }}
                InputLabelProps={{ shrink: true }}
                error={!isDateValid}
                helperText={!isDateValid ? "From Date should be less than or equal to To Date" : ""}
              />
              <TextField
                style={{ width: "48%" }}
                label="To Date"
                type="date"
                name="toDate"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    endDate: e.target.value,
                  });
                }}
                InputLabelProps={{ shrink: true }}
                error={!isDateValid}
                helperText={
                  !isDateValid ? "To Date should be greater than or equal to From Date" : ""
                }
              />
            </div>
          ),
        },
      ]}
      applyFilterDisable={applyFilterDisable}
      setApplyFilterDisable={setApplyFilterDisable}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      applyFilters={getUserAppointmnetsData}
      resetFilters={() => {
        const {
          familyMemberUserId,
          doctorId,
          appointmentType,
          status,
          startDate,
          endDate,
          ...newObj
        } = searchParams;
        setSearchParams(newObj);
        setCustomRange(false);
        setFromDate("");
        setToDate("");
        setStatusCancelled(false);
        setStatusCompleted(false);
        setStatusScheduled(false);
        setClinicVisitSelected(false);
        setTeleconsultationSelected(false);
        setApplyFilterDisable(true);
        getUserAppointmnetsData(newObj);
      }}
      onClose={() => setOpenDrawer(false)}
    />
  );

  return (
    <PatientAppointmentSkeletonStructure>
      <Reveal style={{ paddingBottom: "20px" }}>
        <ModalUI
          visible={isModalOpen}
          close={() => {
            dispatch(setSelectedAppointmentData({}));
            setIsModalOpen(false);
          }}
          title="Reschedule Appointment"
          style={{
            overflowY: "scroll",
            height: "33.375rem",
            width: "38.125rem",
          }}
          component={<BookAppointmentModal reschedule={true} closeModal={handleCloseModal} />}
        />
        <div className="section-heading">
          <h2 className="page-title">All Appointments</h2>
          <div className="buttons">
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              label="New Appointment"
              onClick={() => {
                navigate("/home/doctorSearch");
              }}
            ></CustomButton>
            <CustomButton
              label={sourceType?.label}
              style={{ marginLeft: "20px" }}
              className={"btn--secondary-light"}
              startIcon={<i className="ri-briefcase-line ri-xl" />}
              endIcon={<i className="ri-arrow-down-s-fill" />}
              dropDownMenuItems={sourceTypeMenu}
              onMenuItemClick={handleFilterAppointmentsBySourceType}
              menuItemHoverColor="#29BF91"
            />
            <CustomButton
              style={{ marginLeft: "20px" }}
              label={"Filters"}
              className={"btn--primary-light"}
              startIcon={<i className={open ? "ri-filter-fill" : "ri-filter-line"} />}
              onClick={toggleDrawer(!open)}
            />
          </div>
        </div>
        <CustomTableNew
          columns={columns}
          tableData={appointment}
          actions={actions}
          paginationProps={{
            rowsPerPage: 10,
          }}
        />
        <ModalUI
          visible={open}
          close={closeModal}
          title="Are you sure you want to cancel this Appointment?"
          component={
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                justifyContent: "center",
              }}
            >
              <CustomButton
                className="btn--secondary"
                onClick={() => DeleteAppointment(selected)}
                label="Yes"
                style={{ marginRight: "30px" }}
              />
              <CustomButton className="btn--primary" onClick={() => closeModal()} label="No" />
            </div>
          }
        />
        <ModalUI
          visible={openDetails}
          closeModal={(e) => {
            setOpenDetails(false);
          }}
          title="Details"
          close={(e) => {
            setOpenDetails(e);
            setSelected([]);
          }}
          component={
            <Grid sx={{ mt: 3 }}>
              <Grid>
                {/* <CustomizedTable columns={detailColumn} tableData={selected} /> */}
                <DetailList details={selected} />
              </Grid>
            </Grid>
          }
        />
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
          {" "}
          {filterDrawer}{" "}
        </Drawer>
      </Reveal>
    </PatientAppointmentSkeletonStructure>
  );
};

const PatientAppointmentSkeletonStructure = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={250}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientAppointment;
