import React from "react";
import FormInputField from "./custom-components/Form-components/FormInputField";
import { useState } from "react";
import { HeadersForAccessTokenAPI, loggedInUser, MOBILE_NUMBER } from "store/constant";
import { FingerprintOutlined } from "@mui/icons-material";
import CustomButton from "./custom-components/CustomButton";
import { api } from "services/AxiosInterceptor";
import { useContext } from "react";
import { ToastContext } from "./custom-components/CustomToast";

const AccessTokenModal = ({ handleModalClose }) => {
  const [mobileNumber, setMobileNumber] = useState("7973998261");
  const [otp, setotp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const { handleClick } = useContext(ToastContext);

  const sendOtp = async () => {
    try {
      const response = await api.post(
        `https://hc.argusservices.in/hc-auth/otp/generate`,
        {
          contactField: mobileNumber,
          otpType: MOBILE_NUMBER,
          otp: null,
        },
        { showLoader: false, disabledTenant: true }
      );

      setOtpSent(true);
      handleClick("success", response?.data);
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
      console.error(error, "error fetching otp");
    }
  };

  const verifyOtp = async () => {
    try {
      const requestBody = {
        contactField: mobileNumber,
        otpType: MOBILE_NUMBER,
        otp,
      };

      const response = await api.post(`https://hc.argusservices.in/hc-auth/token`, requestBody, {
        headers: HeadersForAccessTokenAPI,
        withCredentials: true,
        disabledTenant: true,
      });

      const accessTokenForCloud = response?.data?.access_token;
      localStorage.setItem("accessTokenForCloud", accessTokenForCloud);
      handleClick("success", "Access granted");
      handleModalClose();
    } catch (error) {
      console.error(error, "error verifying otp");
    }
  };

  return (
    <div style={{}}>
      <p style={{ margin: "15px 5px" }}>
        You need an access token to proceed. Please obtain the necessary permissions to continue
        using this service.
      </p>
      <h3>Verify your Number for access</h3>

      <FormInputField
        style={{
          width: "100%",
          marginRight: "30px",
          marginTop: "25px",
        }}
        label="Mobile Number"
        type={"tel"}
        disabled={true}
        value={mobileNumber}
        startAdornment={<i className="ri-phone-line ri-xl" />}
        size={"big"}
      />

      {otpSent && (
        <FormInputField
          style={{
            width: "100%",
            marginRight: "30px",
            marginTop: "25px",
          }}
          label="Enter Otp"
          type="tel"
          value={otp}
          onChange={(e) => setotp(e.target.value)}
          startAdornment={<FingerprintOutlined />}
          size={"big"}
        />
      )}

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={otpSent ? "Verify Otp" : "Senddd Otp"}
          className="btn--secondary"
          onClick={otpSent ? verifyOtp : sendOtp}
        />
      </div>
    </div>
  );
};

export default AccessTokenModal;
