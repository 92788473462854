import {
  CancelOutlined,
  DomainVerification,
  EventOutlined,
  HealthAndSafetyOutlined,
  Liquor,
  SmokingRoomsOutlined,
  Visibility,
} from "@mui/icons-material";
import { Avatar, Grid, Menu, MenuList, Skeleton, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { updateAppointmentStatus } from "services/Appointments";
import { getProfilePicture } from "services/patientService";
import { CANCELLED, CHECKED_IN, hiuToken, hiuUrl } from "store/constant";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { calculateAge } from "utils/calculate-age";
import "assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-card.scss";
import ModalUI from "ui-component/ModalUI";
import PatientVitalSignModal from "views/Components/Patient/PatientProfile/PatientVitalSignModal";
import { getVitalSignTypes } from "services/EntitiesServices";
import { useSelector } from "react-redux";
import { getSethuSirLayout } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";

const PatientPrescriptionCard = ({
  patientId,
  patientData,
  showVisited,
  lastVisited,
  lifestyleHabits,
  setNewVitalAdded,
  setIframeSrc,
  setIframeOpen,
  selectedAppointment,
  setCheckedInAppointment,
  handleNewModalOpenForReschedulingAppointment,
  parentAppointmentsRefresh,
  doctorData,
  setTodaysAppointments,
  todaysAppointment,
  handleCancelAppointment,
}) => {
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { handleClick } = useContext(ToastContext);

  const sethuSirLayout = useSelector(getSethuSirLayout);

  const [vitalModalOpen, setVitalModalOpen] = useState(false);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);

  const closeModal = () => {
    setVitalModalOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      setIsLoading(true);
      try {
        const profilePicResponse = await getProfilePicture(
          patientData?.userResponse?.id || patientData?.userId
        );
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        setProfileImageFileWithContent(null);
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfilePic();
  }, [patientData?.userResponse?.id, patientData?.userId]);

  const navigate = useNavigate();
  const actions = [
    {
      label: "Reschedule",
      icon: <EventOutlined style={{ color: "#004c70" }} />,
      onClick: () => {
        handleNewModalOpenForReschedulingAppointment(selectedAppointment);
      },
    },
    {
      label: "Check-In",
      icon: <DomainVerification style={{ color: "#29bf91" }} />,
      onClick: async () => {
        try {
          await updateAppointmentStatus(selectedAppointment?.id, CHECKED_IN);
          handleClick("success", "Patient Checked-in successfully!");
          setCheckedInAppointment(selectedAppointment);
          selectedAppointment.appointmentStatus = CHECKED_IN;
          // const UpdatedItem = { ...item, appointmentStatus: CHECKED_IN };
          // setUpdatedItem(UpdatedItem);
          // parentAppointmentsRefresh(doctorData);
        } catch {
          handleClick("error", "There seems to be an error in making patient checked-in");
        }
      },
      disabled: selectedAppointment?.appointmentStatus !== CHECKED_IN ? false : true,
    },
    {
      label: "View EHR",
      icon: <Visibility style={{ color: "#004c70" }} />,
      onClick: () => {
        navigate(`/home/ehr/${selectedAppointment?.patientId}`);
      },
    },
    {
      label: "Add Vital",
      icon: <HealthAndSafetyOutlined style={{ color: "#29bf91" }} />,
      onClick: () => {
        const fetchVitalSignTypes = async () => {
          try {
            const response = await getVitalSignTypes();
            setVitalSignTypes(response.data);
          } catch (error) {
            console.error("Error fetching Vital sign types");
          }
        };
        fetchVitalSignTypes();
        setVitalModalOpen(true);
      },
    },
    {
      label: "Fetch Abha Records",
      icon: <img height="20px" src="/images/abha_logo.svg" alt="abha_logo"></img>,
      onClick: () => {
        setIframeOpen(true);
        setIframeSrc(hiuUrl(doctorData?.id, patientData?.abhaId));
      },
    },
    {
      label: "Cancel",
      icon: <CancelOutlined style={{ color: "#f5574c" }} />,
      onClick: () => {
        handleCancelAppointment(selectedAppointment);
      },
      // async () => {
      //   try {
      //     await updateAppointmentStatus(selectedAppointment?.id, CANCELLED);
      //     parentAppointmentsRefresh(doctorData);
      //   } catch {
      //     console.error("Error cancelling appointment");
      //   }
      // }
    },
  ];

  const [maxWidthOfName, setMaxWidthOfName] = useState(0);

  useEffect(() => {
    const divElement = document.querySelector(".patient-prescription-details");

    const handleResize = () => {
      try {
        const calculatedWidth = (divElement.offsetWidth * 25) / 100;
        setMaxWidthOfName(calculatedWidth);
      } catch (error) {
        console.error(error);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(divElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    // <div className="common-scrollbar common-scrollbar-x" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "auto", maxHeight: "100%" }}>
    <div className="patient-card">
      <Grid size={{ md: 12 }} className="px-0">
        <div
          className="profile-section"
          style={{
            justifyContent: sethuSirLayout?.value ? undefined : "space-between",
            gap: sethuSirLayout?.value ? "0.3rem" : undefined,
          }}
        >
          <div className="patient-avatar-container">
            {isLoading ? (
              <Skeleton variant="circular" animation="wave" width={"50px"} height={"50px"} />
            ) : (
              <img
                style={{
                  borderRadius: "50%",
                  // marginRight: "6px",
                  width: "50px",
                  height: "50px",
                }}
                src={
                  profileImageFileWithContent
                    ? profileImageFileWithContent.content
                    : (patientData?.userResponse?.gender || patientData?.gender) === "FEMALE"
                    ? "/images/woman.png"
                    : "/images/man.png"
                }
                alt=""
              />
            )}
          </div>
          <div className="patient-details-container">
            <div className="patient-name-block">
              <Tooltip title={patientData?.userResponse?.name || patientData?.patientName}>
                <p className="patient-name" style={{ maxWidth: maxWidthOfName }}>
                  {patientData?.userResponse?.name || patientData?.patientName}
                </p>
              </Tooltip>
              {selectedAppointment?.appointmentStatus === CHECKED_IN && (
                <div className="checked-in-status">
                  <Tooltip title={"Checked-in"}>
                    <i className="ri-checkbox-circle-fill ri-xl icon-primary-green" />
                  </Tooltip>
                </div>
              )}
            </div>
            <p className="extra-details">
              {patientData?.userResponse?.dateOfBirth && (
                <span className="detail">{`${calculateAge(
                  patientData?.userResponse?.dateOfBirth
                )}y`}</span>
              )}
              {patientData?.userResponse?.gender && (
                <span className="detail">{patientData?.userResponse?.gender.charAt(0)}</span>
              )}
              {patientData?.userResponse?.mobileNumber && (
                <span className="detail">{patientData?.userResponse?.mobileNumber}</span>
              )}
            </p>
            {showVisited && (
              <div className="last-visited">
                <img src="/images/hc_rx.svg" alt="hc_rx"></img>
                <span style={{ padding: "0 6px" }}>
                  Last visited {lastVisited !== 0 ? lastVisited : ""}{" "}
                  {lastVisited <= 1 ? (lastVisited === 0 ? "today" : "day ago") : "days ago"}
                </span>
              </div>
            )}
          </div>
          {!sethuSirLayout?.value && (
            <div className="menu-button">
              <div>
                <CustomButton
                  iconButton={<i className="ri-more-2-fill" />}
                  onClick={(event) => handleMenuOpen(event)}
                  style={{ padding: "3px" }}
                ></CustomButton>
                <Menu
                  elevation={1}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{ style: { borderRadius: "4px" } }}
                >
                  <MenuList dense sx={{ p: 0 }}>
                    {actions.map((action, actionIndex) => {
                      return (
                        <CustomMenuItem
                          key={actionIndex}
                          text={action.label}
                          icon={action.icon}
                          onClick={() => {
                            handleMenuClose();
                            action.onClick();
                          }}
                          disabled={action?.disabled}
                        />
                      );
                    })}
                  </MenuList>
                </Menu>
              </div>
            </div>
          )}
        </div>
      </Grid>
      <div className="lifestyle-section">
        <div className="lifestyle">
          <Tooltip title={lifestyleHabits["Drinking"] ? "Drinking" : ""} arrow>
            <Liquor
              sx={{
                p: "5px",
                fontSize: { md: "30px", lg: "30px", xl: "37px" },
                fill: "#DD2025",
                backgroundColor: "inherit",
                filter: !lifestyleHabits["Drinking"] ? "grayscale(100%)" : undefined,
                opacity: !lifestyleHabits["Drinking"] ? "0.5" : undefined,
              }}
            />
          </Tooltip>
          <Tooltip title={lifestyleHabits["Smoking"] ? "Smoking" : ""} arrow>
            <SmokingRoomsOutlined
              sx={{
                p: "5px",
                fontSize: { md: "30px", lg: "30px", xl: "37px" },
                fill: "#F6AB65",
                backgroundColor: "inherit",
                filter: !lifestyleHabits["Smoking"] ? "grayscale(100%)" : undefined,
                opacity: !lifestyleHabits["Smoking"] ? "0.5" : undefined,
              }}
            />
          </Tooltip>
          <Tooltip title={lifestyleHabits["Consuming Tobacco"] ? "Consuming Tobacco" : ""} arrow>
            <Avatar
              src="/images/tobacco.svg"
              sx={{
                padding: "5px",
                color: "gray",
                height: { md: "30px", lg: "30px", xl: "37px" },
                width: { md: "30px", lg: "30px", xl: "37px" },
                backgroundColor: "inherit",
                filter: !lifestyleHabits["Consuming Tobacco"] ? "grayscale(100%)" : undefined,
                opacity: !lifestyleHabits["Consuming Tobacco"] ? "0.5" : undefined,
              }}
              alt="Consuming Tobacco"
            ></Avatar>
          </Tooltip>
          {lifestyleHabits["Vegetarian"] ? (
            <Tooltip title="Vegetarian" arrow>
              <Avatar
                src="/images/vegetarian-diet.svg"
                sx={{
                  padding: "5px",
                  height: { md: "30px", lg: "30px", xl: "37px" },
                  width: { md: "30px", lg: "30px", xl: "37px" },
                  backgroundColor: "inherit",
                }}
                alt="Vegetarian"
              ></Avatar>
            </Tooltip>
          ) : (
            <>
              {lifestyleHabits["Eggetarian"] ? (
                <Tooltip title="Eggetarian" arrow>
                  <Avatar
                    src="/images/eggetarian-diet.svg"
                    sx={{
                      padding: "5px",
                      height: { md: "30px", lg: "30px", xl: "37px" },
                      width: { md: "30px", lg: "30px", xl: "37px" },
                      backgroundColor: "inherit",
                    }}
                    alt="Eggetarian"
                  ></Avatar>
                </Tooltip>
              ) : (
                <>
                  {lifestyleHabits["Non-vegetarian"] ? (
                    <Tooltip title="Non-vegetarian" arrow>
                      <Avatar
                        src="/images/non-vegetarian-diet.svg"
                        sx={{
                          padding: "5px",
                          height: { md: "30px", lg: "30px", xl: "37px" },
                          width: { md: "30px", lg: "30px", xl: "37px" },
                          backgroundColor: "inherit",
                        }}
                        alt="Non-vegetarian"
                      ></Avatar>
                    </Tooltip>
                  ) : (
                    <Avatar
                      src="/images/non-vegetarian-diet.svg"
                      sx={{
                        padding: "5px",
                        height: { md: "30px", lg: "30px", xl: "37px" },
                        width: { md: "30px", lg: "30px", xl: "37px" },
                        backgroundColor: "inherit",
                        filter: "grayscale(100%)",
                        opacity: "0.5",
                      }}
                      alt="Non-vegetarian"
                    ></Avatar>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {selectedAppointment?.patient?.abhaId && (
          <Avatar
            height="25px"
            src="/images/abha_logo.svg"
            alt="abha_logo"
            onClick={(event) => {}}
            sx={{
              padding: "5px",
              height: { md: "30px", lg: "30px", xl: "37px" },
              width: { md: "30px", lg: "30px", xl: "37px" },
              backgroundColor: "inherit",
            }}
          ></Avatar>
        )}
      </div>
      <ModalUI
        visible={vitalModalOpen}
        close={closeModal}
        title={"Add Patient Vital Sign"}
        component={
          <>
            <PatientVitalSignModal
              selected={null}
              close={(operation) => {
                if (operation === "save") {
                  setNewVitalAdded(true);
                  closeModal();
                } else {
                  closeModal();
                }
              }}
              patientId={selectedAppointment?.patientId}
              vitalSignTypes={vitalSignTypes}
            ></PatientVitalSignModal>
          </>
        }
      ></ModalUI>
    </div>
  );
};

export default PatientPrescriptionCard;
