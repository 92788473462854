import React, { useContext, useState } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import "assets/scss/profileCard.scss";
import { InputAdornment } from "@mui/material";
import CustomInputField from "ui-component/custom-components/CustomInputField";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ABHA_ADDRESS } from "store/constant";
import { updateAbdmDetail } from "services/AbhaService";

const AbhaDetails = ({ patientAbhaDetails, setPatientAbhaDetails, txnId, patientId }) => {
  const { handleClick } = useContext(ToastContext);

  const abhaAddressList = patientAbhaDetails?.abhaAddressList?.slice(0, 5);
  const abhaCardPhoto = patientAbhaDetails?.abhaCard;
  const profilePhoto = patientAbhaDetails?.profile?.profilePhoto;

  const formatDateOfBirth = (day, month, year) => {
    if (!day || !month || !year) return "";
    return `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`;
  };

  const dayOfBirth = patientAbhaDetails?.profile?.dayOfBirth;
  const monthOfBirth = patientAbhaDetails?.profile?.monthOfBirth;
  const yearOfBirth = patientAbhaDetails?.profile?.yearOfBirth;

  const combinedDateOfBirth = formatDateOfBirth(dayOfBirth, monthOfBirth, yearOfBirth);
  const [isEditing, setIsEditing] = useState(false);
  const [preferredAbhaAddress, setPreferredAbhaAddress] = useState(
    patientAbhaDetails?.profile?.preferredAbhaAddress || ""
  );

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setPreferredAbhaAddress(event.target.value);
  };

  const handleSave = async (preferredAbhaAddress) => {
    const data = { txnId: txnId, scope: ABHA_ADDRESS, updateDetail: preferredAbhaAddress };
    try {
      const res = await updateAbdmDetail(patientId, data);
      if (res) {
        // settxnId(res.data.txnId);
        const updatedAbhaAddress = res.data.abha_address.preferredAbhaAddress;
        const updatedPatientAbhaDetails = {
          ...patientAbhaDetails,
          profile: {
            ...patientAbhaDetails.profile,
            preferredAbhaAddress: updatedAbhaAddress,
          },
        };
        setPatientAbhaDetails(updatedPatientAbhaDetails);
        setPreferredAbhaAddress(updatedAbhaAddress);
        setIsEditing(false);
        handleClick("success", "Details have been successfully updated.");
      }
    } catch (e) {
      handleClick("error", e.response?.data?.message);
    }
  };

  return (
    <div style={{ display: "flex", gap: "1em" }}>
      <div style={{ width: "35%", display: "flex" }}>
        <div
          className="user-profile-card"
          style={{ maxWidth: "100%", width: "100%", height: "650px", borderRadius: "0px" }}
        >
          <div className="user-image-container" style={{ borderRadius: "0px" }}>
            <img
              className="user-avatar"
              style={{ width: "160px", height: "160px" }}
              src={`data:image/jpeg;base64,${profilePhoto}`}
              alt=""
            ></img>
          </div>
          <div className="user-detail-container" style={{ borderRadius: "0px" }}>
            <div style={{ paddingTop: "60px" }}>
              <div className="user-detail-fullname" style={{ marginTop: "1em" }}>
                <p>{patientAbhaDetails?.profile?.name}</p>
              </div>
              <div className="user-detail-gender" style={{ marginTop: "1em" }}>
                <p>{patientAbhaDetails?.profile?.gender}</p>
              </div>
              <div className="user-detail-birth" style={{ marginTop: "1em" }}>
                <p>{combinedDateOfBirth}</p>
              </div>
              <div className="user-detail-email" style={{ marginTop: "1em" }}>
                <p>{patientAbhaDetails?.profile?.ABHANumber}</p>
              </div>
              <div className="user-detail-email" style={{ marginTop: "1em", marginBottom: "1em" }}>
                {isEditing ? (
                  <>
                    <CustomInputField
                      style={{ width: "80%" }}
                      value={preferredAbhaAddress}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">SAM.123</InputAdornment>,
                      }}
                      autoFocus
                    />
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "40%" }}>
                        <p>Suggestions: </p>
                      </div>
                      <div>
                        {abhaAddressList.map((address, index) => (
                          <p key={index}>{address}</p>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <p style={{ marginLeft: "5em" }}>{preferredAbhaAddress}</p>
                    <i
                      className="ri-edit-fill ri-lg icon-primary-blue hover"
                      onClick={handleEditClick}
                    />
                  </div>
                )}
              </div>
              {isEditing && (
                <div style={{ marginBottom: "1em" }}>
                  <CustomButton
                    label={"Save"}
                    onClick={() => handleSave(preferredAbhaAddress)}
                    className="btn--primary"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "65%", height: "700px" }}>
        {abhaCardPhoto ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              //   borderRadius: "100%",
              backgroundColor: "red",
              backgroundImage: `url(data:image/jpeg;base64,${abhaCardPhoto})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        ) : (
          <div>No image available</div>
        )}
      </div>
    </div>
  );
};

export default AbhaDetails;
