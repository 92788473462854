import {
  AddOutlined,
  ClearOutlined,
  GroupOutlined,
  HomeOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import { useState } from "react";
import {
  createPatientEmergencyContact,
  deletePatientEmergencyContact,
  editPatientEmergencyContact,
  getPatientEmergencyContactByPatientId,
} from "services/patientService";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  currentActiveUser,
  familyRelations,
  startsAndEndsWithAlphabetic,
  startsWithAlphabetic,
} from "store/constant";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { PatientProfileTablesSkeleton } from "./PatientFamilyDetails";
import CustomTableNew from "ui-component/custom-components/custom-table/CustomTableNew";

const columns = [
  { field: "#", label: "#", align: "center", width: "7%", isSerial: true },
  { field: "name", label: "Name", width: "19%" },
  { field: "relationship", label: "Relationship", width: "15%" },
  { field: "mobileNumber", label: "Mobile Number", width: "15%" },
  { field: "email", label: "Email", width: "22%" },
  { field: "address", label: "Address", width: "22%" },
];

const PatientEmergencyContact = () => {
  const patientId = currentActiveUser().roleBasedId;
  const { handleClick } = useContext(ToastContext);

  const [patientEmergencyContacts, setPatientEmergencyContacts] = useState([]);
  const [displayPatientEmergencyContacts, setDisplayPatientEmergencyContacts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientEmergencyContact(row.id);
      handleClick("success", "Patient Emergency-contact has been successfully deleted.");
      const index = patientEmergencyContacts.findIndex((el) => el.id === row.id);
      patientEmergencyContacts.splice(index, 1);
      setDisplayPatientEmergencyContacts(patientEmergencyContacts);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient Emergency-contact.");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pEmergencyContact) => {
    setSelected(pEmergencyContact);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchPatientEmergencyContacts = async () => {
    try {
      const response = await getPatientEmergencyContactByPatientId(patientId);
      setPatientEmergencyContacts(response.data);
    } catch (error) {
      console.error("Error fetching patient emergency contacts");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    fetchPatientEmergencyContacts();
  }, []);

  useEffect(() => {
    setDisplayPatientEmergencyContacts(
      patientEmergencyContacts.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 7) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientEmergencyContacts, searchQuery]);

  return (
    <PatientProfileTablesSkeleton>
      <Reveal>
        <div className="section-heading">
          <h2 className="page-title">Emergency Contact Details</h2>
        </div>
        <Grid display={"flex"} alignItems={"center"} sx={{ pt: "10px", mb: 1.4 }}>
          <FormInputField
            style={{ width: "40.8%", margin: "5px 0" }}
            label={"Search Emergency-contact"}
            value={searchQuery}
            startAdornment={<i className="ri-search-line ri-lg" />}
            endAdornment={
              <IconButton onClick={clearSearch} edge="end">
                {<ClearOutlined fontSize="small" />}
              </IconButton>
            }
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            size="small"
          ></FormInputField>
          <CustomButton
            style={{ marginLeft: "auto" }}
            label={"Add"}
            className={"btn--primary"}
            startIcon={<AddOutlined />}
            onClick={openCreateModal}
          />
        </Grid>

        <CustomTableNew
          tableData={displayPatientEmergencyContacts}
          columns={columns}
          actions={actions}
          paginationProps={{ rowsPerPage: 10 }}
        ></CustomTableNew>

        <ModalUI
          visible={open}
          close={closeModal}
          title={selected ? "Update Patient Emergency Contact" : "Create Patient Emergency Contact"}
          component={
            <PatientEmergencyContactModal
              selected={selected}
              handleClick={handleClick}
              close={(operation) => {
                if (operation === "save") {
                  closeModal();
                  fetchPatientEmergencyContacts();
                } else {
                  closeModal();
                }
              }}
              patientId={patientId}
            />
          }
        />
      </Reveal>
    </PatientProfileTablesSkeleton>
  );
};

const PatientEmergencyContactModal = ({ patientId, selected, handleClick, close }) => {
  const [name, setName] = useState(selected?.name ? selected?.name : "");
  const [nameTouched, setNameTouched] = useState(false);

  const [relationship, setRelationship] = useState(
    selected?.relationship ? selected?.relationship : ""
  );

  const [mobileNumber, setMobileNumber] = useState(
    selected?.mobileNumber ? selected?.mobileNumber : ""
  );
  const [mobileNumberTouched, setMobileNumberTouched] = useState(false);

  const [email, setEmail] = useState(selected?.email ? selected?.email : "");
  const [emailTouched, setEmailTouched] = useState(false);

  const [address, setAddress] = useState(selected?.address ? selected?.address : "");
  const [addressTouched, setAddressTouched] = useState(false);

  const [errors, setErrors] = useState({});

  const validateName = (errors, name) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!name) {
      errors.name = "Please enter a valid name.";
    } else if (name.length < 3) {
      errors.name = "Name must be at least 3 characters long";
    } else if (name.length > 100) {
      errors.name = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(name)) {
      errors.name = "Name can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(name)) {
      errors.name = "Name must start and end with alphabet";
    }
  };

  const validateMobileNumber = (errors, mobileNumber) => {
    if (!mobileNumber) {
      errors.mobileNumber = "Please enter a valid mobile number.";
    } else if (!/^[6-9][0-9]{9}$/.test(mobileNumber)) {
      errors.mobileNumber = "Please enter valid mobile number";
    }
  };

  const validateEmail = (errors, email) => {
    if (!email) {
      errors.email = "Please enter a valid email.";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      errors.email = "Please enter valid email address.";
    }
  };

  const validateAddress = (errors, address) => {
    if (!address) {
      errors.address = "Please enter a valid address.";
    } else if (address.length < 3) {
      errors.address = "Address must be at least 3 characters long";
    } else if (address.length > 255) {
      errors.address = "Address cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(address)) {
      errors.address = "Address must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setNameTouched(true);
    validateName(errors, name);

    setMobileNumberTouched(true);
    validateMobileNumber(errors, mobileNumber);

    setEmailTouched(true);
    validateEmail(errors, email);

    setAddressTouched(true);
    validateAddress(errors, address);

    if (!relationship) {
      errors.relationship = "Please select a relationship.";
    }

    return errors;
  };

  const saveFamilyHistory = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        name,
        relationship,
        mobileNumber,
        email,
        address,
      };

      if (selected) {
        try {
          await editPatientEmergencyContact(selected.id, data);
          handleClick("success", "Patient Emergency Contact has been successfully updated.");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error updating the patient emergency contact");
        }
      } else {
        try {
          await createPatientEmergencyContact(data);
          handleClick("success", "Patient Emergency Contact has been successfully added.");
          close("save");
        } catch (error) {
          handleClick(
            "error",
            "There seems to be an error creating the patient emergency contact."
          );
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={0} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }} sx={{ ml: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Name"
            required
            name="name"
            value={name}
            onBlur={(e) => {
              setNameTouched(true);
              const newError = {};
              validateName(newError, name);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setName(e.target.value);
              if (nameTouched) {
                const newError = {};
                validateName(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.name;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            error={Boolean(errors.name)}
            errorText={errors.name}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mt: 2 }}>
          <FormSelectField
            style={{ width: "100%", marginTop: "7px" }}
            label={"Relationship"}
            name={"relationship"}
            required
            value={relationship}
            size={"big"}
            startAdornment={<GroupOutlined />}
            onChange={(e) => {
              setRelationship(e.target.value);
              const newErrors = { ...errors };
              delete newErrors.relationship;
              setErrors(newErrors);
            }}
            error={Boolean(errors.relationship)}
            errorText={errors.relationship}
            menuItems={familyRelations}
          ></FormSelectField>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mt: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Mobile Number"
            name="mobileNumber"
            required
            type={"tel"}
            value={mobileNumber}
            inputProps={{
              maxLength: 10,
            }}
            onBlur={(e) => {
              setMobileNumberTouched(true);
              const newError = {};
              validateMobileNumber(newError, mobileNumber);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setMobileNumber(e.target.value);
              if (mobileNumberTouched) {
                const newError = {};
                validateMobileNumber(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.mobileNumber;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<i className="ri-phone-line ri-xl" />}
            size={"big"}
            error={Boolean(errors.mobileNumber)}
            errorText={errors.mobileNumber}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mt: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Email"
            type="email"
            required
            name="email"
            value={email}
            onBlur={(e) => {
              setEmailTouched(true);
              const newError = {};
              validateEmail(newError, email);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailTouched) {
                const newError = {};
                validateEmail(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.email;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<i className="ri-mail-line ri-lg" />}
            size={"big"}
            error={Boolean(errors.email)}
            errorText={errors.email}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mt: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Address"
            name="address"
            required
            value={address}
            onBlur={(e) => {
              setAddressTouched(true);
              const newError = {};
              validateAddress(newError, address);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setAddress(e.target.value);
              if (addressTouched) {
                const newError = {};
                validateAddress(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.address;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<HomeOutlined />}
            size={"big"}
            error={Boolean(errors.address)}
            errorText={errors.address}
          />
        </Grid>

        <Grid sx={{ mt: 2 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={saveFamilyHistory}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientEmergencyContact;
