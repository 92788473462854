import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { styled } from "@mui/material/styles";

const StyledTextField = styled("input")(({ theme }) => ({
  backgroundColor: "#e3e3e3",
  borderRadius: "0px",
  padding: "0px 5px",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 500,
  border: "none",
  "&:focus-visible": {
    outline: "none !important",
  },
  "&::placeholder": {
    color: "#939393",
  },
}));

const StyledSpan = styled("span")(({ theme }) => ({
  backgroundColor: "transparent",
  borderRadius: "0px",
  padding: "0px",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  fontFamily: '"IBM Plex Mono"',
  fontSize: "14px",
  lineHeight: "18px",
  color: "#485772",
  fontWeight: 500,
  border: "none",
  width: "auto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const ResizableEditInputField = ({
  name,
  error,
  style,
  errorText,
  initialValue = "",
  handleUpdate,
  index,
  field,
  maxLength,
  textLimitations,
  isTeleconsultation = false,
  showBrackets = false,
  placeHolder = "Add Text",
  isEditable = false,
  InputProps,
  ...restProps
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(value?.length, value?.length);
    }
  }, [readOnly]);

  const handleDoubleClick = () => {
    isEditable && setReadOnly(false);
  };

  const formatDuration = (input) => {
    // Regular expression to match numeric part
    const matchNumeric = input?.match(/\d+/);
    const numericPart = matchNumeric ? parseInt(matchNumeric[0]) : 0;

    // Regular expression to match string part
    const matchString = input?.match(/([^0-9]+)\b/);
    const stringPart = matchString ? matchString[0].trim() : "";

    let durationUnit;
    switch (stringPart.toLowerCase().charAt(0)) {
      case "m":
        durationUnit = "Month";
        break;
      case "w":
        durationUnit = "Week";
        break;
      case "y":
        durationUnit = "Year";
        break;
      default:
        durationUnit = "Day";
        break;
    }

    return `${numericPart} ${durationUnit}${numericPart > 1 ? "s" : ""}`;
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    inputRef.current.style.width = `${event.target.value.length + 2}ch`;
  };

  const handleBlur = () => {
    if (field === "duration" || field === "since") {
      const formattedDuration = formatDuration(value);
      setValue(formattedDuration);
      handleUpdate(index, field, formattedDuration);
    }
    setReadOnly(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (field === "duration" || field === "since") {
        const formattedDuration = formatDuration(event.target.value);
        setValue(formattedDuration);
        handleUpdate(index, field, formattedDuration);
      } else {
        handleUpdate(index, field, event.target.value);
      }
      setReadOnly(true);
    }
  };

  return readOnly ? (
    <StyledSpan
      onDoubleClick={handleDoubleClick}
      style={{
        width: `${value.length}ch`,
        marginLeft: showBrackets ? "4px" : "0px",
        cursor: isEditable ? "pointer" : "default",
        color: "#485772",
      }}
    >
      {value ? (
        showBrackets ? (
          `(${value})`
        ) : (
          value
        )
      ) : (
        <i className="ri-add-line" style={{ color: "green" }} onClick={handleDoubleClick}></i>
      )}
    </StyledSpan>
  ) : (
    <StyledTextField
      ref={inputRef}
      type="text"
      value={value || null}
      placeholder={placeHolder}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      onBlur={handleBlur}
      readOnly={readOnly}
      maxLength={maxLength}
      style={{
        width: value ? `${value.length + 2}ch` : `${placeHolder.length + 2}ch`,
      }}
      {...InputProps}
      {...restProps}
    />
  );
};

export default ResizableEditInputField;
