import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, FormControl, Skeleton, Typography, Grid2 } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Formik } from "formik";
import {
  PATIENT,
  CREATE_ABHA_VIA_AADHAAR,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  VERIFY_AADHAAR_OTP,
  currentActiveUser,
  VERIFICATION_VIA_MOBILE,
  VERIFICATION_VIA_AADHAAR,
  VERIFICATION_VIA_ABHA_NUMBER,
  VERIFICATION_VIA_ABHA_ADDRESS,
  VERIFICATION_VIA_ABHA_USING_MOBILE,
  VERIFICATION_VIA_ABHA_USING_AADHAAR,
  VERIFICATION_ABHA_USING_MOBILE_OTP,
  VERIFICATION_ABHA_USING_AADHAAR_OTP,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
  VERIFY_SELECTED_ABHA_NUMBER,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  AbcOutlined,
  NumbersOutlined,
  FingerprintOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { useLocation } from "react-router";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  generateAbdmAadhaarOtp,
  resendAbdmAadhaarOtp,
  updateAbdmDetail,
  verifyAbdmAadhaarOtp,
  verifyGetAbhaOtpViaMobile,
  verifyGetAbhaOtpViaAbhaEntities,
  getLinkedAbha,
} from "services/AbhaService";
import { getPatientByUserId } from "services/patientService";
import Reveal from "views/utilities/Reveal";
import { createAbhaValidation } from "views/Components/Common/ValidationSchema/createAbhaValidation";
import AbhaDetails from "./AbhaDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import ProfileCard from "ui-component/cards/ProfileCard";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { getProfilePicture } from "services/patientService";

const ManageAbhaNumber = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const { state } = useLocation();
  const patientIdRef = useRef(state?.patientId ? state.patientId : null);
  const [patientInfo, setPatientInfo] = useState({
    fullInfo: {},
    patientId: state?.patientId || null,
  });

  const [checkedStates, setCheckedStates] = useState([true, false, true, true, true, false]);
  const [isSelectedIndex, setIsSelectedIndex] = useState(0);
  const [patientAbhaDetails, setPatientAbhaDetails] = useState({});
  const [isCreateAbha, setIsCreateAbha] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [txnId, setTxnId] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [otpSentMessage, setOtpSentMessage] = useState(null);
  const [getAbhaScope, setGetAbhaScope] = useState(VERIFICATION_VIA_MOBILE);
  const [getAbhaScope2, setGetAbhaScope2] = useState("");
  const [getAbhaEntityValue, setGetAbhaEntityValue] = useState("");
  const formikRef = useRef(null);
  const [accountOptions, setAccountOptions] = useState([]);
  const linkedAbhaNumberRef = useRef(null);
  const [abhaImage, setAbhaImage] = useState(null);
  const [token, setToken] = useState(null);
  const [backBtnStack, setBackBtnStack] = useState([0]);
  const [inputLimits, setInputLimits] = useState({
    maxLength: 10,
    type: "tel",
    message: "Enter Mobile Number",
    kind: null,
  });
  const [showCreateAbhaBtn, setShowCreateAbhaBtn] = useState(false);
  const [resendOtpDataObject, setResendOtpDataObject] = useState({});
  const activeUser = currentActiveUser();
  const navigate = useNavigate();
  const isBackButtonVisible =
    !(isSelectedIndex === 4 && activeUser?.roleName === PATIENT) &&
    !(isSelectedIndex === 0 && activeUser?.roleName === PATIENT && isCreateAbha);
  const showPatientCard =
    activeUser?.roleName === "DOCTOR" && isCreateAbha && isSelectedIndex === 0;

  const handleAutoFillDetails = () => {
    const phoneNumber =
      (activeUser?.roleName === PATIENT ? activeUser?.mobileNumber : state?.patientPhoneNumber) ||
      null;

    formikRef.current.setFieldValue("mobileNumber", phoneNumber);
    formikRef.current.setFieldValue("getAbhaEntityText", phoneNumber);
  };

  const handleCheckboxChange = (index) => (e) => {
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = e.target.checked;
      return newStates;
    });
  };

  const fetchProfilePicture = async (userId) => {
    try {
      const { data: pictureData } = await getProfilePicture(userId, true);
      return {
        filename: pictureData.filename,
        content: `data:image/${pictureData.extension};base64,${pictureData.document}`,
      };
    } catch (error) {
      if (error?.response?.status === 400) {
        return null;
      }
      throw error;
    }
  };

  const handleFetchData = async () => {
    const payload = state?.patientUserId || activeUser.id;

    try {
      const response = await getPatientByUserId(payload);
      const profilePicture = showPatientCard ? await fetchProfilePicture(payload) : null;
      setPatientInfo({
        fullInfo: response.data,
        patientId: response.data.userResponse.roleBasedId,
        profilePicture,
      });

      patientIdRef.current = response.data.userResponse.roleBasedId;
      linkedAbhaNumberRef.current = response.data.abhaNumber;

      if (response.data.abhaNumber && response.data.abhaId) {
        handleViewLinkedAbha();
      } else {
        handleAbhaCreationRouting();
      }
    } catch (error) {
      handleClick("info", error.response?.data?.message || "Please Create/Link Abha.");
    }
  };

  const handleAbhaCreationRouting = () => {
    setCheckedStates([true, false, true, true, true, false]);
    setIsSelectedIndex(0);
    setIsCreateAbha(true);
    setBackBtnStack([0]);
    setShowCreateAbhaBtn(false);
    formikRef?.current?.resetForm();
    handleClick("info", "Please Create/Link Abha.");
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (state?.abhaCreationPending) {
      handleAbhaCreationRouting();
    }
  }, [state?.abhaCreationPending]);

  const handleRadioBtnChange = () => {
    setIsCreateAbha(!isCreateAbha);
    setIsSelectedIndex(0);
    setBackBtnStack([0]);
    formikRef.current.resetForm();
    setGetAbhaScope(VERIFICATION_VIA_MOBILE);
    setShowCreateAbhaBtn(false);
    handleAutoFillDetails();
  };

  useEffect(() => {
    if (isSelectedIndex === 2) {
      setTimer(60);
      setResendEnabled(false);
    }
  }, [isSelectedIndex]);

  useEffect(() => {
    let interval;
    if (!resendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer, resendEnabled]);

  const handlePreviousButtonStack = (value) => {
    setIsSelectedIndex(value);
    if (!isCreateAbha || value > 2) {
      setBackBtnStack([0]);
      return;
    } else if (backBtnStack.length > 0) {
      const lastIndexValue = backBtnStack[backBtnStack.length - 1];
      if (lastIndexValue === value) {
        return;
      }
      setBackBtnStack((prevStack) => [...prevStack, value]);
    }
    setTimer(60);
  };

  const handleGenerateAadhaarOtp = async (aadharNumber) => {
    const trimmedAadharNumber = aadharNumber.replace(/\s+/g, "");
    if (!trimmedAadharNumber) {
      handleClick("error", "Please enter a valid Aadhaar Number.");
      return;
    }
    const data = { loginId: trimmedAadharNumber, scope: CREATE_ABHA_VIA_AADHAAR, txnId };
    try {
      const response = await generateAbdmAadhaarOtp(data);
      if (response.status === 200 && response.data) {
        setTxnId(response.data.txnId);
        setOtpSentMessage(response.data.message);
        handleClick("success", "OTP has been successfully sent.");
        handleAutoFillDetails();
        handlePreviousButtonStack(2);
        setResendOtpDataObject(data);
      } else {
        handleClick("error", "There seems to be an error generating the Aadhaar-OTP.");
      }
    } catch (error) {
      // as per the abdm excel sheet this message is mandatory so adding this one
      if (error.response?.data?.message === "Invalid LoginId\n") {
        handleClick("error", "Aadhaar Number is not valid");
      } else {
        handleClick(
          "error",
          error?.response?.data?.message || "Unknown Error. Please try again later."
        );
      }
    }
  };

  const handleVerifyAadharOtp = async (otp, number) => {
    // Validate mobile number format
    if (!/^[6-9][0-9]{9}$/.test(number)) {
      handleClick("error", "Please enter a valid mobile number");
      return;
    }

    // Ensure OTP is provided
    if (!otp) {
      handleClick("error", "Please enter valid otp.");
      return;
    }

    const data = {
      scope: VERIFY_AADHAAR_OTP,
      aadhaar_otp: {
        txnId,
        otpValue: otp,
        mobile: number,
        includeAbhaAddressSuggestions: true,
        includeProfileQr: true,
      },
    };
    try {
      // Verify Aadhaar OTP
      const response = await verifyAbdmAadhaarOtp(patientInfo.patientId, data);
      setTxnId(response?.data?.txnId);
      setToken(response?.data?.token);
      setPatientAbhaDetails({ ...response?.data, isEditAbhaAddress: true });
      const base64ImageData = `data:image/png;base64,${response.data.abhaCard}`;
      setAbhaImage(base64ImageData);
      if (
        response?.data?.linkMobileVerification === true &&
        response?.data?.otpMessage.includes("OTP sent to mobile number ending with ")
      ) {
        setOtpSentMessage(response?.data?.otpMessage);
        handleClick("info", response?.data?.otpMessage);
        handlePreviousButtonStack(3);
        setResendOtpDataObject(data);
        return;
      }
      if (!response?.data?.isNew) {
        handleClick("info", "ABHA account already exists!");
      } else {
        handleClick("success", "ABHA account created successfully!");
      }
      handlePreviousButtonStack(4);
    } catch (error) {
      if (error?.response?.data === "User is already linked.") {
        await handleFetchData();
      }
      // handleClick("error", error?.response?.data?.message || "Something went wrong."); //error message from response is not clear
      handleClick("error", "Please enter a valid OTP. Entered OTP is either expired or incorrect.");
    }
  };

  const handleResendOtp = async (labelledby) => {
    if (resendCount < 3) {
      try {
        let response;
        switch (labelledby) {
          case "Creation":
            response = await generateAbdmAadhaarOtp(resendOtpDataObject);
            if (response.status === 200 && response.data) {
              setTxnId(response.data.txnId);
              handleClick("success", "OTP has been successfully resent!");
            }
            break;
          case "AddPhoneNumber":
            const data = {
              scope: "update_mobile_number",
              loginId: resendOtpDataObject.aadhaar_otp.mobile,
              txnId: txnId,
            };
            response = await generateAbdmAadhaarOtp(data);
            setTxnId(response?.data?.txnId);
            break;
          case "Verification":
            response = await generateAbdmAadhaarOtp(resendOtpDataObject);
            setTxnId(response?.data?.txnId);
            break;
        }
        setResendEnabled(false);
        setTimer(60);
        setResendCount((prev) => prev + 1);
      } catch (e) {
        handleClick("error", e.response?.data?.message || "Unknown Error. Please try again later.");
      }
    } else {
      setResendEnabled(false);
      setTimer(-1);
      handleClick("info", "You have tried maximum times for receiving the OTP!");
    }
  };

  const handleSubmitForVerifyMobileOtp = async (mobileotp) => {
    if (!mobileotp) {
      return;
    }
    const data = {
      txnId: txnId,
      token: token,
      scope: "mobile_number",
      updateDetail: mobileotp,
      includeProfileQr: true,
    };
    try {
      const response = await updateAbdmDetail(patientInfo.patientId, data);
      if (response?.status === 200) {
        const updatedAbhaMobile = response.data?.profile?.mobile;
        const updatedPatientAbhaDetails = {
          ...patientAbhaDetails,
          abhaCard: response?.data?.abhaCard,
          profile: {
            ...patientAbhaDetails.profile,
            mobile: updatedAbhaMobile,
          },
        };
        setTxnId(response?.data?.txnId);
        setToken(response?.data?.token);
        setPatientAbhaDetails({ ...updatedPatientAbhaDetails, isEditAbhaAddress: true });
        handleClick("success", response?.data?.message);
        setIsSelectedIndex(4);
      }
    } catch (error) {
      handleClick("error", "There seems to be an error in Mobile OTP Verification.");
    }
  };

  const handleGetAbhaRadioBtnChange = (event, handleChange) => {
    const selectedValue = event.target.value;
    handleChange(event);
    setGetAbhaScope(selectedValue);
    const limitMap = {
      [VERIFICATION_VIA_MOBILE]: { maxLength: 10, type: "tel", message: "Enter Mobile Number" },
      [VERIFICATION_VIA_AADHAAR]: {
        maxLength: 12,
        kind: "aadhaarNubmer",
        type: "tel",
        message: "Enter Aadhaar Number",
      },
      [VERIFICATION_VIA_ABHA_NUMBER]: {
        maxLength: 14,
        kind: "abhaNumber",
        type: "tel",
        message: "Enter Abha Number",
      },
      [VERIFICATION_VIA_ABHA_ADDRESS]: { type: "text", message: "Enter Abha Address" },
    };

    setInputLimits(limitMap[selectedValue]);
  };

  const validateGetAbhaEntityText = async (getAbhaEntityText, getAbhaRadioBtn) => {
    const fieldName = "getAbhaEntityText";
    try {
      const values = { [fieldName]: getAbhaEntityText, getAbhaRadioBtn };
      await createAbhaValidation.validateAt(fieldName, values);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSearchAbha = async (value, radioBtn) => {
    const cleanedValue = value.replace(/\s/g, "");
    setGetAbhaEntityValue(cleanedValue);
    try {
      const validation = await validateGetAbhaEntityText(value, radioBtn);
      if (!validation) return;
      if ([VERIFICATION_VIA_ABHA_NUMBER, VERIFICATION_VIA_ABHA_ADDRESS].includes(radioBtn)) {
        handlePreviousButtonStack(1);
        return;
      }
      const data = { scope: getAbhaScope, loginId: cleanedValue, txnId: "" };
      const response = await generateAbdmAadhaarOtp(data);
      setOtpSentMessage(response?.data?.message);
      setResendOtpDataObject({ txnId: response?.data?.txnId, ...data });
      setTxnId(response?.data?.txnId);
      handlePreviousButtonStack(2);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Unknown Error. Please try again later.";
      handleClick("error", errorMessage);
    }
  };

  const handleAuthWithAbha = async () => {
    const data = { scope: getAbhaScope2, loginId: getAbhaEntityValue };
    try {
      const response = await generateAbdmAadhaarOtp(data);
      setOtpSentMessage(response?.data?.message);
      setTxnId(response?.data?.txnId);
      setResendOtpDataObject({ txnId: response?.data?.txnId, ...data });
      handlePreviousButtonStack(2);
    } catch (error) {
      handleClick(
        "error",
        error?.response?.data?.message || "Unknown Error. Please try again later."
      );
    }
  };

  const handleRadioBtnChangeForAuthMethod = (event, setFieldValue) => {
    const selectedValue = event.target.value;
    setFieldValue("authMethodBtn", selectedValue);
    const scopeMap = {
      abhaNumber: {
        mobileOtp: VERIFICATION_VIA_ABHA_USING_MOBILE,
        aadhaarOtp: VERIFICATION_VIA_ABHA_USING_AADHAAR,
      },
      abhaAddress: {
        mobileOtp: VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
        aadhaarOtp: VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
      },
    };
    const selectedScope =
      getAbhaScope === VERIFICATION_VIA_ABHA_NUMBER
        ? scopeMap.abhaNumber[selectedValue]
        : scopeMap.abhaAddress[selectedValue];
    setGetAbhaScope2(selectedScope);
  };

  const handleVerifyOtp = async (otp) => {
    if (!otp) return;
    try {
      const data = { otp, txnId };
      if (getAbhaScope === VERIFICATION_VIA_MOBILE) {
        const response = await verifyGetAbhaOtpViaMobile(data, patientInfo.patientId);
        setTxnId(response?.data?.txnId);
        setToken(response?.data?.token);
        setResendOtpDataObject(data);
        if (response?.data?.accounts.length >= 1) {
          setAccountOptions(response?.data?.accounts);
          handlePreviousButtonStack(3);
          return;
        }
      } else {
        const scopeMap = {
          [VERIFICATION_VIA_ABHA_USING_AADHAAR]: VERIFICATION_ABHA_USING_AADHAAR_OTP,
          [VERIFICATION_VIA_ABHA_USING_MOBILE]: VERIFICATION_ABHA_USING_MOBILE_OTP,
          [VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR]:
            VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
          [VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE]: VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
        };

        const scope = scopeMap[getAbhaScope2] || VERIFICATION_VIA_AADHAAR;

        const abhaData = {
          verificationId: otp,
          txnId,
          scope,
          includeProfileQr: true,
        };

        await verifyGetAbhaOtpViaAbhaEntities(abhaData, patientInfo.patientId);
        handleClick("success", "Abha has been successfully linked!");
      }
      await handleFetchData();
    } catch (error) {
      handleClick(
        "error",
        error.response?.data?.message || "Unknown Error. Please try again later."
      );
      setShowCreateAbhaBtn(true);
    }
  };

  const handleSelectedAccount = async (value) => {
    try {
      const data = {
        verificationId: value,
        txnId: txnId,
        scope: VERIFY_SELECTED_ABHA_NUMBER,
        token: token,
        fingerPrintAuthPid: "",
        includeProfileQr: true,
      };
      const response = await verifyGetAbhaOtpViaAbhaEntities(data, patientInfo.patientId);
      if (response?.status === 200) {
        handleClick("success", "Abha has been successfully linked!");
        await handleFetchData();
      }
    } catch (e) {
      if (e.response?.data?.message === "User is already linked.") {
        await handleFetchData();
      }
      handleClick("error", e.response?.data?.message || "Unknown Error. Please try again later.");
    }
  };

  const handleViewLinkedAbha = async () => {
    try {
      setIsCreateAbha(true);
      if (linkedAbhaNumberRef.current === null) {
        handleAbhaCreationRouting();
        return;
      } else {
        const response = await getLinkedAbha(patientIdRef.current);
        if (response?.status === 200) {
          const base64ImageData = `data:image/png;base64,${response.data.abhaCard}`;
          setAbhaImage(base64ImageData);
          setPatientAbhaDetails({ ...response.data, isEditAbhaAddress: false });
          handlePreviousButtonStack(4);
        }
      }
    } catch (error) {
      handleAbhaCreationRouting();
      if (
        error.response &&
        error.response?.data?.message === "Profile QR does not exists for the Patient"
      ) {
        handleClick("error", "Please link/create ABHA first.");
      } else {
        handleClick(
          "error",
          error.response?.data?.message || "There seems to be an error! Please try again later."
        );
      }
    }
  };

  const DownloadAbhaImage = (dataLink) => {
    if (!dataLink) {
      handleClick("error", "There seems to be an error in Downloading! Please try again later.");
      return;
    }
    try {
      const downloadLink = document.createElement("a");
      downloadLink.href = dataLink;
      downloadLink.download = "abha_card.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.error(e);
      handleClick("error", "Downloading error! Please try again later.");
    }
  };

  const popBackBtnStack = async () => {
    try {
      let poppedValue = 0;

      setBackBtnStack((prevStack) => {
        if (prevStack.length === 1) {
          setGetAbhaScope(VERIFICATION_VIA_MOBILE);
          poppedValue = 0;
          return [0];
        }
        poppedValue = prevStack[prevStack.length - 2];
        return prevStack.slice(0, -1);
      });

      return poppedValue;
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackButton = async () => {
    setCheckedStates(
      Array(6)
        .fill(false)
        .map((_, index) => index !== 1 && index !== 5)
    );
    if (isSelectedIndex === 0) {
      if (!isCreateAbha) {
        setIsCreateAbha(true);
      } else {
        navigate("/home/abhaClinicAdmin");
      }
      return;
    }
    if (isSelectedIndex === 4) {
      navigate("/home/abhaClinicAdmin");
      return;
    }

    const num = await popBackBtnStack();
    formikRef.current.resetForm();
    setIsSelectedIndex(num);
  };

  const genderBasedImage = (gender) => {
    return gender === "FEMALE" ? "/images/woman.png" : "/images/man.png";
  };

  const determineButtonDisabled = (user, checkedStates) => {
    if (user.roleName === "PATIENT") {
      return !(
        checkedStates[0] &&
        checkedStates[1] &&
        checkedStates[2] &&
        checkedStates[3] &&
        checkedStates[4] &&
        !checkedStates[5]
      );
    }
    return !checkedStates.every(Boolean);
  };

  return (
    <PatientAbhaSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isCreateAbha ? (
            <h2
              style={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#007390",
                display: "inline-block",
                margin: "40px",
              }}
            >
              {isSelectedIndex === 0 ? "ABHA" : "CREATE ABHA"}
            </h2>
          ) : (
            <h2
              style={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#007390",
                display: "inline-block",
                margin: "40px",
              }}
            >
              VERIFY ABHA
            </h2>
          )}
          <div style={{ marginLeft: "auto", gap: "10px", display: "flex" }}>
            {showCreateAbhaBtn && (
              <CustomButton
                sx={{ margin: "15px" }}
                className="btn--primary"
                onClick={handleAbhaCreationRouting}
              >
                Create ABHA
              </CustomButton>
            )}
            {isSelectedIndex === 4 && (
              <CustomButton sx={{ margin: "15px" }} onClick={() => DownloadAbhaImage(abhaImage)}>
                <DownloadIcon />
              </CustomButton>
            )}
            {isBackButtonVisible && (
              <CustomButton
                sx={{ margin: "15px" }}
                className="btn--primary"
                onClick={handleBackButton}
              >
                <ArrowBackIcon />
              </CustomButton>
            )}
          </div>
        </div>

        {showPatientCard && patientInfo?.fullInfo?.userResponse && (
          <Reveal>
            <div
              style={{
                background: "#fff",
                width: "fit-content",
                display: "flex",
                marginLeft: "2rem",
                borderRadius: "11px",
                padding: "1rem",
                boxShadow: "2px 2px 8px rgb(221, 221, 221)",
              }}
            >
              <Grid2 xs={5} container sx={{ mr: "1rem" }}>
                <img
                  src={
                    patientInfo?.profilePicture?.content ||
                    genderBasedImage(patientInfo.fullInfo.userResponse.gender)
                  }
                  alt={
                    patientInfo?.profilePicture?.filename || patientInfo.fullInfo.userResponse.name
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginTop: "3px",
                  }}
                />
              </Grid2>

              <Grid2 xs={7} container spacing={2}>
                <Grid2 xs={12}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Name
                  </Typography>
                  <Typography variant="body1">{patientInfo.fullInfo.userResponse.name}</Typography>
                </Grid2>

                <Grid2 xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Gender
                  </Typography>
                  <Typography variant="body1">
                    {patientInfo.fullInfo.userResponse.gender}
                  </Typography>
                </Grid2>

                <Grid2 xs={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Date of Birth
                  </Typography>
                  <Typography variant="body1">
                    {dayjs(patientInfo.fullInfo.userResponse.dateOfBirth, "YYYY-MM-DD").format(
                      "DD MMMM, YYYY"
                    )}
                  </Typography>
                </Grid2>
              </Grid2>
            </div>
          </Reveal>
        )}
        <div style={{ marginLeft: "35px", marginBottom: "5%" }}>
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              aadharNumber: "",
              otp: "",
              mobileNumber: "",
              mobileotp: "",
              firstName: "",
              middleName: "",
              lastName: "",
              email: "",
              password: "",
              healthId: "",
              healthIdForSearch: "",
              mobileNumberForSearch: "",
              otpForVerification: "",
              submit: "",
              getAbhaEntityText: "",
              getAbhaRadioBtn: getAbhaScope,
              getAbhaOtp: "",
              authMethodBtn: "",
              selectedOption: "",
            }}
            validationSchema={createAbhaValidation}
            onSubmit={async (values) => {}}
          >
            {({
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                {isCreateAbha && isSelectedIndex === 0 && (
                  <FormControl sx={{ width: "30%", mr: 3 }}>
                    <RadioGroup
                      row
                      defaultValue="createAbha"
                      aria-labelledby="abhaNumberRadioBtn"
                      name="abhaNumberRadioBtn"
                      value={values.abhaNumberRadioBtn}
                      onChange={handleRadioBtnChange}
                    >
                      <FormControlLabel
                        name="abhaNumberRadioBtn"
                        value="createAbha"
                        control={<Radio />}
                        label="Create ABHA"
                      />
                      <FormControlLabel
                        name="abhaNumberRadioBtn"
                        value="linkAbha"
                        control={<Radio />}
                        label="Verify ABHA"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {isCreateAbha && isSelectedIndex === 0 && (
                  <>
                    <p style={{ margin: "10px 0px 20px 10px" }}>I hereby declare that:</p>
                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        marginBottom: "10px",
                        alignItems: "flex-start",
                      }}
                      label="I am voluntarily sharing my Aadhaar Number / Virtual ID issued by the Unique Identification Authority of India (“UIDAI”), and my demographic information for the purpose of creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”). I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication."
                      control={
                        <Checkbox
                          checked={checkedStates[0]}
                          onChange={handleCheckboxChange(0)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            marginTop: "-8px",
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        marginBottom: "10px",
                        alignItems: "flex-start",
                      }}
                      label="I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) health records and those which will be generated during this encounter."
                      control={
                        <Checkbox
                          checked={checkedStates[1]}
                          onChange={handleCheckboxChange(1)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            marginTop: "-8px",
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        marginBottom: "10px",
                        alignItems: "flex-start",
                      }}
                      label="I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter."
                      control={
                        <Checkbox
                          checked={checkedStates[2]}
                          onChange={handleCheckboxChange(2)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            marginTop: "-8px",
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        marginBottom: "10px",
                        alignItems: "flex-start",
                      }}
                      label="I consent to the anonymization and subsequent use of my health records for public health purposes."
                      control={
                        <Checkbox
                          checked={checkedStates[3]}
                          onChange={handleCheckboxChange(3)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            marginTop: "-9px",
                          }}
                        />
                      }
                    />
                    {activeUser.roleName === "DOCTOR" && (
                      <>
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label={
                            <>
                              I,&nbsp;
                              <span style={{ fontWeight: "bold" }}>Dr. {activeUser.name}</span>,
                              confirm that I have duly informed and explained the beneficiary of the
                              contents of consent for aforementioned purposes.
                            </>
                          }
                          control={
                            <Checkbox
                              checked={checkedStates[4]}
                              onChange={handleCheckboxChange(4)}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                marginTop: "-9px",
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label={
                            <>
                              I,&nbsp;
                              <span style={{ fontWeight: "bold" }}>
                                {patientInfo?.fullInfo?.userResponse?.name}
                              </span>
                              , have been explained about the consent as stated above and hereby
                              provide my consent for the aforementioned purposes.
                            </>
                          }
                          control={
                            <Checkbox
                              checked={checkedStates[5]}
                              onChange={handleCheckboxChange(5)}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                marginTop: "-9px",
                              }}
                            />
                          }
                        />
                      </>
                    )}
                    <Box sx={{ mt: 4 }}>
                      <CustomButton
                        disabled={determineButtonDisabled(activeUser, checkedStates)}
                        className="btn--primary"
                        onClick={() => {
                          handlePreviousButtonStack(1);
                        }}
                        label="Next"
                      />
                    </Box>
                  </>
                )}
                {isCreateAbha && isSelectedIndex === 1 && (
                  <Reveal>
                    <h3>An OTP will be sent to the mobile number linked to this Aadhaar number.</h3>
                    <FormInputField
                      style={{ width: "45%", marginRight: "30px", marginTop: "15px" }}
                      label="Aadhaar Number"
                      type={"tel"}
                      name="aadharNumber"
                      value={values.aadharNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      startAdornment={<PermIdentityOutlined />}
                      size={"big"}
                    />
                    <Box sx={{ mt: 4 }}>
                      <CustomButton
                        disabled={!(values.aadharNumber.toString().length === 14)}
                        className="btn--primary"
                        onClick={() => handleGenerateAadhaarOtp(values.aadharNumber)}
                        label="Generate"
                      ></CustomButton>
                    </Box>
                  </Reveal>
                )}
                {isCreateAbha && isSelectedIndex === 2 && (
                  <Reveal>
                    <div style={{ display: "flex" }}>
                      <div>
                        <h3>Enter Aadhaar OTP</h3>
                        <FormInputField
                          style={{ width: "80%", marginRight: "30px", marginTop: "15px" }}
                          label="OTP"
                          type={"tel"}
                          name="otp"
                          inputProps={{
                            maxLength: 6,
                          }}
                          value={values.otp}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          startAdornment={<FingerprintOutlined />}
                          size={"big"}
                        />
                      </div>
                      <div>
                        <h3>Enter Mobile Number to Link with ABHA</h3>
                        <FormInputField
                          style={{ width: "80%", marginRight: "30px", marginTop: "15px" }}
                          label="Mobile Number"
                          type={"tel"}
                          name="mobileNumber"
                          inputProps={{
                            maxLength: 10,
                          }}
                          value={values.mobileNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          startAdornment={<i className="ri-phone-line ri-xl" />}
                          size={"big"}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <span>{otpSentMessage}</span>
                    </div>
                    <Box sx={{ mt: 2 }}>
                      <CustomButton
                        disabled={!resendEnabled}
                        className="btn--primary"
                        label={`Resend OTP${
                          !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                        }`}
                        onClick={() => handleResendOtp("Creation")}
                        style={{ marginRight: "20px" }}
                      ></CustomButton>
                      <CustomButton
                        disabled={values?.otp?.length !== 6}
                        className="btn--primary"
                        label="Verify"
                        onClick={() => handleVerifyAadharOtp(values.otp, values.mobileNumber)}
                      ></CustomButton>
                    </Box>
                  </Reveal>
                )}
                {isCreateAbha && isSelectedIndex === 3 && (
                  <Reveal>
                    <h3>Mobile Verification</h3>
                    <p style={{ marginTop: "20px" }}>
                      <span>
                        Entered Mobile Number doesn't match with the number linked with Aadhaar.
                        Please Enter {otpSentMessage}
                      </span>
                    </p>
                    <FormInputField
                      style={{ width: "35%", marginRight: "30px", marginTop: "15px" }}
                      label="OTP"
                      type={"tel"}
                      name="mobileotp"
                      inputProps={{
                        maxLength: 6,
                      }}
                      value={values.mobileotp}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setMobileNumber(e.target.value);
                      }}
                      startAdornment={<FingerprintOutlined />}
                      size={"big"}
                    />
                    <Box sx={{ mt: 2 }}>
                      <CustomButton
                        disabled={!resendEnabled}
                        className="btn--primary"
                        label={`Resend OTP${
                          !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                        }`}
                        onClick={() => handleResendOtp("AddPhoneNumber")}
                        style={{ marginRight: "20px" }}
                      ></CustomButton>
                      <CustomButton
                        disabled={values?.mobileotp?.length !== 6}
                        className="btn--primary"
                        label="Verify"
                        onClick={() => handleSubmitForVerifyMobileOtp(values.mobileotp)}
                      ></CustomButton>
                    </Box>
                  </Reveal>
                )}

                {isCreateAbha && isSelectedIndex === 4 && (
                  <AbhaDetails
                    patientId={patientInfo.patientId}
                    patientAbhaDetails={patientAbhaDetails}
                    setPatientAbhaDetails={setPatientAbhaDetails}
                    txnId={txnId}
                    token={token}
                  />
                )}

                {!isCreateAbha && isSelectedIndex === 0 && (
                  <Reveal>
                    <FormInputField
                      style={{ width: "50%", margin: "15px 30px 0px 0px" }}
                      label={inputLimits.message}
                      name="getAbhaEntityText"
                      value={values.getAbhaEntityText}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.getAbhaEntityText && errors.getAbhaEntityText)}
                      errorText={errors.getAbhaEntityText}
                      startAdornment={
                        inputLimits.type === "text" ? <AbcOutlined /> : <NumbersOutlined />
                      }
                      type={inputLimits.type}
                      size={"big"}
                      kind={inputLimits.kind}
                      customLength={inputLimits.maxLength}
                    />
                    <RadioGroup
                      row
                      defaultValue="mobileNoBtn"
                      aria-labelledby="getAbhaRadioBtn"
                      name="getAbhaRadioBtn"
                      value={values.getAbhaRadioBtn}
                      onChange={(e) => handleGetAbhaRadioBtnChange(e, handleChange)}
                      style={{ display: "flex" }}
                    >
                      <FormControlLabel
                        name="getAbhaRadioBtn"
                        value={VERIFICATION_VIA_MOBILE}
                        control={<Radio />}
                        label="Verify via Mobile Number"
                        sx={{ pt: 2 }}
                      />
                      <FormControlLabel
                        name="getAbhaRadioBtn"
                        value={VERIFICATION_VIA_AADHAAR}
                        control={<Radio />}
                        label="Verify via Aadhaar Number"
                        sx={{ pt: 2 }}
                      />
                      <FormControlLabel
                        name="getAbhaRadioBtn"
                        value={VERIFICATION_VIA_ABHA_NUMBER}
                        control={<Radio />}
                        label="Verify via Abha Number"
                        sx={{ pt: 2 }}
                      />
                      <FormControlLabel
                        name="getAbhaRadioBtn"
                        value={VERIFICATION_VIA_ABHA_ADDRESS}
                        control={<Radio />}
                        label="Verify via Abha Address"
                        sx={{ pt: 2 }}
                      />
                    </RadioGroup>
                    <Box sx={{ mt: 2 }}>
                      <CustomButton
                        disabled={!(values.getAbhaEntityText.toString().length >= 5)}
                        className="btn--primary"
                        label="Search"
                        onClick={() =>
                          handleSearchAbha(values.getAbhaEntityText, values.getAbhaRadioBtn)
                        }
                      ></CustomButton>
                    </Box>
                  </Reveal>
                )}
                {!isCreateAbha && isSelectedIndex === 1 && (
                  <Reveal>
                    <h3>Select Method</h3>
                    <FormControl sx={{ width: "30%", mt: 3, mr: 3 }}>
                      <RadioGroup
                        row
                        defaultValue="mobileOtp"
                        aria-labelledby="authMethodBtn"
                        name="authMethodBtn"
                        value={values.authMethodBtn}
                        onChange={(e) => {
                          handleRadioBtnChangeForAuthMethod(e, setFieldValue);
                        }}
                      >
                        <FormControlLabel
                          name="authMethodBtn"
                          value="mobileOtp"
                          control={<Radio />}
                          label="Mobile OTP"
                        />
                        <FormControlLabel
                          name="authMethodBtn"
                          value="aadhaarOtp"
                          control={<Radio />}
                          label="Aadhaar OTP"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <CustomButton
                        className="btn--primary"
                        label="Next"
                        onClick={() => handleAuthWithAbha()}
                      ></CustomButton>
                    </Box>
                  </Reveal>
                )}
                {!isCreateAbha && isSelectedIndex === 2 && (
                  <Reveal>
                    <div style={{ marginTop: "20px" }}>
                      <span>Please Enter {otpSentMessage}</span>
                    </div>
                    <FormInputField
                      style={{ width: "50%", marginRight: "30px", marginTop: "15px" }}
                      label="OTP"
                      type={"tel"}
                      name="otpForVerification"
                      value={values.otpForVerification}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      startAdornment={<FingerprintOutlined />}
                      size={"big"}
                      customLength={6}
                      kind={"otp"}
                    />
                    <Box sx={{ mt: 2 }}>
                      <CustomButton
                        disabled={!resendEnabled}
                        className="btn--primary"
                        label={`Resend OTP${
                          !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                        }`}
                        onClick={() => handleResendOtp("Verification")}
                        style={{ marginRight: "20px" }}
                      ></CustomButton>
                      <CustomButton
                        disabled={!values.otpForVerification}
                        className="btn--primary"
                        label="Verify"
                        onClick={() => handleVerifyOtp(values.otpForVerification)}
                      ></CustomButton>
                    </Box>
                  </Reveal>
                )}
                {!isCreateAbha && isSelectedIndex === 3 && (
                  <Reveal>
                    <h2 style={{ marginBottom: "35px" }}>Select an Account:</h2>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "35px",
                        margin: "10px",
                      }}
                    >
                      {accountOptions.map((account) => (
                        <div
                          key={account.ABHANumber}
                          onClick={() => {
                            setFieldValue("selectedOption", account.ABHANumber);
                          }}
                          className={`profile-card ${
                            values.selectedOption === account.ABHANumber
                              ? "selected"
                              : "not-selected"
                          }`}
                        >
                          <ProfileCard
                            fullName={account.name}
                            dateOfBirth={dayjs(account.dob, "DD-MM-YYYY").format("DD MMMM, YYYY")}
                            gender={
                              account.gender === "M"
                                ? "Male"
                                : account.gender === "F"
                                ? "Female"
                                : "Other"
                            }
                            abhaNumber={account.ABHANumber}
                            abhaAddress={account.preferredAbhaAddress}
                            image={account.profilePhoto}
                          ></ProfileCard>
                        </div>
                      ))}
                    </div>
                    <CustomButton
                      sx={{
                        margin: "25px 0px 0px 10px",
                      }}
                      disabled={!values.selectedOption}
                      className="btn--primary"
                      label="Select"
                      onClick={() => handleSelectedAccount(values.selectedOption)}
                    ></CustomButton>
                  </Reveal>
                )}
              </form>
            )}
          </Formik>
        </div>
      </Reveal>
    </PatientAbhaSkeleton>
  );
};

const PatientAbhaSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid size={{ xs: 12 }} display={"felx"}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageAbhaNumber;
