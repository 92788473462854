import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ChartDisplay from "./ChartDisplay";
import { useEffect } from "react";
import { createDashboard, checkQuery, updateDashboard } from "services/DashboardService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "ui-component/custom-components/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import PreviewDashboard from "./previewDashboard";
import { SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import dayjs from "dayjs";
import Reveal from "views/utilities/Reveal";
import ModalUI from "ui-component/ModalUI";
import "../../../assets/scss/style.scss";
import { useLocation, useNavigate } from "react-router";
import { dashboardTitleSchema } from "../Common/ValidationSchema/generateDashboardValidation";
import { Parser } from "node-sql-parser";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  identifyDateRangeVariables,
  initializeDashboardData,
  replaceUnderscores,
  setDashboardDataForApi,
  updateDataBasedOnFilterParams,
} from "utils/dashboard-utils";
import "../../../assets/scss/colorInputField.scss";

const initialDroppableAreas = [
  {
    id: uuidv4(),
    rows: [],
  },
];

const MyDialog = ({
  open,
  onClose,
  droppableAreas,
  dashboardTitle,
  setDroppableAreas,
  selectedDashboard,
  setSelectedDashboard,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ display: "flex", alignItems: "center", height: "70px" }}>
        <h2>Dashboard Preview</h2>
        <CustomButton
          onClick={onClose}
          iconButton={<CloseIcon></CloseIcon>}
          style={{ marginLeft: "auto" }}
        ></CustomButton>
      </DialogTitle>

      <DialogContent style={{ backgroundColor: "#f2f6f8" }}>
        <DialogContentText>
          <PreviewDashboard
            droppableAreas={droppableAreas}
            dashboardTitle={dashboardTitle}
            setDroppableAreas={setDroppableAreas}
            selectedDashboard={selectedDashboard}
            setSelectedDashboard={setSelectedDashboard}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const CreateEditDashboard = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [colors, setColors] = useState({});
  const [selectedColor, setSelectedColor] = useState("");

  const setColor = (value, key, index) => {
    let newColors = { ...colors };
    newColors = {
      ...newColors,
      [key]: value,
    };
    setColors(newColors);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { handleClick } = useContext(ToastContext);

  const [queryText, setQueryText] = useState("");
  const [xAxis, setXAxis] = useState("");
  const [yAxis, setYAxis] = useState("");
  const [filters, setFilters] = useState("");
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [chartType, setChartType] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [queryData, setQueryData] = useState([]);
  const [params, setParams] = useState({});
  const [checkedQuery, setCheckedQuery] = useState(false);
  const [saveDetails, setsaveDetails] = useState(false);
  const [chartList, setChartList] = useState([]);
  const [saveChartsEnable, setSaveChartsEnable] = useState(false);
  const [addWidget, setAddWidget] = useState(false);
  const [chartTypeOptions, setchartTypeOptions] = useState([
    "Bar Chart",
    "Line Chart",
    "Pie Chart",
    "Table",
    "Count Card",
  ]);
  const [droppableAreas, setDroppableAreas] = useState(initialDroppableAreas);
  const [isDateValid, setIsDateValid] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(location.state ? location.state : "");
  const [headers, setHeaders] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [titleTouched, setTitleTouched] = useState(false);
  const [editChartOption, setEditChartOption] = useState(false);
  const [editDroppableArea, setEditDroppableArea] = useState({
    areaId: "",
    rowId: "",
    columnId: "",
    widgetId: "",
  });

  const handleCheckQuery = async () => {
    try {
      let newParams = {};
      const variables = identifyDateRangeVariables(queryText);

      if (variables?.startDate && variables?.endDate) {
        newParams = { ...newParams, dateRange: {} };
        newParams.dateRange[variables?.startDate] = fromDate === "" ? null : fromDate;
        newParams.dateRange[variables?.endDate] = toDate === "" ? null : toDate;

        setParams(newParams);

        if (newParams && Object.keys(newParams).length > 0) {
          proceedWithQueryCheck(newParams);
        }
      } else {
        proceedWithQueryCheck(params);
      }
    } catch (error) {
      handleClick("error", "Error");
    }
  };

  const proceedWithQueryCheck = async (params) => {
    try {
      const { groupByColumns, aliasColumns, columns } = parseSQLQuery(queryText, params);

      let queryParams = Object.keys(params).reduce((acc, filter) => {
        return { ...acc, ...params[filter] };
      }, {});
      const data = {
        query: queryText,
        params: queryParams,
        status: "ACTIVE",
      };
      const response = await checkQuery(data);
      if (response) {
        handleClick("success", "You can generate chart");
        const column =
          response.data.headers?.length === 2
            ? response.data.headers
            : columns
            ? columns.split("")
            : response.data.headers;
        const cols = !aliasColumns.includes(null) ? aliasColumns : column;
        const header = cols.map((col) => replaceUnderscores(col));
        setHeaders(header);

        if (cols !== null && (cols[0] === "*" || cols.length === 1)) {
          cols[0] !== "*"
            ? setchartTypeOptions(["Table", "Count Card"])
            : setchartTypeOptions(["Table"]);
        } else if (groupByColumns?.length === 0) {
          setchartTypeOptions(["Bar Chart", "Line Chart", "Table"]);
        } else if (cols.length > 2) {
          setchartTypeOptions(["Table"]);
        } else {
          setchartTypeOptions(["Bar Chart", "Line Chart", "Pie Chart", "Table"]);
        }

        setSelectedColor("#004c70");
        setCheckedQuery(true);
        if (editChartOption) setsaveDetails(true);

        const alteredData = response.data.data.map((item) =>
          Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              replaceUnderscores(key),
              replaceUnderscores(value),
            ])
          )
        );

        setDefaultColors(alteredData);
        setQueryData(alteredData);
        setFromDate(null);
        setToDate(null);
      }
    } catch (error) {
      handleClick("error", "Kindly check entered query");
    }
  };

  const setDefaultColors = (data) => {
    const { groupByColumns } = parseSQLQuery(queryText, params);
    const defaultColors = data.reduce((acc, item) => {
      const key = item[headers.filter((column) => groupByColumns.includes(column))];
      acc[key] = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;
      return acc;
    }, {});

    setColors(defaultColors);
  };

  const validateDashboardTitle = async (title) => {
    try {
      await dashboardTitleSchema.validate({ dashboardTitle: title });
      setTitleError("");
    } catch (e) {
      setTitleError(e.message);
    }
  };

  const saveCharts = async () => {
    await validateDashboardTitle(dashboardTitle);
    if (titleError || !dashboardTitle) {
      handleClick("error", "Please enter a dashboard title.");
      return;
    } else {
      try {
        const updatedDroppableAreas = setDashboardDataForApi(droppableAreas);

        try {
          if (!selectedDashboard) {
            throw new Error("Dashboard does not exist...Create one");
          }
          selectedDashboard.widgets.rows = [...updatedDroppableAreas[0].rows];
          selectedDashboard.widgets.screenId = updatedDroppableAreas[0].id;

          let responsedata = await updateDashboard(selectedDashboard.id, selectedDashboard);
          setSelectedDashboard(responsedata.data);
          handleClick("success", "Dashboard saved successfully");
          navigate("/home/generateDashboard");
        } catch (error) {
          if (
            error.response?.status === 400 ||
            error.message === "Dashboard is not active" ||
            error.message === "Dashboard does not exist...Create one"
          ) {
            const dashboarddata = {
              name: dashboardTitle,
              description: "This is the main dashboard for monitoring key metrics.",
              widgets: {
                title: "Health Dashboard",
                screenId: updatedDroppableAreas[0].id,
                rows: updatedDroppableAreas[0].rows,
              },
              url: "/dashboard",
              status: "ACTIVE",
            };
            try {
              let responsedata = await createDashboard(dashboarddata);
              setSelectedDashboard(responsedata.data);
              handleClick("success", "Dashboard created successfully");
              navigate("/home/generateDashboard");
            } catch (createError) {
              handleClick("error", createError);
            }
          }
        }
      } catch (error) {
        console.error(error);

        handleClick("error", "There seems to be an error saving dashboard.");
      }
    }
  };

  const preprocessQuery = (query, params) => {
    return query.replace(/#(\w+)#/g, (match, p1) => {
      if (params.dateRange && params.dateRange[p1]) {
        return params.dateRange[p1];
      }

      if (params[p1] !== undefined) {
        return params[p1];
      }

      return "123";
    });
  };

  const parseSQLQuery = (query, params) => {
    try {
      const parser = new Parser();
      const preprocessedQuery = preprocessQuery(query, params);
      const groupByMatch = query.match(/GROUP BY\s+(.*)/i);

      const ast = parser.astify(preprocessedQuery); // SQL -> AST

      const response = Array.isArray(ast) ? ast[0] : ast;

      const aliasColumns = response?.columns?.map((col) => col.as);
      const groupByColumns =
        response?.groupby?.columns.map((item) =>
          item.type === "column_ref"
            ? typeof item.column === "string"
              ? replaceUnderscores(item.column)
              : item.column
            : typeof item.value === "string"
            ? replaceUnderscores(item.value)
            : item.value
        ) || (groupByMatch ? groupByMatch[1].split(",").map((col) => col.trim()) : []);
      let columns = null;
      if (response.columns.length === 1 && response.columns[0].expr.column === "*") {
        columns = response.columns[0].expr.column;
      }

      return { aliasColumns, groupByColumns, columns };
    } catch (err) {
      console.error("Error parsing SQL:", err);
    }
  };

  const extractFilterVariableNames = (query) => {
    const variableNames = [];
    const regex = /#(\w+)#/g;
    let match;

    while ((match = regex.exec(query)) !== null) {
      variableNames.push(match[1]);
    }

    return variableNames;
  };

  const handleGenerateChart = () => {
    let chart = {
      title,
      params,
      queryText,
      chartType,
      queryData,
    };

    const options = { params };

    if (chartType === "Bar Chart" || chartType === "Line Chart") {
      if (!xAxis || !yAxis) {
        handleClick("error", "Please enter xAxis and yAxis.");
        return;
      } else if (!headers.includes(xAxis)) {
        handleClick("error", `${xAxis} column is not present`);
        return;
      } else if (!headers.includes(yAxis)) {
        handleClick("error", `${yAxis} column is not present`);
        return;
      }
      options["xAxis"] = xAxis;
      options["yAxis"] = yAxis;
      options["Colors"] = [selectedColor];
    } else if (chartType === "Pie Chart") {
      const { groupByColumns } = parseSQLQuery(queryText, params);
      options["Section"] = headers.filter((column) => groupByColumns.includes(column));
      options["Value"] = headers.filter((column) => !groupByColumns.includes(column));
      options["Colors"] = colors;
    } else if (chartType === "Table") {
      queryData.length !== 0 ? (options["Columns"] = headers) : (options["Columns"] = []);
      options["Colors"] = [selectedColor];
    } else {
      options["Colors"] = [selectedColor];
    }

    options["chartOptions"] = chartTypeOptions;

    chart["options"] = options;

    if (!editChartOption) {
      chart = { ...chart, widgetId: uuidv4() };
      setChartList([...chartList, chart]);
    } else {
      setDroppableAreas((prevAreas) =>
        prevAreas.map((area) =>
          area.id === editDroppableArea.areaId
            ? {
                ...area,
                rows: area.rows.map((row) =>
                  row.rowId === editDroppableArea.rowId
                    ? {
                        ...row,
                        columns: row.columns.map((column) =>
                          column.columnId === editDroppableArea.columnId
                            ? {
                                ...column,
                                charts: [
                                  {
                                    ...chart,
                                    widgetId: editDroppableArea.widgetId,
                                  },
                                ],
                              }
                            : column
                        ),
                      }
                    : row
                ),
              }
            : area
        )
      );
    }
    setOpen(false);
    handleReset();
    setParams({});
    setSaveChartsEnable(true);
    setShowChart(true);
  };

  const handleReset = () => {
    setQueryText("");
    setXAxis("");
    setYAxis("");
    setFilters("");
    setChartType("");
    setTitle("");
    setCheckedQuery(false);
    setsaveDetails(false);
    setFromDate(null);
    setToDate(null);
    setEditChartOption(false);
  };

  const resetCharts = () => {
    handleReset();
    setShowChart(false);
    setChartList([]);
    setSaveChartsEnable(false);
  };

  const removeChartFromChartList = (widgetId) => {
    const updatedList = chartList.filter((chartItem) => chartItem.widgetId !== widgetId);
    setChartList(updatedList);
  };

  const initializeComponent = async (data) => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const updatedAreaRows = await initializeDashboardData(data);
        setDroppableAreas([
          {
            id: data.widgets.screenId,
            rows: updatedAreaRows.length !== 0 ? updatedAreaRows : initialDroppableAreas[0].rows,
          },
        ]);

        setDashboardTitle(data.name);
      } else {
        setDroppableAreas(initialDroppableAreas);
        setDashboardTitle("");
        setSelectedDashboard("");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    initializeComponent(location.state);
  }, [location.state]);

  useEffect(() => {
    if (fromDate && toDate) {
      setIsDateValid(dayjs(fromDate) <= dayjs(toDate));
    } else {
      setIsDateValid(true);
    }
  }, [fromDate, toDate]);

  const addRow = (areaId, rowIndex) => {
    setDroppableAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === areaId
          ? {
              ...area,
              rows: [
                ...area.rows.slice(0, rowIndex + 1),
                {
                  rowId: uuidv4(),
                  columns: [{ columnId: uuidv4(), charts: [] }],
                },
                ...area.rows.slice(rowIndex + 1),
              ],
            }
          : area
      )
    );
  };

  const addColumn = (areaId, rowId) => {
    const newAreas = JSON.parse(JSON.stringify(droppableAreas));

    const areaIndex = newAreas.findIndex((area) => area.id === areaId);
    if (areaIndex === -1) return;

    const rowIndex = newAreas[areaIndex].rows.findIndex((row) => row.rowId === rowId);
    if (rowIndex === -1) return;

    const row = newAreas[areaIndex].rows[rowIndex];

    row.columns.push({
      columnId: uuidv4(),
      charts: [],
    });

    setDroppableAreas(newAreas);
  };

  const removeColumn = (areaId, rowId, columnId) => {
    const newAreas = JSON.parse(JSON.stringify(droppableAreas));

    const areaIndex = newAreas.findIndex((area) => area.id === areaId);
    if (areaIndex === -1) return;

    const rowIndex = newAreas[areaIndex].rows.findIndex((row) => row.rowId === rowId);
    if (rowIndex === -1) return;

    const row = newAreas[areaIndex].rows[rowIndex];
    const columnIndex = row.columns.findIndex((column) => column.columnId === columnId);
    if (columnIndex === -1) return;

    row.columns.splice(columnIndex, 1);
    if (row.columns.length === 0) newAreas[areaIndex].rows.splice(rowIndex, 1);
    setDroppableAreas(newAreas);
  };

  const deleteRow = (areaId, rowIndex) => {
    setDroppableAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === areaId
          ? {
              ...area,
              rows: area.rows.filter((_, index) => index !== rowIndex),
            }
          : area
      )
    );
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return; // If dropped outside a droppable area

    const newPreviousCharts = [...chartList];
    const newAreas = JSON.parse(JSON.stringify(droppableAreas));

    const sourceIds = source.droppableId.split("-");
    const destinationIds = destination.droppableId.split("-");

    if (source.droppableId === "chartList") {
      const [movedChart] = newPreviousCharts.splice(source.index, 1); // Remove chart from previousCharts

      const [destinationAreaIndex, destinationRowIndex, destinationColumnIndex] =
        destinationIds.map(Number);

      const destinationArea = newAreas[destinationAreaIndex];
      const destinationRow = destinationArea?.rows?.[destinationRowIndex];
      const destinationColumn = destinationRow?.columns?.[destinationColumnIndex];

      if (!destinationColumn) {
        return;
      }
      if (movedChart.chartType === "Count Card" && destinationRowIndex !== 0) {
        handleClick("error", "Count card charts can only be dropped in the first row");
        return;
      } else if (movedChart.chartType !== "Count Card" && destinationRowIndex === 0) {
        handleClick("error", "Charts other than cards can not be dropped in the first row");
        return;
      }
      if (destinationColumn.charts.length === 0) {
        destinationColumn.charts.splice(destination.index, 0, movedChart);
      } else {
        return;
      }
    } else {
      // Moving within the droppable areas
      if (sourceIds.length < 3 || destinationIds.length < 3) return;

      const [sourceAreaIndex, sourceRowIndex, sourceColumnIndex] = sourceIds.map(Number);
      const [destinationAreaIndex, destinationRowIndex, destinationColumnIndex] =
        destinationIds.map(Number);

      const sourceArea = newAreas[sourceAreaIndex];
      const sourceRow = sourceArea?.rows?.[sourceRowIndex];
      const sourceColumn = sourceRow?.columns?.[sourceColumnIndex];

      if (!sourceColumn) {
        return;
      }

      const destinationArea = newAreas[destinationAreaIndex];
      const destinationRow = destinationArea?.rows?.[destinationRowIndex];
      const destinationColumn = destinationRow?.columns?.[destinationColumnIndex];

      if (!destinationColumn) {
        return;
      }

      // Move the chart within the grid
      const [movedChart] = sourceColumn.charts.splice(source.index, 1);
      if (movedChart.chartType === "Count Card" && destinationRowIndex !== 0) {
        handleClick("error", "Count card can only be dropped in the first row");
        return;
      } else if (movedChart.chartType !== "Count Card" && destinationRowIndex === 0) {
        handleClick("error", "Charts other than cards can not be dropped in the first row");
        return;
      }
      if (destinationColumn.charts.length === 0) {
        destinationColumn.charts.splice(destination.index, 0, movedChart);
      } else {
        return;
      }
    }

    setChartList(newPreviousCharts);
    setDroppableAreas(newAreas);
  };

  const updateFilterParams = (widgetId, chartData, newParams) => {
    const updatedDroppables = updateDataBasedOnFilterParams(
      droppableAreas,
      widgetId,
      chartData,
      newParams
    );
    setDroppableAreas(updatedDroppables);
  };

  const backToGenerateDash = () => {
    navigate(-1);
  };

  const editChart = (areaId, rowId, columnId, chart) => {
    setEditDroppableArea({ areaId, rowId, columnId, widgetId: chart.widgetId });
    setEditChartOption(true);
    setQueryText(chart.queryText);
    setsaveDetails(true);

    let key, value;
    if (chart?.options?.params) {
      [key, value] = Object.entries(chart.options.params)[0] || [];
    }

    if (value && typeof value === "object") {
      setFilters(key);
    }
    if (key === "dateRange") {
      const variables = identifyDateRangeVariables(chart.queryText);

      if (variables?.startDate && variables?.endDate) {
        setFromDate(chart.options?.params.dateRange[variables?.startDate]);
        setToDate(chart.options?.params.dateRange[variables?.endDate]);
      }
    }

    setParams(chart.options?.params);
    setTitle(chart.title);
    setChartType(chart.chartType);

    const { aliasColumns } = parseSQLQuery(chart.queryText, chart.options?.params);

    const column = queryData.headers;
    const cols = !aliasColumns.includes(null) ? aliasColumns : column;
    const header = cols.map((col) => replaceUnderscores(col));
    setHeaders(header);

    if (chart.chartType === "Bar Chart" || chart.chartType === "Line Chart") {
      setXAxis(chart.options?.xAxis);
      setYAxis(chart.options?.yAxis);

      setSelectedColor(chart.options?.Colors[0]);
    } else if (chart.chartType === "Pie Chart") {
      setColors(chart.options?.Colors);
    } else {
      setSelectedColor(chart.options?.Colors[0]);
    }
    setchartTypeOptions(chart.options?.chartOptions);
    setQueryData(chart.queryData);
    setAddWidget(true);
    setOpen(true);
  };

  return (
    <GenerateDashBoardSkeleton>
      <Reveal>
        <MyDialog
          open={openDialog}
          onClose={handleCloseDialog}
          droppableAreas={droppableAreas}
          dashboardTitle={dashboardTitle}
          setDroppableAreas={setDroppableAreas}
          selectedDashboard={selectedDashboard}
          setSelectedDashboard={setSelectedDashboard}
        />
        <Grid container spacing={2}>
          <Grid size={{ xs: 6 }} alignContent="center">
            <i
              className="ri-arrow-left-line ri-xl"
              style={{ color: "#004c70", fontSize: "25px", cursor: "pointer" }}
              onClick={backToGenerateDash}
            />
          </Grid>
          <Grid container size={{ xs: 6 }} spacing={2} display="flex" justifyContent="flex-end">
            <Grid>
              <CustomButton className="btn--primary" onClick={handleOpenDialog} label={"Preview"} />
            </Grid>
            <Grid>
              <CustomButton
                className="btn--primary"
                onClick={saveCharts}
                label={
                  Object.keys(selectedDashboard).length === 0
                    ? "Create Dashboard"
                    : "Save Dashboard"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Paper elevation={3} style={{ padding: "16px", marginTop: "10px" }}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h4" style={{ color: "#004c70" }} gutterBottom>
              Dashboard Title
            </Typography>
            <TextField
              fullWidth
              label="Dashboard Title"
              value={dashboardTitle}
              error={Boolean(titleError)}
              helperText={titleError}
              onBlur={(e) => {
                setTitleTouched(true);
                validateDashboardTitle(e.target.value);
              }}
              onChange={(e) => {
                setDashboardTitle(e.target.value);
                if (titleTouched) {
                  validateDashboardTitle(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }} container spacing={2}>
            <Grid sx={{ marginTop: "10px" }}>
              <CustomButton
                className="btn--primary"
                onClick={() => {
                  setAddWidget(true);
                  setOpen(true);
                }}
                label={"Add Widget"}
              />
            </Grid>
            {saveChartsEnable && (
              <Grid sx={{ marginTop: "10px" }}>
                <CustomButton
                  className="btn--primary"
                  onClick={resetCharts}
                  label={"Reset All Charts"}
                />
              </Grid>
            )}
          </Grid>
          {addWidget && (
            <ModalUI
              visible={open}
              close={() => {
                setAddWidget(false);
                setEditChartOption(false);
                setOpen(false);
                handleReset();
              }}
              title={editChartOption ? "Edit Widget" : "Add Widget"}
              style={{
                width: "1000px",
                padding: "20px",
              }}
              component={
                <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                  <Grid size={{ xs: 12 }}>
                    <FormControl fullWidth>
                      <FormInputField
                        label="Query Text"
                        placeholder="Please enter your query"
                        type="text"
                        required
                        shrink={true}
                        multiline
                        rows={4}
                        value={queryText}
                        onChange={(e) => {
                          setsaveDetails(false);
                          setQueryText(e.target.value);
                        }}
                        size="big"
                      />
                    </FormControl>
                  </Grid>
                  <Grid container size={{ xs: 12 }}>
                    <Grid size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel id="filter-label">Filter</InputLabel>
                        <Select
                          labelId="filter-label"
                          value={filters}
                          onChange={(e) => setFilters(e.target.value)}
                        >
                          <MenuItem value="">No filter</MenuItem>
                          <MenuItem value="dateRange">Date Range</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {filters === "dateRange" && (
                      <Grid size={{ xs: 6, sm: 6 }}>
                        <FormInputField
                          style={{ width: "100%", marginBottom: "5px" }}
                          label="From Date"
                          name="fromDate"
                          type="date"
                          required
                          shrink={true}
                          value={fromDate}
                          error={!isDateValid}
                          errorText={
                            !isDateValid ? "From Date should be less than or equal to To Date" : ""
                          }
                          onChange={(e) => {
                            setsaveDetails(false);
                            setFromDate(e.target.value);
                          }}
                          size="big"
                        />
                        <FormInputField
                          style={{ width: "100%" }}
                          label="To Date"
                          name="toDate"
                          type="date"
                          required
                          shrink={true}
                          value={toDate}
                          error={!isDateValid}
                          errorText={
                            !isDateValid
                              ? "To Date should be greater than or equal to From Date"
                              : ""
                          }
                          onChange={(e) => {
                            setsaveDetails(false);
                            setToDate(e.target.value);
                          }}
                          size="big"
                        />
                      </Grid>
                    )}
                  </Grid>

                  {(checkedQuery || editChartOption) && (
                    <Grid size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel id="chart-type-label">Chart Types</InputLabel>
                        <Select
                          labelId="chart-type-label"
                          id="chart-type-select"
                          value={chartType}
                          onChange={(e) => {
                            setChartType(e.target.value);
                            if (e.target.value === "Pie Chart") setDefaultColors(queryData);
                          }}
                          fullWidth
                        >
                          {chartTypeOptions?.map((chart) => (
                            <MenuItem key={chart} value={chart}>
                              {chart}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {(chartType.includes("Line Chart") || chartType.includes("Bar Chart")) && (
                    <Grid size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel id="xAxis-label">X-axis</InputLabel>
                        <Select
                          labelId="xAxis-label"
                          required
                          value={xAxis}
                          onChange={(e) => setXAxis(e.target.value)}
                          style={{ marginBottom: "5px" }}
                        >
                          {headers.map((header) => (
                            <MenuItem key={header} value={header}>
                              {header}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="yAxis-label">Y-axis</InputLabel>
                        <Select
                          labelId="yAxis-label"
                          required
                          value={yAxis}
                          onChange={(e) => setYAxis(e.target.value)}
                        >
                          {headers.map((header) => (
                            <MenuItem key={header} value={header}>
                              {header}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {chartType !== "" && (
                    <>
                      <Grid size={{ xs: 6 }}>
                        {chartType === "Pie Chart" ? (
                          <>
                            {queryData.length !== 0 ? (
                              <Grid container spacing={1} className="color-input-field">
                                {Object.keys(colors).map((colorObj, index) => {
                                  return (
                                    <Grid
                                      container
                                      size={{ xs: 12 }}
                                      key={index}
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Grid size={{ xs: 5 }}>{colorObj}</Grid>
                                      <Grid size={{ xs: 5 }}>
                                        <span className="color-chip-pie">
                                          <span
                                            className="color-swatch"
                                            style={{
                                              backgroundColor: colors[colorObj],
                                              display: "inline-block",
                                              width: 20,
                                              height: 20,
                                              borderRadius: "50%",
                                              marginRight: 8,
                                            }}
                                          ></span>
                                          {colors[colorObj]}
                                        </span>
                                      </Grid>
                                      <Grid size={{ xs: 2 }}>
                                        <input
                                          type="color"
                                          value={colors[colorObj]}
                                          onChange={(e) =>
                                            setColor(e.target.value, colorObj, index)
                                          }
                                          style={{ width: "100%" }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            ) : (
                              <div style={{ marginTop: "15px", marginLeft: "5px" }}>
                                No data to display.
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="color-input-field">
                            <div className="color-chips">
                              <span className="color-chip">
                                <span
                                  className="color-swatch"
                                  style={{ backgroundColor: selectedColor }}
                                ></span>
                                {selectedColor}
                              </span>
                            </div>
                            <input
                              type="color"
                              value={selectedColor}
                              onChange={(e) => setSelectedColor(e.target.value)}
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid size={{ xs: 6 }}>
                        <TextField
                          fullWidth
                          label="Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          style={{ marginBottom: "5px" }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid container size={{ xs: 12 }} spacing={2}>
                    {(!checkedQuery || (!checkQuery && editChartOption)) && (
                      <Grid item>
                        <CustomButton
                          className="btn--primary"
                          onClick={handleCheckQuery}
                          label={"Execute query"}
                        />
                      </Grid>
                    )}

                    {checkedQuery && !saveDetails && (
                      <Grid item>
                        <CustomButton
                          className="btn--primary"
                          onClick={handleGenerateChart}
                          label={"Generate Chart"}
                        />
                      </Grid>
                    )}
                    {saveDetails && (
                      <Grid item>
                        <CustomButton
                          className="btn--primary"
                          onClick={handleGenerateChart}
                          label={"Save Details"}
                        />
                      </Grid>
                    )}
                    {!editChartOption && !saveDetails && (
                      <Grid item>
                        <CustomButton
                          className="btn--primary"
                          onClick={handleReset}
                          label={"Reset"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
            />
          )}
        </Paper>
        <DragDropContext onDragEnd={onDragEnd}>
          {showChart && (
            <Droppable droppableId="chartList" direction="horizontal">
              {(provided) => (
                <Grid container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                  {chartList.map((chart, index) => (
                    <Draggable key={chart.widgetId} draggableId={chart.widgetId} index={index}>
                      {(provided) => (
                        <Grid
                          size={{ xs: 6, sm: 6, md: 4 }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="chart-container"
                        >
                          <Grid sx={{ padding: 2, cursor: "move" }}>
                            <Box display="flex" justifyContent="flex-end">
                              <CustomButton
                                className="btn--primary"
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  border: "none",
                                  boxShadow: "none",
                                  color: "#000000",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeChartFromChartList(chart.widgetId)}
                              >
                                <span style={{ marginLeft: "auto" }}>X</span>
                              </CustomButton>
                            </Box>
                            <ChartDisplay querydata={chart} isChartList={true} />
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          )}

          {droppableAreas.map((area, areaIndex) => (
            <Reveal key={area.id} style={{ marginTop: "15px" }}>
              {area.rows.map((row, rowIndex) => (
                <Reveal key={row.rowId}>
                  <Grid container spacing={1} style={{ marginTop: "5px" }}>
                    <Grid size={{ xs: 12 }}>
                      <Paper elevation={3} sx={{ padding: 0.5, borderRadius: 2 }}>
                        <Grid container spacing={1}>
                          {row.columns.map((column, columnIndex) => (
                            <Droppable
                              key={column.columnId}
                              droppableId={`${areaIndex}-${rowIndex}-${columnIndex}`}
                              direction="vertical"
                              isDropDisabled={column.charts.length > 0}
                            >
                              {(provided) => (
                                <Grid
                                  size={{ xs: 12, sm: 6, md: 4 }}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <Paper
                                    elevation={1}
                                    sx={{
                                      padding: 0,
                                      minHeight: "100px",
                                      borderRadius: 2,
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <i
                                        className="ri-edit-fill ri-xl icon-primary-blue"
                                        onClick={() =>
                                          editChart(
                                            area.id,
                                            row.rowId,
                                            column.columnId,
                                            column.charts[0]
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                      <CustomButton
                                        className="btn--primary"
                                        style={{
                                          backgroundColor: "transparent",
                                          padding: 0,
                                          border: "none",
                                          boxShadow: "none",
                                          color: "#000000",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          removeColumn(area.id, row.rowId, column.columnId)
                                        }
                                        label={"X"}
                                      />
                                    </Box>
                                    {column.charts.map((chart, chartIndex) => (
                                      <Draggable
                                        key={chart.widgetId}
                                        draggableId={chart.widgetId}
                                        index={chartIndex}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Grid sx={{ padding: 0, cursor: "move" }}>
                                              <ChartDisplay
                                                querydata={chart}
                                                onFilterParamsChange={(chartData, newParams) =>
                                                  updateFilterParams(
                                                    chart.widgetId,
                                                    chartData,
                                                    newParams
                                                  )
                                                }
                                              />
                                            </Grid>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Paper>
                                </Grid>
                              )}
                            </Droppable>
                          ))}
                          <Grid
                            size={{ xs: 4 }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CustomButton
                              className="btn--secondary-light"
                              onClick={() => addColumn(area.id, row.rowId)}
                              label={"+ Column"}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="5px"
                  >
                    <Grid item>
                      <CustomButton
                        className="btn--secondary-light"
                        onClick={() => addRow(area.id, rowIndex)}
                        label={"+ Row"}
                      />
                    </Grid>
                    <Grid item>
                      <CustomButton
                        className="btn--secondary-light"
                        onClick={() => deleteRow(area.id, rowIndex)}
                        label={"- Above Row"}
                      />
                    </Grid>
                  </Grid>
                </Reveal>
              ))}
              {area.rows.length === 0 && (
                <Grid item display="flex" justifyContent="flex-end">
                  <CustomButton
                    className="btn--secondary-light"
                    onClick={() => addRow(area.id, -1)}
                    label={"+ Row"}
                  />
                </Grid>
              )}
            </Reveal>
          ))}
        </DragDropContext>
      </Reveal>
    </GenerateDashBoardSkeleton>
  );
};

export const GenerateDashBoardSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={125}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={125}
          />
        </Grid>
        <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"end"}>
          <Grid size={{ xs: 3 }} display={"flex"}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={30}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateEditDashboard;
